import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useSignUp } from "../../../../contexts/SignUpContext";
import { useAuth } from "../../../../contexts/UserContext";
import api from "../../../../utils/api";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { Input } from "../../../Input/Input";
import "../ResendCode.sass";

export const ResendEmailCode = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useAuth()
  const { signUpInfo, setSignUpInfo } = useSignUp();

  /**
   * The line bellow is occasionally causing an loop of requests to user endpoint.
   * This line fix the redirect problem that we have when the user click in the resend code button but 
   * because the error mentioned above we need to comment this line.
   */
  // if (checkAuth() === null) { 
  if (!user) {
    console.log("User is not logged in")
    return (
      <Navigate to="/login" />
    )
  }


  const resendCode = () => {

    setIsLoading(true);

    api.post("/api/user/resend/email", { email: signUpInfo.email })
      .then(
        (res) => {
          navigate("/signup/code")
        }
      )
      .catch(
        (err) => {
          if (err.response.data.detail === "INVALID_EMAIL") {
            setEmailErrorMessage(t("register.errors.invalidEmail"));
            setEmailError(true);
          }

          if (err.response.data.detail === "USER_ALREADY_EXISTS") {
            setEmailErrorMessage(t("register.errors.userAlreadyExists"));
            setEmailError(true);
          }

          if (err.response.data.detail === "ALREADY_VERIFIED") {
            setEmailErrorMessage(t("register.errors.userAlreadyVerified"));
            setEmailError(true);
          }
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <h1 className="title">{t('register.emailVerification')}</h1>
      <span className="subtitle">{t("register.description.correctEmailVerification")}</span>
      <Input
        label={t("register.email")}
        id="email_resend"
        type="email"
        required={true}
        autoComplete="off"
        onChange={(e) => { setSignUpInfo(signUpInfo => { signUpInfo.email = e.target.value }); setEmailError(false) }}
        // value={user.email}
        value={signUpInfo.email}
        placeholder={t("register.placeholder.email")}
        error={emailError}
        errorMessage={emailErrorMessage}
      />
      <div className="confirm-code">
        <ButtonPrimary onClick={resendCode} children={t("register.resendCode")} id="confirm-btn" className='signup-primary-btn' isLoading={isLoading} ></ButtonPrimary>
      </div>
    </>
  )
};
