import { Dialog } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaTimes } from "react-icons/fa"
import { useParams } from "react-router-dom"
import { SwiperSlide } from "swiper/react"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { TextArea } from "../../../Input/TextArea"
import { AddImageSlider } from "../../../Slider/AddImageSlider"
import "./ActivityModal.sass"
import { useAlertContext } from "../../../../contexts/AlertContext"

export const ActivityModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

  const { t } = useTranslation()

  const [imageError, setImageError] = useState<boolean>(false)
  const [filesError, setFilesError] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<boolean>(false)

  const { presentAlert } = useAlertContext()

  const investmentToken = useParams<{ id: string }>().id

  const [activityMessage, setActivityMessage] = useState<string>("")

  const [images, setImages] = useState<Array<ServerFile | File>>([])
  const [documents, setDocuments] = useState<Array<ServerFile | File> | null>([])

  const addImages = (e: HTMLInputElement) => {
    setImageError(false)

    let oldArray = images || [];

    if (e.files && e.files.length > 0) {

      let array = [];

      for (let index = 0; index < e.files.length; index++) {
        array.push(e.files[index])
      }

      oldArray = oldArray.concat(array);
    }

    setImages(oldArray)
  }

  const addFiles = (e: HTMLInputElement) => {
    let oldArray = documents || [];

    if (e.files && e.files.length > 0) {

      let array = [];

      for (let index = 0; index < e.files.length; index++) {
        array.push(e.files[index])
      }

      oldArray = oldArray.concat(array);
    }

    setDocuments(oldArray)
  }

  const deleteImage = (index: number) => {
    let arrayCopy = structuredClone(images);
    arrayCopy.splice(index, 1);
    setImages(arrayCopy);
  }

  const deleteFile = (index: number) => {
    let arrayCopy = structuredClone(documents);
    if (arrayCopy !== null) {
      arrayCopy.splice(index, 1);
    }
    setDocuments(arrayCopy);
  }

  const saveNewActivity = () => {
    if (!activityMessage) {
      setMessageError(true)
      return
    } else {
      api.post(`/api/investment/activity/${investmentToken}`, { "message": activityMessage, "type": "Public" })
        .then((response) => {
          presentAlert(
            {
              title: t("dashboard.investments.activityHistoric.activityModal.activityAlertTitle"),
              text: t("dashboard.investments.activityHistoric.activityModal.activityAlertText"),
              type: "success",
              timeout: 3000
            }
          )
          handleClose()
        })
    }
  }

  return (

    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { "borderRadius": "12px" }
      }}
      maxWidth={"md"}
      scroll="body"
      fullWidth
    >
      <div className="investment-modal-container">
        <button className="close-button" onClick={() => handleClose()}>
          <FaTimes className="icon" />
        </button>
        <div className="left-container">

          <div className="message-container">
            <h1 className="title">{t("dashboard.investments.activityHistoric.activityModal.investmentActivity")}</h1>
            <TextArea
              label={t("dashboard.investments.activityHistoric.activityModal.message")}
              placeholder={t("dashboard.investments.activityHistoric.activityModal.messagePlaceholder")}
              onChange={(e) => { setActivityMessage(e.target.value); setMessageError(false) }}
              value={activityMessage}
              error={messageError}
              errorMessage={t("dashboard.investments.activityHistoric.activityModal.messageError")}
              required
            />
          </div>

          <AddImageSlider
            label={t("dashboard.investments.activityHistoric.activityModal.pictures")}
            images={images}
            addFileCallback={addImages}
            errorState={imageError}
            errorSetState={setImageError}
            documentSliderComponent={
              images.map((data, index) =>
                <SwiperSlide key={index} data-testid="image-slider-test">
                  <div className='reg-inv-images-slide-viewer relative'>
                    <img className='img-blur' src={"id" in data ? `/api/investment/document/${data.id}` : (URL.createObjectURL(data))} alt="" />
                    <img src={"id" in data ? `/api/investment/document/${data.id}` : (URL.createObjectURL(data))} alt="investment" className='img-view' />
                    <button className="delete-reg-inv-image" onClick={() => { deleteImage(index) }}>
                      <FaTimes />
                    </button>
                  </div>
                </SwiperSlide>
              )
            }
          />

          <DocumentsInput
            label={t("dashboard.investments.activityHistoric.activityModal.documents")}
            documents={documents}
            addFileCallback={addFiles}
            errorState={filesError}
            errorSetState={setFilesError}
            documentsComponent={
              documents !== null &&
              documents.map((file: File | ServerFile, index: number) => (
                <InvestmentDocument file={file} index={index} key={index} investmentContext={documents} setInvestmentContext={setDocuments} deleteFileCallback={deleteFile} />
              ))
            }
          />
        </div>

        <div className="right-container">
          <ButtonPrimary
            children={t("dashboard.investments.activityHistoric.activityModal.save")}
            onClick={() => saveNewActivity()}
          />
        </div>
      </div>
    </Dialog>
  )
}