import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { Updater, useImmer } from "use-immer";
import { StepProps } from "../components/ProgressBar";
import api from "../utils/api";
import { Utils } from "../utils/utils";
import { ServerFile, ServerInvestment, ServerPropose, useInvestmentContext } from "./InvestmentContext";
import { ValidatePages } from "./ValidateInvestment";


export interface ProposeProps {
  id: string
  title: string
  situation: number
  stage: number
  available_value: number
  rentability: number | null
}

export interface NewInvestmentProps {
  title: string
  description: string
  investment_type: number | null
  category: number
  links: string[]
}

export interface InvestmentImagesProps {
  images: Array<File | ServerFile>
}

export interface RentabilityProps {
  total: string
  minCapture: string
  minimumAport: string
  userParticipation: string
  valueJustification: string
  expectedValue: string
  overcomeValue: string
  pessimistValue: string
  expectedDate: string | null
  overcomeDate: string | null
  pessimistDate: string | null
  rentabilityJustification: string
  documents: Array<File | ServerFile> | null
}

interface Projection {
  projection_type: number
  profitability: string
  term: number
}

export interface Stage {
  title: String | null,
  expected_date: Date | null
}

export interface Theme {
  theme: String | null,
  description: String | null
}

export interface ScheduleProps {
  stages: Stage[]
  themes: Theme[]
}

export interface InvestmentSecurityProps {
  securityType: number | null
  description: string
  documents: Array<File | ServerFile>
}

export interface PublicDocumentsProps {
  documents: Array<File | ServerFile>
}


interface CreateInvestmentContextProps {
  newInvestment: NewInvestmentProps,
  setNewInvestment: Updater<NewInvestmentProps>,
  investmentImages: InvestmentImagesProps,
  setInvestmentImages: Updater<InvestmentImagesProps>,
  investmentValues: RentabilityProps,
  setInvestmentValues: Updater<RentabilityProps>,
  investmentSecurity: InvestmentSecurityProps,
  setInvestmentSecurity: Updater<InvestmentSecurityProps>,
  investmentSchedule: ScheduleProps,
  setInvestmentSchedule: Updater<ScheduleProps>,
  publicDocuments: PublicDocumentsProps,
  setPublicDocuments: Updater<PublicDocumentsProps>,
  progress: number,
  setProgress: Dispatch<SetStateAction<number>>,
  stepsProgress: Array<StepProps>,
  setStepsProgress: Dispatch<SetStateAction<Array<StepProps>>>,
  isDataLoading: boolean,
  investmentId: string | null,
  setInvestmentId: Dispatch<SetStateAction<string | null>>,
  createInvestmentStep: number,
  getAndSetInvestment: (token?: string) => void,
  checkNewInvestment: (newInvestment: NewInvestmentProps) => boolean,
  checkInvestmentValues: (investmentValues: RentabilityProps) => boolean,
  checkInvestmentSchedule: (investmentSchedule: ScheduleProps) => boolean,
  checkInvestmentSecurity: (investmentSecurity: InvestmentSecurityProps) => boolean,
  setServerInvestment: (investment: ServerInvestment) => void
}


export const CreateInvestmentContext = createContext({} as CreateInvestmentContextProps)

export const CreateInvestmentProvider = ({ children }: { children: ReactNode }) => {
  const [progress, setProgress] = useState(1)
  const [createInvestmentStep, setCreateInvestmentStep] = useState(0)
  const [stepsProgress, setStepsProgress] = useState<Array<StepProps>>([]);
  const [progressErrors, setProgressErrors] = useState<Array<number>>([])

  useEffect(() => {
    let stepsHrefs: Array<string> = ['../', 'images/', 'values/', 'schedule/', 'security/', 'public-documents/'];
    setStepsProgress(Array.from({ length: 6 - 1 + 1 }, (e, i) => i + 6).map((_, index) => {
      let stepProp: StepProps = {
        step: index + 1,
        completed: createInvestmentStep > index,
        // completed: createInvestmentStep > (index + 1), This is the old array from register
        error: progressErrors.includes(index + 1),
        href: stepsHrefs[index]
      }
      return stepProp
    }))
  }, [progress, createInvestmentStep, progressErrors]);

  const [investmentId, setInvestmentId] = useState<string | null>(null)

  const [newInvestment, setNewInvestment] = useImmer<NewInvestmentProps>({
    title: "",
    description: "",
    links: [] as string[]
  } as NewInvestmentProps)

  const [investmentImages, setInvestmentImages] = useImmer<InvestmentImagesProps>({
    images: [] as File[]
  })

  const [investmentValues, setInvestmentValues] = useImmer<RentabilityProps>({
    total: "",
    minCapture: "",
    minimumAport: "",
    userParticipation: "",
    valueJustification: "",
    expectedValue: "",
    overcomeValue: "",
    pessimistValue: "",
    expectedDate: null,
    overcomeDate: null,
    pessimistDate: null,
    rentabilityJustification: "",
    documents: []
  } as RentabilityProps)

  const [investmentSecurity, setInvestmentSecurity] = useImmer<InvestmentSecurityProps>({ description: "", documents: [] as File[] } as InvestmentSecurityProps)

  const [investmentSchedule, setInvestmentSchedule] = useImmer<ScheduleProps>({
    stages: [] as Object[] | null,
    themes: [] as Object[]
  } as ScheduleProps)

  const [publicDocuments, setPublicDocuments] = useImmer<PublicDocumentsProps>({ documents: [] as File[] } as PublicDocumentsProps)

  const { investment, setInvestment } = useInvestmentContext()

  const checkNewInvestment = (newInvestment: NewInvestmentProps): boolean => {
    let modifiedCount: number = 0

    if (newInvestment.title !== investment.title) {
      modifiedCount++
    }
    if (newInvestment.description !== investment.description) {
      modifiedCount++
    }
    if (newInvestment.investment_type !== investment.investment_type) {
      modifiedCount++
    }
    if (newInvestment.links !== investment.links) {
      modifiedCount++
    }
    if (newInvestment.category !== investment.category) {
      modifiedCount++
    }

    if (modifiedCount <= 0) {
      return false
    } else {
      return true
    }
  }

  const checkInvestmentValues = (investmentValues: RentabilityProps): boolean => {
    let modifiedCount: number = 0

    if (investment.investment_values === null) {
      return true
    }

    if (Utils.realFormat(investment.investment_values?.total_value, 2) !== investmentValues.total) {
      modifiedCount++
    }
    if (Utils.realFormat(investment.investment_values?.minimum_aport, 2) !== investmentValues.minimumAport) {
      modifiedCount++
    }
    if (Utils.realFormat(investment.investment_values?.minimum_foundation, 2) !== investmentValues.minCapture) {
      modifiedCount++
    }
    if (Utils.realFormat(investment.investment_values?.participation, 2) !== investmentValues.userParticipation) {
      modifiedCount++
    }
    if (investment.investment_values?.values_justification !== investmentValues.valueJustification) {
      modifiedCount++
    }
    if (investment.investment_values?.projections_justifications !== investmentValues.rentabilityJustification) {
      modifiedCount++
    }
    if (Utils.realFormat(investment.investment_values?.projections.find((element: Projection) => element.projection_type === 0)?.profitability, 2) !== investmentValues.expectedValue) {
      modifiedCount++
    }
    if (Utils.realFormat(investment.investment_values?.projections.find((element: Projection) => element.projection_type === 1)?.profitability, 2) !== investmentValues.overcomeValue) {
      modifiedCount++
    }
    if (Utils.realFormat(investment.investment_values?.projections.find((element: Projection) => element.projection_type === 2)?.profitability, 2) !== investmentValues.pessimistValue) {
      modifiedCount++
    }
    let expectedTerm = investment.investment_values?.projections.find((element: Projection) => element.projection_type === 0)?.term
    if (expectedTerm !== null && expectedTerm !== undefined && expectedTerm.toString() !== investmentValues.expectedDate) {
      modifiedCount++
    }
    let overcomeTerm = investment.investment_values?.projections.find((element: Projection) => element.projection_type === 1)?.term
    if (overcomeTerm !== null && overcomeTerm !== undefined && overcomeTerm.toString() !== investmentValues.overcomeDate) {
      modifiedCount++
    }
    let pessimistTerm = investment.investment_values?.projections.find((element: Projection) => element.projection_type === 2)?.term
    if (pessimistTerm !== null && pessimistTerm !== undefined && pessimistTerm.toString() !== investmentValues.pessimistDate) {
      modifiedCount++
    }

    if (modifiedCount <= 0) {
      return false
    } else {
      return true
    }
  }

  const checkInvestmentSchedule = (investmentSchedule: ScheduleProps): boolean => {
    let modifiedCount: number = 0

    if (JSON.stringify(investment.investment_schedule?.stages) !== JSON.stringify(investmentSchedule.stages)) {
      modifiedCount++
    }
    if (JSON.stringify(investment.investment_schedule?.operation_details) !== JSON.stringify(investmentSchedule.themes)) {
      modifiedCount++
    }

    if (modifiedCount <= 0) {
      return false
    } else {
      return true
    }

  }

  const checkInvestmentSecurity = (investmentSecurity: InvestmentSecurityProps) => {
    let modifiedCount: number = 0

    if (investment.investment_security?.security_type !== investmentSecurity.securityType) {
      modifiedCount++
    }
    if (investment.investment_security?.description !== investmentSecurity.description) {
      modifiedCount++
    }

    if (modifiedCount <= 0) {
      return false
    } else {
      return true
    }
  }

  const setServerInvestment = (investment: ServerInvestment) => {

    setInvestment(investment)

    setInvestmentId(investment.id)


    setNewInvestment({
      title: investment["title"],
      description: investment["description"],
      investment_type: investment["investment_type"],
      category: investment["category"],
      links: investment["links"]
    })

    setCreateInvestmentStep(1)

    if (investment["investment_documents"] && investment["investment_documents"].length > 0 && investment["investment_documents"].filter((file: ServerFile) => file.category === "IMAGES").length > 0) {
      setInvestmentImages({ images: [...investment["investment_documents"].filter((file: ServerFile) => file.category === "IMAGES")] })
      setCreateInvestmentStep(2)
    }


    if (investment["investment_values"]) {
      setInvestmentValues({
        total: Utils.realFormat(investment["investment_values"]["total_value"], 2),
        minCapture: Utils.realFormat(investment["investment_values"]["minimum_foundation"], 2),
        minimumAport: Utils.realFormat(investment["investment_values"]["minimum_aport"], 2),
        userParticipation: Utils.realFormat(investment["investment_values"]["participation"], 2),
        valueJustification: investment["investment_values"]["values_justification"],
        expectedValue: Utils.realFormat(investment["investment_values"]["projections"][0]["profitability"], 2), // TODO: Após arrumar a forma como a API está retornando esses dados, lembrar de formatar ela aqui. 
        overcomeValue: Utils.realFormat(investment["investment_values"]["projections"][1]["profitability"], 2),
        pessimistValue: Utils.realFormat(investment["investment_values"]["projections"][2]["profitability"], 2),
        expectedDate: investment["investment_type"] !== 1 ? investment["investment_values"]["projections"][0].term.toString() : null,
        overcomeDate: investment["investment_type"] !== 1 ? investment["investment_values"]["projections"][1].term.toString() : null,
        pessimistDate: investment["investment_type"] !== 1 ? investment["investment_values"]["projections"][2].term.toString() : null,
        rentabilityJustification: investment["investment_values"]["projections_justifications"],
        documents: investment["investment_documents"] ? investment["investment_documents"].filter((file: ServerFile) => file.category === "VALUES") : []
      })
      setCreateInvestmentStep(3)
    }

    if (investment["investment_schedule"] && investment["investment_schedule"]["stages"].length > 0) {
      setInvestmentSchedule({
        stages: [...investment["investment_schedule"]["stages"]],
        themes: [...investment["investment_schedule"]["operation_details"]]
      })
      setCreateInvestmentStep(4)
    }

    if (investment["investment_security"]) {
      setInvestmentSecurity({
        securityType: investment["investment_security"]["security_type"],
        description: investment["investment_security"]["description"],
        documents: investment["investment_documents"] ? investment["investment_documents"].filter((file: ServerFile) => file.category === "SECURITY") : []
      })
      setCreateInvestmentStep(5)
    }

    if (investment["investment_documents"] && investment["investment_documents"].length > 0 && investment["investment_documents"].filter((file: ServerFile) => file.category === "PUBLIC").length > 0) {
      setPublicDocuments({ documents: investment["investment_documents"].filter((file: ServerFile) => file.category === "PUBLIC") })
      setCreateInvestmentStep(6)
    }
  }

  const getReviewErrors = (reviews: ValidatePages[] | null) => {
    reviews?.forEach((review: ValidatePages) => {
      if (review.accepted === false) {
        setProgressErrors(prevState => {
          return [...prevState, review.page_id]
        })
      }
    })
  }

  const investmentToken = useParams<{ id: string }>().id

  const { isLoading } = useQuery("investment",
    () => investmentToken !== "null" ? api.get<ServerPropose>(`/api/investment/propose/${investmentToken}`)
      .then((response) => { setServerInvestment(response.data); getReviewErrors(response.data.reviews) })
      .catch((error) => {
        if (error.response.data.detail === "INVESTMENT_NOT_FOUND") {
          return <Navigate to="/dashboard/my-proposes" />
        }
      }) : null
  )

  const getAndSetInvestment = (token?: string) => {
    if (investmentToken !== "null" || token) {
      api.get<ServerPropose>(`/api/investment/propose/${investmentToken === "null" ? token : investmentToken}`)
        .then((response) => {
          setServerInvestment(response.data); setInvestmentId(response.data.id)
        }).catch(
          (error) => { console.log(error) }
        )
    }
  }

  return (
    <CreateInvestmentContext.Provider
      value={{
        progress: progress,
        setProgress: setProgress,
        investmentId: investmentId,
        setInvestmentId: setInvestmentId,
        newInvestment: newInvestment,
        setNewInvestment: setNewInvestment,
        investmentImages: investmentImages,
        setInvestmentImages: setInvestmentImages,
        investmentValues: investmentValues,
        setInvestmentValues: setInvestmentValues,
        investmentSecurity: investmentSecurity,
        setInvestmentSecurity: setInvestmentSecurity,
        investmentSchedule: investmentSchedule,
        setInvestmentSchedule: setInvestmentSchedule,
        publicDocuments: publicDocuments,
        setPublicDocuments: setPublicDocuments,
        checkNewInvestment: checkNewInvestment,
        checkInvestmentValues: checkInvestmentValues,
        checkInvestmentSchedule: checkInvestmentSchedule,
        checkInvestmentSecurity: checkInvestmentSecurity,
        setServerInvestment: setServerInvestment,
        isDataLoading: isLoading,
        getAndSetInvestment: getAndSetInvestment,
        createInvestmentStep: createInvestmentStep,
        stepsProgress: stepsProgress,
        setStepsProgress: setStepsProgress
      }}
    >
      {children}
    </CreateInvestmentContext.Provider>
  )
}

export const useCreateInvestment = () => {
  return useContext(CreateInvestmentContext)
}