import { ReactNode, createContext, useContext } from "react";
import { Updater, useImmer } from "use-immer";
import { ValidatePages } from "./ValidateInvestment";

export interface ServerFile {
  id: string
  name: string
  type: string
  category: "IMAGES" | "SECURITY" | "PUBLIC" | "VALUES"
}

export interface InvestmentResponse {
  token: string
}

export interface ServerInvestment {
  id: string
  title: string
  description: string
  investment_type: number
  category: number
  links: string[]
  code: string
  status: number
  date_start_raising: Date | undefined
  date_end_raising: Date | undefined
  investment_values: {
    total_value: number
    minimum_foundation: number
    minimum_aport: number
    participation: number
    values_justification: string
    raised_value: number
    projections: {
      projection_type: number
      profitability: string
      monthly_profit: string
      term: number
    }[]
    projections_justifications: string
  } | null
  investment_schedule: {
    stages: {
      index: number
      title: string
      expected_date: Date
    }[]
    operation_details: {
      theme: string
      description: string
    }[]
  } | null
  investment_security: {
    security_type: number
    description: string
  } | null
  investment_documents: ServerFile[] | null
}

export interface ServerPropose extends ServerInvestment {
  reviews: ValidatePages[]
}

interface InvestmentContextProps {
  investment: ServerInvestment | ServerPropose
  setInvestment: Updater<ServerInvestment | ServerPropose>
}

export const InvestmentContext = createContext({} as InvestmentContextProps)

export const InvestmentContextProvider = ({ children }: { children: ReactNode }) => {

  const [investment, setInvestment] = useImmer<ServerInvestment>({} as ServerInvestment)

  return (
    <InvestmentContext.Provider
      value={
        {
          investment: investment,
          setInvestment: setInvestment
        }
      }
    >
      {children}
    </InvestmentContext.Provider>
  )
}

export const useInvestmentContext = () => {
  return useContext(InvestmentContext)
}