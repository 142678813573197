import { InvestmentCardProps } from "../../InvestmentCard"
import { SmartLink } from "../../../SmartLink"

import "./Links.sass"
import { useTranslation } from "react-i18next"

/**
 * Optional Investment Links session
 */
export const Links = ({ links }: { links: string[] }) => {
  const { t } = useTranslation()

  return (
    <>
      {
        links.length > 0 && (
          <div className="inv_links">
            <h2>{t("dashboard.links")}</h2>
            <div className="flex gap-2">
              {
                links.map((link, index) => <SmartLink link={link} key={index} />)
              }
            </div>
          </div>
        )}
    </>
  )
}