import "./ProgressLine.sass"

interface ProgressLineProps {
  value: number,
  color: "green" | "blue" | "red",
  withSpan?: boolean
}

export const ProgressLine = ({ value, color, withSpan = false }: ProgressLineProps) => {
  return (
    <div className="progress-line-container">
      <div className={`progress-line-active animate-pulse 
      ${color === "green" ? "bg-green-default" :
          color === "red" ? "bg-red-500" : "bg-blue-500"}`}
        style={{ width: `${value}%` }}>
        {withSpan &&
          <span className="progress-line-value">{value + "%"}</span>
        }
      </div>
    </div>
  )
}