import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FaCaretDown } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { Column, useTable } from "react-table"
import { ProposeProps } from "../../../../contexts/CreateInvestment"

import "./ProposesTable.sass"

export interface ProposesTableProps {
  data: ProposeProps[]
  columns: Column[]
  tableTitle: string
}

export const ProposesTable = ({ data, columns, tableTitle }: ProposesTableProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const proposes = useMemo(() => data.map(investment => ({
    ...investment,
    title: investment.title,
    situation: t(`dashboard.investments.situation.${investment.situation}`),
    stage: t(`dashboard.investments.stage.${investment.stage}`),
    rentability: investment.rentability !== null && `${investment.rentability.toFixed(2)}%`
  })), [data, t])

  const situationClasses = useMemo(() => ["gray", "blue", "yellow", "orange", "red", "blue", "green", "yellow", "green"], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: proposes })

  function selectInvestment(investment: any) {
    navigate(`/investment/${investment.id}/create/`)
  }

  return (
    <>
      <div className="table-title-text">
        <h1 className="title">{tableTitle}</h1>
      </div>
      <div className="horizontal-wrapper">
        <table {...getTableProps()} className="table-container">
          <thead className="table-head">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="head-row">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={`head-col-container ${column.id === "title" ? "name-col" : column.id === "stage" ? "step-col" : column.id === "situation" ? "situation-col" : ""}`}>
                    <div className={`head-col-text`}>{column.render("Header")} <FaCaretDown /></div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-body">
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="body-row">
                  {row.cells.map(cell => {
                    return (
                      <td data-testid="cell" role={"cell"} {...cell.getCellProps()} className={`body-col ${cell.column.id === "title" ? "body-name-col" : ""}`} onClick={(e) => { e.stopPropagation(); selectInvestment(cell.row.original) }} >
                        <div className={
                          `body-col-text ${(cell.column.id === "situation" || cell.column.id === "author") ? "body-progress-text" :
                            cell.column.id === "title" ? "body-title-text" :
                              cell.column.id === "stage" ? "body-step-text" : ""
                          }
                          `}>

                          {cell.column.id === "stage" && data[index].stage >= 0 &&
                            <div className="step-circle">{data[index].stage}</div>
                          }

                          <div className={`                          
                          ${situationClasses[data[index].situation + 1]}
                          ${cell.column.id === "situation" ? "body-progress-div" :
                              cell.column.id === "stage" ? "body-step-div" :
                                cell.column.id === "title" ? "body-title-div" : ""}
                          `}>
                            {cell.column.id === "stage" &&
                              <span className="text-xs text-gray-500">01/01/2022</span>
                            }
                            {cell.render("Cell")}
                          </div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}