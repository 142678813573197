import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import '../Input.sass';

interface TextAreaProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  /**
   * Html atributte id to input.
   */
  id?: string
  /**
   * Iput label, helps on accessibility of the project as well.
   */
  label?: string
  /**
   * onChange event.
   */
  onChange?: (e: any) => void | any
  /**
   * Html atributte autoComplete to input, define how browser should auto complete the field.
   */
  autoComplete?: string | undefined
  /**
   * Define the value of input.
   */
  value?: any
  /**
   * Define the value to show in input before enter any value.
   */
  placeholder?: any
  /**
   * Html atribbute className, define the class name of the input.
   */
  className?: string
  /**
   * Is a text that is under the input element, can be used for describe the field, drescribe constraints, etc. 
   */
  span?: string | ReactNode
  /**
   * Input error mode.
   */
  error?: boolean
  /**
   * Is a red error message that is under the input element, can be used for alert the user about something wrong.
   */
  errorMessage?: string
};

/**
 * Text default with labels and details span bellow
 * 
 * @author Alexsandro Di G. Junior
 * @since 2022-02-29
 */
export const TextArea = ({ id, label, onChange, autoComplete, value, placeholder, className, span, required, errorMessage, error, ...attr }: TextAreaProps) => {

  return (
    <div data-testid="textarea-test" className={`textarea-default ${className ? className : ''} ${error ? 'input-default-error' : ''}`}>
      {
        label !== undefined && <label htmlFor={id}>{label}{required === true ? "*" : ''}</label>
      }
      <div className='textarea-default-group'>
        <textarea
          id={id}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          {...attr}

          className="rounded-md"
        />
      </div>
      {
        span !== undefined && <span>{span}</span>
      }
      {
        errorMessage !== undefined && <span className="input-error-message">{errorMessage}</span>
      }
    </div>
  );
};