import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react"
import { Updater, useImmer } from "use-immer"
import { ServerInvestment } from "./InvestmentContext"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import api from "../utils/api"
import { StepProps } from "../components/ProgressBar"

interface InvestProps {
  currentBalance: number,
  amountInvested: string,
  expectedReturn: number,
  totalResult: number,
  percentage: number,
}

interface InvestContextProps {
  investInfo: InvestProps
  setInvestInfo: Updater<InvestProps>
  investment: ServerInvestment
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  stepsProgress: Array<StepProps>
  setStepsProgress: Dispatch<SetStateAction<Array<StepProps>>>
}

export const InvestContext = createContext({} as InvestContextProps)

export const InvestContextProvider = ({ children }: { children: ReactNode }) => {

  const [investment, setInvestment] = useImmer({} as ServerInvestment)

  const [progress, setProgress] = useImmer(0)

  const id = useParams().id

  const [stepsProgress, setStepsProgress] = useState<Array<StepProps>>([]);

  useEffect(() => {
    let stepsHrefs: Array<string> = ['balance/', 'contracts/', 'confirm/'];
    setStepsProgress(Array.from({ length: 3 - 1 + 1 }, (e, i) => i + 6).map((_, index) => {
      let stepProp: StepProps = {
        step: index + 1,
        completed: progress > (index + 1),
        error: false,
        href: stepsHrefs[index]
      }
      return stepProp
    }))
  }, [progress]);

  useQuery(["investment", id], () => {
    api.get<ServerInvestment>(`/api/investment/${id}`)
      .then((res) => {
        setInvestment(res.data)
      })
      .catch()
      .finally()
  })

  const [investInfo, setInvestInfo] = useImmer({
    currentBalance: 0,
    amountInvested: "",
    expectedReturn: 0,
    totalResult: 0,
  } as InvestProps)

  return (
    <InvestContext.Provider value={{ investInfo, setInvestInfo, investment, progress, setProgress, stepsProgress, setStepsProgress }}>
      {children}
    </InvestContext.Provider>
  )
}

export const useInvest = () => {
  return useContext(InvestContext)
}