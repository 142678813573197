import { ClickAwayListener, Grow, MenuList, MenuListProps, Paper, Popper } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';

const MenuPopover = styled((props: MenuListProps) => (
  <MenuList
    {...props}
  />
))
  (({ theme }) => ({
    '&': {
      borderRadius: 8,
      marginTop: theme.spacing(1),
      minWidth: 220,
      backgroundColor: "rgb(243, 244, 246)",
      boxShadow:
        '18px 46px 20px rgba(0, 0, 0, 0.01), 10px 26px 17px rgba(0, 0, 0, 0.05), 5px 11px 12px rgba(0, 0, 0, 0.09), 1px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)',
      '& .MuiMenu-list': {
        padding: '0px 0px',
      },
      '& .MuiMenuItem-root': {
        '& .options-item': {
          "justify-content": "flex-start",
          height: "36px"/* 44px */,
          gap: "1rem",
          display: "flex",
          "align-items": "center",
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            "#00A862",
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

interface MenuStripProps {
  children: ReactNode
  className?: string
  title: ReactNode
};

export default function MenuStrip({ children, className, title }: MenuStripProps) {

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from!open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className="relative">
      <ButtonPrimary
        children={title}
        className={className}
        buttonRef={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              style={
                {
                  borderRadius: 8,
                  boxShadow: 'none'
                }
              }
            >
              <ClickAwayListener onClickAway={handleClose}>
                {
                  /**
                   * @author Samuel S. de Ataides
                   * @since 2022-03-08
                   * I found out that the ClickAwayListener's childs should be wrapped around a div element
                   * so that the click event could be triggered properly.
                   * @link https://stackoverflow.com/questions/67832882/clickawaylistener-component-not-working-with-dragdropcontext
                  */
                }
                <div>
                  <MenuPopover
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    children={children}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}