import { useNavigate } from "react-router-dom";
import { WhiteLoadingRing } from "../../Loading/WhiteLoadingRing";
import "../Buttons.sass"
import { ButtonHTMLAttributes, DetailedHTMLProps, LegacyRef } from 'react'

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  /**
   * Optional navigate to
   */
  to?: string | null
  /**
   * Optional childrens elements or text into button
   */
  children?: React.ReactNode | string
  /**
   * Optional aditional classes
   */
  className?: string
  /**
   * Optional element id
   */
  id?: string | undefined
  /**
   * Optional argument to render loading ring
   */
  isLoading?: boolean
  /**
   * Children icon
   */
  icon?: React.ReactNode | any
  /**
   * Element ref position
   */
  buttonRef?: LegacyRef<HTMLButtonElement>
};

/**
 * This primary button can be used as a form submitter, action button, or any mode. Just pass into parameter the direction (to) or a function to exec on click event (onClick)
 * 
 * @author Samuel Silva de Ataídes
 * @since 2022-09-15
 */
export const ButtonPrimary = ({ onClick, type, to, children, className, id, isLoading, disabled, buttonRef }: ButtonProps) => {
  const nav = useNavigate();

  const href = () => {
    if (to) {
      nav(to);
    }
  }

  const click = onClick ? onClick : href

  return (
    <button
      onClick={click}
      id={id}
      className={`button-default primary ${className ? className : ''}`}
      disabled={isLoading ? true : disabled}
      type={type}
      data-testid="button-primary-test"
      ref={buttonRef}
    >
      {
        isLoading ? (
          <div data-testid="loading" className="w-8 h-8">
            <WhiteLoadingRing />
          </div>
        ) : (
          <>
            {children}
          </>
        )
      }
    </button>
  );
};