import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft, FaCheck, FaQuestionCircle, FaTimes } from "react-icons/fa"
import { useQuery } from "react-query"
import { Navigate, useNavigate } from "react-router-dom"
import { useRegister } from "../../../contexts/RegisterContext"
import { useAuth } from "../../../contexts/UserContext"
import api from "../../../utils/api"
import { BackButton } from "../../Buttons/BackButton"
import { ButtonPrimary } from "../../Buttons/ButtonPrimary"
import Icon from "../../Icon"
import { Input } from "../../Input/Input"
import { InputDatePicker } from "../../Input/InputDatePicker"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import { SelectSync } from "../../Select/SelectSync"
import "../Register.sass"
import { HelpModal } from "./HelpModal"

export const Security = () => {

  const { user, checkAuth } = useAuth()

  const { t } = useTranslation()

  const optionsDocumentTypes: any = [
    {
      value: 0,
      label: "RG"
    },
    {
      value: 1,
      label: "CNH"
    },
    {
      value: 2,
      label: "RNE"
    },
    {
      value: 3,
      label: "Passaporte"
    }
  ];

  // Forms errors controller
  const [documentTypeError, setDocumentTypeError] = useState<boolean>(false);
  const [issuingBodyError, setIssuingBodyError] = useState<boolean>(false);
  const [documentNumberError, setDocumentNumberError] = useState<boolean>(false);
  const [stateIssuingBodyError, setStateIssuingBodyError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [motherNameError, setMotherNameError] = useState<boolean>(false);
  const [frontalDocError, setFrontalDocError] = useState<boolean>(false);
  const [backDocError, setBackDocError] = useState<boolean>(false);
  const [selfieDocError, setSelfieDocError] = useState<boolean>(false);

  const [checkingError, setCheckingError] = useState<boolean>(false)

  const frontalDocElRef = useRef<HTMLInputElement>(null);
  const backDocElRef = useRef<HTMLInputElement>(null);
  const selfieDocElRef = useRef<HTMLInputElement>(null);

  const [frontDocumentName, setFrontDocumentName] = useState<string>("");
  const [backDocumentName, setBackDocumentName] = useState<string>("");
  const [selfieDocumentName, setSelfieDocumentName] = useState<string>("");

  const [showModal, setShowModal] = useState(false)

  const [optionsStates, setOptionsStates] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { setProgress, documents, setDocuments } = useRegister()

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  const navigate = useNavigate()

  useEffect(() => {
    setProgress(5)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let elementSelect = document.getElementsByClassName("select-default-error")[0]
    let elementInput = document.getElementsByClassName("input-default-error")
    if ((elementInput[0] || elementSelect) && !checkingError) {
      setCheckingError(true)
      if (elementSelect) {
        elementSelect.scrollIntoView({ behavior: "smooth", block: "center" })
      } else {
        elementInput[0].scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeError, issuingBodyError, documentNumberError, stateIssuingBodyError, dateError, motherNameError, frontalDocError, backDocError, selfieDocError])

  useQuery("security", () => {
    setIsDataLoading(true);

    api.get("/api/user/register/step-data/5")
      .then((response) => {
        setDocuments(documents => {

          documents.document_type = response.data.document_type;
          documents.issuing_body = response.data.issuing_body;
          documents.state_issuing_body = response.data.state_issuing_body;
          documents.issue_date = response.data.issue_date ? new Date(response.data.issue_date) : null;
          documents.document_number = response.data.document_number ?? '';
          documents.mother_name = response.data.mother_name ?? '';
          documents.father_name = response.data.father_name ?? '';

          documents.front_document = response.data.front_document;
          documents.back_document = response.data.back_document;
          documents.selfie_document = response.data.selfie_document;

        });
      });
  })

  const [optionsIssuingBodies, setOptionsIssuingBodies] = useState<Array<any>>([]);

  const mapResponseToValuesAndLabels = (data: any) => {
    return {
      value: data.abbreviation,
      label: `${data.abbreviation} - ${data.name}`
    }
  };

  const statesToValueLabel = (data: any) => {
    return {
      value: data.id,
      label: data.name,
      code: data.code
    }
  };

  useEffect(() => {
    if (optionsStates.length <= 0 && optionsIssuingBodies.length <= 0) {

      /**
       * This need to be modified, removing the hard coded state id and adding dynamically the state id.
       */
      api.get(`/api/utils/states/31`)
        .then((response) => {
          response.data = response.data.map((data: any) => statesToValueLabel(data))
          setOptionsStates(response.data);
          setIsDataLoading(false);
        })

      api.get(`/api/utils/issuing-bodies`)
        .then((response) => {
          response.data = response.data.map((data: any) => mapResponseToValuesAndLabels(data))
          setOptionsIssuingBodies(response.data);
        })
    }
  }, [optionsIssuingBodies.length, optionsStates.length]);

  const verifyFrontalDocElRef = (e: HTMLInputElement) => {
    setDocuments(documents => {
      if (e.files) {
        documents.front_document = e.files[0]
        setFrontDocumentName(e.files[0].name)
        setFrontalDocError(false);
      }
    });
  }

  const verifyBackDocElRef = (e: HTMLInputElement) => {
    setDocuments(documents => {
      if (e.files) {
        documents.back_document = e.files[0]
        setBackDocumentName(e.files[0].name)
        setBackDocError(false);
      }
    });
  }

  const verifySelfieDocElRef = (e: HTMLInputElement) => {
    setDocuments(documents => {
      if (e.files) {
        documents.selfie_document = e.files[0]
        setSelfieDocumentName(e.files[0].name)
        setSelfieDocError(false);
      }
    });
  }

  const confirmSecurityInfo = () => {

    let errorsCount: number = 0;

    if (!documents.document_type) {
      setDocumentTypeError(true);
      errorsCount++
    }
    if (!documents.issuing_body) {
      setIssuingBodyError(true);
      errorsCount++
    }
    if (!documents.state_issuing_body) {
      setStateIssuingBodyError(true);
      errorsCount++
    }
    if (!documents.document_number) {
      setDocumentNumberError(true);
      errorsCount++
    }
    if (!documents.issue_date) {
      setDateError(true);
      errorsCount++
    }
    if (!documents.mother_name) {
      setMotherNameError(true);
      errorsCount++
    }
    if (!documents.front_document) {
      setFrontalDocError(true);
      setTimeout(() => setFrontalDocError(false), 500)
      errorsCount++
    }
    if (!documents.back_document) {
      setBackDocError(true);
      setTimeout(() => setBackDocError(false), 500)
      errorsCount++
    }
    if (!documents.selfie_document) {
      setSelfieDocError(true);
      setTimeout(() => setSelfieDocError(false), 500)
      errorsCount++
    }

    if (errorsCount > 0)
      return

    const frontDocument = new FormData();
    const backDocument = new FormData();
    const selfieDocument = new FormData();

    if (documents.front_document instanceof File)
      frontDocument.append("image", documents.front_document);

    if (documents.back_document instanceof File)
      backDocument.append("image", documents.back_document);

    if (documents.selfie_document instanceof File)
      selfieDocument.append("image", documents.selfie_document);

    const securityInfo = {
      document_type: documents.document_type,
      issuing_body: documents.issuing_body,
      state_issuing_body: documents.state_issuing_body,
      issue_date: documents.issue_date,
      document_number: documents.document_number,
      mother_name: documents.mother_name,
      father_name: documents.father_name
    }

    setIsLoading(true)

    api.post("/api/user/register", securityInfo)
      .then(() => {

        let uploadArray: Array<any> = [];

        if (documents.front_document instanceof File)
          uploadArray.push(api.post("/api/user/register/document?type=front", frontDocument, { headers: { 'Content-Type': 'multipart/form-data' } }))

        if (documents.back_document instanceof File)
          uploadArray.push(api.post("/api/user/register/document?type=back", backDocument, { headers: { 'Content-Type': 'multipart/form-data' } }))

        if (documents.selfie_document instanceof File)
          uploadArray.push(api.post("/api/user/register/document?type=selfie", selfieDocument, { headers: { 'Content-Type': 'multipart/form-data' } }))

        axios.all(uploadArray).then(([..._]) => {
          if (!documents.front_document) {
            setFrontalDocError(true);
            setTimeout(() => setFrontalDocError(false), 500)
            errorsCount++
          }

          if (!documents.back_document) {
            setBackDocError(true);
            setTimeout(() => setBackDocError(false), 500)
            errorsCount++
          }

          if (!documents.selfie_document) {
            setSelfieDocError(true);
            setTimeout(() => setSelfieDocError(false), 500)
            errorsCount++
          }

          if (errorsCount <= 0) {
            checkAuth().then(() => { navigate("/dashboard/register/special") })
          }

        }).catch(([errorFront, errorBack, errorSelfie]) => {
          if (errorFront !== undefined) {
            setFrontalDocError(true);
            setTimeout(() => setFrontalDocError(false), 500)
            errorsCount++
          }
          if (errorBack !== undefined) {
            setBackDocError(true);
            setTimeout(() => setBackDocError(false), 500)
            errorsCount++
          }

          if (errorSelfie !== undefined) {
            setSelfieDocError(true);
            setTimeout(() => setSelfieDocError(false), 500)
          }
        }).finally(() => {
          setIsLoading(false)
        })
      }).catch(
        (err) => {
          if (err.response.data.detail === "INVALID_STEP") {
            navigate("/dashboard/register") // redirects to right step
          }

          if (err.response.data.detail === "ALREADY_REGISTERED") {
            navigate("/dashboard/")
          }
        }
      )
  }

  if (user === null) {
    return <Navigate to="/login" />
  }

  if (user.register_step < 5) {
    return <Navigate to="/dashboard/register" />
  }


  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`} onClick={() => showModal ? setShowModal(false) : null}>
        <div className="left-container">
          <h1>{t("register.security.security")}</h1>
          <span className="register-description">{t("register.security.subtitle")}</span>
          <div className="form">
            <div className="input-block">
              <div className="down-input-group">
                <SelectSync
                  label={t("register.security.documentType")}
                  id="document-type"
                  type={"text"}
                  required={true}
                  options={optionsDocumentTypes}
                  onChange={(value: any) => { setDocuments(documents => { documents.document_type = value.label }); setDocumentTypeError(false) }}
                  value={optionsDocumentTypes.find((option: any) => option.label === documents.document_type)}
                  placeholder={t("register.security.placeholder.documentType")}
                  error={documentTypeError}
                  errorMessage={t("register.security.errors.documentType")}
                />
                <SelectSync
                  id="issuing-body"
                  label={t("register.security.issuing")}
                  type={"text"}
                  autoComplete="new"
                  required={true}
                  options={optionsIssuingBodies}
                  onChange={(value: any) => { setDocuments(documents => { documents.issuing_body = value.value }); setIssuingBodyError(false) }}
                  value={optionsIssuingBodies.find((option: any) => option.value === documents.issuing_body)}
                  placeholder={t("register.security.placeholder.documentType")}
                  error={issuingBodyError}
                  errorMessage={t("register.security.errors.issuingBody")}
                />
              </div>
              <div className="down-input-group">
                <SelectSync
                  label={t('register.security.state_issuing_body')}
                  id="state_issuing_body"
                  type="text"
                  required={true}
                  autoComplete="off"
                  options={optionsStates}
                  onChange={(value: any) => { setDocuments(documents => { documents.state_issuing_body = value.value }); setStateIssuingBodyError(false) }}
                  value={optionsStates.find(option => option.value === documents.state_issuing_body)}
                  placeholder={t("register.security.placeholder.state_issuing_body")}
                  error={stateIssuingBodyError}
                  errorMessage={t("register.security.errors.state_issuing_body")}
                />
                <Input
                  id="document-number"
                  label={t("register.security.documentNumber")}
                  type={"text"}
                  autoComplete=""
                  placeholder={t("register.security.placeholder.documentNumber")}
                  onChange={(e) => { setDocuments(documents => { documents.document_number = e.target.value }); setDocumentNumberError(false) }}
                  value={documents.document_number}
                  maxLength={30}
                  required={true}
                  error={documentNumberError}
                  errorMessage={t("register.security.errors.documentNumber")}
                />
              </div>
              <div className="down-input-group">
                <InputDatePicker
                  dateFormat={"dd/MM/yyyy"}
                  momentDateFormat="DD/MM/YYYY"
                  selected={documents.issue_date}
                  minDate={new Date("Thursday, 1 January 1970 00:00:00 UTC-00:00")}
                  maxDate={new Date()}
                  onChange={(date: Date | null) => { setDocuments(documents => { documents.issue_date = date }); if (date !== null) { setDateError(false) }; }}
                  id="issue-date"
                  label={t("register.security.issueDate")}
                  type={"text"}
                  placeholder={t("register.security.placeholder.issueDate")}
                  required={true}
                  error={dateError}
                  errorMessage={t("register.security.errors.date")}
                />
              </div>
              <Input
                id="mother-name"
                label={t("register.security.mother")}
                type={"text"}
                autoComplete="motherName"
                placeholder={t("register.security.placeholder.mother")}
                onChange={(e) => { setDocuments(documents => { documents.mother_name = e.target.value }); setMotherNameError(false) }}
                value={documents.mother_name}
                maxLength={70}
                required={true}
                error={motherNameError}
                errorMessage={t("register.security.errors.motherName")}
              />
              <Input
                id="father-name"
                label={t("register.security.father")}
                type={"text"}
                autoComplete="fatherName"
                placeholder={t("register.security.placeholder.father")}
                onChange={(e) => { setDocuments(documents => { documents.father_name = e.target.value }); }}
                value={documents.father_name}
                maxLength={70}
              />
            </div>
          </div>

          <div className="document-picture">
            <h1 className="title">{t("register.security.documentPicture.title")}</h1>

            <div data-testid="frontal-div" className={`steps-container ${frontalDocError ? 'error-input-file' : ''}`} onClick={() => frontalDocElRef.current !== null ? frontalDocElRef.current.click() : false}>
              <div className="step-image-container">
                <img src="/front-personal-document.svg" alt="Front document step" />
              </div>
              <div className="step-text-container">
                <h1>{t("register.security.documentPicture.frontPhoto")}</h1>
                <span>{frontDocumentName}</span>
              </div>
              <div className="step-toggle-container">
                <Icon className={`step-icon-container bg-gray-white-rounded bg-gray-300 ${!documents.front_document ? "" : "hide-icon"}`}><FaTimes className="icon" /></Icon>
                <Icon className={`step-icon-container bg-gray-white-rounded bg-green-default ${documents.front_document ? "show-icon" : "hidden"}`}><FaCheck className="icon" /></Icon>
              </div>
            </div>

            <input type="file" accept="image/*" data-testid="front-doc" ref={frontalDocElRef} onChange={(e: React.ChangeEvent<HTMLInputElement>) => verifyFrontalDocElRef(e.target)} className="hidden" />

            <div className={`steps-container ${backDocError ? 'error-input-file' : ''}`} onClick={() => backDocElRef.current !== null ? backDocElRef.current.click() : false}>
              <div className="step-image-container">
                <img src="/back-personal-document.svg" alt="Back document step" />
              </div>
              <div className="step-text-container">
                <h1>{t("register.security.documentPicture.backPhoto")}</h1>
                <span>{backDocumentName}</span>
              </div>
              <div className="step-toggle-container">
                <Icon className={`step-icon-container bg-gray-white-rounded bg-gray-300 ${!documents.back_document ? "" : "hide-icon"}`}><FaTimes className="icon" /></Icon>
                <Icon className={`step-icon-container bg-gray-white-rounded bg-green-default ${documents.back_document ? "show-icon" : "hidden"}`}><FaCheck className="icon" /></Icon>
              </div>
            </div>

            <input type="file" accept="image/*" data-testid="back-doc" ref={backDocElRef} onChange={(e: React.ChangeEvent<HTMLInputElement>) => verifyBackDocElRef(e.target)} className="hidden" />

            <div className={`steps-container ${selfieDocError ? 'error-input-file' : ''}`} onClick={() => selfieDocElRef.current !== null ? selfieDocElRef.current.click() : false}>
              <div className="step-image-container">
                <img src="/selfie-personal-document1.svg" alt="Selfie with document step" />
              </div>
              <div className="step-text-container">
                <h1>{t("register.security.documentPicture.selfiePhoto")}</h1>
                <span>{selfieDocumentName}</span>
              </div>
              <div className="step-toggle-container">
                <Icon className={`step-icon-container bg-gray-white-rounded bg-gray-300 ${!documents.selfie_document ? "" : "hide-icon"}`}><FaTimes className="icon" /></Icon>
                <Icon className={`step-icon-container bg-gray-white-rounded bg-green-default ${documents.selfie_document ? "show-icon" : "hidden"}`}><FaCheck className="icon" /></Icon>
              </div>

              <input type="file" accept="image/*" data-testid="selfie-doc" ref={selfieDocElRef} onChange={(e: React.ChangeEvent<HTMLInputElement>) => verifySelfieDocElRef(e.target)} className="hidden" />

            </div>

            <div className="help-button-text">
              <button className="help-icon" onClick={(e) => { e.preventDefault(); setShowModal(!showModal) }}>
                <FaQuestionCircle className="icon" />{t("register.security.needHelp")}
              </button>
            </div>

            <HelpModal showModal={showModal} />

          </div>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="/dashboard/register/bank-data"
              children={t("register.back")}
              className="back-button"
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={confirmSecurityInfo} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}