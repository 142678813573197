import * as React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
/**
 * Removing modules that is not used.
 */
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/thumbs";

import "./SliderVertical.sass";

// import required modules
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import SwiperCore, { A11y, FreeMode, Mousewheel, Navigation, Pagination, Scrollbar, Thumbs } from "swiper";
import Icon from "../../Icon";

interface SliderProps {
  /**
   * Arrays of images
   */
  imgs: Array<string>
}

/**
 * Vertical and horizontal slider
 * @param imgs Array of images to show in slider
 */
export default function SliderVertical({ imgs }: SliderProps) {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperCore>();
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <div className="main">
      <Swiper
        spaceBetween={10}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}

        onInit={(swiper) => {
          swiper.navigation.update()
        }}

        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="img-viewer"
        direction="horizontal"
      >
        {imgs.map((data, index) =>
          <SwiperSlide key={index}>
            <div className='slide-viewer'>
              <div className='img-blur' style={{ backgroundImage: `url(${data})` }}></div>
              <img src={data} alt="/" className='img-view' />
            </div>
          </SwiperSlide>
        )}
        <button ref={navigationPrevRef} className={`prev-btn `} onClick={() => { }}>
          <Icon className='bg-gray-white-rounded'>
            <FaAngleLeft className='icon'></FaAngleLeft>
          </Icon>
        </button>
        <button ref={navigationNextRef} className={`next-btn `} onClick={() => { }}>
          <Icon className='bg-gray-white-rounded'>
            <FaAngleRight className='icon'></FaAngleRight>
          </Icon>
        </button>
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        watchSlidesProgress={true}
        modules={[Navigation, Pagination, A11y, FreeMode, Scrollbar, Mousewheel]}
        className="slider-vertical"
        pagination={{
          clickable: true
        }}
        mousewheel={true}
        breakpoints={{
          0: {
            spaceBetween: 13,
            slidesPerView: 6,
            slidesPerGroup: 6,
            direction: "horizontal"
          },
          1024: {
            spaceBetween: 13,
            slidesPerGroup: 6,
            slidesPerView: 6,
            direction: "vertical"
          }
        }}
        onResize={(swiper) => {
          const pagination = document.getElementsByClassName('swiper-pagination')[0];

          if (window.innerWidth > 1024) {
            pagination.classList.toggle("swiper-pagination-horizontal", false);
            pagination.classList.toggle("swiper-pagination-vertical", true);
          }
          else {
            pagination.classList.toggle("swiper-pagination-horizontal", true);
            pagination.classList.toggle("swiper-pagination-vertical", false);
          }

          swiper.update()
          swiper.pagination.update()
        }}
      >
        {imgs.map((data, index) =>
          <SwiperSlide key={index}>
            <div className='slider-img'>
              <img src={data} className="img" alt="" />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}
