import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { ImageSlider } from "../../Slider/ImageSlider"
import "./InvestmentCard.sass"
import { ServerInvestment } from "../../../contexts/InvestmentContext"
import { useTranslation } from "react-i18next"
import { Utils } from "../../../utils/utils"

export interface InvestmentCardProps {
  /**
   * Investment id
   */
  investment: ServerInvestment
}

/**
 * This Investment Card that is in the main page, inside the slider or in the filter screen.
 * When the cursor is hover, the card expand showing more information. 
 */
export const InvestmentCard = ({ investment }: InvestmentCardProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const images = investment.investment_documents?.filter(document => document.category === "IMAGES") || []
  const img_url = images.length <= 0 ? "/no_image.png" : `/api/investment/image/${investment.code}/${images[0].id}`
  return (
    <div className="investment-card" onClick={e => { e.stopPropagation(); navigate(`/investment/${investment.id}`) }}>
      <div className="investment-image">
        {images.length > 0 &&
          <img src={img_url} alt="" />
        }
      </div>
      <div className="investment-info">
        <h4>{investment.title}</h4>
        <p>{investment.investment_values?.projections[0].term} {t("investmentPage.months")}</p>
        <p>{investment.investment_values?.projections[0].monthly_profit}% {t("investmentPage.perMonth")}</p>
      </div>
      <ExpandedCard investment={investment} />
    </div>
  )
}

/**
 * This is the Expanded Investment Card component.
 */
const ExpandedCard = ({ investment }: InvestmentCardProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const images = investment.investment_documents?.filter(document => document.category === "IMAGES").map(document => `/api/investment/image/${investment.code}/${document.id}`) || []
  const expireIn = useMemo(() => {
    let result = undefined
    if (investment.date_end_raising) {
      const difference = Utils.diffInDays(investment.date_end_raising, new Date())
      if (difference < 15) {
        result = difference
      }
    }

    return result
  }, [investment])

  const reserved = useMemo(() => parseFloat((investment.investment_values ? (investment.investment_values.participation / investment.investment_values?.total_value) * 100 : 0).toFixed(2)), [investment])
  const progress = useMemo(() => parseFloat(((investment.investment_values ? (investment.investment_values?.raised_value / investment.investment_values?.total_value) * 100 : 0) + reserved).toFixed(2)), [investment, reserved])

  return (
    <div className="expanded-card" onClick={e => { e.stopPropagation(); navigate(`/investment/${investment.code}`) }} >
      <div className="investment-image relative">
        <ImageSlider images={images} />
      </div>

      <div className="investment-info">
        {expireIn !== undefined && (
          <span className="expire">
            {t("investmentPage.expireIn", { expireIn: expireIn })}
          </span>
        )}
        <h4>{investment.title}</h4>
        <div className="progress">
          <p className="price">
            {investment.investment_values?.total_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </p>
          <div className="progress-bar">
            <span style={{ width: `${reserved}%` }} className="green-bar" />
            <span style={{ width: `${progress}%` }} className="blue-bar" />
          </div>
          <p className="percentage">
            {progress}% {t("investmentPage.raised")}
          </p>
        </div>
        <div className="details">
          <p>{t("investmentPage.term")}</p>
          <p><b>{investment.investment_values?.projections[0].term} {t("investmentPage.months")}</b></p>

          <p>{t("investmentPage.profitability")} </p>
          <p><b>{investment.investment_values?.projections[0].monthly_profit}% {t("investmentPage.perMonth")}</b></p>

        </div>
      </div>
    </div>
  )
}