import { Link } from "react-router-dom"
import "../Buttons.sass"
import { ButtonProps } from "../ButtonPrimary"
import "./BackButton.sass"

export const BackButton = ({ to, children, className, id, icon }: ButtonProps) => {
  return (
    <Link
      id={id}
      className={`back-button-component ${className ? className : ""}`}
      to={to ? to : "../"}
    >
      {icon} {children}
    </Link>
  )
}