import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaPlusCircle, FaTrash } from "react-icons/fa";
import { useQuery } from 'react-query';
import { Navigate, useNavigate } from "react-router-dom";
import { useImmer } from 'use-immer';
import { useRegister } from '../../../contexts/RegisterContext';
import { useAuth } from '../../../contexts/UserContext';
import api from '../../../utils/api';
import { Utils } from '../../../utils/utils';
import { BackButton } from '../../Buttons/BackButton';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { Input } from '../../Input/Input';
import { GreenLoadingRing } from '../../Loading/GreenLoadingRing';
import { SelectSync } from '../../Select/SelectSync';
import "../Register.sass";
import { BankCard } from './BankCard';
import "./BankData.sass";

export interface BankAccountProps {
  /**
   * Account type
   */
  accountType: string
  /**
   * Bank id
   */
  bank: string
  /**
   * Bank code
   */
  code: string
  /**
   * Bank branch
   */
  branch: string
  /**
   * Bank account
   */
  account: string
  /**
   * Account verification digit
   */
  digit: string
  /**
   * Account ID (token)
   */
  id: string | null
};

/**
 * Bank data screen to the register step
*/
export const BankData = () => {

  const { t } = useTranslation();
  const { user, checkAuth } = useAuth()

  const { setProgress, bankData, setBankData } = useRegister()
  const navigate = useNavigate()

  const [bankAccount, setBankAccount] = useImmer({
    accountType: "",
    bank: "",
    code: "",
    branch: "",
    account: "",
    digit: "",
    id: null
  } as BankAccountProps);

  const [viewAccount, setViewAccount] = useState<boolean>(false);

  const [indexBankEditing, setIndexBankEditing] = useState<number | null>(null);

  const [bankCards, setBankCards] = useState<Array<BankAccountProps>>([]);

  const [options, setOptions] = useState<Array<any>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Form errors controller
  const [monthlyIncomeError, setMonthlyIncomeError] = useState<boolean>(false)
  const [realEstatesError, setRealEstatesError] = useState<boolean>(false)
  const [financeApplicationsError, setFinanceApplicationsError] = useState<boolean>(false)
  const [movablePropertiesError, setMovablePropertiesError] = useState<boolean>(false)

  const [accountTypeError, setAccountTypeError] = useState<boolean>(false)
  const [codeError, setCodeError] = useState<boolean>(false)
  const [branchError, setBranchError] = useState<boolean>(false)
  const [accountError, setAccountError] = useState<boolean>(false)
  const [digitError, setDigitError] = useState<boolean>(false)

  const [checkingError, setCheckingError] = useState<boolean>(false);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  const optionsAccountTypes: any = [
    {
      value: 0,
      label: t("register.bankData.accountTypes.checking.individual")
    },
    {
      value: 1,
      label: t("register.bankData.accountTypes.checking.joint")
    },
    {
      value: 2,
      label: t("register.bankData.accountTypes.savings.individual")
    },
    {
      value: 3,
      label: t("register.bankData.accountTypes.savings.joint")
    }
  ]

  useEffect(() => {
    setProgress(4)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let elementSelect = document.getElementsByClassName("select-default-error")[0]
    let elementInput = document.getElementsByClassName("input-default-error")
    if ((elementInput[0] || elementSelect) && !checkingError) {
      setCheckingError(true)
      if (elementSelect) {
        elementSelect.scrollIntoView({ behavior: "smooth", block: "center" })
      } else {
        elementInput[0].scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyIncomeError, realEstatesError, financeApplicationsError, movablePropertiesError])

  const newAccount = () => {
    setViewAccount(true);
    setIndexBankEditing(null);
    setBankAccount(bankAccount => {
      bankAccount.accountType = "";
      bankAccount.bank = "";
      bankAccount.code = "";
      bankAccount.branch = "";
      bankAccount.account = "";
      bankAccount.digit = "";
      bankAccount.id = null
    });
  }

  /**
   * Add bank account card
   */
  const addBank = () => {

    let errorsCount: number = 0;

    if (!bankAccount.accountType && parseInt(bankAccount.accountType) !== 0) {
      setAccountTypeError(true)
      errorsCount++
    }
    if (!bankAccount.code) {
      setCodeError(true)
      errorsCount++
    }
    if (!bankAccount.branch) {
      setBranchError(true)
      errorsCount++
    }
    if (!bankAccount.account) {
      setAccountError(true)
      errorsCount++
    }
    if (!bankAccount.digit) {
      setDigitError(true)
      errorsCount++
    }

    if (errorsCount > 0)
      return

    let cards = bankCards;

    if (indexBankEditing === null) {
      cards.push(bankAccount);
    } else {
      cards[indexBankEditing] = bankAccount;
    }

    setBankCards(cards);

    setViewAccount(false);
  };

  /**
   * Edit bank account card 
   */
  const editAccount = (card: BankAccountProps) => {
    setIndexBankEditing(bankCards.indexOf(card));

    setViewAccount(true);
    setBankAccount(bankAccount => {
      bankAccount.accountType = card.accountType;
      bankAccount.bank = card.bank;
      bankAccount.code = card.code;
      bankAccount.branch = card.branch;
      bankAccount.account = card.account;
      bankAccount.digit = card.digit;
      bankAccount.id = card.id
    });
  };

  /**
   * Delete bank account card
   */
  const delBank = () => {
    let cards = structuredClone(bankCards);
    if (indexBankEditing !== null) {
      let card = cards.splice(indexBankEditing, 1)[0];

      if (card.id !== null) {
        api.delete(`/api/user/register/delete-account/${card.id}`)
          .then(() => {
            setBankCards(cards);
            setViewAccount(false);
            return
          })
      }

      setBankCards(cards);
      setViewAccount(false);
    }
  };

  const mapResponseToValuesAndLabels = (data: any) => {
    return {
      value: data.code.toString().padStart(3, '0'),
      label: `${data.code.toString().padStart(3, '0')} - ${data.name}`
    }
  };

  const searchBanks = async () => {
    await api.get(`https://brasilapi.com.br/api/banks/v1`)
      .then((response) => {
        response.data = response.data.filter((i: any) => i.code !== null)
        response.data = response.data.map((data: any) => mapResponseToValuesAndLabels(data))
        setOptions(response.data);
      })
  }

  useQuery("bank-data", () => {

    setIsDataLoading(true);

    searchBanks();

    api.get("/api/user/register/step-data/4").then(
      (response) => {
        setBankCards(response.data.bank_accounts.map((bankAccount: any) => {
          let card_bank_account = {
            accountType: bankAccount.account_type,
            code: bankAccount.bank_code,
            branch: bankAccount.bank_branch,
            account: bankAccount.bank_account,
            digit: bankAccount.account_digit,
            bank: "",
            id: bankAccount.id
          }
          return card_bank_account
        }));

        setBankData(bankData => {
          bankData.monthly_income = response.data.monthly_income !== null ? Utils.realFormat(response.data.monthly_income, 2) : '';
          bankData.real_estates = response.data.real_estates !== null ? Utils.realFormat(response.data.real_estates, 2) : '';
          bankData.finance_applications = response.data.finance_applications !== null ? Utils.realFormat(response.data.finance_applications, 2) : '';
          bankData.movable_properties = response.data.movable_properties !== null ? Utils.realFormat(response.data.movable_properties, 2) : '';
        })
      })
  })

  useEffect(() => {
    let bankCardsNames: any = bankCards.map(
      (bankAccount: any) => {
        bankAccount.bank = options.find((option: any) => option.value === bankAccount.code)?.label
        return bankAccount;
      }
    );
    setBankCards(bankCardsNames);
    setIsDataLoading(!isDataLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const addBankData = () => {

    let cards: Array<any> = []

    let errorsCount: number = 0;

    if (!bankData.monthly_income) {
      setMonthlyIncomeError(true)
      errorsCount++
    }
    if (!bankData.real_estates) {
      setRealEstatesError(true)
      errorsCount++
    }
    if (!bankData.finance_applications) {
      setFinanceApplicationsError(true)
      errorsCount++
    }
    if (!bankData.movable_properties) {
      setMovablePropertiesError(true)
      errorsCount++
    }

    if (errorsCount > 0) {
      setCheckingError(false);
      return
    }

    setIsLoading(true)

    bankCards.forEach((element) => {
      cards.push(
        {
          account_type: element.accountType,
          bank_code: element.code,
          bank_branch: element.branch,
          bank_account: element.account,
          account_digit: element.digit,
          id: element.id
        }
      )
    })

    const data = {
      monthly_income: Utils.str2float(bankData.monthly_income, "br"),
      real_estates: Utils.str2float(bankData.real_estates, "br"),
      finance_applications: Utils.str2float(bankData.finance_applications, "br"),
      movable_properties: Utils.str2float(bankData.movable_properties, "br"),
      bank_accounts: cards
    }

    api.post("/api/user/register", data)
      .then(
        (response) => {
          checkAuth().then(() => { navigate("/dashboard/register/security") })
        }
      )
      .catch(
        (err) => {
          if (err.response.data.detail === "INVALID_STEP") {
            navigate("/dashboard/register/security")
          }

          if (err.response.data.detail === "ALREADY_REGISTERED") {
            navigate("/dashboard/")
          }
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (user === null) {
    return <Navigate to="/login" />
  }

  if (user.register_step < 4) {
    return <Navigate to="/dashboard/register" />
  }


  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t('register.bankData.title')}</h1>
          <span className="register-description">{t('register.bankData.descriptionInitial')}</span>
          <div className="form">
            <button data-testid="add-account" className="register-addAccount" onClick={newAccount}>
              <FaPlusCircle className="icon" /> {t('register.bankData.addAccount')}
            </button>
            <div className="input-block">
              <div className={`bank-form ${!viewAccount ? "hidden" : ''}`}>
                <div className="down-input-group">
                  <SelectSync
                    label={t('register.bankData.accountType')}
                    id="accountType"
                    type="text"
                    required={true}
                    autoComplete="off"
                    options={optionsAccountTypes}
                    onChange={(value: any) => { setBankAccount(bankAccount => { bankAccount.accountType = value.value; }); setAccountTypeError(false) }}
                    value={optionsAccountTypes.find((option: any) => option.value === bankAccount.accountType)}
                    placeholder={t("register.bankData.placeholder.bank")}
                    error={accountTypeError}
                    errorMessage={t("register.bankData.errors.accountType")}
                  />
                  <SelectSync
                    label={t('register.bankData.bank')}
                    id="bank"
                    type="text"
                    required={true}
                    options={options}
                    autoComplete="off"
                    onChange={(value: any) => { setBankAccount(bankAccount => { bankAccount.code = value.value; bankAccount.bank = value.label }); setCodeError(false) }}
                    value={options.find(option => option.value === bankAccount.code)}
                    placeholder={t("register.bankData.placeholder.bank")}
                    error={codeError}
                    errorMessage={t("register.bankData.errors.bank")}
                  />
                </div>
                <div className="down-input-group">
                  <Input
                    label={t('register.bankData.branch')}
                    id="branch"
                    type="text"
                    required={true}
                    autoComplete="off"
                    onChange={(e) => setBankAccount(bankAccount => { bankAccount.branch = e.target.value; setBranchError(false) })}
                    value={bankAccount.branch}
                    placeholder={t("register.bankData.placeholder.branch")}
                    error={branchError}
                    errorMessage={t("register.bankData.errors.branch")}
                    maxLength={15}
                  />
                  <div className="register-account-digit">
                    <Input
                      label={t('register.bankData.account')}
                      id="bankAccount"
                      type="text"
                      required={true}
                      autoComplete="off"
                      onChange={(e) => setBankAccount(bankAccount => { bankAccount.account = e.target.value; setAccountError(false) })}
                      value={bankAccount.account}
                      placeholder={t("register.bankData.placeholder.account")}
                      error={accountError}
                      errorMessage={t("register.bankData.errors.account")}
                      maxLength={15}

                    />
                    <Input
                      label={t('register.bankData.digit')}
                      id="accountDigit"
                      type="text"
                      required={true}
                      autoComplete="off"
                      onChange={(e) => setBankAccount(bankAccount => { bankAccount.digit = e.target.value; setDigitError(false) })}
                      value={bankAccount.digit}
                      placeholder={t("register.bankData.placeholder.digit")}
                      error={digitError}
                      errorMessage={t("register.bankData.errors.digit")}
                      maxLength={1}
                    />
                  </div>
                </div>
                <div className="down-input-group">
                  <ButtonPrimary onClick={addBank}>{t("register.bankData.save")}</ButtonPrimary>
                  <div className={`delete-button ${indexBankEditing == null ? 'hidden' : ''}`}>
                    <button data-testid="delete-account" className="register-bank-cards-delete" onClick={delBank}><FaTrash className='icon' /> {t("register.bankData.delete")}</button>
                  </div>
                </div>
              </div>

              <div className='register-bank-cards'>
                {bankCards.map((card, index) => (
                  <BankCard key={index}
                    card={card}
                    editAccount={editAccount}
                  />
                ))}
              </div>

              <span className="register-description">{t('register.bankData.descriptionSecondary')}</span>
              <div className="down-input-group">
                <Input
                  label={t('register.bankData.monthlyIncome')}
                  id="monthlyIncome"
                  type="text"
                  required={true}
                  autoComplete="off"
                  onChange={(e: any) => setBankData(bankData => { bankData.monthly_income = e.target.value; setMonthlyIncomeError(false) })}
                  onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                  value={bankData.monthly_income ?? ""}
                  maxLength={18}
                  before="R$"
                  placeholder={t("register.bankData.placeholder.money")}
                  error={monthlyIncomeError}
                  errorMessage={t("register.bankData.errors.monthlyIncome")}
                />
                <Input
                  label={t('register.bankData.realEstates')}
                  id="realEstates"
                  type="text"
                  required={true}
                  autoComplete="off"
                  onChange={(e: any) => setBankData(bankData => { bankData.real_estates = e.target.value; setRealEstatesError(false) })}
                  onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                  value={bankData.real_estates ?? ''}
                  maxLength={18}
                  before="R$"
                  placeholder={t("register.bankData.placeholder.money")}
                  error={realEstatesError}
                  errorMessage={t("register.bankData.errors.realEstates")}
                />
              </div>
              <div className="down-input-group">
                <Input
                  label={t('register.bankData.financeApplications')}
                  id="financeApplications"
                  type="text"
                  required={true}
                  autoComplete="off"
                  onChange={(e: any) => setBankData(bankData => { bankData.finance_applications = e.target.value; setFinanceApplicationsError(false) })}
                  onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                  value={bankData.finance_applications ?? ''}
                  maxLength={18}
                  placeholder={t("register.bankData.placeholder.money")}
                  before="R$"
                  error={financeApplicationsError}
                  errorMessage={t("register.bankData.errors.financeApplications")}
                />
                <Input
                  label={t('register.bankData.movableProperties')}
                  id="movableProperties"
                  type="text"
                  required={true}
                  autoComplete="off"
                  onChange={(e: any) => setBankData(bankData => { bankData.movable_properties = e.target.value; setMovablePropertiesError(false) })}
                  onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                  value={bankData.movable_properties ?? ''}
                  maxLength={18}
                  placeholder={t("register.bankData.placeholder.money")}
                  before="R$"
                  error={movablePropertiesError}
                  errorMessage={t("register.bankData.errors.movableProperties")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="/dashboard/register/address"
              children={t("register.back")}
              className="back-button"
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={addBankData} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

