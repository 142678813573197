import { Dialog } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaTimes } from "react-icons/fa"
import { Utils } from "../../../../utils/utils"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import "./SettingsModal.sass"

interface SettingsModalProps {
  title: string
  addressee: string
  errorCodeMessage: string
  open: boolean
  handleClose: () => void
}

export const SettingsModal = ({ open, handleClose, title, addressee, errorCodeMessage }: SettingsModalProps) => {

  const { t } = useTranslation()

  const [codeError, setCodeError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const verifKeyDown = (e: any) => {
    Utils.verifKeyDown(e)
  };

  const verifKeyUp = (e: any) => {
    Utils.verifKeyUp(e, () => { "confirmCode" })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { "borderRadius": "12px" }
      }}
      maxWidth={"sm"}
      scroll="body"
      fullWidth
    >
      <div className="investment-modal-container settings-modal-container">
        <button className="close-button" onClick={handleClose}>
          <FaTimes className="icon" />
        </button>
        <h1 className="title">{title}</h1>
        <span className="subtitle">{t("register.description.emailVerification", { email: addressee })}</span>
        <div className={`code-input ${codeError ? 'code-input-default-error' : ''}`}>
          <label htmlFor="0">{t("register.code")}*</label>

          <div className="code-grid">
            <input type="number" className="input-code-grid" id="0" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoFocus autoComplete="off" maxLength={1} />
            <input type="number" className="input-code-grid" id="1" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
            <input type="number" className="input-code-grid" id="2" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
            <input type="number" className="input-code-grid" id="3" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
          </div>
          <div className="code-input-error-message">
            {
              errorCodeMessage !== undefined && <span>{errorCodeMessage}</span>
            }
          </div>
        </div>
        <div className="confirm-code">
          <ButtonPrimary onClick={() => { }} children={t("register.confirm")} id="confirm-btn" className='signup-primary-btn' isLoading={isLoading} ></ButtonPrimary>
        </div>
      </div>
    </Dialog>
  )
}