
import { useMemo } from "react";
import { Utils } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import SliderVertical from '../../Slider/SliderVertical';
import { InformationCard } from "../InformationCard";
import "./InvestmentInfo.sass";
import { ServerInvestment } from "../../../contexts/InvestmentContext";


interface InvestmentCardProps {
  investment: ServerInvestment
}

/**
 * Investment page info. Contains the image slider and information card
 */
export const InvestmentInfo = ({ investment }: InvestmentCardProps) => {
  const { t } = useTranslation();

  const images = investment.investment_documents?.filter(document => document.category === "IMAGES").map(document => `/api/investment/image/${investment.code}/${document.id}`) || []

  const expireIn: number | undefined = useMemo(() => {
    let result = undefined
    if (investment.date_end_raising) {
      const difference = Utils.diffInDays(new Date(), new Date(investment.date_end_raising))
      if (difference < 15) {
        result = Math.abs(difference)
      }
    }

    return result
  }, [investment])
  const startDate = investment.date_start_raising

  return (
    <>
      <div className="header-info">
        {expireIn !== undefined && (
          <span className="expire">
            {t("investmentPage.expireIn", { expireIn })}
          </span>
        )}
        <h1>{investment.title}</h1>
        <h4>{`${t("investmentPage.start")} ${startDate ? (new Date(startDate).toLocaleDateString()) : (t("investmentPage.toBeDecided"))} - ${t("investmentPage.code")}: ${investment.code}`}</h4>
      </div>

      <div className='main-img-card'>
        <div className='imgs-viewer'>
          <SliderVertical imgs={images} />
        </div>

        <InformationCard investment={investment} />
      </div>
    </>
  );
};