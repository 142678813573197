import { MenuItem } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaCheck, FaCogs, FaEdit, FaHeadset, FaInfoCircle, FaLink, FaTimes, FaUserCircle } from "react-icons/fa"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { SwiperSlide } from "swiper/react"
import { ServerFile, ServerInvestment } from "../../../contexts/InvestmentContext"
import api from "../../../utils/api"
import { DocumentsInput } from "../../Input/DocumentsInput"
import { InvestmentDocument } from "../../Input/DocumentsInput/InvestmentDocument"
import { InvestmentDetailsList } from "../../Investment/Create/ConfirmInvestment/InvestmentDetailsList"
import { ThemesSlider } from "../../Investment/Create/ConfirmInvestment/ThemesSlider"
import { Timeline } from "../../Investment/Create/ConfirmInvestment/Timeline"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import MenuStrip from "../../MenuStrip"
import { AddImageSlider } from "../../Slider/AddImageSlider"
import { SwiperImage } from "../../Slider/AddImageSlider/SwiperImage"
import "../InvestmentDashboard/InvestmentDashboard.sass"
import { LaunchInvestmentModal } from "./LaunchInvestmentModal"
import { LinkCompanyModal } from "./LinkCompanyModal"
import { RejectInvestmentModal } from "./RejectInvestmentModal"
import "./WaitingRoom.sass"


export const WaitingRoom = () => {

  const navigate = useNavigate()

  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()

  const [investment, setInvestment] = useState<ServerInvestment>()

  const [makeInvestmentModal, setMakeInvestmentModal] = useState<boolean>(false)
  const [rejectInvestmentModal, setRejectInvestmentModal] = useState<boolean>(false)
  const [linkCompanyModal, setLinkCompanyModal] = useState<boolean>(false)

  const { isLoading } = useQuery("investment",
    () => api.get<ServerInvestment>(`/api/investment/propose/${id}`)
      .then((res) => { setInvestment(res.data) })
  )

  function handleEditClick() {
    navigate(`/investment/${id}/validate/`)
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`investment-dashboard ${isLoading ? 'hidden' : ''}`}>
        {makeInvestmentModal &&
          <LaunchInvestmentModal open={makeInvestmentModal} handleClose={() => setMakeInvestmentModal(false)} />
        }
        {rejectInvestmentModal &&
          <RejectInvestmentModal open={rejectInvestmentModal} handleClose={() => setRejectInvestmentModal(false)} />
        }
        {linkCompanyModal &&
          <LinkCompanyModal open={linkCompanyModal} handleClose={() => setLinkCompanyModal(false)} />
        }
        <div className="investment-revision-container">
          <div className="title-container">
            <div>
              <h1 className="primary-title">{investment?.title}</h1>
              <span className="subtitle">{"Cod. " + investment?.code}</span>
            </div>
            <div>
              <MenuStrip className="options-button" title={<><FaCogs className="w-5 h-5" />{t("investment.validate.room.menu.menuTitle")}</>}>
                <MenuItem data-testid="option-menu-item-test" onClick={() => handleEditClick()} disableRipple>
                  <div className="options-item">
                    <FaEdit className="w-5 h-5 text-blue-600" /> {t("investment.validate.room.menu.validateField")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => setMakeInvestmentModal(true)} disableRipple>
                  <div className="options-item" >
                    <FaCheck className="w-5 h-5 text-green-default" /> {t("investment.validate.room.menu.available")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => setRejectInvestmentModal(true)} disableRipple>
                  <div className="options-item" >
                    <FaTimes className="w-5 h-5 text-red-500" /> {t("investment.validate.room.menu.reject")}
                  </div>
                </MenuItem>
                <MenuItem data-testid="option-menu-item-test" onClick={() => setLinkCompanyModal(true)} disableRipple>
                  <div className="options-item" >
                    <FaLink className="w-5 h-5 text-blue-600" /> {t("investment.validate.room.menu.link")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => { }} disableRipple>
                  <div className="options-item">
                    <FaHeadset className="w-5 h-5" /> {t("investment.validate.room.menu.support")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => { }} disableRipple>
                  <div className="options-item">
                    <FaInfoCircle className="w-5 h-5" /> {t("investment.validate.room.menu.logs")}
                  </div>
                </MenuItem>
              </MenuStrip>
            </div>
          </div>
          <div className="top-container">
            <div className="left-container">
              <InvestmentDetailsList
                title={investment?.title || ""}
                description={investment?.description || ""}
                term={investment?.investment_values?.projections[0].term ? investment?.investment_values?.projections[0].term.toString() + t("investment.validate.room.termMonths") : "0"}
                category={investment?.category.toString() || ""}
                desired_capture={investment?.investment_values?.total_value.toString() || ""}
                invested_value={investment?.investment_values?.participation.toString() || ""}
                investment_type={investment?.investment_type || 0}
                min_capture={investment?.investment_values?.minimum_foundation.toString() || ""}
                projected_profitability={investment?.investment_values?.projections[0].profitability.toString() || ""}
              />
              <AddImageSlider
                title="Imagens"
                images={investment?.investment_documents?.filter((element) => element.category === "IMAGES") || []}
                documentSliderComponent=
                {investment?.investment_documents?.filter((element) => element.category === "IMAGES").map((data, index) =>
                  <SwiperSlide key={index}>
                    <SwiperImage data={data} index={index} canDelete={false} />
                  </SwiperSlide>
                )}
              />
            </div>
            <div className="right-container">
              <Timeline
                stages={investment?.investment_schedule?.stages || []}
                title={t("investment.confirm.schedule")} />

              <div className="responsible-contact-container">
                <h1 className="title">{t("investment.validate.room.contact.title")}</h1>
                <div className="contact-container">
                  <div className="contact-name">
                    <FaUserCircle className="w-7 h-7" />
                    <div>
                      <p className="contact-label">{t("investment.validate.room.contact.name")}</p>
                      <span className="contact-text">Manuela Mayer</span>
                    </div>
                  </div>
                  <div>
                    <p className="contact-label">{t("investment.validate.room.contact.phone")}</p>
                    <span className="contact-text">+55 (62) 99999-9999</span>
                  </div>
                  <div>
                    <p className="contact-label">{t("investment.validate.room.contact.email")}</p>
                    <span className="contact-text">manuela_mayer01@gmail.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <div className="left-container">
              <ThemesSlider
                title={t("investment.validate.room.operationDetails")}
                themes={investment?.investment_schedule?.operation_details || []}
              />

              <DocumentsInput
                title={t("investment.validate.room.publicDocuments")}
                documents={investment?.investment_documents?.filter((element) => element.category === "PUBLIC") || []}
                documentsComponent={
                  investment?.investment_documents?.filter((element) => element.category === "PUBLIC").map((file: File | ServerFile, index: number) => (
                    <InvestmentDocument file={file} index={index} key={index} />
                  ))
                }
              />

              <DocumentsInput
                title={t("investment.validate.room.privateDocuments")}
                documents={investment?.investment_documents?.filter((element) => element.category === "VALUES" || element.category === "SECURITY") || []}
                addFileCallback={() => { }}
                documentsComponent={
                  investment?.investment_documents?.filter((element) => element.category === "VALUES" || element.category === "SECURITY").map((file: File | ServerFile, index: number) => (
                    <>
                      <InvestmentDocument file={file} index={index} key={index} />
                    </>
                  ))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}