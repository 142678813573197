import { useNavigate } from "react-router-dom";
import { WhiteLoadingRing } from "../../Loading/WhiteLoadingRing";
import "../Buttons.sass"
import { ButtonProps } from "../ButtonPrimary";

/**
 * This secondary button can be used as a form submitter, action button, or any mode. Just pass into parameter the direction (to) or a function to exec on click event (onClick)
 * 
 * @author Samuel Silva de Ataídes
 * @since 2022-09-15
 */
export const ButtonSecondary = ({ onClick, type, to, children, className, id, isLoading, disabled }: ButtonProps) => {
  const nav = useNavigate();

  const href = () => {
    if (to) {
      nav(to);
    }
  }

  const click = onClick ? onClick : href

  return (
    <button
      onClick={click}
      id={id}
      className={`button-default secondary ${className ? className : ''}`}
      disabled={disabled}
      type={type}
    >
      {
        isLoading ? (
          <div data-testid="loading" className="w-8 h-8">
            <WhiteLoadingRing />
          </div>
        ) : (
          <>
            {children}
          </>
        )
      }
    </button>
  );
};
