import pt from "date-fns/locale/pt-BR";
import i18next from 'i18next';
import moment from 'moment';
import React, { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { InputMasked } from '../InputMasked';
import '../Input.sass';

registerLocale("pt", pt);

export interface InputDatePickerProps extends ReactDatePickerProps {
  /**
  * Html attribute id to input.
  */
  id?: string
  /**
   * Input label, helps on accessibility of the project as well.
   */
  label?: string
  /**
   * Html attribute type ot input, define the type of the input.
   */
  type?: string
  /**
   * onChange event.
   */
  onChange: (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void
  /**
   * Define the value to show in input before enter any value.
   */
  placeholder?: any
  /**
   * Html attribute className, define the class name of the input.
   */
  className?: string
  /**
   * Is a text that is under the input element, can be used for describe the field, describe constraints, etc. 
   */
  span?: string | ReactNode
  /**
   * Input error mode.
   */
  error?: boolean
  /**
   * Is a red error message that is under the input element, can be used for alert the user about something wrong.
   */
  errorMessage?: string
  /**
   * Is a label or component placed before the input element that is used for some information, can be used for money notation. 
   */
  before?: string
  /**
   * Is a label or component placed after the input element that is used for some action, can be used for toggle view password. 
   */
  after?: string | ReactNode
  /**
   * JS date format.
   */
  dateFormat: string
  /**
   * Moment date format. Read Moment doc. to see how this works.
   */
  momentDateFormat: string
};

export const InputDatePicker = (inputDatePickerProps: InputDatePickerProps) => {

  const language = i18next.language;

  const [dateCopy, setDateCopy] = useState<Date | null>(null);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (firstTime || inputDatePickerProps.selected) {
      setDateCopy(inputDatePickerProps.selected || null);
      setFirstTime(false);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const CustomInputMasked = useCallback(forwardRef(({ onClick }: { onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }, ref: React.LegacyRef<HTMLButtonElement>) => {

    const [date, setDate] = useState<string | null>(dateCopy instanceof Date && !inputDatePickerProps.error ? dateCopy.toLocaleDateString("pt-BR") : null);

    return (
      <InputMasked
        id={inputDatePickerProps.id}
        label={inputDatePickerProps.label}
        type={inputDatePickerProps.type}
        onChange={(e: any) => {
          if (e.target.value.length === inputDatePickerProps.dateFormat.length) {

            let newDate: any = moment(e.target.value, inputDatePickerProps.momentDateFormat).toDate();

            if (!(!isNaN(newDate) && newDate instanceof Date)) {
              inputDatePickerProps.onChange(null, e);
            }
            else if (inputDatePickerProps.maxDate && (newDate > inputDatePickerProps.maxDate)) {
              inputDatePickerProps.onChange(inputDatePickerProps.maxDate ? inputDatePickerProps.maxDate : null, e);
              setDateCopy(inputDatePickerProps.maxDate || null);
            }
            else if (inputDatePickerProps.minDate && (newDate < inputDatePickerProps.minDate)) {
              inputDatePickerProps.onChange(inputDatePickerProps.minDate ? inputDatePickerProps.minDate : null, e);
              setDateCopy(inputDatePickerProps.minDate || null);
            }
            else {
              inputDatePickerProps.onChange(newDate, e);
              setDateCopy(newDate || null);
            }

          }
          else {
            inputDatePickerProps.onChange(null, e);
          }
          setDate(e.target.value);
        }}
        autoComplete="off"
        value={date ? date : ""}
        placeholder={inputDatePickerProps.placeholder}
        className={inputDatePickerProps.className}
        span={inputDatePickerProps.span}
        required={inputDatePickerProps.required}
        errorMessage={inputDatePickerProps.errorMessage}
        error={inputDatePickerProps.error}
        mask={inputDatePickerProps.dateFormat.replace(/[^\s/:]/ig, "9")}
        after={<button ref={ref} onClick={onClick}><FaCalendarAlt /></button>}
      />
    )
  }), [inputDatePickerProps.error, dateCopy]);

  return (
    <div>
      <ReactDatePicker
        locale={language.slice(0, 2) === 'en' ? "" : "pt"}
        selected={dateCopy}
        customInput={React.createElement(CustomInputMasked)}
        showYearDropdown
        yearDropdownItemNumber={30}
        scrollableYearDropdown
        {...inputDatePickerProps}
      />
    </div>
  );
};