import axios from "axios"
import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useAlertContext } from "../../../../contexts/AlertContext"
import { useCreateInvestment } from "../../../../contexts/CreateInvestment"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { Utils } from "../../../../utils/utils"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { Input } from "../../../Input/Input"
import { InputMasked } from "../../../Input/InputMasked"
import { TextArea } from "../../../Input/TextArea"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import "../../../Register/Register.sass"
import "./InvestmentValues.sass"

export const InvestmentValues = () => {

  const { newInvestment, investmentValues, setInvestmentValues, setProgress, investmentId, isDataLoading, checkInvestmentValues, getAndSetInvestment } = useCreateInvestment()

  const { presentAlert, setShowAlert } = useAlertContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(1)
  const [showProgress, setShowProgress] = useState<boolean>(false)

  const [checkingError, setCheckingError] = useState<boolean>(false)

  // Form errors
  const [totalError, setTotalError] = useState<boolean>(false)
  const [minCaptureError, setMinCaptureError] = useState<boolean>(false)
  const [participationError, setParticipationError] = useState<boolean>(false)
  const [justificationError, setJustificationError] = useState<boolean>(false)
  const [expectedTermError, setExpectedTermError] = useState<boolean>(false)
  const [expectedProfitError, setExpectedProfitError] = useState<boolean>(false)
  const [overcomeTermError, setOvercomeTermError] = useState<boolean>(false)
  const [overcomeProfitError, setOvercomeProfitError] = useState<boolean>(false)
  const [pessimistTermError, setPessimistTermError] = useState<boolean>(false)
  const [pessimistProfitError, setPessimistProfitError] = useState<boolean>(false)
  const [rentabilityJustificationError, setRentabilityJustificationError] = useState<boolean>(false)
  const [filesError, setFilesError] = useState<boolean>(false)

  const addFiles = (e: HTMLInputElement) => {
    setInvestmentValues(investmentValues => {
      let oldArray = investmentValues.documents || [];

      if (e.files && e.files.length > 0) {

        let array = [];

        for (let index = 0; index < e.files.length; index++) {
          array.push(e.files[index])
        }

        investmentValues.documents = oldArray.concat(array);
      }
    })
  }


  const confirmInvestmentValues = () => {
    let errorsCount: number = 0;

    if (parseFloat(investmentValues.total.replaceAll(".", "").replace(",", ".")) < 50000 || investmentValues.total === "") {
      setTotalError(true);
      errorsCount++
    }
    if (!investmentValues.minCapture) {
      setMinCaptureError(true);
      errorsCount++
    }
    if (!investmentValues.userParticipation) {
      setParticipationError(true);
      errorsCount++
    }
    if (!investmentValues.valueJustification) {
      setJustificationError(true);
      errorsCount++
    }
    if (!investmentValues.expectedDate && newInvestment.investment_type === 0) {
      setExpectedTermError(true);
      errorsCount++
    }
    if (!investmentValues.expectedValue) {
      setExpectedProfitError(true);
      errorsCount++
    }
    if (!investmentValues.overcomeDate && newInvestment.investment_type === 0) {
      setOvercomeTermError(true);
      errorsCount++
    }
    if (!investmentValues.overcomeValue) {
      setOvercomeProfitError(true);
      errorsCount++
    }
    if (!investmentValues.pessimistDate && newInvestment.investment_type === 0) {
      setPessimistTermError(true);
      errorsCount++
    }
    if (!investmentValues.pessimistValue) {
      setPessimistProfitError(true);
      errorsCount++
    }
    if (!investmentValues.rentabilityJustification) {
      setRentabilityJustificationError(true);
      errorsCount++
    }

    if (investmentValues.documents === null || investmentValues.documents.length <= 0) {
      setFilesError(true);
      errorsCount++
    }

    return errorsCount
  }

  const submitValues = () => {
    if (confirmInvestmentValues() > 0) {
      setCheckingError(false)
      presentAlert({
        title: t("investment.investmentValues.alerts.emptyFields.title"),
        text: t("investment.investmentValues.alerts.emptyFields.text"),
        type: "danger",
        timeout: 3500
      })
      return;
    }

    setShowAlert(false)
    setIsLoading(true)
    setShowProgress(true)
    setUploadProgress(0)

    const fileData = new FormData()

    investmentValues.documents?.forEach(file => {
      if (!("id" in file)) {
        fileData.append("documents", file)
      }
    })

    const requests = []

    if (checkInvestmentValues(investmentValues)) {
      requests.push(
        api.post(`/api/investment/new/${investmentId}`, {
          "total_value": parseFloat(investmentValues.total.replaceAll(".", "").replace(",", ".")),
          "minimum_foundation": parseFloat(investmentValues.minCapture),
          "minimum_aport": parseFloat(investmentValues.minimumAport.replaceAll(".", "").replace(",", ".")),
          "participation": parseFloat(investmentValues.userParticipation.replaceAll(".", "").replace(",", ".")),
          "values_justification": investmentValues.valueJustification,
          "projections_justifications": investmentValues.rentabilityJustification,
          "projections": [
            {
              "projection_type": 0,
              "profitability": parseFloat(investmentValues.expectedValue.replaceAll(".", "").replace(",", ".")),
              "term": investmentValues.expectedDate
            },
            {
              "projection_type": 1,
              "profitability": parseFloat(investmentValues.overcomeValue.replaceAll(".", "").replace(",", ".")),
              "term": investmentValues.overcomeDate
            },
            {
              "projection_type": 2,
              "profitability": parseFloat(investmentValues.pessimistValue.replaceAll(".", "").replace(",", ".")),
              "term": investmentValues.pessimistDate
            }
          ]
        })
      )
    }

    if (fileData.get("documents")?.valueOf()) {
      requests.push(api.post(`/api/investment/${investmentId}/documents?document_category=VALUES`, fileData, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round(progressEvent.loaded * 100) / progressEvent.total)
        }
      }))
    }


    if (requests.length > 0) {
      axios.all(requests)
        .then((...responses) => {
          getAndSetInvestment()
          navigate(`/investment/${investmentId}/create/schedule`)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      navigate(`/investment/${investmentId}/create/schedule`)
    }

  }

  useEffect(() => {
    let element = document.getElementsByClassName("input-default-error")[0]
    let documentError = document.getElementsByClassName("documents-span-error")[0]
    if ((element || documentError) && !checkingError) {
      setCheckingError(true)
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" })
      } else if (documentError) {
        documentError.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalError, minCaptureError, participationError, justificationError, expectedTermError, expectedProfitError, overcomeTermError, overcomeProfitError, pessimistTermError, pessimistProfitError, rentabilityJustificationError, filesError])

  /**
   * This handle if the user is trying to input a value greater than 100 in the min funding input
   * @param event OnChange event from the input
   */
  const handleMinFundingInput = (event: ChangeEvent<HTMLInputElement>) => {
    let funding = parseFloat(event.target.value.replace(",", "."))

    if (funding > 100) {
      event.target.value = "100"
    }
  }

  useEffect(() => {
    setProgress(3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.investmentValues.values")}</h1>

          <div className="form">
            <div className="input-block">
              <Input
                id="investment-value"
                label={t("investment.investmentValues.label.value")}
                type="text"
                onChange={(e) => { setInvestmentValues((investmentValue) => { investmentValue.total = e.target.value }); setTotalError(false) }}
                onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                value={investmentValues.total ?? ""}
                placeholder="50.000,00"
                className="value-input"
                span={t("investment.investmentValues.span.value")}
                before={"R$"}
                autoComplete="off"
                errorMessage={t("investment.investmentValues.errors.total")}
                error={totalError}
                required
              />
              <Input
                id="min-aport"
                label={t("investment.investmentValues.label.minimumAport")}
                type="text"
                onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.minimumAport = e.target.value }); setTotalError(false) }}
                onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                value={investmentValues.minimumAport ?? ""}
                placeholder="5.000,00"
                className="min-aport-input"
                span={t("investment.investmentValues.span.minimumAport")}
                before={"R$"}
                autoComplete="off"
                errorMessage={t("investment.investmentValues.errors.minimumAport")}
                error={totalError}
                required
              />
              <div className="down-input-group">
                <Input
                  id="min-capture"
                  label={t("investment.investmentValues.label.minCapitation")}
                  type="text"
                  onChange={(e: any) => { handleMinFundingInput(e); setInvestmentValues((investmentValue) => { investmentValue.minCapture = e.target.value.replace(",", ".") }); setMinCaptureError(false) }}
                  value={investmentValues.minCapture}
                  placeholder="100,00"
                  className="min-value"
                  span={t("investment.investmentValues.span.minCapitation")}
                  required={true}
                  before={"%"}
                  autoComplete="off"
                  errorMessage={t("investment.investmentValues.errors.minCapture")}
                  error={minCaptureError}
                  maxLength={5}
                />
                <Input
                  id="participation"
                  label={t("investment.investmentValues.label.authorParticipation")}
                  type="text"
                  onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.userParticipation = e.target.value }); setParticipationError(false) }}
                  onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                  value={investmentValues.userParticipation ?? ""}
                  placeholder="25.000,00"
                  className="participation-input"
                  span={t("investment.investmentValues.span.authorParticipation")}
                  required={true}
                  before={"R$"}
                  autoComplete="off"
                  errorMessage={t("investment.investmentValues.errors.participation")}
                  error={participationError}
                />
              </div>

              <TextArea
                id="value-justification"
                label={t("investment.investmentValues.label.justification")}
                onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.valueJustification = e.target.value }); setJustificationError(false) }}
                value={investmentValues.valueJustification}
                placeholder={t("investment.investmentValues.placeholder.valuesJustification")}
                className="value-justification-input"
                required={true}
                autoComplete="off"
                errorMessage={t("investment.investmentValues.errors.justificationValue")}
                error={justificationError}
                maxLength={500}
              />
            </div>
          </div>

          <div className="rentability-container">
            <h1>{t("investment.investmentValues.termProfit")}</h1>
            <p className="rentability-text first">
              {t("investment.investmentValues.termProfitDescription.firstParagraph.first")}
              <span className="text-blue-500">{t("investment.investmentValues.termProfitDescription.firstParagraph.second")}</span>
              {t("investment.investmentValues.termProfitDescription.firstParagraph.third")}
              <span className="text-green-500">{t("investment.investmentValues.termProfitDescription.firstParagraph.fourth")}</span>
              {t("investment.investmentValues.termProfitDescription.firstParagraph.fifth")}
              <span className="text-red-500">{t("investment.investmentValues.termProfitDescription.firstParagraph.sixth")}</span>
              {t("investment.investmentValues.termProfitDescription.firstParagraph.seventh")}
            </p>
            <p className="rentability-text second">
              {t("investment.investmentValues.termProfitDescription.secondParagraph")}
            </p>

            <div className="form">
              <div className="input-block">
                <div className="input-row">
                  <h2>{t("investment.investmentValues.profitTitles.expectedScenario")}</h2>
                  <div className="down-input-group">
                    {newInvestment.investment_type === 0 &&
                      <InputMasked
                        id="expected-term"
                        label={t("investment.investmentValues.label.term")}
                        type="text"
                        onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.expectedDate = e.target.value }); setExpectedTermError(false) }}
                        value={investmentValues.expectedDate ?? ""}
                        placeholder="12"
                        className="expected-term-input"
                        span={t("investment.investmentValues.span.term.text")}
                        required={true}
                        before={t("investment.investmentValues.span.term.months")}
                        autoComplete="off"
                        errorMessage={t("investment.investmentValues.errors.term")}
                        error={expectedTermError}
                        mask="999"
                      />
                    }

                    <Input
                      id="expected-profit"
                      label={t("investment.investmentValues.label.grossProfit")}
                      type="text"
                      onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.expectedValue = e.target.value }); setExpectedProfitError(false) }}
                      onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                      value={investmentValues.expectedValue ?? ""}
                      placeholder="25.000,00"
                      className="expected-profit-input"
                      span={t("investment.investmentValues.span.grossProfit")}
                      required={true}
                      before="R$"
                      autoComplete="off"
                      errorMessage={t("investment.investmentValues.errors.expectedProfit")}
                      error={expectedProfitError}
                    />
                  </div>
                </div>

                <div className="input-row">
                  <h2>{t("investment.investmentValues.profitTitles.overcomeScenario")}</h2>
                  <div className="down-input-group">
                    {newInvestment.investment_type === 0 &&
                      <InputMasked
                        id="overcome-term"
                        label={t("investment.investmentValues.label.term")}
                        type="text"
                        onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.overcomeDate = e.target.value }); setOvercomeTermError(false) }}
                        value={investmentValues.overcomeDate ?? ""}
                        placeholder="12"
                        className="overcome-term-input"
                        span={t("investment.investmentValues.span.term.text")}
                        required={true}
                        before={t("investment.investmentValues.span.term.months")}
                        autoComplete="off"
                        errorMessage={t("investment.investmentValues.errors.term")}
                        error={overcomeTermError}
                        mask="999"
                      />
                    }

                    <Input
                      id="overcome-profit"
                      label={t("investment.investmentValues.label.grossProfit")}
                      type="text"
                      onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.overcomeValue = e.target.value }); setOvercomeProfitError(false) }}
                      onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                      value={investmentValues.overcomeValue ?? ""}
                      placeholder="25.000,00"
                      className="overcome-profit-input"
                      span={t("investment.investmentValues.span.grossProfit")}
                      required={true}
                      before="R$"
                      autoComplete="off"
                      errorMessage={t("investment.investmentValues.errors.overcomeProfit")}
                      error={overcomeProfitError}
                    />
                  </div>
                </div>

                <div className="input-row">
                  <h2>{t("investment.investmentValues.profitTitles.pessimisticScenario")}</h2>
                  <div className="down-input-group">
                    {newInvestment.investment_type === 0 &&
                      <InputMasked
                        id="pessimist-term"
                        label={t("investment.investmentValues.label.term")}
                        type="text"
                        onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.pessimistDate = e.target.value }); setPessimistTermError(false) }}
                        value={investmentValues.pessimistDate ?? ""}
                        placeholder="12"
                        className="pessimist-term-input"
                        span={t("investment.investmentValues.span.term.text")}
                        required={true}
                        before={t("investment.investmentValues.span.term.months")}
                        autoComplete="off"
                        errorMessage={t("investment.investmentValues.errors.term")}
                        error={pessimistTermError}
                        mask="999"
                      />
                    }

                    <Input
                      id="pessimist-profit"
                      label={t("investment.investmentValues.label.grossProfit")}
                      type="text"
                      onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.pessimistValue = e.target.value }); setPessimistProfitError(false) }}
                      onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
                      value={investmentValues.pessimistValue ?? ""}
                      placeholder="25.000,00"
                      className="pessimist-profit-input"
                      span={t("investment.investmentValues.span.grossProfit")}
                      required={true}
                      before="R$"
                      autoComplete="off"
                      errorMessage={t("investment.investmentValues.errors.pessimistProfit")}
                      error={pessimistProfitError}
                    />
                  </div>
                </div>

                <TextArea
                  id="rentability-justification"
                  label={t("investment.investmentValues.label.justification")}
                  onChange={(e: any) => { setInvestmentValues((investmentValue) => { investmentValue.rentabilityJustification = e.target.value }); setRentabilityJustificationError(false) }}
                  value={investmentValues.rentabilityJustification}
                  placeholder={t("investment.investmentValues.placeholder.profitJustification")}
                  className="rentability-justification-input"
                  required={true}
                  autoComplete="off"
                  errorMessage={t("investment.investmentValues.errors.rentabilityJustification")}
                  error={rentabilityJustificationError}
                  maxLength={500}
                />
              </div>

              <DocumentsInput
                title={t("investment.investmentValues.documents.title")}
                subtitle={t("investment.investmentValues.documents.subtitle")}
                documents={investmentValues.documents}
                showProgressBar={showProgress}
                uploadFileProgress={uploadProgress}
                addFileCallback={addFiles}
                errorState={filesError}
                errorSetState={setFilesError}
                documentsComponent={
                  investmentValues.documents !== null &&
                  investmentValues.documents.map((file: File | ServerFile, index: number) => (
                    <InvestmentDocument file={file} index={index} key={index} investmentContext={investmentValues} setInvestmentContext={setInvestmentValues} />
                  ))
                }
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="../images"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => submitValues()} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}