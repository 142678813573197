import { ChangeEventHandler } from 'react'
import "./RadioCheckbox.sass"

interface RadioCheckboxProps {
  id?: string
  label?: string,
  htmlFor?: string,
  checked?: boolean,
  name?: string,
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export const RadioCheckbox = ({ id, label, htmlFor, name, checked, onChange }: RadioCheckboxProps) => {

  return (
    <>
      <label htmlFor={htmlFor} className="radio-content">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          name={name}
          className={"radio-input"}
          onChange={onChange}
        />
        <span>{label}</span>
      </label>
    </>
  )
}