import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaAngleLeft, FaPlusCircle } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { Column } from "react-table"
import { ProposeProps } from "../../../contexts/CreateInvestment"
import api from "../../../utils/api"
import { BackButton } from "../../Buttons/BackButton"
import { ButtonPrimary } from "../../Buttons/ButtonPrimary"
import { Footer } from "../../Footer"
import { DashboardHeader } from "../../Headers/DashboardHeader"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import "../DashboardInvestments.sass"
import { ProposesTable } from "./ProposesTable"

export const MyProposes = () => {

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [proposes, setProposes] = useState<ProposeProps[]>([])

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  useEffect(() => {

    setIsDataLoading(true)

    api.get<ProposeProps[]>("/api/investment/proposes").then(
      response => {
        setProposes([...response.data])
        setIsDataLoading(false)
      }
    )
  }, [])

  const columns = useMemo<Column[]>(() => [
    {
      Header: t("dashboard.investments.table.title"),
      accessor: "title"
    },
    {
      Header: t("dashboard.investments.table.progress"),
      accessor: "situation"
    },
    {
      Header: t("dashboard.investments.table.step"),
      accessor: "stage"
    },
    {
      Header: t("dashboard.investments.table.valueAvailable"),
      accessor: "available_value"
    },
    {
      Header: t("dashboard.investments.table.rentability"),
      accessor: "rentability"
    },
  ], [t])

  function createInvestment() {
    navigate("/investment/null/create/")
  }

  return (
    <div className="my-investments">
      <DashboardHeader />
      <div className="content">
        <div className="investments-card">
          <div className="title-text">
            <BackButton
              className="back-button"
              icon={<FaAngleLeft />}
              children={t("dashboard.investments.backButton")}
            />
            <ButtonPrimary
              onClick={createInvestment}
              children={<><FaPlusCircle /> {t("dashboard.investments.new")}</>}
              className="new-button"
            />
          </div>
          <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
            <GreenLoadingRing />
          </div>
          <div className={` ${isDataLoading ? 'hidden' : ''}`}>
            {/* <Outlet context={{ data: proposes, columns: columns, tableTitle: t("dashboard.investments.propose") } as ProposesTableProps} /> */}
            <ProposesTable data={proposes} columns={columns} tableTitle={t("dashboard.investments.propose")} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}