import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { SwiperProps, SwiperSlide } from "swiper/react"
import { useAlertContext } from "../../../../contexts/AlertContext"
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import { AddImageSlider } from "../../../Slider/AddImageSlider"
import { SwiperImage } from "../../../Slider/AddImageSlider/SwiperImage"
import "../Validate.sass"
import { ValidateInvestmentButtons } from "../ValidateInvestmentButtons"

export const ValidateImagesInvestment = () => {

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const sliderSettings: SwiperProps = {
    freeMode: true,
    className: "swiper-container-horizontal-reg-inv",
    slidesPerView: 4,
    spaceBetween: 20,
    scrollbar: true,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current
    }
  }

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { pages, progress, setProgress, investmentImages, isDataLoading } = useValidateInvestment()

  const { presentAlert } = useAlertContext()

  const investmentToken = useParams<{ id: string }>().id

  useEffect(() => {
    setProgress(2)
  })

  const confirmImages = () => {

    const data: Array<ValidatePages> = pages.filter(page => page !== null && (page.page_id === progress))

    if (data.length < 1) {
      presentAlert(
        {
          title: t("investment.validate.alert.empty.title"),
          text: t("investment.validate.alert.empty.text"),
          type: "danger",
          timeout: 4000
        }
      )

      return
    }

    setIsLoading(true)

    api.put(`/api/investment/${investmentToken}/validate`, data)
      .then(() => {
        navigate(`/investment/${investmentToken}/validate/values`)
      }).catch(() => {
        presentAlert(
          {
            title: t("investment.validate.alert.error.title"),
            text: t("investment.validate.alert.error.text"),
            type: "danger",
            timeout: 3000
          }
        )
      }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1 className="title">{t("investment.images.title")}</h1>
          <span className="subtitle">{t("investment.images.description")}</span>

          <div className="validate-images-container">
            <ValidateInvestmentButtons page_id={2} input_id={1} textareaId="validate-image-investment" textareaLabel={t("investment.images.title")} />
            <AddImageSlider
              sliderSettings={sliderSettings}
              images={investmentImages.images}
              documentSliderComponent={
                investmentImages.images.map((data, index) =>
                  <SwiperSlide key={index}>
                    <SwiperImage data={data} index={index} canDelete={false} />
                  </SwiperSlide>
                )
              }
            />
          </div>

        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="../"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => confirmImages()} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}