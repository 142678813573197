import { Outlet } from "react-router-dom";
import "./SignUpCard.sass";

export const SignUpCard = () => {
  return (
    <>
      <div className="signup-logo">
        <a href="/" >
          <img src="/single-inv-black.svg" alt="Logo Single Investimentos" />
        </a>
      </div>
      <div className="signup-card">
        <Outlet />
      </div>
    </>
  );
};
