import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, useState } from 'react';
import { IoAdd } from "react-icons/io5";
import { SmartLinkSmall } from '../../SmartLink';
import '../Input.sass';

interface InputLinkProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
  /**
   * Iput label, helps on accessibility of the project as well.
   */
  label?: string
  /**
   * Links to render
   */
  links?: string[]
  /**
   * Is a text that is under the input element, can be used for describe the field, drescribe constraints, etc. 
   */
  span?: string | ReactNode
  /**
   * Input error mode.
   */
  error?: boolean
  /**
   * Is a red error message that is under the input element, can be used for alert the user about something wrong.
   */
  errorMessage?: string
  /**
   * Is a label or component placed before the input element that is used for some information, can be used for money nottation. 
   */
  before?: string
  /**
   * Is a label or component placed after the input element that is used for some action, can be used for toggle view password. 
   */
  after?: string | ReactNode
  /**
   * To disabled all the input, include the button.
   */
  disabled?: boolean
  /**
   * On change is only called when link is submitted and validated
   */
  onChange?: (value: string) => void
  /**
   * Remove link is a function to remove local saved links from array
   */
  removeLink?: (value: number) => void
}


/**
 * Input Links with labels and smart links bellow
 * 
 * @author Samuel S. de Ataídes
 * @since 2022-02-29
 */
export const InputLinks = ({ id, label, type, onChange, removeLink, autoComplete, value, placeholder, className, span, links, required, errorMessage, disabled, before, after, ...attr }: InputLinkProps) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [currentLink, setCurrentLink] = useState<string>("")

  const addLink = (link: string) => {
    if (link.match(/(?:(?:https?):\/\/)/igm) == null) {
      link = `https://${link}`
    }
    // OLD REGEX
    // const domain = link.match(/(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm)
    const domain = link.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/igm)
    if (domain === null) {
      setHasError(true)
      return
    }

    onChange !== undefined && onChange(link)
    setCurrentLink("")

  }

  return (
    <div className={`input-default ${className ? className : ''} ${hasError ? 'input-default-error' : ''}`}>
      {
        label !== undefined && <label htmlFor={id}>{label}{required === true ? "*" : ''}</label>
      }
      <div className='input-default-group'>
        {
          before !== undefined &&
          <div className='input-default-group-before rounded-tl-md rounded-bl-md'>
            <div>
              {before}
            </div>
          </div>
        }
        <div className="link-group">
          <input
            type={type}
            id={id}
            onChange={e => { setCurrentLink(e.target.value); setHasError(false) }}
            onKeyDown={e => { if (e.keyCode === 13) { addLink(currentLink) } }}
            value={currentLink}
            placeholder={placeholder}
            autoComplete={autoComplete}
            required={required}
            disabled={disabled}
            {...attr}

            className={`w-11/12 ${before === undefined && after === undefined ? "rounded-md" : (before !== undefined && after !== undefined ? "" : (before !== undefined ? "rounded-tr-md rounded-br-md" : "rounded-tl-md rounded-bl-md"))}`}
          />

          <button type="button" disabled={disabled} onClick={e => { addLink(currentLink) }}>
            <IoAdd className="icon" />
          </button>
        </div>
        {
          after !== undefined &&
          <div className='input-default-group-after rounded-tr-md rounded-br-md'>
            {after}
          </div>
        }
      </div>
      {
        span !== undefined && <span>{span}</span>
      }
      {
        errorMessage !== undefined && <span className="input-error-message">{errorMessage}</span>
      }
      <div className="flex flex-col gap-2 py-2">
        {
          links !== undefined && (links.map((link, index) => (
            <SmartLinkSmall link={link} index={index} close={removeLink} key={index} />
          )))
        }
      </div>
    </div>
  );
};
