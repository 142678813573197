import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useAlertContext } from "../../../../contexts/AlertContext";
import { ServerFile } from "../../../../contexts/InvestmentContext";
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment";
import api from "../../../../utils/api";
import { BackButton } from "../../../Buttons/BackButton";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { DocumentsInput } from "../../../Input/DocumentsInput";
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument";
import { Input } from "../../../Input/Input";
import { InputMasked } from "../../../Input/InputMasked";
import { TextArea } from "../../../Input/TextArea";
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing";
import { ValidateInvestmentButtons } from "../ValidateInvestmentButtons";

export const ValidateValuesInvestment = () => {

  const navigate = useNavigate()

  const { t } = useTranslation()

  const { progress, setProgress, pages, newInvestment, investmentValues, isDataLoading } = useValidateInvestment()

  const { presentAlert } = useAlertContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const investmentToken = useParams<{ id: string }>().id

  const confirmValues = () => {

    const data: Array<ValidatePages> = pages.filter(page => page !== null && (page.page_id === progress))

    if ((newInvestment.investment_type === 0 && data.length < 13) || (data.length < 10 && newInvestment.investment_type === 1)) {
      presentAlert(
        {
          title: t("investment.validate.alert.empty.title"),
          text: t("investment.validate.alert.empty.text"),
          type: "danger",
          timeout: 4000
        }
      )

      return
    }

    setIsLoading(true)

    api.put(`/api/investment/${investmentToken}/validate`, data)
      .then(() => {
        navigate(`/investment/${investmentToken}/validate/schedule`)
      }).catch(() => {
        presentAlert(
          {
            title: t("investment.validate.alert.error.title"),
            text: t("investment.validate.alert.error.text"),
            type: "danger",
            timeout: 3000
          }
        )

      }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setProgress(3)
  })

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.investmentValues.values")}</h1>

          <div className="form">
            <div className="input-block">
              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={3} input_id={1} textareaId="validate-values-investment" textareaLabel={t("investment.validate.validateTotalValue")} />
                <Input
                  id="investment-value"
                  label={t("investment.investmentValues.label.value")}
                  type="text"
                  value={investmentValues.total ?? ""}
                  className="value-input"
                  span={t("investment.investmentValues.span.value")}
                  before={"R$"}
                  disabled
                />
              </div>
              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={3} input_id={2} textareaId="validate-aport-investment" textareaLabel={t("investment.investmentValues.label.minimumAport")} />
                <Input
                  id="min-aport"
                  label={t("investment.investmentValues.label.minimumAport")}
                  type="text"
                  value={investmentValues.minimumAport ?? ""}
                  placeholder="5.000,00"
                  className="min-aport-input"
                  span={t("investment.investmentValues.span.minimumAport")}
                  before={"R$"}
                  disabled
                />
              </div>
              <div className="down-input-group">
                <div className="validate-input-group-container left-container">
                  <ValidateInvestmentButtons page_id={3} input_id={3} textareaId="validate-capture-investment" textareaLabel={t("investment.investmentValues.label.minCapitation")} />
                  <Input
                    id="min-capture"
                    label={t("investment.investmentValues.label.minCapitation")}
                    type="text"
                    value={investmentValues.minCapture}
                    className="min-value"
                    span={t("investment.investmentValues.span.minCapitation")}
                    required={true}
                    before={"%"}
                    disabled
                  />
                </div>
                <div className="validate-input-group-container">
                  <ValidateInvestmentButtons page_id={3} input_id={4} textareaId="validate-participation-investment" textareaLabel={t("investment.investmentValues.label.authorParticipation")} />
                  <Input
                    id="participation"
                    label={t("investment.investmentValues.label.authorParticipation")}
                    type="text"
                    value={investmentValues.userParticipation ?? ""}
                    className="participation-input"
                    span={t("investment.investmentValues.span.authorParticipation")}
                    required={true}
                    before={"R$"}
                    disabled
                  />
                </div>
              </div>

              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={3} input_id={5} textareaId="validate-value-justification-investment" textareaLabel={t("investment.investmentValues.label.justification")} />
                <TextArea
                  id="value-justification"
                  label={t("investment.investmentValues.label.justification")}
                  value={investmentValues.valueJustification}
                  className="value-justification-input"
                  required={true}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="rentability-container">
            <h1>{t("investment.investmentValues.termProfit")}</h1>
            <p className="rentability-text first">
              {t("investment.investmentValues.termProfitDescription.firstParagraph.first")}
              <span className="text-blue-500">{t("investment.investmentValues.termProfitDescription.firstParagraph.second")}</span>
              {t("investment.investmentValues.termProfitDescription.firstParagraph.third")}
              <span className="text-green-500">{t("investment.investmentValues.termProfitDescription.firstParagraph.fourth")}</span>
              {t("investment.investmentValues.termProfitDescription.firstParagraph.fifth")}
              <span className="text-red-500">{t("investment.investmentValues.termProfitDescription.firstParagraph.sixth")}</span>
              {t("investment.investmentValues.termProfitDescription.firstParagraph.seventh")}
            </p>
            <p className="rentability-text second">
              {t("investment.investmentValues.termProfitDescription.secondParagraph")}
            </p>

            <div className="form">
              <div className="input-block">
                <div className="input-row">
                  <h2>{t("investment.investmentValues.profitTitles.expectedScenario")}</h2>
                  <div className="down-input-group">
                    {newInvestment.investment_type === 0 &&
                      <div className="validate-input-group-container left-container">
                        <ValidateInvestmentButtons page_id={3} input_id={6} textareaId="validate-expected-investment" textareaLabel={t("investment.investmentValues.label.term")} />
                        <InputMasked
                          id="expected-term"
                          label={t("investment.investmentValues.label.term")}
                          type="text"
                          value={investmentValues.expectedDate}
                          className="expected-term-input"
                          span={t("investment.investmentValues.span.term.text")}
                          required={true}
                          before={t("investment.investmentValues.span.term.months")}
                          mask="999"
                          disabled
                        />
                      </div>
                    }

                    <div className="validate-input-group-container right-up-input">
                      <ValidateInvestmentButtons page_id={3} input_id={7} textareaId="validate-expected-value-investment" textareaLabel={t("investment.investmentValues.label.grossProfit")} />
                      <Input
                        id="expected-profit"
                        label={t("investment.investmentValues.label.grossProfit")}
                        type="text"
                        value={investmentValues.expectedValue ?? ""}
                        className="expected-profit-input"
                        span={t("investment.investmentValues.span.grossProfit")}
                        required={true}
                        before="R$"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="input-row">
                  <h2>{t("investment.investmentValues.profitTitles.overcomeScenario")}</h2>
                  <div className="down-input-group">
                    {newInvestment.investment_type === 0 &&
                      <div className="validate-input-group-container left-container">
                        <ValidateInvestmentButtons page_id={3} input_id={8} textareaId="validate-overcome-investment" textareaLabel={t("investment.investmentValues.label.term")} />
                        <InputMasked
                          id="overcome-term"
                          label={t("investment.investmentValues.label.term")}
                          type="text"
                          value={investmentValues.overcomeDate}
                          className="overcome-term-input"
                          span={t("investment.investmentValues.span.term.text")}
                          required={true}
                          before={t("investment.investmentValues.span.term.months")}
                          mask="999"
                          disabled
                        />
                      </div>
                    }

                    <div className="validate-input-group-container right-up-input">
                      <ValidateInvestmentButtons page_id={3} input_id={9} textareaId="validate-overcome-value-investment" textareaLabel={t("investment.investmentValues.label.grossProfit")} />
                      <Input
                        id="overcome-profit"
                        label={t("investment.investmentValues.label.grossProfit")}
                        type="text"
                        value={investmentValues.overcomeValue ?? ""}
                        className="overcome-profit-input"
                        span={t("investment.investmentValues.span.grossProfit")}
                        required={true}
                        before="R$"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="input-row">
                  <h2>{t("investment.investmentValues.profitTitles.pessimisticScenario")}</h2>
                  <div className="down-input-group">
                    {newInvestment.investment_type === 0 &&
                      <div className="validate-input-group-container left-container">
                        <ValidateInvestmentButtons page_id={3} input_id={10} textareaId="validate-pessimist-investment" textareaLabel={t("investment.investmentValues.label.term")} />
                        <InputMasked
                          id="pessimist-term"
                          label={t("investment.investmentValues.label.term")}
                          type="text"
                          value={investmentValues.pessimistDate}
                          className="pessimist-term-input"
                          span={t("investment.investmentValues.span.term.text")}
                          required={true}
                          before={t("investment.investmentValues.span.term.months")}
                          mask="999"
                          disabled
                        />
                      </div>
                    }

                    <div className="validate-input-group-container right-up-input">
                      <ValidateInvestmentButtons page_id={3} input_id={11} textareaId="validate-pessimist-value-investment" textareaLabel={t("investment.investmentValues.label.grossProfit")} />
                      <Input
                        id="pessimist-profit"
                        label={t("investment.investmentValues.label.grossProfit")}
                        type="text"
                        value={investmentValues.pessimistValue ?? ""}
                        className="pessimist-profit-input"
                        span={t("investment.investmentValues.span.grossProfit")}
                        required={true}
                        before="R$"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="validate-input-container">
                  <ValidateInvestmentButtons page_id={3} input_id={12} textareaId="validate-rentability-investment" textareaLabel={t("investment.investmentValues.label.justification")} />
                  <TextArea
                    id="rentability-justification"
                    label={t("investment.investmentValues.label.justification")}
                    value={investmentValues.rentabilityJustification}
                    className="rentability-justification-input"
                    required={true}
                    disabled
                  />
                </div>
              </div>

              <DocumentsInput
                title={t("investment.investmentValues.documents.title")}
                subtitle={t("investment.investmentValues.documents.subtitle")}
                documents={investmentValues.documents}
                validate_page_id={3}
                validate_input_id={13}
                textareaId="validate-documents-investment"
                textareaLabel={t("investment.investmentValues.documents.title")}
                documentsComponent={
                  investmentValues.documents !== null &&
                  investmentValues.documents.map((file: File | ServerFile, index: number) => (
                    <InvestmentDocument file={file} index={index} key={index} investmentContext={investmentValues} />
                  ))
                }
              />

            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="../images"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => confirmValues()} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}