import { FaShieldAlt } from "react-icons/fa"
import { ServerInvestment } from "../../../contexts/InvestmentContext"

export const InvestmentSecurityCard = ({ investment }: { investment: ServerInvestment }) => {
  return (
    <div className="rounded-lg bg-white py-4 pb-6 px-3 mt-11">
      <div className="flex items-center gap-3 mb-4">
        <FaShieldAlt className="text-blue-600" />
        <h1 className="text-lg text-black">Segurança do Investimento</h1>
      </div>

      <span className="text-gray-500 text-sm">{investment.investment_security?.description}</span>
    </div>
  )
}