import moment from "moment"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { Navigate, useNavigate } from "react-router-dom"
import { useAlertContext } from "../../../../contexts/AlertContext"
import { useCreateInvestment } from "../../../../contexts/CreateInvestment"
import { ServerFile, useInvestmentContext } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { Footer } from "../../../Footer"
import { DashboardHeader } from "../../../Headers/DashboardHeader"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { InvestmentInfo } from "../../InvestmentInfo"
import "./ConfirmInvestment.sass"
import { ThemesSlider } from "./ThemesSlider"

export const ConfirmInvestment = () => {
  const { investment } = useInvestmentContext()
  const { investmentSchedule, publicDocuments } = useCreateInvestment()
  const { presentAlert } = useAlertContext()
  const navigate = useNavigate()
  const { t } = useTranslation()


  if (investment === undefined) {
    return <Navigate to={"/"} />
  }

  const documents = investment.investment_documents || []

  const confirmInvestment = () => {
    api.put(`/api/investment/${investment.id}/submit`).then(
      (res) => {
        presentAlert(
          {
            title: t("investment.confirm.alert.success.title"),
            text: t("investment.confirm.alert.success.text"),
            type: "success",
            timeout: 6000
          }
        )

        navigate("/dashboard/my-proposes/")
      },
      (err) => {
        if (err.response.status === 409 && err.response.data.detail === "INVESTMENT_ALREADY_SUBMITTED") {
          navigate("/dashboard/my-proposes/")
        } else {
          presentAlert(
            {
              title: t("investment.confirm.alert.error.title"),
              text: t("investment.confirm.alert.error.text"),
              type: "danger",
              timeout: 4000
            }
          )
        }
      }
    )
  }


  return (
    <div className="my-investments">
      <DashboardHeader />
      <div className="content">
        <div className="confirm-investments-card">
          <div className="investment-revision-container">
            <InvestmentInfo investment={investment} />
            <div className="bottom-container">
              <div className="left-container">
                {/* <AddImageSlider
                  title={t("investment.confirm.images")}
                  images={investmentImages.images}
                  documentSliderComponent=
                  {investmentImages.images.map((data, index) =>
                    <SwiperSlide key={index}>
                      <div className='reg-inv-images-slide-viewer'>
                        <img className='img-blur' src={"id" in data ? `/api/investment/document/${data.id}` : (URL.createObjectURL(data))} alt="" />
                        <img src={"id" in data ? `/api/investment/document/${data.id}` : (URL.createObjectURL(data))} alt="/" className='img-view' />
                      </div>
                    </SwiperSlide>
                  )}
                /> */}
                <h1 className="title" >{t("investment.confirm.operationDetail")}</h1>
                <ThemesSlider themes={investmentSchedule.themes ?? []} />
                <DocumentsInput
                  title={t("investment.confirm.publicDocuments")}
                  documents={publicDocuments.documents}
                  documentsComponent={
                    documents.map((file: ServerFile, index: number) => {
                      return (
                        <InvestmentDocument file={file} index={index} key={index} investmentContext={publicDocuments} />
                      )
                    })
                  }
                />
              </div>

              <div className="right-container flex-col">
                {(investmentSchedule.stages != null && investmentSchedule.stages.length > 0) &&
                  <div className="timeline-container">
                    <h1 className="title">{t("investment.schedule.schedule")}</h1>
                    <div className="timeline">

                      {investmentSchedule.stages.map((stage, index) => {
                        return (
                          <div className="step-row" key={index}>
                            <div className="circle-step">
                              {index !== 0 && <span className={`bar`}></span>}
                              <span className={`step`}>
                                <span className="text">{index + 1}</span>
                              </span>
                            </div>

                            <div className={`step-description ${index !== 0 ? "with-bar" : ""}`}>
                              <span className="finish-date">{moment(stage.expected_date).format("DD/MM/YYYY")}</span>
                              <span className="title-schedule">{stage.title}</span>
                            </div>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                }

                <div className="buttons-container">
                  <div className="row">
                    <BackButton
                      to="../public-documents"
                      children={t("investment.confirm.back")}
                      className="back-button"
                      icon={<FaArrowLeft className="icon" />}
                    />
                    <ButtonPrimary onClick={() => confirmInvestment()} children={t("investment.confirm.confirm")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}