import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FaCaretDown } from "react-icons/fa"
import { Column, useTable } from "react-table"

interface WalletProps {
  entry: string
  type: number
  date: string
  value: string
  partial_balance: string
}

export const WalletTable = ({ data, columns }: { data: Array<WalletProps>, columns: Column[] }) => {

  const { t } = useTranslation()

  const proposes = useMemo(() => data.map(investment => ({
    ...investment,
    entry: investment.entry,
    type: t(`dashboard.wallet.table.typeClass.${investment.type}`),
    date: investment.date,
    value: investment.value,
    partial_balance: investment.partial_balance
  })), [data, t])

  const typeClasses = useMemo(() => ["blue", "yellow", "green", "red"], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: proposes })

  return (
    <>
      <div className="horizontal-wrapper">
        <table {...getTableProps()} className="table-container">
          <thead className="table-head">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="head-row">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={`head-col-container ${column.id === "entry" ? "name-col" : column.id === "date" ? "date-col" : column.id === "type" ? "situation-col" : ""}`}>
                    <div className={`head-col-text`}>{column.render("Header")} <FaCaretDown /></div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-body">
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="body-row">
                  {row.cells.map(cell => {
                    return (
                      <td data-testid="cell" onClick={(e) => { e.stopPropagation(); }} {...cell.getCellProps()} className={`body-col ${cell.column.id === "entry" ? "body-name-col" : ""}`}>
                        <div className={
                          `body-col-text ${cell.column.id === "type"
                            ? "body-transaction-div" :
                            cell.column.id === "entry"
                              ? "body-title-text" : ""}`
                        }>

                          <div className={`                          
                          ${typeClasses[data[index].type]}
                          ${cell.column.id === "type" ? "body-transaction-div" : ""}`}>
                            {cell.render("Cell")}
                          </div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}