import { Swiper, SwiperProps } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import './Slider.sass';

import { A11y, FreeMode, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper';
import { ReactNode } from 'react';

/**
 * Slider props 
 */
interface SliderProps {
  settings: SwiperProps
  children: ReactNode
}

/**
 * Interface used in other sliders
 */
export interface SwiperSettings {
  isStart: boolean
  isEnd: boolean
}
/**
 * Class of a raw slider
 * 
 * @param settings Slider configs 
 * @param children Slider itens arrays 
 */
export default function Slider({ settings, children }: SliderProps) {
  return <Swiper modules={[Navigation, Pagination, A11y, Mousewheel, FreeMode, Scrollbar]} {...settings}>{children}</Swiper>
};