import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaCheck, FaEye, FaEyeSlash, FaSyncAlt, FaTimes } from "react-icons/fa"
import { useAlertContext } from "../../../../contexts/AlertContext"
import { useAuth } from "../../../../contexts/UserContext"
import api from "../../../../utils/api"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { Input } from "../../../Input/Input"
import { SettingsModal } from "../SettingsModal"
import "./UserProfile.sass"

export const UserProfile = () => {

  const { t } = useTranslation()

  const { user } = useAuth()

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [testPwdLength, setTestPwdLength] = useState<boolean>(false);
  const [testPwdUpperandLowerChar, setTestPwdUpperandLowerChar] = useState<boolean>(false);
  const [testPwdNumbers, setTestPwdNumbers] = useState<boolean>(false);
  const [testPwdSpecialsChar, setTestPwdSpecialsChar] = useState<boolean>(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState<string>("");
  const [pwdError, setPwdError] = useState<boolean>(false);
  const [rePwdError, setRePwdError] = useState<boolean>(false);
  const [isPwdVerified, setIsPwdVerified] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(true)
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [currentPasswordError, setCurrentPasswordError] = useState<boolean>(false)

  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  const [email, setEmail] = useState<string | undefined>("")

  const [codeEmailModal, setCodeEmailModal] = useState<boolean>(false)
  const [isPasswordLoading, setIsPasswordLoading] = useState<boolean>(false)

  const { presentAlert } = useAlertContext()

  useEffect(() => {
    setEmail(user?.email)
  }, [user?.email])

  const verifyKey = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  }

  const verifyPassword = (pwd: string) => {
    if (pwd.length >= 8)
      setTestPwdLength(true);
    else
      setTestPwdLength(false);


    if (/[a-z]/.test(pwd) && /[A-Z]/.test(pwd))
      setTestPwdUpperandLowerChar(true);
    else
      setTestPwdUpperandLowerChar(false);


    if (/[0-9]/.test(pwd))
      setTestPwdNumbers(true);
    else
      setTestPwdNumbers(false);


    if (/[^a-zA-Z0-9]/.test(pwd))
      setTestPwdSpecialsChar(true);
    else
      setTestPwdSpecialsChar(false);

    setIsPwdVerified(testPwdLength && testPwdUpperandLowerChar && testPwdNumbers && testPwdSpecialsChar);
  };

  const checkPasswords = () => {
    if (rePassword && (password !== rePassword)) {
      setRePwdError(true)
    }
    else {
      setRePwdError(false)
    }
  };

  const submitPassword = async () => {
    verifyPassword(password)
    checkPasswords()
    if (currentPasswordError || pwdError || rePwdError) return
    setIsPasswordLoading(true)
    try {
      await api.put("/api/user/password", {
        current_password: currentPassword,
        new_password: password
      })
      setPassword("")
      setRePassword("")
      presentAlert({
        title: t("dashboard.settings.userProfile.passwordSuccess.title"),
        type: "success",
        text: t("dashboard.settings.userProfile.passwordSuccess.text"),
        timeout: 5000
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              setCurrentPasswordError(true)
              break;
            case 406:
              setPwdError(true)
          }
        }
      }
    } finally {
      setIsPasswordLoading(false)
    }
  }

  return (
    <div className="user-profile-container">
      <h1 className="title">{t("dashboard.settings.userProfile.title")}</h1>

      <div className="avatar-container">
        <img src="" alt="Profile" />
        <div className="text">
          <span className="highlight-text">{t("dashboard.settings.userProfile.avatar")}</span>
          <span className="sub-text">{t("dashboard.settings.userProfile.pictureSize")}</span>
        </div>
      </div>

      <div className="edit-field">
        <Input
          id="user-name"
          className="user-field"
          type="text"
          autoComplete="name"
          placeholder={"Nome"}
          value={user?.name}
          label={t("dashboard.settings.userProfile.name")}
          disabled
        />
        <div className="alter-input-container">
          <Input
            id="user-email"
            className="user-field"
            type="text"
            onChange={(e) => { setEmail(e.target.value) }}
            autoComplete="email"
            placeholder={"Email"}
            value={email ?? ""}
            label={t("dashboard.settings.userProfile.email")}
          />
          <button className="alter-left" onClick={() => setCodeEmailModal(true)}><FaSyncAlt className="w-3 h-3" />{t("dashboard.settings.userProfile.update")}</button>
          <SettingsModal open={codeEmailModal} handleClose={() => { setCodeEmailModal(false) }} title="Email verification" addressee={email ?? ""} errorCodeMessage="Error" />
        </div>

        <h1 className="subtitle">{t("dashboard.settings.userProfile.subtitle")}</h1>

        <Input
          label={t("dashboard.settings.userProfile.currentPassword")}
          id="change-current_password"
          type="password"
          autoComplete="off"
          className="user-field"
          onChange={(e) => { setCurrentPassword(e.target.value); setCurrentPasswordError(false) }}
          onKeyDown={(e) => verifyKey(e)}
          onKeyUp={(e) => checkPasswords()}
          value={currentPassword}
          placeholder={t("dashboard.settings.userProfile.currentPassword")}
          span={t("register.description.currentPassword")}
          error={currentPasswordError}
          errorMessage={t("dashboard.settings.userProfile.wrongPassword")}
        />

        <Input
          label={t("dashboard.settings.userProfile.newPwd")}
          className="user-field"
          id="change-password"
          type={`${!showPassword ? "password" : "text"}`}
          autoComplete="off"
          onChange={(e) => { setPassword(e.target.value); setPwdError(false); verifyPassword(e.target.value); }}
          onKeyDown={(e) => verifyKey(e)}
          onKeyUp={(e: any) => { setPassword(e.target.value); verifyPassword(e.target.value); }}
          onInput={(e: any) => { setPassword(e.target.value); verifyPassword(e.target.value); }}
          value={password}
          placeholder={"•••••••••••"}
          after={
            <button onClick={() => setShowPassword(!showPassword)}>
              <FaEye className={`${!showPassword ? "block" : "hidden"}`} />
              <FaEyeSlash className={`${showPassword ? "block" : "hidden"}`} />
            </button>}
          span={
            <div className="mt-1">
              <p>{t("register.description.pwd")}</p>
              <ul className="list-disc ml-2">
                <li className="flex gap-1">
                  <FaCheck className={`${testPwdLength ? "block" : "hidden"} text-green-default`} />
                  <FaTimes className={`${!testPwdLength ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdMin8Char")}
                </li>
                <li className="flex gap-1">
                  <FaCheck className={`${testPwdUpperandLowerChar ? "block" : "hidden"} text-green-default`} />
                  <FaTimes className={`${!testPwdUpperandLowerChar ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdUseUpperAndLower")}
                </li>
                <li className="flex gap-1">
                  <FaCheck className={`${testPwdNumbers ? "block" : "hidden"} text-green-default`} />
                  <FaTimes className={`${!testPwdNumbers ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdUseNumbers")}
                </li>
                <li className="flex gap-1">
                  <FaCheck className={`${testPwdSpecialsChar ? "block" : "hidden"} text-green-default`} />
                  <FaTimes className={`${!testPwdSpecialsChar ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdUseSpecialCharacter")}
                </li>
              </ul>
            </div>
          }
          error={pwdError}
          errorMessage={pwdErrorMessage}
        />

        <div className="alter-input-container">
          <Input
            label={t("dashboard.settings.userProfile.confirmPwd")}
            id="change-confirm_password"
            type="password"
            autoComplete="off"
            className="user-field"
            onChange={(e) => setRePassword(e.target.value)}
            onKeyDown={(e) => verifyKey(e)}
            onKeyUp={(e) => checkPasswords()}
            value={rePassword}
            placeholder={t("register.placeholder.confirmPwd")}
            span={t("register.description.confirmPwd")}
            error={rePwdError}
            errorMessage={t("register.errors.differentPasswords")}
          />
          <ButtonPrimary className="password-button" onClick={(e) => { e.preventDefault(); submitPassword() }} children={t("dashboard.settings.userProfile.changePwd")} isLoading={isPasswordLoading} />
        </div>
      </div>
    </div >
  )
}