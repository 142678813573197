import { ECharts, EChartsOption, getInstanceByDom, init } from "echarts";
import { useEffect, useRef } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import "../GraphCard.sass";
import { useTranslation } from "react-i18next";

export const DoughnutCard = () => {

  const { t } = useTranslation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const option: EChartsOption = {
    legend: {
      orient: 'horizontal',
      bottom: "0",
      itemGap: 16,
      selectorPosition: "start",
    },
    tooltip: {
      trigger: "item"
    },
    series: [
      {
        width: 250,
        height: 170,
        type: 'pie',
        center: ["79%", "65%"],
        radius: ['30%', '70%'],
        avoidLabelOverlap: false,
        labelLine: {
          lineStyle: {
            color: 'black'
          },
          smooth: 0.2,
          length: 10,
          length2: 20
        },
        label: {
          formatter: "{d}%"
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold'
          }
        },
        data: [
          { value: 335, name: t(`dashboard.charts.${0}`) },
          { value: 310, name: t(`dashboard.charts.${1}`) },
          { value: 234, name: t(`dashboard.charts.${2}`) },
          { value: 1548, name: t(`dashboard.charts.${3}`) },
          { value: 566, name: t(`dashboard.charts.${6}`) },
          { value: 417, name: t(`dashboard.charts.${7}`) }
        ]
      }
    ]
  };


  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [])

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart !== undefined) {
        chart.setOption(option);
      }
    }
  }, [option]);

  return (
    <div className="card doughnut-card">
      <div className="doughnut-container">
        <h1 className="card-title">{t("dashboard.charts.doughnutTitle")}</h1>
        <button className="open-window-button"><FaExternalLinkAlt className="icon" /></button>
        <div ref={chartRef} className="doughnut-chart">
        </div>
      </div>
    </div>
  )
}