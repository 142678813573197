import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { useAlertContext } from "../../../../contexts/AlertContext"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"

export const ValidatePublicDocuments = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { pages, setProgress, investmentPublicDocument, haveInputError, isDataLoading } = useValidateInvestment()

  const { presentAlert } = useAlertContext()

  const investmentToken = useParams<{ id: string }>().id

  useEffect(() => {
    setProgress(6)
  })

  const confirmPublicDocuments = () => {

    const data: Array<ValidatePages> = pages.filter(page => page !== null && (page.page_id === 6))

    if (data.length < 1) {
      presentAlert(
        {
          title: t("investment.validate.alert.empty.title"),
          text: t("investment.validate.alert.empty.text"),
          type: "danger",
          timeout: 4000
        }
      )

      return
    }

    setIsLoading(true)

    api.put(`/api/investment/${investmentToken}/validate`, data)
      .then(() => {
        const status = haveInputError() ? 2 : 4

        api.put(`/api/investment/${investmentToken}/status`, { "status": status })
          .then(() => {
            navigate(`/dashboard/validate-investments/`)
          })
      }
      ).catch(() => {
        presentAlert(
          {
            title: t("investment.validate.alert.error.title"),
            text: t("investment.validate.alert.error.text"),
            type: "danger",
            timeout: 3000
          }
        )
      }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1 className="title">{t("investment.publicDocuments.title")}</h1>
          <span className="subtitle">{t("investment.publicDocuments.description")}</span>

          <DocumentsInput
            documents={investmentPublicDocument.documents}
            validate_input_id={1}
            validate_page_id={6}
            textareaId="validate-documents-investment"
            textareaLabel={t("investment.publicDocuments.title")}
            documentsComponent={
              investmentPublicDocument.documents !== null &&
              investmentPublicDocument.documents.map((file: File | ServerFile, index: number) => (
                <InvestmentDocument file={file} index={index} key={index} investmentContext={investmentPublicDocument} />
              ))
            }
          />

        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="../security/"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => confirmPublicDocuments()} children={t("register.finally")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}