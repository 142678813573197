import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FaCaretDown } from "react-icons/fa"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Column, useTable } from "react-table"

export interface InvestmentProps {
  id: string
  title: string
  situation: number
  author: string
  available_value: number
  rentability: number | null
}

interface InvestmentTableProps {
  data: InvestmentProps[]
  columns: Column[]
  tableTitle: string
}

export const InvestmentTable = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data, columns, tableTitle } = useOutletContext<InvestmentTableProps>()

  const investments = useMemo(() => data.map(investment => ({
    ...investment,
    title: investment.title,
    progress: investment.situation !== undefined ? t(`dashboard.investments.situation.${investment.situation}`) : t(`dashboard.investments.situation.${-1}`),
    author: investment.author ?? "Airton Martins Ferreira", // Para manter uma visualização do autor.
    rentability: investment.rentability !== null && investment.rentability !== undefined && `${investment.rentability.toFixed(2)}%`
  })), [data, t])

  const situationClasses = useMemo(() => ["gray", "blue", "yellow", "orange", "red", "blue", "green", "yellow", "green"], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: investments })

  const selectInvestment = (investment: any) => {
    navigate(`./investment/${investment.id}`)
  }

  return (
    <>
      <div className="table-title-text">
        <h1 className="title">{tableTitle}</h1>
      </div>
      <div className="horizontal-wrapper">
        <table {...getTableProps()} className="table-container">
          <thead className="table-head">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="head-row">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={`head-col-container ${column.id === "title" ? "name-col" : column.id === "stage" ? "step-col" : column.id === "progress" ? "situation-col" : ""}`}>
                    <div className={`head-col-text`}>{column.render("Header")} <FaCaretDown /></div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-body">
            {rows.map((row, index: number) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="body-row">
                  {row.cells.map(cell => {
                    return (
                      <td role={"cell"} {...cell.getCellProps()} className={`body-col ${cell.column.id === "title" ? "body-name-col" : ""}`} onClick={(e) => { e.stopPropagation(); selectInvestment(cell.row.original) }} >
                        <div className={
                          `body-col-text ${(cell.column.id === "situation" || cell.column.id === "author") ? "body-progress-text" :
                            cell.column.id === "title" ? "body-title-text" :
                              cell.column.id === "stage" ? "body-step-text" : ""
                          }
                          `}>

                          <div className={`  
                          ${situationClasses[data[index].situation + 1]}                        
                          ${cell.column.id === "progress" ? "body-progress-div" :
                              cell.column.id === "stage" ? "body-step-div" : ""}
                          `}>
                            {cell.column.id === "stage" &&
                              <span className="text-xs text-gray-500">01/01/2022</span>
                            }
                            {cell.render("Cell")}
                          </div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}