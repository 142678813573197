import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Headers/Header';
import { DocumentsInput } from '../../components/Input/DocumentsInput';
import { InvestmentDocument } from '../../components/Input/DocumentsInput/InvestmentDocument';
import { Timeline } from '../../components/Investment/Create/ConfirmInvestment/Timeline';
import { InvestmentDetails } from '../../components/Investment/InvestmentDetails';
import { InvestmentInfo } from '../../components/Investment/InvestmentInfo';
import { InvestmentOperationDetails } from '../../components/Investment/InvestmentOperationDetails';
import { InvestmentSecurityCard } from '../../components/Investment/InvestmentSecurityCard';
import { BlackLoadingRing } from '../../components/Loading/BlackLoadingRing';
import { ServerFile, ServerInvestment } from '../../contexts/InvestmentContext';
import api from '../../utils/api';
import "./Investment.sass";


export const Investment = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [investmentData, setInvestmentData] = useState<ServerInvestment>({} as ServerInvestment)
  const id = useParams().id

  useEffect(() => {
    if (id === undefined) return;
    setIsLoading(true)
    api.get<ServerInvestment>(`/api/investment/${id}`).then(response => {
      setInvestmentData(response.data)
    }).catch(error => {
      // setWarning
    }).finally(() => {
      setIsLoading(false)
    })
  }, [id])

  if (id === undefined) {
    return <Navigate to="/" />
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 mt-72 ${!isLoading ? 'hidden' : ''}`}>
        <BlackLoadingRing />
      </div>
      <div className={`flex flex-col w-full max-w-screen items-center min-h-screen justify-between ${isLoading ? 'hidden' : ''}`}>
        <Header />
        <div className="flex flex-col w-full max-w-screen-xl px-2">
          <InvestmentInfo investment={investmentData} />

          <div className=' max-w-[640px] w-full mx-auto xl:ml-16 sm:px-0 px-4'>
            <InvestmentDetails investment={investmentData} />
            <Timeline title={t("investment.schedule.schedule")} stages={investmentData.investment_schedule?.stages} />
            <InvestmentOperationDetails investment={investmentData} />
            <InvestmentSecurityCard investment={investmentData} />
            <div className='md:max-w-[735px] max-w-[400px] w-full my-14'>
              <h1 className="text-2xl font-semibold mb-6">{t("investment.confirm.publicDocuments")}</h1>
              <DocumentsInput
                className='investment-presentation-documents'
                documents={investmentData.investment_documents ?? []}
                documentsComponent={
                  investmentData.investment_documents && investmentData.investment_documents.filter((file: ServerFile) => file.category === "PUBLIC").map((file: ServerFile, index: number) => {
                    return (
                      <InvestmentDocument file={file} index={index} key={index} investment_code={investmentData.code} />
                    )
                  })
                }
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};