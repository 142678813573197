import { Router } from "./router";

import './styles/index.css'

function App() {
  return (
    <Router />
  );
}

export default App;
