import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FaCaretDown } from "react-icons/fa"
import { Column, useTable } from "react-table"
import "./TransactionTable.sass"

export interface TransactionProps {
  title: string
  type: number
  date: string
  step?: any
  value: number
}

interface TransactionTableProps {
  transactions: TransactionProps[]
  haveHeader?: boolean
  columns: Column[]
  title?: string
}

export const TransactionsTable = ({ transactions, haveHeader = true, columns, title }: TransactionTableProps) => {

  const { t } = useTranslation()

  const transactionsFormatted = useMemo(() => transactions.map(transactions => ({
    ...transactions,
    title: transactions.title,
    type: transactions.type !== undefined ? t(`dashboard.investments.transaction.${transactions.type}`) : t(`dashboard.investments.transaction.${-1}`),
    date: transactions.date,
    stage: transactions.step !== undefined ? transactions.step.title : "",
    value: transactions.value !== null && transactions.value !== undefined && `${transactions.value.toFixed(2)}`
  })), [t, transactions])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: transactionsFormatted })

  const transactionTypeClasses = useMemo(() => ["green", "red", "blue"], [])

  return (
    <div className="investment-transactions-container">
      {title && <h2 className="title">{title}</h2>}
      <div className="investment-transaction-table">
        <table {...getTableProps()} className="table-container">
          {
            haveHeader && <thead className="table-head">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="head-row">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={`head-col-container 
                  ${column.id === "date" ? "date-col" :
                        column.id === "type" ? "type-col" :
                          column.id === "title" ? "reason-col" : ""
                      }`}>
                      <div className={`head-col-text`}>{column.render("Header")} <FaCaretDown /></div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          }

          <tbody {...getTableBodyProps()} className="table-body">
            {rows.map((row, index: number) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="body-row">
                  {row.cells.map(cell => {
                    return (
                      <td role={"cell"} {...cell.getCellProps()} className={`body-col ${cell.column.id === "type" ? "body-type-col" : ""}`} onClick={(e) => { e.stopPropagation(); }} >
                        <div className={
                          `body-col-text ${cell.column.id === "date" ? "body-date-text" :
                            cell.column.id === "title" ? "body-title-text" :
                              cell.column.id === "stage" ? "body-step-text" :
                                cell.column.id === "value" ? "body-value-text" : ""
                          }
                          `}>

                          <div className={`
                          ${transactionTypeClasses[transactions[index].type]}
                          ${cell.column.id === "type" ? "body-transaction-div" :
                              cell.column.id === "stage" ? "body-step-div" :
                                cell.column.id === "value" ? "body-value-div" :
                                  cell.column.id === "title" ? "body-title-div" : ""}
                          `}>
                            {cell.column.id === "stage" &&
                              <span className="text-xs text-gray-500">01/01/2022</span>
                            }
                            {cell.render("Cell")}
                          </div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}