import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { AlertContextProvider } from './contexts/AlertContext';
import { InvestmentContextProvider } from './contexts/InvestmentContext';
import { SignUpContextProvider } from './contexts/SignUpContext';
import { UserContextProvider } from './contexts/UserContext';
import "./i18n";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <InvestmentContextProvider>
          <SignUpContextProvider>
            <AlertContextProvider>
              <App />
            </AlertContextProvider>
          </SignUpContextProvider>
        </InvestmentContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
