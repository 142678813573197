import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { SwiperProps, SwiperSlide } from "swiper/react"
import Slider from "../../Slider"
import "./WelcomeSlider.sass"
import { useTranslation } from "react-i18next"

export const WelcomeSlider = () => {

  const { t } = useTranslation()

  const welcomeSliderSettings: SwiperProps = {
    className: "primary-sliders",
    navigation: {
      prevEl: ".prev-btn",
      nextEl: ".next-btn",
    },
    pagination: {
      clickable: true,
      bulletClass: "swiper-pagination-bullet custom-bullet",
      bulletActiveClass: "swiper-pagination-bullet-active custom-bullet-active"
    },
    touchReleaseOnEdges: true
  }

  return (
    <Slider settings={welcomeSliderSettings}>
      <SwiperSlide className="welcome-slider">
        <div className="welcome-content">
          <div className="left-content">
            <div className="welcome-text">
              <span className="welcome-title">{t("landingPage.slider.welcome.title")}<b>{t("landingPage.slider.welcome.boldTitle")}</b></span>
              <span className="welcome-description">{t("landingPage.slider.welcome.description")}</span>
            </div>
          </div>
          <div className="right-content">
            <img src='/landing-happy-woman.png' alt='Happy womem with a phone in your hand.' className='welcome-happy-woman' />
            <img src="/single-inv-white.svg" alt="Single investments logo" className='single-inv-logo' />
          </div>
        </div>

        <button className="prev-btn" onClick={() => { }}>
          <FaAngleLeft className='icon' />
        </button>
        <button className="next-btn" onClick={() => { }}>
          <FaAngleRight className='icon' />
        </button>
      </SwiperSlide>
      <SwiperSlide className="welcome-slider">
        <div className="welcome-content">
          <div className="left-content">
            <div className="welcome-text">
              <span className="welcome-title">{t("landingPage.slider.welcome.title")}<b>{t("landingPage.slider.welcome.boldTitle")}</b></span>
              <span className="welcome-description">{t("landingPage.slider.welcome.description")}</span>
            </div>
          </div>
          <div className="right-content">
            <img src='/landing-happy-woman.png' alt='Happy womem with a phone in your hand.' className='welcome-happy-woman' />
            <img src="/single-inv-white.svg" alt="Single investments logo" className='single-inv-logo' />
          </div>
        </div>

        <button className="prev-btn" onClick={() => { }}>
          <FaAngleLeft className='icon' />
        </button>
        <button className="next-btn" onClick={() => { }}>
          <FaAngleRight className='icon' />
        </button>
      </SwiperSlide>
      <SwiperSlide className="welcome-slider">
        <div className="welcome-content">
          <div className="left-content">
            <div className="welcome-text">
              <h1 className="welcome-title">
                <span>Bem vindo à <b>Single Investimentos!</b></span>
              </h1>
              <span className="welcome-description">A maior plataforma de investimentos alternativos do Brasil.</span>
            </div>
          </div>
          <div className="right-content">
            <img src='/landing-happy-woman.png' alt='Happy womem with a phone in your hand.' className='welcome-happy-woman' />
            <img src="/single-inv-white.svg" alt="Single investments logo" className='single-inv-logo' />
          </div>
        </div>

        <button className="prev-btn" onClick={() => { }}>
          <FaAngleLeft className='icon' />
        </button>
        <button className="next-btn" onClick={() => { }}>
          <FaAngleRight className='icon' />
        </button>
      </SwiperSlide>
    </Slider>
  )
}