import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/UserContext";
import api from "../../utils/api";
import { ButtonPrimary } from "../Buttons/ButtonPrimary";
import { Input } from "../Input/Input";
import "./Login.sass";

/**
 * Login page content
 * @author Samuel Silva de Ataídes
 * @since 2022-09-19
 */
export const Login = () => {
  const { t } = useTranslation();

  const { user, checkAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [enableButton, setEnableButton] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [navigating, setNavigating] = useState(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname + state.from.search : '/dashboard/'

  /** AUTO-LOGIN
   * 
   *  The useEffect below is used to check if there is a valid login token in the browser's header.
   *  The user is supposed to be set automatically if there is a token. Otherwise, the user will continue on page to login.
   */
  useEffect(() => {
    if (navigating) {
      // console.log("Auto login check cancelled: Already navigating")
      return
    }

    if (user !== null) {
      // console.log('User was already set locally')
      // console.log('Auto navigating to: ', from)
      setNavigating(true)
      navigate(from);
    }
    else {
      // console.log('User is not set locally. Checking with the server')
      checkAuth()
        .then((response) => {
          if (response.status === 204) {
            // console.log(response)
            // console.log('User is already authorized to the server')
            // console.log('Auto navigating to: ', from)
            setNavigating(true)
            navigate(from)
          }
          else {
            // console.log('User is not authorized to the server')
            setNavigating(false)
          }
        })
    }
  }, [user, navigate, from, navigating, checkAuth])

  const logIn = (e: FormEvent<HTMLFormElement>) => {

    if (!email || !pwd) {
      setEmailError(false);
      setPasswordError(false);
      return
    }

    e.preventDefault()
    // setEnableButton(true)

    setIsLoading(true)
    api.post("/api/user/login", {
      email: email,
      password: pwd,
      remember_me: remember
    }).then(
      response => {
        checkAuth()
        navigate("/dashboard/register/")
      },
      error => {
        setEmailError(true);
        setPasswordError(true);
        // setEnableButton(false)
      }
    )
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <>
      <h1 className="title">{t('logIn.logIn')}</h1>

      <form onSubmit={logIn} >
        <Input
          label={t("logIn.email")}
          id="email"
          type="email"
          value={email}
          required={true}
          onChange={(e) => { setEmail(e.target.value); setPasswordError(false); setEmailError(false); }}
          onInput={(e: any) => { setEmail(e.target.value); }}
          placeholder={t("logIn.placeholder.email")}
          error={emailError}
          errorMessage={t("logIn.errorMessage")}
        />

        <Input
          label={t("register.pwd")}
          id="password"
          type={`${!showPassword ? "password" : "text"}`}
          value={pwd}
          required={true}
          onChange={(e) => { setPwd(e.target.value); setPasswordError(false); setEmailError(false); }}
          onInput={(e: any) => { setPwd(e.target.value); }}
          placeholder={t("logIn.placeholder.pwd")}
          error={passwordError}
          errorMessage={t("logIn.errorMessage")}
          after={
            <button type="button" onClick={() => setShowPassword(!showPassword)}>
              <FaEye className={`${!showPassword ? "block" : "hidden"}`} />
              <FaEyeSlash className={`${showPassword ? "block" : "hidden"}`} />
            </button>}
        />

        <Link to={"/login/reset-password/"} className="forgot-password">{t("logIn.forgotPassword")}</Link>

        <Input
          className="input-checkbox"
          label={t("logIn.remember")}
          id="remember-me"
          type="checkbox"
          value={remember}
          onChange={e => setRemember(remember => !remember)}
        />

        <div className="create-account">
          <ButtonPrimary type="submit" children={t("logIn.logIn")} className='signup-primary-btn' isLoading={isLoading} />
          <div className="desc">{t("logIn.description.logIn")} <a href="/signup">{t("logIn.description.createAccount")}</a></div>
        </div>
      </form>
    </>
  )
};