import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlertContext } from "../../../../contexts/AlertContext";
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment";
import api from "../../../../utils/api";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { Input } from "../../../Input/Input";
import { TextArea } from "../../../Input/TextArea";
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing";
import { SelectSync } from "../../../Select/SelectSync";
import { SmartLinkSmall } from "../../../SmartLink";
import { optionsInvestmentCategory } from "../../Create/NewInvestment";
import "../Validate.sass";
import { ValidateInvestmentButtons } from "../ValidateInvestmentButtons";

export const ValidateNewInvestment = () => {

  const { pages, progress, setProgress, newInvestment, isDataLoading } = useValidateInvestment()

  const { presentAlert } = useAlertContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const investmentId = useParams<{ id: string }>().id

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [titleDisable, setTitleDisable] = useState<boolean>(true)
  const [descriptionDisable, setDescriptionDisable] = useState<boolean>(true)
  const [categoryDisable, setCategoryDisable] = useState<boolean>(true)
  const [investmentTypeDisable, setInvestmentTypeDisable] = useState<boolean>(true)

  const optionsInvestmentTypes: { value: number, label: string }[] = [
    {
      value: 0,
      label: t("investment.create.investment_type.options.deadline")
    },
    {
      value: 1,
      label: t("investment.create.investment_type.options.noDeadline")
    }
  ]

  const confirmNew = () => {

    const data: Array<ValidatePages> = pages.filter(page => page !== null && (page.page_id === progress))

    if (data.length < 5) {
      presentAlert(
        {
          title: t("investment.validate.alert.empty.title"),
          text: t("investment.validate.alert.empty.text"),
          type: "danger",
          timeout: 4000
        }
      )

      return
    }

    setIsLoading(true)

    api.put(`/api/investment/${investmentId}/validate`, data)
      .then(() => {
        api.put(`/api/investment/${investmentId}/status`, { status: 1 }).then(() => navigate("../images/"))
      }).catch(() => {
        presentAlert(
          {
            title: t("investment.validate.alert.error.title"),
            text: t("investment.validate.alert.error.text"),
            type: "danger",
            timeout: 3000
          }
        )
      }).finally(() => { setIsLoading(false) })
  }

  useEffect(() => {
    setProgress(1)
  })

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.create.newInvestment")}</h1>
          <form className="form">
            <div className="input-block">
              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={1} input_id={1} handleDisable={setTitleDisable} textareaId="validate-title-investment" textareaLabel={t("investment.create.title.label")} />
                <Input
                  label={t("investment.create.title.label")}
                  value={newInvestment.title ?? ""}
                  disabled={titleDisable}
                />
              </div>

              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={1} input_id={2} handleDisable={setDescriptionDisable} textareaId="validate-description-investment" textareaLabel={t("investment.create.description.label")} />
                <TextArea
                  label={t("investment.create.description.label")}
                  value={newInvestment.description ?? ""}
                  disabled={descriptionDisable}
                />
              </div>

              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={1} input_id={3} handleDisable={setCategoryDisable} textareaId="validate-type-investment" textareaLabel={t("investment.create.investment_type.label")} />
                <SelectSync
                  label={t("investment.create.investment_type.label")}
                  value={optionsInvestmentTypes.find(option => option.value === newInvestment.investment_type)}
                  options={optionsInvestmentTypes}
                  span={t("investment.create.investment_type.span")}
                  onChange={() => { }}
                  disabled={categoryDisable}
                />
              </div>

              <div className="validate-input-container up-modal">
                <ValidateInvestmentButtons page_id={1} input_id={4} handleDisable={setInvestmentTypeDisable} textareaId="validate-category-investment" textareaLabel={t("investment.create.category.label")} />
                <SelectSync
                  label={t("investment.create.category.label")}
                  value={optionsInvestmentCategory.find(category => category.value === newInvestment.category)}
                  options={optionsInvestmentCategory}
                  onChange={() => { }}
                  disabled={investmentTypeDisable}
                />
              </div>

              <div className="validate-input-container links">
                <label>{t("investment.create.links.label")}</label>
                <ValidateInvestmentButtons page_id={1} input_id={5} textareaId="validate-link-investment" textareaLabel={t("investment.create.links.label")} />
                <div className="small-links-group">
                  {newInvestment.links.map((element, index) => (
                    <SmartLinkSmall key={index} link={element} index={index} />
                  ))}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="right-container">
          <div className="col">
            <Link className="exit-button" to="/dashboard/validate-investments/"><FaArrowCircleLeft className="icon" /> {t("register.exitIdentification")} </Link>
            <ButtonPrimary isLoading={isLoading} onClick={() => confirmNew()} children={t("register.nextButton")} />
          </div>
        </div>
      </div>
    </>
  )
}