import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FaExternalLinkAlt } from "react-icons/fa"
import { Column } from "react-table"
import { TransactionProps, TransactionsTable } from "../../DashboardInvestments/InvestmentDashboard/TransactionsTable"
import "./TransactionsCard.sass"

export const TransactionsCard = () => {

  const mockTransactions: TransactionProps[] = [
    {
      title: "Distribuição de lucros",
      type: 1,
      date: "01/01/2002",
      value: 340000000
    },
    {
      title: "Distribuição de lucros",
      type: 2,
      date: "01/01/2002",
      value: 340000000
    },
    {
      title: "Distribuição de lucros",
      type: 2,
      date: "01/01/2002",
      value: 340000000
    },
    {
      title: "Distribuição de lucros",
      type: 0,
      date: "01/01/2002",
      value: 340000000
    },
  ]

  const { t } = useTranslation()

  const columns = useMemo<Column[]>(() => [
    {
      Header: t("dashboard.investments.table.title"),
      accessor: "title"
    },
    {
      Header: t("dashboard.investments.table.progress"),
      accessor: "type"
    },
    {
      Header: "Data",
      accessor: "date"
    },
    {
      Header: t("dashboard.investments.table.rentability"),
      accessor: "value"
    },
  ], [t])

  return (
    <div className="card transactions-card-container">
      <div>
        <div className="title-container">
          <h1 className="card-title">Últimas movimentações</h1>
          <button className="open-window-button"><FaExternalLinkAlt className="icon" /></button>
        </div>
        <TransactionsTable transactions={mockTransactions} haveHeader={false} columns={columns} />
      </div>
    </div>
  )
}