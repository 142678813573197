import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { SwiperSlide } from "swiper/react"
import { useCreateInvestment } from "../../../../contexts/CreateInvestment"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { ProgressLine } from "../../../ProgressLine"
import "../../../Register/Register.sass"
import { AddImageSlider } from "../../../Slider/AddImageSlider"
import { SwiperImage } from "../../../Slider/AddImageSlider/SwiperImage"
import "./InvestmentImages.sass"

export const InvestmentImages = () => {

  const { investmentImages, setInvestmentImages, setProgress, investmentId, isDataLoading, getAndSetInvestment } = useCreateInvestment()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(1)
  const [showProgress, setShowProgress] = useState<boolean>(false)

  const [imageError, setImageError] = useState<boolean>(false)

  useEffect(() => {
    setProgress(2)
  })

  const addImages = (e: HTMLInputElement) => {
    setImageError(false)
    setInvestmentImages(investmentImages => {
      let oldArray = investmentImages.images || [];

      if (e.files && e.files.length > 0) {

        let array = [];

        for (let index = 0; index < e.files.length; index++) {
          array.push(e.files[index])
        }

        investmentImages.images = oldArray.concat(array);
      }
    })
  }


  const submitImages = () => {
    if (investmentImages.images.length === 0) {
      setImageError(true)
      return;
    }

    setIsLoading(true)

    const data = new FormData()

    investmentImages.images.forEach(image => {
      if (!("id" in image)) {
        data.append("documents", image)
      }
    })


    if (data.get("documents")?.valueOf()) {
      setShowProgress(true)
      api.post(`/api/investment/${investmentId}/documents?document_category=IMAGES`, data,
        {
          onUploadProgress: (progressEvent) => {
            setUploadProgress(Math.round(progressEvent.loaded * 100) / progressEvent.total)
          }
        }).then(
          () => {
            getAndSetInvestment()
            navigate(`/investment/${investmentId}/create/values`)
          }
        ).finally(() => {
          setIsLoading(false)
          setShowProgress(false)
        })

    } else {
      navigate(`/investment/${investmentId}/create/values`)
      setIsLoading(false)
    }
  }

  return (
    <div className="register-content">
      <div className="left-container">
        <h1 className="title">{t("investment.images.title")}</h1>
        <span className="subtitle">{t("investment.images.description")}</span>

        <AddImageSlider
          images={investmentImages.images}
          addFileCallback={addImages}
          errorState={imageError}
          errorSetState={setImageError}
          isLoading={isDataLoading}
          documentSliderComponent={
            investmentImages.images.map((data, index) =>
              <SwiperSlide key={index}>
                <SwiperImage data={data} index={index} canDelete={true} />
              </SwiperSlide>
            )
          }
        />

        {showProgress && <div className="progress">
          <ProgressLine color="green" value={uploadProgress} />
        </div>}
      </div>
      <div className="right-container">
        <div className="row">
          <BackButton
            to="../"
            className="back-button"
            children={t("register.back")}
            icon={<FaArrowLeft className="icon" />}
          />
          <ButtonPrimary onClick={() => { submitImages() }} children={t("register.nextButton")} isLoading={isLoading} />
        </div>
      </div>
    </div >
  )
}