import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { SelectSync } from "../../../Select/SelectSync";
import "./LinkCompanyModal.sass"
import { useRef } from "react";

export const LinkCompanyModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

  const { t } = useTranslation()

  const filesRef = useRef<HTMLInputElement>(null)

  return (
    <Dialog open={open}
      onClose={handleClose}
      PaperProps={{
        style: { "borderRadius": "12px" }
      }}
      maxWidth={"md"}
      scroll="body"
      fullWidth
    >
      <div className="investment-modal-container">
        <button className="close-button" onClick={() => handleClose()}>
          <FaTimes className="icon" />
        </button>

        <div className="left-container">
          <div className="message-container">
            <h1 className="title">{t("investment.validate.room.linkCompanyModal.title")}</h1>
            <SelectSync
              label={t("investment.validate.room.linkCompanyModal.label")}
              onChange={e => { }}
              placeholder={t("investment.validate.room.linkCompanyModal.placeholder")}
              maxLength={300}
            />
          </div>
          <div className="message-container mt-6">
            <label className="link-company-label">{t("investment.validate.room.linkCompanyModal.logo")}</label>
            <div className="link-company-image-container">
              <img className="link-company-image" src="/gray.png" alt="" onClick={() => filesRef.current !== null ? filesRef.current.click() : false} />
              <input data-testid="file-input-test" type="file" accept="*" ref={filesRef} className="hidden" multiple
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { }}
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <ButtonPrimary
            children={t("investment.validate.room.launchModal.save")}
            onClick={() => { }}
          />
        </div>
      </div>
    </Dialog>
  )
}