import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useSignUp } from "../../../contexts/SignUpContext";
import api from "../../../utils/api";
import { ButtonPrimary } from "../../Buttons/ButtonPrimary";
import { Input } from "../../Input/Input";
import { InputMasked } from "../../Input/InputMasked";
import { Utils } from "../../../utils/utils";


export const Register = () => {
  const { t } = useTranslation();

  const { signUpInfo, setSignUpInfo } = useSignUp();

  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const [cpfError, setCpfError] = useState<boolean>(false);

  const [pwdErrorMessage, setPwdErrorMessage] = useState<string>("");
  const [pwdError, setPwdError] = useState<boolean>(false);

  const [rePwdError, setRePwdError] = useState<boolean>(false);

  const [nameError, setNameError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [testPwdLength, setTestPwdLength] = useState<boolean>(false);
  const [testPwdUpperandLowerChar, setTestPwdUpperandLowerChar] = useState<boolean>(false);
  const [testPwdNumbers, setTestPwdNumbers] = useState<boolean>(false);
  const [testPwdSpecialsChar, setTestPwdSpecialsChar] = useState<boolean>(false);

  const [isPwdVerified, setIsPwdVerified] = useState<boolean>(false);

  const navigate = useNavigate()

  const verifyKey = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  }

  const verifyPassword = (pwd: string) => {
    if (pwd.length >= 8)
      setTestPwdLength(true);
    else
      setTestPwdLength(false);


    if (/[a-z]/.test(pwd) && /[A-Z]/.test(pwd))
      setTestPwdUpperandLowerChar(true);
    else
      setTestPwdUpperandLowerChar(false);


    if (/[0-9]/.test(pwd))
      setTestPwdNumbers(true);
    else
      setTestPwdNumbers(false);


    if (/[^a-zA-Z0-9]/.test(pwd))
      setTestPwdSpecialsChar(true);
    else
      setTestPwdSpecialsChar(false);

    setIsPwdVerified(testPwdLength && testPwdUpperandLowerChar && testPwdNumbers && testPwdSpecialsChar);
  };

  const checkPasswords = () => {
    if (signUpInfo.rePassword && (signUpInfo.password !== signUpInfo.rePassword)) {
      setRePwdError(true)
    }
    else {
      setRePwdError(false)
    }
  };

  const confirmSignUp = () => {
    if (!signUpInfo.name) {
      setNameError(true);
      return;
    }

    if (!isPwdVerified) {
      setPwdErrorMessage(t("register.errors.invalidPassword"));
      setPwdError(true)
      return;
    }

    if (signUpInfo.password !== signUpInfo.rePassword) {
      setRePwdError(true)
      return;
    }

    if (!Utils.isCpf(signUpInfo.cpf)) {
      setCpfError(true)
      return;
    }

    setIsLoading(true);

    api.post("/api/user/signup",
      {
        "email": signUpInfo.email,
        "password": signUpInfo.password,
        "name": signUpInfo.name,
        "cpf": signUpInfo.cpf.replace(/[^0-9]/g, ''),
      })
      .then(
        (res) => {
          if (res.data === null && res.status === 201) {
            navigate("/signup/code")
          }
        }
      )
      .catch(
        (err) => {
          if (err.response.data.detail === "USER_ALREADY_EXISTS") {
            setEmailErrorMessage(t("register.errors.userAlreadyExists"));
            setEmailError(true);
          }

          if (err.response.data.detail === "INVALID_EMAIL") {
            setEmailErrorMessage(t("register.errors.invalidEmail"));
            setEmailError(true);
          }

          if (err.response.data.detail === "INVALID_PASSWORD") {
            setPwdErrorMessage(t("register.errors.invalidPassword"));
            setPwdError(true);
          }
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <h1 className="title">{t('register.signup')}</h1>
      <Input
        label={t("register.fullName")}
        id="full_name"
        type="name"
        required={true}
        autoComplete="name"
        onChange={(e) => { setSignUpInfo(signUpInfo => { signUpInfo.name = e.target.value }); setNameError(false) }}
        value={signUpInfo.name}
        placeholder={t("register.placeholder.fullName")}
        error={nameError}
        errorMessage={t("register.errors.invalidName")}
      />

      <Input
        label={t("register.email")}
        id="email"
        type="email"
        required={true}
        autoComplete="email"
        onChange={(e) => { setSignUpInfo(signUpInfo => { signUpInfo.email = e.target.value }); setEmailError(false); }}
        value={signUpInfo.email}
        placeholder={t("register.placeholder.email")}
        error={emailError}
        errorMessage={emailErrorMessage}
      />

      <InputMasked
        mask={"999.999.999-99"}
        label={t("register.cpf")}
        id="cpf"
        type="text"
        required={true}
        autoComplete="off"
        onChange={(e) => { setSignUpInfo(signUpInfo => { signUpInfo.cpf = e.target.value }); setCpfError(false); }}
        value={signUpInfo.cpf}
        placeholder={t("register.placeholder.cpf")}
        errorMessage={t("register.errors.cpfError")}
        error={cpfError}
      />

      <Input
        label={t("register.pwd")}
        id="new-password"
        type={`${!showPassword ? "password" : "text"}`}
        required={true}
        autoComplete="password"
        onChange={(e) => { setSignUpInfo(signUpInfo => { signUpInfo.password = e.target.value }); setPwdError(false); verifyPassword(e.target.value); }}
        onKeyDown={(e) => verifyKey(e)}
        onKeyUp={(e: any) => { setSignUpInfo(signUpInfo => { signUpInfo.password = e.target.value }); verifyPassword(e.target.value); }}
        onInput={(e: any) => { setSignUpInfo(signUpInfo => { signUpInfo.password = e.target.value }); verifyPassword(e.target.value); }}
        value={signUpInfo.password}
        placeholder={t("register.placeholder.pwd")}
        after={
          <button onClick={() => setShowPassword(!showPassword)}>
            <FaEye className={`${!showPassword ? "block" : "hidden"}`} />
            <FaEyeSlash className={`${showPassword ? "block" : "hidden"}`} />
          </button>}
        span={
          <div className="mt-1">
            <p>{t("register.description.pwd")}</p>
            <ul className="list-disc ml-2">
              <li className="flex gap-1">
                <FaCheck className={`${testPwdLength ? "block" : "hidden"} text-green-default`} />
                <FaTimes className={`${!testPwdLength ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdMin8Char")}
              </li>
              <li className="flex gap-1">
                <FaCheck className={`${testPwdUpperandLowerChar ? "block" : "hidden"} text-green-default`} />
                <FaTimes className={`${!testPwdUpperandLowerChar ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdUseUpperAndLower")}
              </li>
              <li className="flex gap-1">
                <FaCheck className={`${testPwdNumbers ? "block" : "hidden"} text-green-default`} />
                <FaTimes className={`${!testPwdNumbers ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdUseNumbers")}
              </li>
              <li className="flex gap-1">
                <FaCheck className={`${testPwdSpecialsChar ? "block" : "hidden"} text-green-default`} />
                <FaTimes className={`${!testPwdSpecialsChar ? "block" : "hidden"} text-red-500`} />{t("register.description.pwdUseSpecialCharacter")}
              </li>
            </ul>
          </div>
        }
        error={pwdError}
        errorMessage={pwdErrorMessage}
      />

      <Input
        label={t("register.confirmPwd")}
        id="new-confirm_password"
        type="password"
        required={true}
        autoComplete="off"
        onChange={(e) => setSignUpInfo(signUpInfo => { signUpInfo.rePassword = e.target.value })}
        onKeyDown={(e) => verifyKey(e)}
        onKeyUp={(e) => checkPasswords()}
        value={signUpInfo.rePassword}
        placeholder={t("register.placeholder.confirmPwd")}
        span={t("register.description.confirmPwd")}
        error={rePwdError}
        errorMessage={t("register.errors.differentPasswords")}
      />

      <div className="create-account">
        <ButtonPrimary onClick={confirmSignUp} children={t("register.createAccount")} className='signup-primary-btn' isLoading={isLoading}></ButtonPrimary>
        <div className="desc">{t("register.description.createAccount")} <a href="/login">{t("register.logIn")}</a></div>
      </div>
    </>
  )
};