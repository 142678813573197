import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { useAuth } from '../../../contexts/UserContext';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';

import "./Header.sass";
import { HeaderLinks } from './HeaderLinks';

/**
 * This is Header component to use in the main pages
 * 
 * @author Samuel Silva de Ataídes
 * @since 2022-09-15
 */
export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLinksBar, setShowLinksBar] = useState(window.innerWidth >= 1024);

  const { t } = useTranslation();
  const { user } = useAuth()

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        setShowLinksBar(true)
        setIsOpen(false)
      }
      else {
        setShowLinksBar(false)
      }
    })
  }, []);

  return (
    <header className='w-full'>
      <nav className="nav">
        <div className="nav-div">
          <a href="/" className="nav-div-grp-img">
            <img src="/single-inv-black.svg" className="img img-long" alt="Single Investimentos Logo" />
            <img src="/single-black.svg" className="img img-short" alt="Single Investimentos Logo" />
          </a>
          <div className="main-btns">
            {
              user === null ? (
                <>
                  <a href="/login" className="enter">{t("header.logIn")}</a>
                  <ButtonPrimary children={t("header.startUp")} className='header' to="/signup" ></ButtonPrimary>
                </>
              ) : (
                <ButtonPrimary children={t("header.dashboard")} className='header' to="/dashboard/" ></ButtonPrimary>
              )
            }

            <button type="button" onClick={() => { setIsOpen(!isOpen) }} className="btn-menu">
              <span className="sr-only">Abra o Menu Principal</span>
              <IoMdMenu className={`${isOpen ? 'hidden' : ''}`} />
              <IoMdClose className={`${isOpen ? '' : 'hidden'}`} />
            </button>
          </div>
          <div data-testid="large-links" className={`${showLinksBar ? '' : 'hidden'} links-lg`}>
            <ul className="links-lg-ul">
              <HeaderLinks />
            </ul>
          </div>

          <div data-testid="small-links" className={`${isOpen && !showLinksBar ? '' : 'hidden'} links`}>
            <ul className="links-ul">
              <HeaderLinks />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};