import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { Updater, useImmer } from "use-immer"
import { StepProps } from "../components/ProgressBar"
import { useAuth } from "./UserContext"

interface IdentificationProps {
  gender: string,
  profession: string,
  civil_status: string,
  nationality: number | null,
  phone_number: string,
  identification: string,
  birthday: Date | null
}

interface AddressProps {
  country: number | null,
  postal_code: string,
  state: number | null,
  city: number | null,
  district: string,
  number: string,
  address: string,
  complement: string
}

interface SpecialDeclarationsProps {
  from_usa: boolean
  publicly_exposed: boolean
  single_linked: boolean
  none_previous: boolean
}

interface BankDataProps {
  monthly_income: string | null
  real_estates: string | null
  finance_applications: string | null
  movable_properties: string | null
  bank_accounts: {
    id: string | null
    account_type: string
    bank_code: string
    bank_branch: string
    bank_account: string
    digit: string
  }[]
}

interface DocumentsProps {
  document_type: string
  issuing_body: string
  state_issuing_body: number | null,
  issue_date: Date | null
  document_number: string
  mother_name: string
  father_name?: string
  front_document: File | boolean
  back_document: File | boolean
  selfie_document: File | boolean
}

interface RegisterContextProps {
  identificationInfo: IdentificationProps
  setIdentificationInfo: Updater<IdentificationProps>
  addressInfo: AddressProps
  setAddressInfo: Updater<AddressProps>
  specialDeclarations: SpecialDeclarationsProps
  setSpecialDeclarations: Updater<SpecialDeclarationsProps>
  bankData: BankDataProps
  setBankData: Updater<BankDataProps>
  documents: DocumentsProps
  setDocuments: Updater<DocumentsProps>
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  stepsProgress: Array<StepProps>
  setStepsProgress: Dispatch<SetStateAction<Array<StepProps>>>
}

export const RegisterContext = createContext({} as RegisterContextProps)

export const RegisterContextProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const location = useLocation()

  const [progress, setProgress] = useState(user?.register_step || 1)

  const registerStep: number = user?.register_step || 1

  const [stepsProgress, setStepsProgress] = useState<Array<StepProps>>([]);

  useEffect(() => {
    let stepsHrefs: Array<string> = ['identification/', 'code/', 'address/', 'bank-data/', 'security/', 'special/'];
    setStepsProgress(Array.from({ length: 6 - 1 + 1 }, (e, i) => i + 6).map((_, index) => {
      let stepProp: StepProps = {
        step: index + 1,
        completed: registerStep > (index + 1),
        error: false,
        href: stepsHrefs[index]
      }
      return stepProp
    }))
  }, [progress, registerStep]);

  const [identificationInfo, setIdentificationInfo] = useImmer<IdentificationProps>({
    gender: "",
    profession: "",
    civil_status: "",
    nationality: null,
    phone_number: "",
    identification: "",
    birthday: null
  })

  const [addressInfo, setAddressInfo] = useImmer<AddressProps>({
    country: null,
    postal_code: "",
    state: null,
    city: null,
    district: "",
    number: "",
    address: "",
    complement: ""
  })

  const [bankData, setBankData] = useImmer<BankDataProps>({
    monthly_income: null,
    real_estates: null,
    finance_applications: null,
    movable_properties: null,
    bank_accounts: []
  })

  const [documents, setDocuments] = useImmer<DocumentsProps>({
    document_type: "",
    issuing_body: "",
    state_issuing_body: null,
    document_number: "",
    mother_name: "",
    father_name: ""
  } as DocumentsProps)


  const [specialDeclarations, setSpecialDeclarations] = useImmer<SpecialDeclarationsProps>({
    from_usa: false,
    publicly_exposed: false,
    single_linked: false,
    none_previous: false
  })

  if (user === null) {
    return (
      <Navigate to="/login" state={{ from: location }} />
    )
  }

  return (
    <RegisterContext.Provider
      value={{
        identificationInfo,
        setIdentificationInfo,
        addressInfo,
        setAddressInfo,
        specialDeclarations,
        setSpecialDeclarations,
        bankData,
        setBankData,
        documents,
        setDocuments,
        progress,
        setProgress,
        stepsProgress,
        setStepsProgress
      }}
    >
      {children}
    </RegisterContext.Provider>
  )
}

export const useRegister = () => {
  return useContext(RegisterContext)
}