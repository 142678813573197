import { ReactNode } from "react"
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import { Settings } from "./components/Dashboard/Settings"
import { UserData } from "./components/Dashboard/Settings/UserData"
import { UserProfile } from "./components/Dashboard/Settings/UserProfile"
import { InvestmentDashboard } from "./components/DashboardInvestments/InvestmentDashboard"
import { MyInvestments } from "./components/DashboardInvestments/MyInvestments"
import { InvestmentTable } from "./components/DashboardInvestments/MyInvestments/InvestmentTable"
import { MyProposes } from "./components/DashboardInvestments/MyProposes"
import { ValidateInvestments } from "./components/DashboardInvestments/ValidateInvestments"
import { ValidateTable } from "./components/DashboardInvestments/ValidateInvestments/ValidateTable"
import { Wallet } from "./components/DashboardInvestments/Wallet"
import { ConfirmInvestment } from "./components/Investment/Create/ConfirmInvestment"
import { InvestmentImages } from "./components/Investment/Create/InvestmentImages"
import { InvestmentPublicDocuments } from "./components/Investment/Create/InvestmentPublicDocuments"
import { InvestmentSchedule } from "./components/Investment/Create/InvestmentSchedule"
import { InvestmentSecurity } from "./components/Investment/Create/InvestmentSecurity"
import { InvestmentValues } from "./components/Investment/Create/InvestmentValues"
import { NewInvestment } from "./components/Investment/Create/NewInvestment"
import { Balance } from "./components/Investment/Invest/Balance"
import { Confirm } from "./components/Investment/Invest/Confirm"
import { Contracts } from "./components/Investment/Invest/Contracts"
import { ValidateImagesInvestment } from "./components/Investment/Validate/Images"
import { ValidateNewInvestment } from "./components/Investment/Validate/New"
import { ValidatePublicDocuments } from "./components/Investment/Validate/PublicDocuments"
import { ValidateScheduleInvestment } from "./components/Investment/Validate/Schedule"
import { ValidateSecurityInvestment } from "./components/Investment/Validate/Security"
import { ValidateValuesInvestment } from "./components/Investment/Validate/Values"
import { Login } from "./components/Login"
import { ResetPassword } from "./components/Login/ResetPassword"
import { Address } from "./components/Register/Address"
import { BankData } from "./components/Register/BankData"
import { Identification } from "./components/Register/Identification"
import { PhoneVerification } from "./components/Register/PhoneVerification"
import { Security } from "./components/Register/Security"
import { SpecialDeclarations } from "./components/Register/Special"
import { ScrollToTop } from "./components/ScrollToTop"
import { CodeVerification } from "./components/SignupCard/CodeVerification"
import { Register } from "./components/SignupCard/Register"
import { ResendEmailCode } from "./components/SignupCard/ResendCode/ResendEmailCode"
import { ResendPhoneCode } from "./components/SignupCard/ResendCode/ResendPhoneCode"
import { CreateInvestmentProvider, useCreateInvestment } from "./contexts/CreateInvestment"
import { InvestContextProvider, useInvest } from "./contexts/InvestContext"
import { RegisterContextProvider, useRegister } from "./contexts/RegisterContext"
import { useAuth } from "./contexts/UserContext"
import { ValidateInvestmentProvider, useValidateInvestment } from "./contexts/ValidateInvestment"
import { Dashboard } from "./pages/Dashboard"
import { Investment } from "./pages/Investment"
import { InvestmentsSearch } from "./pages/InvestmentsSearch"
import { LandingPage } from "./pages/LandingPage"
import { Register as RegisterPage } from "./pages/Register"
import { Root, RootWithoutPadding } from "./pages/Root"
import { SignUp } from "./pages/SignUp"
import { WaitingRoom } from "./components/DashboardInvestments/WaitingRoom"

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<Root />} >
          <Route path="/" element={<LandingPage />} />
        </Route>

        <Route path="/investments">
          <Route path="" element={<RootWithoutPadding />}>
            <Route path="" element={<InvestmentsSearch />}></Route>
          </Route>
        </Route>

        <Route path="/investment/:id/">
          <Route path="" element={<Investment />} />

          <Route path="invest/" element={
            <ProtectedRoute>
              <InvestContextProvider>
                <RegisterPage
                  useRegister={useInvest}
                  stepAmount={3}
                />
              </InvestContextProvider>
            </ProtectedRoute>
          }>
            <Route path="balance/" element={<Balance />} />
            <Route path="contracts/" element={<Contracts />} />
            <Route path="confirm/" element={<Confirm />} />
          </Route>

          <Route path="validate/" element={
            <ConsultantRoute>
              <ValidateInvestmentProvider>
                <RegisterPage
                  useRegister={useValidateInvestment}
                  stepAmount={6}
                />
              </ValidateInvestmentProvider>
            </ConsultantRoute>
          }>

            <Route path="" element={<ValidateNewInvestment />} />
            <Route path="images/" element={<ValidateImagesInvestment />} />
            <Route path="values/" element={<ValidateValuesInvestment />} />
            <Route path="schedule/" element={<ValidateScheduleInvestment />} />
            <Route path="security/" element={<ValidateSecurityInvestment />} />
            <Route path="public-documents/" element={<ValidatePublicDocuments />} />
          </Route>

          <Route path="create/" element={
            <ProtectedRoute>
              <CreateInvestmentProvider>
                <Outlet />
              </CreateInvestmentProvider>
            </ProtectedRoute>
          }>
            <Route path="" element={
              <RegisterPage
                useRegister={useCreateInvestment}
                stepAmount={6}
              />
            }>
              <Route path="" element={<NewInvestment />} />
              <Route path="images/" element={<InvestmentImages />} />
              <Route path="values/" element={<InvestmentValues />} />
              <Route path="schedule/" element={<InvestmentSchedule />} />
              <Route path="security/" element={<InvestmentSecurity />} />
              <Route path="public-documents/" element={<InvestmentPublicDocuments />} />
            </Route>

            <Route path="confirm-investment/" element={<ConfirmInvestment />} />
          </Route>
        </Route>

        <Route path="/signup/" element={<SignUp />} >
          <Route path="" element={<Register />} />
          <Route path="code" element={<CodeVerification />} />
          <Route path="resend" element={<ResendEmailCode />} />
        </Route>

        <Route path="/login/" element={<SignUp />} >
          <Route path="" element={<Login />} />
          <Route path="reset-password/" element={<ResetPassword />} />
        </Route>

        <Route path="/dashboard/" element={<ProtectedRoute><Outlet /></ProtectedRoute>} >
          <Route path="" element={<Dashboard />} />
          <Route path="settings/" element={<Settings />}>
            <Route path="" element={<UserProfile />} />
            <Route path="user-data/" element={<RegisterContextProvider><UserData /></RegisterContextProvider>} />
            <Route path="bank-details/" element={<></>} />
          </Route>

          <Route path="my-investments/" element={<MyInvestments />}>
            <Route path="" element={<InvestmentTable />} />
            <Route path="investment/:id" element={<InvestmentDashboard />} />
          </Route>

          <Route path="my-proposes/" element={<MyProposes />}>
            <Route path="investment/" element={<InvestmentDashboard />} />
          </Route>

          <Route path="my-wallet/" element={<Wallet />} />

          <Route path="validate-investments/" element={<ConsultantRoute><ValidateInvestments /></ConsultantRoute>}>
            <Route path="" element={<ValidateTable />} />
            <Route path="validate/:id/" element={<WaitingRoom />} />
          </Route>

          <Route path="register/" element={<RegisterContextProvider><RegisterPage useRegister={useRegister} stepAmount={6} /></RegisterContextProvider>} >
            <Route path="identification/" element={<Identification />} />
            <Route path="code/" element={<PhoneVerification />} />
            <Route path="code/resend" element={<ResendPhoneCode />} />
            <Route path="address/" element={<Address />} />
            <Route path="bank-data/" element={<BankData />} />
            <Route path="security/" element={<Security />} />
            <Route path="special/" element={<SpecialDeclarations />} />
            <Route path="" element={<CheckStep />} />
          </Route>
        </Route>

        <Route path="/tests/"></Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes >
    </BrowserRouter >
  )
}

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const location = useLocation()

  if (user === null) {
    return (
      <Navigate to="/login" state={{ from: location }} />
    )
  }

  if (user.is_email_verified === false) {
    return (
      <Navigate to="/signup/code" state={{ from: location }} />
    )
  }


  return (
    <>
      {children}
    </>
  )
}

const ConsultantRoute = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const location = useLocation()

  if (user === null) {
    return (
      <Navigate to="/login" state={{ from: location }} />
    )
  }

  if (user.is_email_verified === false) {
    return (
      <Navigate to="/signup/code" state={{ from: location }} />
    )
  }

  if (user.type === "investor") {
    return (
      <Navigate to="/dashboard/" />
    )
  }

  return (
    <>
      {children}
    </>
  )
}

const CheckStep = () => {
  const { user } = useAuth()

  if (user === null) {
    return (
      <Navigate to="/login" />
    )
  }

  switch (user.register_step) {
    case 1:
      return (
        <Navigate to="/dashboard/register/identification" />
      )
    case 2:
      return (
        <Navigate to="/dashboard/register/code" />
      )
    case 3:
      return (
        <Navigate to="/dashboard/register/address" />
      )
    case 4:
      return (
        <Navigate to="/dashboard/register/bank-data" />
      )
    case 5:
      return (
        <Navigate to="/dashboard/register/security" />
      )
    case 6:
      return (
        <Navigate to="/dashboard/register/special" />
      )
    case 7:
      return (
        <Navigate to="/dashboard/" />
      )
  }

  return (
    <Navigate to="/dashboard/" />
  )

}