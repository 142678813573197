import { useTranslation } from "react-i18next"
import "./HelpModal.sass"

interface ModalProps {
  showModal: boolean
}

export const HelpModal = (props: ModalProps) => {
  const { t } = useTranslation();

  if (props.showModal !== true) {
    return null
  }

  return (
    <div className="modal-content">
      <div className="card modal">
        <span className="register-description">
          {t("register.security.documentModal")}
        </span>
        <div className="pictures-container">
          <div className="picture-div">
            <img src="/selfie-wrong-example0.svg" alt="Wrong selfie with finger in front of the document" />
          </div>
          <div className="picture-div">
            <img src="/selfie-correct-example.svg" alt="Correct selfie" />
          </div>
          <div className="picture-div">
            <img src="/selfie-wrong-example1.svg" alt="Wrong selfie with hat and sunglasses" />
          </div>
        </div>
      </div>
    </div>
  )
}