import { Fragment } from "react";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./ProgressBar.sass";

interface ProgressBarProps {
  /**
   * Array of all progress bar steps
   */
  steps: Array<StepProps>
  /**
   * Current step of the progress bar
   */
  progress: number
}

export interface StepProps {
  /**
   * Step number
   */
  step: number
  /**
   * Text that be placed above the circle
   */
  title?: string
  /**
   * If the step is completed
   */
  completed: boolean
  /**
   * If the step is error
   */
  error: boolean
  /**
   * Step ref location
   */
  href?: string
}

/**
 * Method to get the steps.
 * @param param0 from and to attribute to move the progress
 * @returns Returns a array with the steps
 */

export const ProgressBar = ({ steps, progress }: ProgressBarProps) => {

  const nav = useNavigate();

  const changeStep = (step: StepProps) => {
    if (step.href && step.completed) {
      nav(step.href);
    }
  }

  return (
    <div className="progress-bar">
      {
        steps.map((step, index) => (
          <Fragment key={index}>
            {index !== 0 &&
              <span className={`bar ${(index + 1) <= progress ? "green" : ""}`}></span>
            }
            <span data-testid="test-step" className={`step ${(index + 1) <= progress ? "green" : ""} ${(index + 1) === progress && "active"} ${step.error && "error"} ${step.href && step.completed ? "hover:cursor-pointer" : "hover:cursor-not-allowed"}`} onClick={(e) => changeStep(step)}>
              {step.completed && !step.error && (
                <div className="IconCircle" >
                  <FaCheck className="QontoStepIcon-Icon" />
                </div>
              )}
              {step.error && (
                <div className="IconCircle error">
                  <FaExclamationTriangle className="Icon" />
                </div>
              )}
              <span className="text">{(step.step)}</span>
            </span>
          </Fragment>
        ))
      }
    </div>
  )
}