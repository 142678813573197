import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft, FaPlus, FaTimes } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { SwiperProps } from "swiper/react"
import { ScheduleProps, useCreateInvestment } from "../../../../contexts/CreateInvestment"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { Input } from "../../../Input/Input"
import { InputDatePicker } from "../../../Input/InputDatePicker"
import { TextArea } from "../../../Input/TextArea"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import { ThemesSlider } from "../ConfirmInvestment/ThemesSlider"
import "./ScheduleInvestment.sass"

export const InvestmentSchedule = () => {

  const sliderSettings: SwiperProps = {
    className: "theme-slider",
    slidesPerView: 2,
    spaceBetween: 10,
    navigation: {
      prevEl: ".prev-btn",
      nextEl: ".next-btn"
    }
  }

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { investmentSchedule, setInvestmentSchedule, setProgress, investmentId, isDataLoading, checkInvestmentSchedule, getAndSetInvestment } = useCreateInvestment()

  const [stageTitle, setStageTitle] = useState<string | null>("")
  const [stageDate, setStageDate] = useState<Date | null>(null)

  const [themeTitle, setThemeTitle] = useState<string | null>("")
  const [themeDescription, setThemeDescription] = useState<string | null>("")

  const [checkingError, setCheckingError] = useState<boolean>(false)

  const [stageError, setStageError] = useState<boolean>(false)
  const [stageDateError, setStageDateError] = useState<boolean>(false)
  const [themeError, setThemeError] = useState<boolean>(false)
  const [themeDescriptionError, setThemeDescriptionError] = useState<boolean>(false)

  const [confirmStageError, setConfirmStageError] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const addStage = () => {
    let errorsCount: number = 0;

    if (!stageTitle) {
      setStageError(true);
      errorsCount++
    }
    if (!stageDate) {
      setStageDateError(true);
      errorsCount++
    }

    if (errorsCount > 0)
      return

    setConfirmStageError(false)

    setInvestmentSchedule((investmentSchedule) => {
      let oldArray = investmentSchedule.stages || [];

      if (stageTitle === null || stageDate === null) return

      let array: ScheduleProps["stages"] = [
        {
          title: stageTitle,
          expected_date: stageDate
        }
      ]

      investmentSchedule.stages = oldArray.concat(array).sort((a, b) => {
        if (a.expected_date !== null && b.expected_date !== null) {
          return new Date(a.expected_date)?.getTime() - new Date(b.expected_date)?.getTime()
        }
        return 0
      });
    })
    setStageTitle("")
    setStageDate(null)
  }

  const addTheme = () => {
    let errorsCount: number = 0;

    if (!themeTitle) {
      setThemeError(true);
      errorsCount++
    }
    if (!themeDescription) {
      setThemeDescriptionError(true);
      errorsCount++
    }

    if (errorsCount > 0)
      return

    setInvestmentSchedule(investmentSchedule => {
      let oldArray = investmentSchedule.themes || [];
      if (themeTitle === null || themeDescription === null) return

      let array: ScheduleProps["themes"] = [
        {
          theme: themeTitle,
          description: themeDescription
        }
      ]

      investmentSchedule.themes = oldArray.concat(array);
    })
    setThemeTitle("")
    setThemeDescription("")
  }

  const deleteStage = (index: number) => {
    let arrayCopy = structuredClone(investmentSchedule.stages);
    arrayCopy.splice(index, 1);
    setInvestmentSchedule(investmentSchedule => { investmentSchedule.stages = arrayCopy });
  }

  const deleteTheme = (index: number) => {
    let arrayCopy = structuredClone(investmentSchedule.themes);
    arrayCopy.splice(index, 1);
    setInvestmentSchedule(investmentSchedule => { investmentSchedule.themes = arrayCopy });
  }

  const confirmInvestmentSchedule = () => {
    let errorsCount = 0

    if (!investmentSchedule.stages || investmentSchedule.stages.length <= 1) {
      setConfirmStageError(true)
      errorsCount++
    }


    return errorsCount
  }


  const submitSchedule = () => {
    if (confirmInvestmentSchedule() > 0) return;

    setIsLoading(true)
    setCheckingError(false)

    if (checkInvestmentSchedule(investmentSchedule)) {
      api.post(`/api/investment/new/${investmentId}`, {
        "stages": investmentSchedule.stages?.map((stage, index) => ({ "index": index, "title": stage.title, "expected_date": stage.expected_date })),
        "operation_details": investmentSchedule.themes?.map((theme, index) => ({ "index": index, "theme": theme.theme, "description": theme.description })),
      }).then(
        (response) => {
          getAndSetInvestment()
          navigate(`/investment/${investmentId}/create/security`)
        }
      ).finally(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
      navigate(`/investment/${investmentId}/create/security`)
    }
  }

  useEffect(() => {
    let element = document.getElementsByClassName("schedule-error-message")
    if (element[0] && !checkingError) {
      setCheckingError(true)
      element[0].scrollIntoView({ behavior: "smooth", block: "center" })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmStageError])

  useEffect(() => {
    setProgress(4)
  })


  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.schedule.schedule")}</h1>
          <div className="form">
            <div className="input-block step-button-input">
              <div className="down-input-group">
                <Input
                  id="step-title"
                  label={t("investment.schedule.stage")}
                  type="text"
                  onChange={(e: any) => { setStageTitle(e.target.value); setStageError(false) }}
                  value={stageTitle}
                  placeholder={t("investment.schedule.placeholder.stages")}
                  className="step-title-input"
                  required={true}
                  autoComplete={"off"}
                  errorMessage={t("investment.schedule.errors.stage")}
                  error={stageError}
                  maxLength={40}
                />
                <InputDatePicker
                  dateFormat={"dd/MM/yyyy"}
                  momentDateFormat="DD/MM/YYYY"
                  id="step-value"
                  label={t("investment.schedule.expectedDate")}
                  type="text"
                  selected={stageDate}
                  onChange={(date: Date | null) => { setStageDate(date); setStageDateError(false) }}
                  placeholder="01/01/2000"
                  required={true}
                  errorMessage={t("investment.schedule.errors.stageDate")}
                  error={stageDateError}
                />
              </div>
              <ButtonPrimary
                id="add-step"
                type="button"
                className="add-step-btn"
                onClick={() => addStage()}
                children={<><FaPlus /> {t("investment.schedule.add")}</>}
              />

              {confirmStageError &&
                <div>
                  <span className="schedule-error-message">{t("investment.schedule.errors.stageMinError")}</span>
                </div>
              }
            </div>
            {(investmentSchedule.stages != null && investmentSchedule.stages.length > 0) &&
              <div className="timeline-container">
                <div className="timeline">

                  {investmentSchedule.stages.map((stage, index) => {
                    return (
                      <div className="step-row" key={index}>
                        <div className="circle-step">
                          {index !== 0 && <span className={`bar`}></span>}
                          <span className={`step`}>
                            <span className="text">{index + 1}</span>
                          </span>
                          <button className={`close-button ${index !== 0 ? "with-bar" : ""}`} onClick={() => deleteStage(index)}>
                            <FaTimes />
                          </button>
                        </div>

                        <div className={`step-description ${index !== 0 ? "with-bar" : ""}`}>
                          <span className="finish-date">{moment(stage.expected_date).format("DD/MM/YYYY")}</span>
                          <span className="title-schedule">{stage.title}</span>
                        </div>
                      </div>
                    )
                  })}

                </div>
              </div>
            }

            <div className="input-block">
              <h2 className="second-title">{t("investment.schedule.operationDetail")}</h2>
              <Input
                id="operation-theme"
                label={t("investment.schedule.theme")}
                type="text"
                onChange={(e: any) => { setThemeTitle(e.target.value); setThemeError(false) }}
                value={themeTitle}
                placeholder={t("investment.schedule.placeholder.theme")}
                className="step-title-input"
                errorMessage={t("investment.schedule.errors.theme")}
                error={themeError}
                autoComplete="off"
                maxLength={20}
              />
              <TextArea
                id="operation-description"
                label={t("investment.schedule.description")}
                type="text"
                onChange={(e: any) => { setThemeDescription(e.target.value); setThemeDescriptionError(false) }}
                value={themeDescription}
                placeholder={t("investment.schedule.placeholder.description")}
                className="step-title-input"
                errorMessage={t("investment.schedule.errors.themeDescription")}
                error={themeDescriptionError}
                autoComplete="off"
                maxLength={400}
              />
              <div className="down-input-group step-button-input mb-5">
                <div></div>
                <ButtonPrimary
                  id="add-step"
                  type="button"
                  className="add-step-btn"
                  onClick={() => { addTheme() }}
                  children={<><FaPlus className="icon" /> {t("investment.schedule.add")}</>}
                />
              </div>
            </div>
          </div>

          <ThemesSlider
            themes={investmentSchedule.themes}
            addThemeCallback={addTheme}
            deleteThemeCallback={deleteTheme}
            sliderSettings={sliderSettings}
          />
        </div>

        <div className="right-container">
          <div className="row">
            <BackButton
              to="../values/"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => { submitSchedule() }} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}