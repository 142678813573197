import { Dialog } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaTimes } from "react-icons/fa"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { Input } from "../../../Input/Input"
import { TextArea } from "../../../Input/TextArea"
import { Timeline } from "../../../Investment/Create/ConfirmInvestment/Timeline"
import { SelectSync } from "../../../Select/SelectSync"
import "./RequestResourceModal.sass"

export const RequestResourceModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

  const withdrawOptions = [
    {
      value: 0,
      label: "QRCode para saque em caixa"
    },
    {
      value: 1,
      label: "PIX para o responsável"
    },
    {
      value: 2,
      label: "TED para o fornecedor"
    },
    {
      value: 3,
      label: "PIX para o fornecedor"
    },
  ]

  const [documents, setDocuments] = useState<Array<ServerFile | File>>([])

  const [withdrawType, setWithdrawType] = useState<number>()

  const [options, setOptions] = useState<Array<any>>([]);

  const [bank, setBank] = useState(null)
  const [bankBranch, setBankBranch] = useState(null)
  const [bankAccount, setBankAccount] = useState(null)
  const [bankDigit, setBankDigit] = useState(null)

  const { t } = useTranslation()

  const addFiles = (e: HTMLInputElement) => {
    let oldArray = documents || [];

    if (e.files && e.files.length > 0) {

      let array = [];

      for (let index = 0; index < e.files.length; index++) {
        array.push(e.files[index])
      }

      oldArray = oldArray.concat(array);
    }

    setDocuments(oldArray)
  }

  const deleteFile = (index: number) => {
    let arrayCopy = structuredClone(documents);
    arrayCopy.splice(index, 1);
    setDocuments(arrayCopy);
  }

  const mapResponseToValuesAndLabels = (data: any) => {
    return {
      value: data.code.toString().padStart(3, '0'),
      label: `${data.code.toString().padStart(3, '0')} - ${data.name}`
    }
  };

  useEffect(() => {
    api.get(`https://brasilapi.com.br/api/banks/v1`).then(
      (response) => {
        response.data = response.data.filter((i: any) => i.code !== null)
        response.data = response.data.map((data: any) => mapResponseToValuesAndLabels(data))
        setOptions(response.data);
      })
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { "borderRadius": "12px" }
      }}
      maxWidth={"md"}
      scroll="body"
      fullWidth
    >

      <div className="investment-modal-container">
        <button data-testid="close-button-test" className="close-button" onClick={() => handleClose()}>
          <FaTimes className="icon" />
        </button>

        <div className="left-container">
          <h1 className="title">{t("dashboard.investments.optionButton.getResource")}</h1>
          <div className="message-container">
            <TextArea
              label={t("dashboard.investments.optionButton.requestResourceModal.justification")}
              placeholder={t("dashboard.investments.optionButton.requestResourceModal.justificationPlaceholder")}
              required
            />
          </div>

          <div className="select-step-container">
            <SelectSync
              label={t("dashboard.investments.optionButton.requestResourceModal.step")}
              placeholder={t("dashboard.investments.optionButton.requestResourceModal.stepPlaceholder")}
              options={[]}
              onChange={() => { }}
              className="select-step"
              required
            />
            <div>
              <Timeline stages={[{ expected_date: new Date("01/01/2022"), title: "Construção dos imóveis" }]} />
            </div>
          </div>

          <div className="resource-container">
            <label>{t("dashboard.investments.optionButton.requestResourceModal.resourceAvailable")}</label>
            <h1 className="resource-text">R$ 531.300,00</h1>
            <Input
              label={t("dashboard.investments.optionButton.requestResourceModal.requestLabel")}
              placeholder={"R$ 5.000,00"}
            />
          </div>

          <div className="withdraw-container">
            <SelectSync
              label={t("dashboard.investments.optionButton.requestResourceModal.withdrawalForms")}
              placeholder={t("dashboard.investments.optionButton.requestResourceModal.withdrawalPlaceholder")}
              className="select-withdraw"
              value={withdrawOptions.find(type => type.value === withdrawType)}
              options={withdrawOptions}
              onChange={(option: { value: number, label: string }) => { setWithdrawType(option.value) }}
              required
            />
            {
              withdrawType === 0 ?
                <span className="withdraw-warning">{t("dashboard.investments.optionButton.requestResourceModal.withdraw")}</span>
                : withdrawType === 1 ?
                  <SelectSync
                    label={t("dashboard.investments.optionButton.requestResourceModal.bankAccount")}
                    placeholder={t("dashboard.investments.optionButton.requestResourceModal.bankAccountPlaceholder")}
                    options={[]}
                    onChange={(e) => { }}
                  />
                  : withdrawType === 2 ?
                    <>
                      <SelectSync
                        label={t('register.bankData.bank')}
                        id="bank"
                        type="text"
                        required={true}
                        options={options}
                        onChange={(value: any) => { setBank(value.value) }}
                        value={options.find(option => option.value === bank)}
                        placeholder={t("register.bankData.placeholder.bank")}
                        errorMessage={t("register.bankData.errors.bank")}
                      />
                      <div className="down-input-group">
                        <Input
                          label={t('register.bankData.branch')}
                          id="branch"
                          type="text"
                          required={true}
                          autoComplete="off"
                          onChange={(e) => setBankBranch(e.target.value)}
                          value={bankBranch}
                          placeholder={t("register.bankData.placeholder.branch")}
                          maxLength={15}
                        />
                        <div className="register-account-digit">
                          <Input
                            label={t('register.bankData.account')}
                            id="bank"
                            type="text"
                            required={true}
                            autoComplete="off"
                            onChange={(e) => setBankAccount(e.target.value)}
                            value={bankAccount}
                            placeholder={t("register.bankData.placeholder.account")}
                            maxLength={15}

                          />
                          <Input
                            label={t('register.bankData.digit')}
                            id="accountDigit"
                            type="text"
                            required={true}
                            onChange={(e) => setBankDigit(e.target.value)}
                            value={bankDigit}
                            placeholder={t("register.bankData.placeholder.digit")}
                            maxLength={1}
                          />
                        </div>
                      </div>
                    </>
                    : withdrawType === 3 &&
                    <Input
                      label={t("dashboard.investments.optionButton.requestResourceModal.pix")}
                      placeholder={t("dashboard.investments.optionButton.requestResourceModal.pixPlaceholder")}
                    />
            }
          </div>

          <DocumentsInput
            label={t("dashboard.investments.activityHistoric.activityModal.documents")}
            documents={documents}
            addFileCallback={addFiles}
            documentsComponent={
              documents !== null &&
              documents.map((file: File | ServerFile, index: number) => (
                <InvestmentDocument file={file} index={index} investmentContext={documents} setInvestmentContext={setDocuments} />
              ))
            }
          />
        </div>
        <div className="right-container">
          <ButtonPrimary
            className="save-button"
            children={t("dashboard.investments.activityHistoric.activityModal.save")}
          />
        </div>
      </div>
    </Dialog>
  )
}