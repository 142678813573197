import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const HeaderLinks = () => {
  const { t } = useTranslation();
  return (
    <>
      <li>
        <NavLink className={navData => navData.isActive ? "active" : ""} to="/">{t("header.home")}</NavLink>
      </li>
      <li>
        <NavLink className={navData => navData.isActive ? "active" : ""} to="/investments" >{t("header.investments")}</NavLink>
      </li>
      <li>
        <NavLink className={navData => navData.isActive ? "active" : ""} to="/to-be-changed">{t("header.whoWeAre")}</NavLink>
      </li>
      <li>
        <NavLink className={navData => navData.isActive ? "active" : ""} to="/to-be-changed">{t("header.faq")}</NavLink>
      </li>
      <li>
        <NavLink className={navData => navData.isActive ? "active" : ""} to="/to-be-changed">{t("header.contacts")}</NavLink>
      </li>
    </>
  );
};