import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaAngleLeft } from "react-icons/fa"
import { Outlet, useLocation } from "react-router-dom"
import { Column } from "react-table"
import { DashboardHeader } from "../../../components/Headers/DashboardHeader"
import api from "../../../utils/api"
import { BackButton } from "../../Buttons/BackButton"
import { Footer } from "../../Footer"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import "../DashboardInvestments.sass"
import { InvestmentProps } from "./InvestmentTable"

export const MyInvestments = () => {

  const location = useLocation()

  const { t } = useTranslation()

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  const [investments, setInvestments] = useState<InvestmentProps[]>([])

  const columns = useMemo<Column[]>(() => [
    {
      Header: t("dashboard.investments.table.title"),
      accessor: "title"
    },
    {
      Header: t("dashboard.investments.table.progress"),
      accessor: "progress"
    },
    {
      Header: t("dashboard.investments.table.author"),
      accessor: "author"
    },
    {
      Header: t("dashboard.investments.table.valueInvested"),
      accessor: "value"
    },
    {
      Header: t("dashboard.investments.table.rentability"),
      accessor: "rentability"
    },
  ], [t])

  const mockInvestments = useMemo<InvestmentProps[]>(() => [
    {
      id: "1",
      author: "Airton Martins Ferreira",
      available_value: 1000,
      rentability: 0.1,
      situation: 0,
      title: "Investimento 1"
    }
  ], [])

  return (
    <div className="my-investments">
      <DashboardHeader />
      <div className="content">
        <div className="investments-card">
          <div className="title-text">
            <BackButton
              className="back-button"
              icon={<FaAngleLeft />}
              children={t("dashboard.investments.backButton")}
              to={location.pathname === "/dashboard/my-investments/" ? "../" : "/dashboard/my-investments/"}
            />
          </div>
          <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
            <GreenLoadingRing />
          </div>
          <div className={`${isDataLoading ? 'hidden' : ''}`}>
            <Outlet context={{ data: mockInvestments, columns: columns, tableTitle: t("dashboard.investments.investment") }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}