import { ECharts, EChartsOption, getInstanceByDom, init } from "echarts";
import { useEffect, useRef } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import "../GraphCard.sass";
import { useTranslation } from "react-i18next";

export const BarCard = () => {

  const { t } = useTranslation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const option: EChartsOption = {
    legend: {
      orient: 'horizontal',
      itemGap: 16,
      top: "10",
      selectorPosition: "start",
      padding: [0, 0, 30, 0],

    },
    grid: {
      left: '1%',
      right: '2%',
      bottom: '-1%',
      containLabel: true,
      top: "80"
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    series: [
      {
        name: t("dashboard.charts.0"),
        type: 'bar',
        data: [257485, 61313, 269735, 457946, 175217, 412342, 269735, 457946, 175217]
      },
      {
        name: t("dashboard.charts.1"),
        type: 'bar',
        data: [19325, 23438, 57394, 121594, 134141, 937552, 57394, 121594, 134141]
      },
      {
        name: t("dashboard.charts.2"),
        type: 'bar',
        data: [829217, 691014, 31000, 224917, 572336, 472278, 31000, 224917, 121594]
      },
      {
        name: t("dashboard.charts.3"),
        type: 'bar',
        data: []
      },
      {
        name: t("dashboard.charts.6"),
        type: 'bar',
        data: [69217, 41014, 81000, 269735, 31000, 121594, 31000, 224917, 572336]
      }
      ,
      {
        name: t("dashboard.charts.7"),
        type: 'bar',
        data: [829217, 691014, 31000, 98313, 61313, 472278, 31000, 224917, 572336]
      }
    ]
  };

  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [])

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart !== undefined) {
        chart.setOption(option);
      }
    }
  }, [option]);

  return (
    <div className="card bar-card">
      <div className="bar-container">
        <h1 className="card-title">{t("dashboard.charts.barTitle")}</h1>
        <button className="open-window-button"><FaExternalLinkAlt className="icon" /></button>
        <div ref={chartRef} className="bar-chart">
        </div>
      </div>
    </div>
  )
}