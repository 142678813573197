import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowCircleLeft } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import { useCreateInvestment } from "../../../../contexts/CreateInvestment"
import { InvestmentResponse } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { Input } from "../../../Input/Input"
import { InputLinks } from "../../../Input/InputLinks"
import { TextArea } from "../../../Input/TextArea"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import "../../../Register/Register.sass"
import { SelectSync } from "../../../Select/SelectSync"
import "./NewInvestment.sass"


export const optionsInvestmentCategory: { value: number, label: string }[] = [
  {
    value: 1,
    label: "Atleta e Esportes"
  },
  {
    value: 2,
    label: "Marcas e Produtos"
  },
  {
    value: 3,
    label: "Imobiliária"
  },
  {
    value: 4,
    label: "Artistas"
  }
]


export const NewInvestment = () => {

  const { newInvestment, setNewInvestment, setProgress, investmentId, isDataLoading, checkNewInvestment, getAndSetInvestment } = useCreateInvestment()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [checkingError, setCheckingError] = useState<boolean>(false);

  const [titleError, setTitleError] = useState<boolean>(false)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [investmentTypeError, setInvestmentTypeError] = useState<boolean>(false)
  const [categoryError, setCategoryError] = useState<boolean>(false)
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false)

  const optionsInvestmentTypes: { value: number, label: string }[] = [
    {
      value: 0,
      label: t("investment.create.investment_type.options.deadline")
    },
    {
      value: 1,
      label: t("investment.create.investment_type.options.noDeadline")
    }
  ]

  const confirmNewInvestment = () => {
    let errorsCount: number = 0;

    if (!newInvestment.title) {
      setTitleError(true);
      errorsCount++
    }
    if (!newInvestment.description) {
      setDescriptionError(true);
      errorsCount++
    }
    if (!newInvestment.investment_type && newInvestment.investment_type !== 0) {
      setInvestmentTypeError(true);
      errorsCount++
    }
    if (!newInvestment.category && newInvestment.investment_type !== 0) {
      setCategoryError(true);
      errorsCount++
    }

    if (errorsCount > 0) { setCheckingError(false); return; }

    setSubmitLoading(true)

    if (checkNewInvestment(newInvestment)) {
      api.post<InvestmentResponse>(`${investmentId ? ("/api/investment/new/" + investmentId) : ("/api/investment/new")}`,
        {
          "title": newInvestment.title,
          "description": newInvestment.description,
          "category": newInvestment.category,
          "investment_type": newInvestment.investment_type,
          "links": newInvestment.links
        }).then(
          (response) => {
            navigate(`/investment/${response.data.token}/create/images`)
            getAndSetInvestment(response.data.token)
          }
        ).finally(() => {
          setSubmitLoading(false)
        })
    } else {
      setSubmitLoading(false)
      navigate(`/investment/${investmentId}/create/images`)
    }
  }

  useEffect(() => {

    let element = document.getElementsByClassName("input-default-error")
    if (element[0] && !checkingError) {
      setCheckingError(true)
      element[0].scrollIntoView({ behavior: "smooth", block: "center" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleError, descriptionError, investmentTypeError, categoryError, isSubmitLoading])

  useEffect(() => {
    setProgress(1)
  })

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.create.newInvestment")}</h1>
          <form className="form">
            <div className="input-block">
              <Input
                label={t("investment.create.title.label")}
                value={newInvestment.title}
                onChange={e => { setNewInvestment(investment => { investment.title = e.target.value; setTitleError(false) }) }}
                placeholder={t("investment.create.title.placeholder")}
                error={titleError}
                autoComplete="off"
                errorMessage={t("investment.create.errors.title")}
                maxLength={80}
              />
              <TextArea
                label={t("investment.create.description.label")}
                value={newInvestment.description}
                onChange={e => { setNewInvestment(investment => { investment.description = e.target.value; setDescriptionError(false) }) }}
                placeholder={t("investment.create.description.placeholder")}
                error={descriptionError}
                autoComplete="off"
                errorMessage={t("investment.create.errors.description")}
              />
              <SelectSync
                label={t("investment.create.investment_type.label")}
                value={optionsInvestmentTypes.find(option => option.value === newInvestment.investment_type)}
                options={optionsInvestmentTypes}
                onChange={(option: any) => { setNewInvestment(investment => { investment.investment_type = option.value; setInvestmentTypeError(false) }) }}
                span={t("investment.create.investment_type.span")}
                error={investmentTypeError}
                placeholder={t("investment.create.investment_type.placeholder")}
                autoComplete="off"
                errorMessage={t("investment.create.errors.investmentType")}
              />
              <SelectSync
                label={t("investment.create.category.label")}
                value={optionsInvestmentCategory.find(category => category.value === newInvestment.category)}
                options={optionsInvestmentCategory}
                onChange={(option: { value: number, label: string }) => { setNewInvestment(investment => { investment.category = option.value; setCategoryError(false) }) }}
                error={categoryError}
                placeholder={t("investment.create.category.placeholder")}
                autoComplete="off"
                errorMessage={t("investment.create.errors.category")}
              />
              <InputLinks
                label={t("investment.create.links.label")}
                links={newInvestment.links ?? []}
                onChange={(value) => setNewInvestment(investment => { investment.links.push(value) })}
                removeLink={(index) => { setNewInvestment(investment => { investment.links.splice(index, 1) }) }}
                errorMessage={t("investment.create.errors.link")}
                autoComplete="off"
              />
            </div>
          </form>
        </div>
        <div className="right-container">
          <div className="col">
            <Link className="exit-button" to="/dashboard/my-proposes/" ><FaArrowCircleLeft className="icon" /> {t("register.exitIdentification")} </Link>
            <ButtonPrimary isLoading={isSubmitLoading} onClick={() => confirmNewInvestment()} children={t("register.nextButton")} />
          </div>
        </div>
      </div>
    </>
  )
}