import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSignUp } from "../../../contexts/SignUpContext";
import { useAuth } from "../../../contexts/UserContext";
import api from "../../../utils/api";
import { Utils } from "../../../utils/utils";
import { ButtonPrimary } from "../../Buttons/ButtonPrimary";
import "../SignUpCard.sass";

export const CodeVerification = () => {
  const { t } = useTranslation();

  const [codeErrorMessage, setCodeErrorMessage] = useState<string>("");
  const [codeError, setCodeError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { signUpInfo } = useSignUp();

  const { user, checkAuth, logOut } = useAuth();

  const navigate = useNavigate()

  const confirmCode = () => {

    const inputs = Array.from(document.querySelectorAll("input.input-code-grid"));
    const code = inputs.map((inp: any) => { return inp.value }).join("");

    if (code.length === 4) {

      setIsLoading(true);

      api.post("/api/user/verify/email", { "code": code })
        .then(
          (res) => {
            if (res.data === null && res.status === 200) {
              checkAuth().then(() => {
                navigate("/dashboard/");
              })
            }
          }
        )
        .catch(
          (err) => {
            if (err.response.data.detail === "EXPIRED_CODE") {
              setCodeErrorMessage(t("register.errors.expiredCode"));
              setCodeError(true);
            }

            if (err.response.data.detail === "INVALID_CODE") {
              setCodeErrorMessage(t("register.errors.invalidCode"));
              setCodeError(true);
            }
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
    else {
      setCodeErrorMessage(t("register.errors.invalidCode"));
      setCodeError(true);
    }
  }

  const verifKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    Utils.verifKeyDown(e)
  };

  const verifKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    Utils.verifKeyUp(e, confirmCode)
  }

  const pasteCode = (e: React.ClipboardEvent<HTMLInputElement>) => {
    let clipBoardData = e.clipboardData.getData('Text')

    if (clipBoardData.length < 4 || !(/^[0-9]{4}$/ig.test(clipBoardData))) {
      e.preventDefault();
      return
    }

    const firstInput = (document.getElementById("0") as HTMLInputElement);
    const secondInput = (document.getElementById("1") as HTMLInputElement);
    const thirdInput = (document.getElementById("2") as HTMLInputElement);
    const fourthInput = (document.getElementById("3") as HTMLInputElement);

    firstInput.value = clipBoardData[0]
    secondInput.value = clipBoardData[1]
    thirdInput.value = clipBoardData[2]
    fourthInput.value = clipBoardData[3]

    document.getElementById('confirm-btn')?.focus();
    confirmCode()

  }

  function navigateToLogin() {
    logOut().then(() => { navigate("/login") })
  }

  return (
    <>
      <h1 className="title">{t('register.emailVerification')}</h1>
      <span className="subtitle">{t("register.description.emailVerification", { email: signUpInfo.email === "" ? user?.email : signUpInfo.email })}</span>
      <div className={`code-input ${codeError ? 'code-input-default-error' : ''}`}>
        <label htmlFor="0">{t("register.code")}*</label>

        <div className="code-grid">
          <input type="text" className="input-code-grid" id="0" onPaste={(e) => pasteCode(e)} onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoFocus autoComplete="off" maxLength={1} />
          <input type="text" className="input-code-grid" id="1" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
          <input type="text" className="input-code-grid" id="2" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
          <input type="text" className="input-code-grid" id="3" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
        </div>
        <div className="code-input-error-message">
          {
            codeErrorMessage !== undefined && <span>{codeErrorMessage}</span>
          }
        </div>
      </div>
      <div className="confirm-code">
        <div className="resend-code"><Link to="/signup/resend">{t("register.resendCode")}</Link></div>
        <ButtonPrimary onClick={confirmCode} children={t("register.confirm")} id="confirm-btn" className='signup-primary-btn' isLoading={isLoading} ></ButtonPrimary>
        <div className="resend-code not-your-account"><button onClick={() => { navigateToLogin() }}>{t("register.notYourAccount")}</button></div>
      </div>
    </>
  )
};