import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft, FaFileContract, FaTimes } from "react-icons/fa"
import { Link, Navigate, useParams } from "react-router-dom"

import { useInvest } from "../../../../contexts/InvestContext"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"

import Icon from "../../../Icon"
import { InformationCard } from "../../InformationCard"

/**
 * This component is a Contracts Investment page, where the investor read and agree the terms and contracts. 
 */
export const Contracts = () => {
  const { t } = useTranslation()

  const id = useParams().id

  const { investment, setProgress } = useInvest()

  useEffect(() => {
    setProgress(2)
  })

  if (id === undefined) {
    return <Navigate to="/" />
  }

  return (
    <div className="invest-content">
      <div className="left-container">
        <h1 className="title">{t("investmentPage.investForm.contracts.title")}</h1>
        <span className="subtitle">{t("investmentPage.investForm.contracts.subtitle")}</span>
        <div className="contracts-container">
          <div className="contract-step">
            <div className="step-icon-container">
              <FaFileContract className="contract-icon" />
            </div>
            <div className="step-text-container">
              <h1>{t("investmentPage.investForm.contracts.mainContract")}</h1>
            </div>
            <div className="step-toggle-container">
              <Icon className="step-icon-container bg-gray-white-rounded bg-gray-300"><FaTimes className="icon" /></Icon>
            </div>
          </div>

          <div className="contract-step">
            <div className="step-icon-container">
              <FaFileContract className="contract-icon" />
            </div>
            <div className="step-text-container">
              <h1>{t("investmentPage.investForm.contracts.investmentContract")}</h1>
            </div>
            <div className="step-toggle-container">
              <Icon className="step-icon-container bg-gray-white-rounded bg-gray-300"><FaTimes className="icon" /></Icon>
            </div>
          </div>

          <div className="contract-step">
            <div className="step-icon-container">
              <FaFileContract className="contract-icon" />
            </div>
            <div className="step-text-container">
              <h1>{t("investmentPage.investForm.contracts.sampleContract")}</h1>
            </div>
            <div className="step-toggle-container">
              <Icon className="step-icon-container bg-gray-white-rounded bg-gray-300"><FaTimes className="icon" /></Icon>
            </div>
          </div>
        </div>
      </div>

      <div className="right-container">
        <div className="information-card">
          <InformationCard toInvestForm={true} investment={investment} />
        </div>

        <div className="row button-container">
          <Link className="back-button" to="../balance" ><FaArrowLeft className="icon" /> {t("register.back")} </Link>
          <ButtonPrimary to={"../confirm"} children={t("register.nextButton")} />
        </div>
      </div>
    </div>
  )
}