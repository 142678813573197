import { useState, useEffect } from "react"
import { FaTimes } from "react-icons/fa"
import { AlertProps, useAlertContext } from "../../contexts/AlertContext"
import "./Alert.sass"

export const Alert = ({ title, type, text, timeout }: AlertProps) => {

  const [active, setActive] = useState<boolean>(false)

  const { showAlert, setShowAlert } = useAlertContext()

  useEffect(() => {
    setActive(true);
  }, [])

  useEffect(() => {

    const toClose = setTimeout(() => {
      setActive(false)
      setTimeout(() => {
        setShowAlert(false)
      }, 600)
    }, timeout)

    return () => clearTimeout(toClose)
  })

  const closeMenu = () => {
    setActive(!active)
    setTimeout(() => {
      setShowAlert(!showAlert)
    }, 600)
  }

  return (
    <>
      {showAlert &&
        <div className={`alert-container ${active === true ? "active" : ""}`} data-testid="alert">
          <div className={`alert-card ${type === "success" ? "alert-success" : type === "warning" ? "alert-warning" : type === "danger" ? "alert-danger" : "alert-common"}`}>
            <div className="alert-text">
              <h1>{title}</h1>
              <span>{text}</span>
            </div>
            <button className="alert-button" onClick={() => closeMenu()}>
              <FaTimes className="icon" />
            </button>
          </div>
        </div>
      }
    </>
  )
}