import { Outlet } from "react-router-dom"
import { Footer } from "../../components/Footer"
import { Header } from "../../components/Headers/Header"
import { WelcomeSlider } from "../../components/Landing/WelcomeSlider"

export const Root = () => {
  return (
    <div className="flex flex-col w-full max-w-screen items-center min-h-screen justify-between">
      <Header />
      <WelcomeSlider />
      <div className="flex flex-col w-full max-w-screen-xl px-2">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export const RootWithoutPadding = () => {
  return (
    <div className="flex flex-col w-full max-w-screen items-center min-h-screen h-full justify-between">
      <Header />
      <div className="w-full h-full">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}