import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useCreateInvestment } from "../../../../contexts/CreateInvestment"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { TextArea } from "../../../Input/TextArea"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import "../../../Register/Register.sass"
import { SelectSync } from "../../../Select/SelectSync"
import "./InvestmentSecurity.sass"


export const InvestmentSecurity = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { investmentSecurity, setInvestmentSecurity, setProgress, investmentId, isDataLoading, checkInvestmentSecurity, getAndSetInvestment } = useCreateInvestment()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(1)
  const [showProgress, setShowProgress] = useState<boolean>(false)

  const [checkingError, setCheckingError] = useState<boolean>(false)

  const [securityTypeError, setSecurityTypeError] = useState<boolean>(false)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [filesError, setFilesError] = useState<boolean>(false)

  const securityTypes: { value: number | null, label: string }[] = [
    {
      value: 0,
      label: t("investment.create.investmentSecurity.securityType.options.insurance")
    },
    {
      value: 1,
      label: t("investment.create.investmentSecurity.securityType.options.collateral")
    },
    {
      value: 2,
      label: t("investment.create.investmentSecurity.securityType.options.guarantor")
    },
  ]

  const addFiles = (e: HTMLInputElement) => {
    setInvestmentSecurity(investmentSecurity => {
      let oldArray = investmentSecurity.documents || [];

      if (e.files && e.files.length > 0) {

        let array = [];

        for (let index = 0; index < e.files.length; index++) {
          array.push(e.files[index])
        }

        investmentSecurity.documents = oldArray.concat(array);
      }
    })
  }

  const confirmInvestmentSecurity = () => {
    let errorsCount: number = 0;

    if (!investmentSecurity.securityType && investmentSecurity.securityType !== 0) {
      setSecurityTypeError(true);
      errorsCount++
    }
    if (!investmentSecurity.description) {
      setDescriptionError(true);
      errorsCount++
    }

    if (investmentSecurity.documents === null || investmentSecurity.documents.length <= 0) {
      setFilesError(true);
      errorsCount++
    }

    return errorsCount
  }

  useEffect(() => {
    setProgress(5)
  })

  useEffect(() => {
    let element = document.getElementsByClassName("input-default-error")
    let selectError = document.getElementsByClassName("select-default-error")
    let documentError = document.getElementsByClassName("documents-span-error")
    if ((element[0] || documentError[0]) && !checkingError) {
      setCheckingError(true)
      if (selectError[0]) {
        selectError[0].scrollIntoView({ behavior: "smooth", block: "center" })
      } else if (element[0]) {
        element[0].scrollIntoView({ behavior: "smooth", block: "center" })
      } else if (documentError[0]) {
        documentError[0].scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityTypeError, descriptionError, filesError])

  const submitSecurity = () => {
    if (confirmInvestmentSecurity() > 0) { setCheckingError(false); return };

    setIsLoading(true)
    setShowProgress(true)
    setUploadProgress(0)

    const documents = new FormData()

    investmentSecurity.documents.forEach(document => {
      if (!("id" in document)) {
        documents.append("documents", document)
      }
    })

    let requests = []

    if (checkInvestmentSecurity(investmentSecurity)) {
      requests.push(
        api.post(`/api/investment/new/${investmentId}`, {
          "security_type": investmentSecurity.securityType,
          "description": investmentSecurity.description
        })
      )
    }

    if (documents.get("documents")?.valueOf()) {
      requests.push(api.post(`/api/investment/${investmentId}/documents?document_category=SECURITY`, documents, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round(progressEvent.loaded * 100) / progressEvent.total)
        }
      }))
    }

    if (requests.length > 0) {
      axios.all(requests)
        .then(
          (...responses) => {
            getAndSetInvestment()
            navigate(`/investment/${investmentId}/create/public-documents`)
          }
        ).finally(
          () => {
            setIsLoading(false)
            setShowProgress(false)
          }
        )
    } else {
      navigate(`/investment/${investmentId}/create/public-documents`)
    }

  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content investment-security ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.create.investmentSecurity.title")}</h1>
          <p>{t("investment.create.investmentSecurity.subtitle")}</p>
          <form className="form">
            <div className="input-block">
              <SelectSync
                label={t("investment.create.investmentSecurity.securityType.label")}
                value={securityTypes.find(category => category.value === investmentSecurity.securityType)}
                options={securityTypes}
                onChange={(option: any) => { setInvestmentSecurity(investment => { investment.securityType = option.value }); setSecurityTypeError(false) }}
                error={securityTypeError}
                errorMessage={t("investment.create.investmentSecurity.errors.securityType")}
                required
              />
              <TextArea
                label={t("investment.create.investmentSecurity.description.label")}
                value={investmentSecurity.description}
                onChange={e => { setInvestmentSecurity(investment => { investment.description = e.target.value }); setDescriptionError(false) }}
                placeholder={t("investment.create.investmentSecurity.description.placeholder")}
                error={descriptionError}
                errorMessage={t("investment.create.investmentSecurity.errors.description")}
                autoComplete="off"
                maxLength={200}
                required
              />

              <h2>{t("investment.create.investmentSecurity.documents.label")}</h2>

              <DocumentsInput
                documents={investmentSecurity.documents}
                showProgressBar={showProgress}
                uploadFileProgress={uploadProgress}
                errorState={filesError}
                errorSetState={setFilesError}
                addFileCallback={addFiles}
                documentsComponent={
                  investmentSecurity.documents !== null &&
                  investmentSecurity.documents.map((file: File | ServerFile, index: number) => (
                    <InvestmentDocument file={file} index={index} key={index} investmentContext={investmentSecurity} setInvestmentContext={setInvestmentSecurity} />
                  ))
                }
              />
            </div>
          </form>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="../schedule/"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => { submitSecurity() }} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}