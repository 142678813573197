import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaPlusCircle } from "react-icons/fa"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { ActivityModal } from "../ActivityModal"
import "../InvestmentDashboard.sass"

export const ActivityHistory = () => {

  const { t } = useTranslation()

  const [activityModal, setActivityModal] = useState<boolean>(false)

  return (
    <div className="activities-container">
      <ActivityModal open={activityModal} handleClose={() => setActivityModal(false)} />
      <div>
        <h2 className="title">{t("dashboard.investments.activityHistoric.historic")}</h2>
        <div className="new-activity-container">
          <ButtonPrimary
            className="new-activity-button"
            children={<><FaPlusCircle /> {t("dashboard.investments.activityHistoric.newActivity")}</>}
            onClick={() => { setActivityModal(!activityModal) }}
          />
        </div>
      </div>
      <div>
        <div className="date-container">
          <span className="date last-date">
            Hoje, 4 Maio
          </span>
          <div className="line"></div>
        </div>

        <div className="time-row">
          <span className="time">2:00pm</span>
          <div className="description">
            <span>@Manuela Mayer Hoje daremos início às obras.</span>
          </div>
        </div>

        <div className="time-row">
          <span className="time">5:00pm</span>
          <div className="description">
            <span>A Etapa 2 foi iniciada.</span>
          </div>
        </div>
      </div>

      <div>
        <div className="date-container">
          <span className="date">
            5 Maio
          </span>
          <div className="line"></div>
        </div>

        <div className="time-row">
          <span className="time">6:00am</span>
          <div className="description">
            <span>Foi aplicado R$ 350.000,00 na Etapa 2.</span>
          </div>
        </div>
      </div>

      <div>
        <div className="date-container">
          <span className="date">
            28 Abril
          </span>
          <div className="line"></div>
        </div>

        <div className="time-row">
          <span className="time">11:00pm</span>
          <div className="description">
            <span>@Manuela Mayer O orçamento para a Etapa 2, que se inicia nos próximos dias, está em anexo. Valor final do orçamento contando mão-de-obra e materiais.</span>
          </div>
        </div>
      </div>
    </div>
  )
}