import { createContext, ReactNode, useContext } from "react";
import { Updater, useImmer } from "use-immer";

export interface SignUpProps {
  name: string,
  email: string,
  cpf: string,
  password: string,
  rePassword: string,
  user_type: string
}

interface SignUpContextProps {
  signUpInfo: SignUpProps
  setSignUpInfo: Updater<SignUpProps>
}


export const SignUpContext = createContext({} as SignUpContextProps)

export const SignUpContextProvider = ({ children }: { children: ReactNode }) => {

  const [signUpInfo, setSignUpInfo] = useImmer({
    name: "",
    email: "",
    cpf: "",
    password: "",
    rePassword: "",
    user_type: "investor"
  } as SignUpProps);

  return (
    <SignUpContext.Provider value={{ signUpInfo, setSignUpInfo }} >
      {children}
    </SignUpContext.Provider>
  )
}

export const useSignUp = () => {
  return useContext(SignUpContext)
}
