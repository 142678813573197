import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaAngleLeft, FaChartLine, FaDollarSign, FaEye, FaEyeSlash, FaPercentage, FaWallet } from "react-icons/fa"
import { Column } from "react-table"
import { BackButton } from "../../Buttons/BackButton"
import { Footer } from "../../Footer"
import { DashboardHeader } from "../../Headers/DashboardHeader"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import { WalletTable } from "./WalletTable"

export const Wallet = () => {

  const mockTransactions = [
    {
      "entry": "Saque PIX",
      "type": 3,
      "date": "23/01/2024",
      "value": "R$ 510.000,00",
      "partial_balance": "R$ 2.000.000,00"
    },
    {
      "entry": "Fazenda Ailton Agronegócios - Plantação de soja",
      "type": 1,
      "date": "15/04/2023",
      "value": "R$ 850.000,00",
      "partial_balance": "R$ 1.150.000,00"
    },
  ]

  const { t } = useTranslation()

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  const columns = useMemo<Column[]>(() => [
    {
      Header: t("dashboard.wallet.table.entry"),
      accessor: "entry"
    },
    {
      Header: t("dashboard.wallet.table.type"),
      accessor: "type"
    },
    {
      Header: t("dashboard.wallet.table.date"),
      accessor: "date"
    },
    {
      Header: t("dashboard.wallet.table.value"),
      accessor: "value"
    },
    {
      Header: t("dashboard.wallet.table.partialBalance"),
      accessor: "partial_balance"
    },
  ], [t])

  const [showBalance, setShowBalance] = useState<boolean>(true)

  return (
    <div className="my-investments">
      <DashboardHeader />
      <div className="content">
        <div className="investments-card">
          <div className="title-text">
            <BackButton
              className="back-button"
              icon={<FaAngleLeft />}
              children={t("dashboard.investments.backButton")}
            />
          </div>
          <div className="wallet-title">
            <h1 className="title">{t("dashboard.wallet.title")}</h1>
            <button onClick={() => { setShowBalance(!showBalance) }}>
              {showBalance ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
            <GreenLoadingRing />
          </div>
          <div className={` ${isDataLoading ? 'hidden' : ''}`}>
            <div className="balance-wallet-container">
              <div className="balance-wallet-card">
                <div className="balance">
                  <span className="label"><FaWallet className="icon" />{t("dashboard.wallet.accountBalance")}</span>
                  <div className={`${!showBalance ? "skeleton-title" : ""}`}>
                    <h1 className={`title`}>R$ 2.000.000,00</h1>
                  </div>
                </div>
                <div className="line" />
                <div className="balance">
                  <span className="label"><FaDollarSign className="icon" />{t("dashboard.wallet.amountInvested")}</span>
                  <div className={`${!showBalance ? "skeleton-title" : ""}`}>
                    <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>R$ 0,00</h1>
                  </div>
                </div>
                <div className="line" />
                <div className="balance">
                  <span className="label"><FaPercentage className="icon" />{t("dashboard.wallet.averageProfit")}</span>
                  <div className={`${!showBalance ? "skeleton-title" : ""}`}>
                    <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>0% A.A.</h1>
                  </div>
                </div>
                <div className="line" />
                <div className="balance">
                  <span className="label"><FaChartLine className="icon" />{t("dashboard.wallet.prospectedReturn")}</span>
                  <div className={`${!showBalance ? "skeleton-title" : ""}`}>
                    <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>R$ 0,00</h1>
                  </div>
                </div>
              </div>
            </div>

            <WalletTable data={mockTransactions} columns={columns} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}