import AsyncSelect from 'react-select/async';
import "../Select.sass";
import { SelectProps } from "../SelectSync";

/**
 * Select default with labels and details span bellow
 * 
 * @author Samuel S. de Ataídes
 * @since 2022-09-19
 */
export const SelectAsync = ({ id, label, onChange, value, placeholder, className, span, required, loadOptions, error, errorMessage, defaultOptions = true, idParentElement }: SelectProps) => {

  const handleChange = (option: any | null) => {
    onChange(option);
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 'regular',
      fontSize: '14px'
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    control: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 'regular',
      fontSize: '14px',
      lineHeight: "20px",
      height: '2.5rem',
      borderRadius: '6px',
      border: `1px solid ${error ? 'red' : '#c0c0c0'} !important`,
      boxShadow: state.isFocused ? '0 0 8px #bbbbbb !important' : 'none',
      marginTop: '12px'
    })
  }

  return (
    <div className={`select-default ${className ? className : ''} ${error ? 'select-default-error' : ''}`} data-testid="select-async">
      {
        label !== undefined && <label htmlFor={id}>{label}{required === true ? "*" : ''}</label>
      }
      <AsyncSelect
        id={id}
        className='select-container'
        styles={customStyles}
        placeholder={placeholder}
        value={value}
        loadOptions={loadOptions}
        onChange={handleChange}
        cacheOptions
        defaultOptions={defaultOptions}
        classNamePrefix="react-select"
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: '#00a86240',
            primary50: '#00a86280',
            primary75: '#00a862bf',
            primary: '#00A862',
          },
        })}
        menuPortalTarget={idParentElement ? document.getElementById(idParentElement) : document.body}
      />
      {
        span !== undefined && <span className='select-description'>{span}</span>
      }
      {
        errorMessage !== undefined && <span className="select-error-message">{errorMessage}</span>
      }
    </div>
  );
};