import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { Link, Navigate, useParams } from "react-router-dom"
import { useInvest } from "../../../../contexts/InvestContext"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { InformationCard } from "../../InformationCard"

/**
 * This component is a Investment Confirm page, where the investor confirm the investment. 
 */
export const Confirm = () => {

  const { t } = useTranslation()

  const id = useParams().id

  const { investInfo, investment, setProgress } = useInvest()

  useEffect(() => {
    setProgress(3)
  })

  if (id === undefined) {
    return <Navigate to="/" />
  }

  return (
    <div className="invest-content">
      <div className="left-container">
        <h1 className="title">{t("investmentPage.investForm.confirm.title")}</h1>
        <span className="subtitle">{t("investmentPage.investForm.confirm.subtitle")}</span>
        <div className="data-container">
          <span className="topic-title">{t("investmentPage.investForm.confirm.investedAmount")}</span>
          <h2 className="value">R$ {investInfo.amountInvested}</h2>
        </div>

        <div className="data-container">
          <span className="topic-title">{t("investmentPage.investForm.confirm.percentage")}</span>
          <h2 className="percentual">~ 0,2%</h2>
        </div>
      </div>

      <div className="right-container">
        <div className="information-card">
          <InformationCard toInvestForm={true} investment={investment} />
        </div>

        <div className="row button-container">
          <Link className="back-button" to="../contracts" ><FaArrowLeft className="icon" /> {t("register.back")} </Link>
          <ButtonPrimary to={"../confirm"} children={t("register.nextButton")} />
        </div>
      </div>
    </div>
  )
}