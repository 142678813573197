import { Outlet } from "react-router-dom"
import { Footer } from "../../Footer"
import { DashboardHeader } from "../../Headers/DashboardHeader"
import "./Settings.sass"
import { SettingsLinks } from "./SettingsLinks"

export const Settings = () => {

  return (
    <div className="settings-content">
      <DashboardHeader />
      <div className="content">
        <div className="settings-card">
          <SettingsLinks />
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}