import { SliderUnstyledOwnProps } from "@mui/base";
import { Slider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../Input.sass";

interface RangeSliderProps extends SliderUnstyledOwnProps {
  /**
   * Html atributte id to input.
   */
  id?: string
  /**
   * Iput label, helps on accessibility of the project as well.
   */
  label?: string
  /**
   * Html atributte type ot input, define the type of the input.
   */
  type: "currency" | "percentage" | "months"
  /**
   * onChange event.
   */
  onChange?: (event: Event, value: number | number[], activeThumb: number) => void
  /**
   * Is a value for the inputs, but in this format => [firstValue, secondValue]
   */
  value: [number, number]
  /**
   * Html atribbute className, define the class name of the input.
   */
  className?: string
  /**
   * Is minimun value
   */
  min: number
  /**
   * Is maximum value
   */
  max: number
};

export const RangeSlider = ({ id, label, type, onChange, value, className, min, max, ...attr }: RangeSliderProps) => {

  const { t } = useTranslation();

  return (
    <div className="w-full mt-4">
      <div className="mb-2">
        {
          label !== undefined && <label htmlFor={id} className="text-sm font-semibold">{label}</label>
        }
      </div>
      <Typography className="w-full relative" gutterBottom>
        <span className="values-labels-values-slider-input absolute -top-2">
          {t("input.rangeSlider.from")}&nbsp;
          {type === "currency" && value[0].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          {type === "percentage" && (value[0] / 100).toLocaleString('pt-br', { style: 'percent' })}
          {type === "months" && <>{value[0]} {t("input.rangeSlider.months")}</>}
        </span>
      </Typography>
      <Slider
        id={id}
        getAriaLabel={() => label ?? ''}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        valueLabelDisplay="off"
        sx={{
          color: '#C5C5C5',
          height: 8,
          '& .MuiSlider-track': {
            border: 'none',
            color: "#7E7E7E",
            height: 3
          },
          '& .MuiSlider-rail': {
            height: 3,
            borderRadius: 0
          },
          '& .MuiSlider-thumb': {
            height: 16,
            width: 16,
            backgroundColor: '#D9D9D9',
            border: '2px solid #7E7E7E',
            '&:before': {
              display: 'none',
            },
          }
        }}
        {...attr}
      />
      <Typography id="non-linear-slider" className="w-full relative" gutterBottom>
        <span className="values-labels-values-slider-input absolute right-0 -top-4">
          {t("input.rangeSlider.to")}&nbsp;
          {type === "currency" && value[1].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          {type === "percentage" && (value[1] / 100).toLocaleString('pt-br', { style: 'percent' })}
          {type === "months" && <>{value[1]} {t("input.rangeSlider.months")}</>}
        </span>
      </Typography>
    </div>
  )
};
