import { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'
import "../Select.sass"

export interface SelectProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /**
   * Select label, helps on accessibility of the project as well.
   */
  label?: string
  /**
   * Is a text that is under the select element, can be used for describe the field, drescribe constraints, etc. 
   */
  span?: string
  /**
   * When you want to remake the search or want a method to load the datas, you use this.
   */
  loadOptions?: any
  /**
   * When you want to use a fixed data list, you use this.
   */
  options?: OptionsOrGroups<any, GroupBase<any>> | undefined
  /**
   * On change event
   */
  onChange: (value: any) => void
  /**
   * Value
   */
  value?: any
  /**
   * Input error mode.
   */
  error?: boolean
  /**
   * Is a red error message that is under the input element, can be used for alert the user about something wrong.
   */
  errorMessage?: string
  /**
   * The default set of options to show before the user starts searching. When set to true, the results for loadOptions('') will be autoloaded.
   */
  defaultOptions?: boolean | OptionsOrGroups<any, GroupBase<any>> | undefined
  /**
   * If is possible select multiple values
   */
  isMulti?: boolean
  /**
   * The id of element parent to link the scroll with the select menu, but is not so necessary.
   */
  idParentElement?: string
}

export const SelectSync = ({ id, label, onChange, value, placeholder, className, span, required, options, disabled, error, errorMessage, isMulti, idParentElement }: SelectProps) => {

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 'regular',
      fontSize: '14px'
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: 9999, bottom: state.bottom }),
    control: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 'regular',
      fontSize: '14px',
      lineHeight: "20px",
      height: '2.5rem',
      borderRadius: '6px',
      border: `1px solid ${error ? 'red' : '#c0c0c0'} !important`,
      boxShadow: state.isFocused ? '0 0 8px #bbbbbb !important' : 'none',
      marginTop: '12px'
    })
  }

  const handleChange = (option: any | null) => {
    onChange(option);
  }

  return (
    <div className={`select-default ${className ? className : ''} ${error ? 'select-default-error' : ''}`} data-testid="select-sync">
      {
        label !== undefined && <label htmlFor={id}>{label}{required === true ? "*" : ''}</label>
      }
      <Select
        id={id}
        className='select-container'
        styles={customStyles}
        placeholder={placeholder}
        value={value}
        options={options}
        onChange={handleChange}
        isDisabled={disabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: '#00a86240',
            primary50: '#00a86280',
            primary75: '#00a862bf',
            primary: '#00A862',
          },
        })}
        menuPortalTarget={idParentElement ? document.getElementById(idParentElement) : document.body}
        isMulti={isMulti}
      />
      {
        span !== undefined && <span className='select-description'>{span}</span>
      }
      {
        errorMessage !== undefined && <span className="select-error-message">{errorMessage}</span>
      }
    </div>

  );
};