import { useTranslation } from "react-i18next"
import { FaAngleLeft, FaChartLine, FaTachometerAlt, FaWallet } from "react-icons/fa"
import { NavLink } from "react-router-dom"
import { BackButton } from "../../../Buttons/BackButton"

import "../Settings.sass"

export const SettingsLinks = () => {

  const { t } = useTranslation()

  return (
    <div className="navigation-container">
      <BackButton
        id="back"
        children={t("dashboard.settings.back")}
        to={"../"}
        className="back-button-profile"
        icon={<FaAngleLeft />}
      />

      <nav>
        <NavLink data-testid="navlink1" className={(navData) => navData.isActive ? "item selected" : "item"} to={"./"}>
          <div>
            <FaTachometerAlt className="icon" /> {t("dashboard.settings.profile")}
          </div>
        </NavLink>
        <NavLink data-testid="navlink2" className={(navData) => navData.isActive ? "item selected" : "item"} to={"./user-data/"}>
          <div>
            <FaChartLine className="icon" /> {t("dashboard.settings.data")}
          </div>
        </NavLink>
        <NavLink data-testid="navlink3" className={(navData) => navData.isActive ? "item selected" : "item"} to={"./bank-details/"}>
          <div>
            <FaWallet className="icon" /> {t("dashboard.settings.bankDetails")}
          </div>
        </NavLink>
      </nav>
    </div>
  )
}