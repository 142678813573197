import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaAngleLeft } from "react-icons/fa"
import { Outlet, useLocation } from "react-router-dom"
import { Column } from "react-table"
import { ProposeProps } from "../../../contexts/CreateInvestment"
import api from "../../../utils/api"
import { BackButton } from "../../Buttons/BackButton"
import { DashboardHeader } from "../../Headers/DashboardHeader"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import { ProposesTableProps } from "../MyProposes/ProposesTable"
import { Footer } from "../../Footer"

export const ValidateInvestments = () => {

  const { t } = useTranslation()

  const [investments, setInvestments] = useState<ProposeProps[]>([])

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  const { pathname } = useLocation()

  const columns = useMemo<Column[]>(() => [
    {
      Header: t("dashboard.investments.table.title"),
      accessor: "title"
    },
    {
      Header: t("dashboard.investments.table.progress"),
      accessor: "situation"
    },
    {
      Header: t("dashboard.investments.table.step"),
      accessor: "stage"
    },
    {
      Header: t("dashboard.investments.table.valueAvailable"),
      accessor: "available_value"
    },
    {
      Header: t("dashboard.investments.table.rentability"),
      accessor: "rentability"
    },
  ], [t])

  useEffect(() => {
    setIsDataLoading(true)
    api.get("/api/investment/validate").then(
      (res) => {
        setIsDataLoading(false)
        setInvestments([...res.data])
      }
    )
  }, [])

  return (
    <div className="my-investments">
      <DashboardHeader />
      <div className="content">
        <div className="investments-card">
          <div className="title-text">
            <BackButton
              className="back-button"
              icon={<FaAngleLeft />}
              children={t("dashboard.investments.backButton")}
              to={pathname === "/dashboard/validate-investments/" ? ".." : "/dashboard/validate-investments/"}
            />
          </div>
          <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
            <GreenLoadingRing />
          </div>
          <div className={` ${isDataLoading ? 'hidden' : ''}`}>
            <Outlet context={{ data: investments, columns: columns, tableTitle: t("dashboard.validate.allInvestments") } as ProposesTableProps} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}