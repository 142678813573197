import i18next from "i18next"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowCircleLeft } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { useRegister } from "../../../contexts/RegisterContext"
import { useAuth } from "../../../contexts/UserContext"
import api from "../../../utils/api"
import { Utils } from "../../../utils/utils"
import { ButtonPrimary } from "../../Buttons/ButtonPrimary"
import { InputDatePicker } from "../../Input/InputDatePicker"
import { InputMasked } from "../../Input/InputMasked"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import { SelectAsync } from "../../Select/SelectAsync"
import { SelectSync } from "../../Select/SelectSync"
import "../Register.sass"

export const Identification = () => {
  const { t } = useTranslation()
  const { checkAuth } = useAuth()

  const optionsGender = [
    {
      value: 'male',
      label: t("register.identification.gender.male")
    },
    {
      value: 'female',
      label: t("register.identification.gender.female")
    },
    {
      value: 'other',
      label: t("register.identification.gender.other")
    },
    {
      value: 'notsay',
      label: t("register.identification.gender.notSay")
    }
  ];

  const optionsCivilStatus = [
    {
      value: 'married',
      label: t("register.identification.civilStatusList.married")
    },
    {
      value: 'divorced',
      label: t("register.identification.civilStatusList.divorced")
    },
    {
      value: 'separated',
      label: t("register.identification.civilStatusList.separated")
    },
    {
      value: 'single',
      label: t("register.identification.civilStatusList.single")
    },
    {
      value: 'widowed',
      label: t("register.identification.civilStatusList.widowed")
    },
    {
      value: 'stable union',
      label: t("register.identification.civilStatusList.stableUnion")
    }
  ];

  const { identificationInfo, setIdentificationInfo, setProgress } = useRegister()

  const language = i18next.language;

  const [optionsCountries, setOptionsCountries] = useState<Array<any>>([]);

  // Forms errors controller
  const [genderError, setGenderError] = useState<boolean>(false);
  const [occupationError, setOccupationError] = useState<boolean>(false);
  const [civilStatusError, setCivilStatusError] = useState<boolean>(false);
  const [nationalityError, setNationalityError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [identificationError, setIdentificationError] = useState<boolean>(false);
  const [identificationMessageError, setIdentificationMessageError] = useState<string>("");
  const [birthdayError, setBirthdayError] = useState<boolean>(false);

  const [checkingError, setCheckingError] = useState<boolean>(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [defaultOccupation, setDefaultOccupation] = useState<any>();

  const navigate = useNavigate()

  useEffect(() => {
    setProgress(1)
  })

  useEffect(() => {
    let elementSelect = document.getElementsByClassName("select-default-error")[0]
    let elementInput = document.getElementsByClassName("input-default-error")
    if ((elementInput[0] || elementSelect) && !checkingError) {
      setCheckingError(true)
      if (elementSelect) {
        elementSelect.scrollIntoView({ behavior: "smooth", block: "center" })
      } else {
        elementInput[0].scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderError, occupationError, civilStatusError, nationalityError, phoneError, identificationError, identificationMessageError, birthdayError])

  useQuery("identification", () => {
    api.get("/api/user/register/step-data/1").then(
      (response) => {

        setIdentificationInfo(registerInfo => {
          registerInfo.gender = response.data.gender;
          registerInfo.civil_status = response.data.civil_status;
          registerInfo.nationality = response.data.nationality;
          registerInfo.phone_number = response.data.phone_number ?? "";
          registerInfo.identification = (response.data.nationality === 31 ? Utils.formatCpf(response.data.identification) : response.data.identification) ?? "";
          registerInfo.birthday = response.data.birthday ? new Date(response.data.birthday) : null;

          registerInfo.profession = response.data.profession ?? null;

        });

        if (response.data.profession !== null) {
          setDefaultOccupation([{ value: response.data.profession, label: response.data.occupation }]);
        }

      }
    ).finally(() => {
      if (optionsCountries.length <= 0) {
        api.get(`/api/utils/countries`)
          .then((response) => {
            response.data = response.data.map((data: any) => countriesToValueLabel(data))
            setOptionsCountries(response.data);
          }).finally(() => {
            setIsDataLoading(false)
          })
      }
    });

  })

  const countriesToValueLabel = (data: any) => {
    return {
      value: data.id,
      label: data.name
    }
  };

  const mapOccupations = (data: any) => {
    return {
      value: data.code,
      label: data.occupation
    }
  };

  // add `return` keyword
  const loadOptionsOccupations = async (q: string = ''): Promise<any[]> => {
    return api.get("/api/utils/occupations",
      {
        params: {
          'q': q
        }
      })
      .then((response: any) => {
        let data = response.data;
        return data.map((result: any) => {
          return mapOccupations(result)
        })
      });
  }

  const confirmIdentification = () => {

    let errorsCount: number = 0;

    let numberPhone: string = identificationInfo.phone_number;

    if (!identificationInfo.gender) {
      setGenderError(true);
      errorsCount++
    }
    if (!identificationInfo.profession) {
      setOccupationError(true);
      errorsCount++
    }
    if (!identificationInfo.civil_status) {
      setCivilStatusError(true);
      errorsCount++
    }
    if (!identificationInfo.nationality) {
      setNationalityError(true);
      errorsCount++
    }
    if (numberPhone.replace(/[^0-9]/g, '').length !== 13) {
      setPhoneError(true);
      errorsCount++
    }
    if (!identificationInfo.identification) {
      setIdentificationMessageError(t("register.identification.errors.identification"));
      setIdentificationError(true);
      errorsCount++
    }
    if (identificationInfo.nationality === 31 && !Utils.isCpf(identificationInfo.identification) && identificationInfo.identification) {
      setIdentificationMessageError(t("register.identification.errors.invalidIdentification"));
      setIdentificationError(true);
      errorsCount++
    }
    if (!identificationInfo.birthday) {
      setBirthdayError(true);
      errorsCount++
    }

    if (errorsCount > 0) {
      setCheckingError(false)
      return
    }

    setIsSubmitLoading(true)

    let data = {
      gender: identificationInfo.gender,
      profession: identificationInfo.profession,
      civil_status: identificationInfo.civil_status,
      nationality: identificationInfo.nationality,
      phone_number: numberPhone.replace(/[^0-9]/g, ''),
      identification: identificationInfo.identification.replace(/[^0-9]/g, ''),
      birthday: identificationInfo.birthday
    }

    api.post("/api/user/register", data)
      .then(
        (response) => {
          checkAuth().then(() => { navigate("/dashboard/register/code") })
          navigate("/dashboard/register/code")
        },
        (err) => {
          if (err.response.data.detail === "INVALID_STEP") {
            navigate("/dashboard/register/code")
          }

          if (err.response.data.detail === "ALREADY_REGISTERED") {
            navigate("/dashboard/")
          }
        }
      ).finally(() => {
        setIsSubmitLoading(false)
      })
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t('register.identification.identification')}</h1>
          <div className="form">
            <div className="input-block">
              <SelectSync
                label={t('register.identification.gender.gender')}
                id="gender"
                type="text"
                required={true}
                options={optionsGender}
                onChange={(value: any) => { setIdentificationInfo(registerInfo => { registerInfo.gender = value.value }); setGenderError(false) }}
                value={optionsGender.find(option => option.value === identificationInfo.gender)}
                placeholder={t("register.identification.placeholder.gender")}
                error={genderError}
                errorMessage={t("register.identification.errors.gender")}
              />
              <SelectAsync
                label={t('register.identification.profession')}
                id="profession"
                type="text"
                required={true}
                autoComplete="off"
                loadOptions={loadOptionsOccupations}
                onChange={(value: any) => { setIdentificationInfo(registerInfo => { registerInfo.profession = value.value }); setOccupationError(false) }}
                value={defaultOccupation?.filter((option: any) => option.value === identificationInfo.profession)[0]}
                defaultOptions={defaultOccupation}
                placeholder={t("register.identification.placeholder.profession")}
                error={occupationError}
                errorMessage={t("register.identification.errors.occupation")}
              />
              <div className="down-input-group">
                <SelectSync
                  label={t('register.identification.civilStatus')}
                  id="civilStatus"
                  type="text"
                  required={true}
                  autoComplete="civilStatus"
                  options={optionsCivilStatus}
                  onChange={(value: any) => { setIdentificationInfo(registerInfo => { registerInfo.civil_status = value.value }); setCivilStatusError(false) }}
                  value={optionsCivilStatus.find(option => option.value === identificationInfo.civil_status)}
                  placeholder={t("register.identification.placeholder.civilStatus")}
                  error={civilStatusError}
                  errorMessage={t("register.identification.errors.civilStatus")}
                />
                <SelectSync
                  label={t('register.identification.nationality')}
                  id="nationality"
                  type="text"
                  required={true}
                  autoComplete="nationality"
                  options={optionsCountries}
                  onChange={(value: any) => { setIdentificationInfo(registerInfo => { registerInfo.nationality = value.value }); setNationalityError(false) }}
                  value={optionsCountries.find(option => option.value === identificationInfo.nationality)}
                  placeholder={t("register.identification.placeholder.nationality")}
                  error={nationalityError}
                  errorMessage={t("register.identification.errors.nationality")}
                />
              </div>
              <div className="down-input-group">
                <InputMasked
                  mask={"+99 (99) 99999-9999"}
                  label={t('register.identification.phoneNumber')}
                  id="phone"
                  type="phone"
                  required={true}
                  autoComplete="phone"
                  onChange={(e) => { setIdentificationInfo((registerInfo) => { registerInfo.phone_number = e.target.value }); setPhoneError(false) }}
                  value={identificationInfo.phone_number}
                  placeholder={t("register.identification.placeholder.phoneNumber")}
                  error={phoneError}
                  errorMessage={t("register.identification.errors.phoneNumber")}
                />
                <InputMasked
                  mask={identificationInfo.nationality === 31 ? "999.999.999-99" : "99999999999"}
                  label={t('register.identification.id')}
                  id="identification"
                  type="text"
                  required={true}
                  autoComplete="identification"
                  onChange={(e) => { setIdentificationInfo((registerInfo) => { registerInfo.identification = e.target.value }); setIdentificationError(false) }}
                  value={identificationInfo.identification}
                  placeholder={t("register.identification.placeholder.id")}
                  maxLength={11}
                  error={identificationError}
                  errorMessage={identificationMessageError}
                />
              </div>
              <div className="down-input-group">
                <InputDatePicker
                  dateFormat={"dd/MM/yyyy"}
                  momentDateFormat="DD/MM/YYYY"
                  selected={identificationInfo.birthday}
                  maxDate={new Date()}
                  yearDropdownItemNumber={80}
                  onChange={(date: Date | null) => { setIdentificationInfo(registerInfo => { registerInfo.birthday = date }); if (date !== null) { setBirthdayError(false) }; }}
                  id="issue-date"
                  label={t("register.identification.birthday")}
                  type={"text"}
                  placeholder={t("register.identification.placeholder.birthday")}
                  required={true}
                  error={birthdayError}
                  errorMessage={t("register.identification.errors.birthday")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="col">
            <Link className="exit-button" to="/dashboard/"><FaArrowCircleLeft className="icon" /> {t("register.exitIdentification")} </Link>
            <ButtonPrimary isLoading={isSubmitLoading} onClick={confirmIdentification} children={t("register.nextButton")} />
          </div>
        </div>
      </div>
    </>
  )
}