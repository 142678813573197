import { useEffect, useState } from 'react'
import ReactHtmlParser from "react-html-parser"
import { useTranslation } from "react-i18next"
import { TbArrowsLeftRight, TbWallet, TbWorld } from 'react-icons/tb'
import { SwiperSlide } from "swiper/react"
import { ButtonPrimary } from '../../components/Buttons/ButtonPrimary'
import { ProposeInvestment } from "../../components/Landing/ProposeInvestment"
import Slider from '../../components/Slider'
import { InvestmentSlider } from "../../components/Slider/InvestmentSlider"

import "./LandingPage.sass"
import { ServerInvestment } from '../../contexts/InvestmentContext'
import api from '../../utils/api'
import { InvestmentSearchResponse } from '../InvestmentsSearch'


export const LandingPage = () => {

  const { t } = useTranslation()

  const [width, setWidth] = useState<number>(window.innerWidth)
  const [investments, setInvestments] = useState<ServerInvestment[]>([] as ServerInvestment[])

  useEffect(() => {
    api.get<InvestmentSearchResponse>("/api/investment/search").then(response => {
      setInvestments([...response.data.investments])
    }).catch(error => {

    })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  return (
    <div className="presentation">
      <section className="advantages" >
        <div className="content w-full md:w-1/2">
          <h1>{ReactHtmlParser(t('landingPage.easy_invest.title'), {})}</h1>
          <p>{t('landingPage.easy_invest.content')}</p>
          <ButtonPrimary to="signup" className="w-[220px] h-[45px]" >
            {t('landingPage.create_account')}
          </ButtonPrimary>
        </div>
        <div className="gp-img-1">
          <img src="/resources/fade-ball.svg" alt="" />
          <img src="/resources/laptop.png" alt="" />
          <img src="/resources/phone.png" alt="" />
        </div>
      </section>
      <section className="advantages" >
        <div className="gp-img-2">
          <img className="visa-blue" src="/resources/visa-blue.svg" alt="" />
          <img className="visa-sweet" src="/resources/visa-sweet.svg" alt="" />
          <img className="visa-black" src="/resources/visa-black.svg" alt="" />
        </div>

        <div className="content w-full md:w-2/3">
          <h1>{ReactHtmlParser(t('landingPage.money_anywhere.title'), {})}</h1>
          <p>{t('landingPage.money_anywhere.content')}</p>
        </div>

      </section>

      <section className="blue-card">

        {
          width >= 640 && (
            <>
              <div className="content" >
                <div className="title">
                  <TbArrowsLeftRight className="icon" />
                  <h3>{t('landingPage.blueCard.transferMoney.title')}</h3>

                </div>
                <p>
                  {t('landingPage.blueCard.transferMoney.content')}
                </p>

              </div>
              <div className="content" >
                <div className="title">
                  <TbWallet className="icon" />
                  <h3>{t('landingPage.blueCard.digitalWallet.title')}</h3>

                </div>
                <p>
                  {t('landingPage.blueCard.digitalWallet.content')}
                </p>

              </div>
              <div className="content" >
                <div className="title">
                  <TbWorld className="icon" />
                  <h3>{t('landingPage.blueCard.anywhere.title')}</h3>

                </div>
                <p>
                  {t('landingPage.blueCard.anywhere.content')}
                </p>

              </div>
            </>
          )
        }

        {
          width < 640 && (
            <Slider settings={{ pagination: true, className: "image-slider" }} >
              <SwiperSlide>
                <div className="content" >
                  <div className="title">
                    <TbArrowsLeftRight className="icon" />
                    <h3>{t('landingPage.blueCard.transferMoney.title')}</h3>
                  </div>
                  <p>
                    {t('landingPage.blueCard.transferMoney.content')}
                  </p>
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className="content" >
                  <div className="title">
                    <TbWallet className="icon" />
                    <h3>{t('landingPage.blueCard.digitalWallet.title')}</h3>

                  </div>
                  <p>
                    {t('landingPage.blueCard.digitalWallet.content')}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="content" >
                  <div className="title">
                    <TbWorld className="icon" />
                    <h3>{t('landingPage.blueCard.anywhere.title')}</h3>

                  </div>
                  <p>
                    {t('landingPage.blueCard.anywhere.content')}
                  </p>
                </div>
              </SwiperSlide>
            </Slider>
          )
        }


      </section>

      <section className="advantages" >
        <div className="content w-full md:w-1/2">
          <h1>{ReactHtmlParser(t('landingPage.idealInvestment.title'), {})}</h1>
          <p>{t('landingPage.idealInvestment.content')}</p>
          <ButtonPrimary className="w-[326px] h-[45px]" >
            {t('landingPage.checkOurInvestments')}
          </ButtonPrimary>
        </div>
        <div className="gp-img-3">
          <img className="woman-laughing" src="/resources/woman_laughing.png" alt="" />
          <div className="balls">
            <img className="dollar-ball" src="/resources/dollar-ball.svg" alt="" />
            <img className="wallet-ball" src="/resources/wallet-ball.svg" alt="" />
            <img className="rocket-ball" src="/resources/rocket-ball.svg" alt="" />
          </div>
        </div>
      </section>
      <section className="investments">
        <h2>{t('landingPage.checkOurInvestments')}</h2>
        <InvestmentSlider label={t('landingPage.highlights')} investments={investments} />
      </section>
      <ProposeInvestment />
    </div>
  )
}