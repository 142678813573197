import { Navigate, useNavigate } from "react-router-dom";
import api from "../../../../utils/api";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { InputMasked } from "../../../Input/InputMasked";
import { useState } from "react";
import { useRegister } from "../../../../contexts/RegisterContext";
import { useAuth } from "../../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import "../ResendCode.sass";

export const ResendPhoneCode = () => {
  const { t } = useTranslation();
  const { user } = useAuth()
  const { identificationInfo, setIdentificationInfo } = useRegister();

  const navigate = useNavigate();

  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("");
  const [phoneError, setPhoneError] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>(identificationInfo.phone_number)

  const [isLoading, setIsLoading] = useState<boolean>(false);


  if (user === null) {
    return (
      <Navigate to="/login" />
    )
  }

  const resendCode = () => {

    setIsLoading(true);

    setIdentificationInfo(identificationInfo => { identificationInfo.phone_number = phoneNumber });

    api.post("/api/user/resend/number", { number: identificationInfo.phone_number })
      .then(
        (res) => {
          navigate("/dashboard/register/code")
        }
      )
      .catch(
        (err) => {
          if (err.response.data.detail === "NUMBER_ALREADY_REGISTERED") {
            setPhoneErrorMessage(t("register.errors.numberAlreadyRegistered"));
            setPhoneError(true);
          }

          if (err.response.data.detail === "ALREADY_VERIFIED") {
            setPhoneErrorMessage(t("register.errors.userAlreadyVerified"));
            setPhoneError(true);
          }

          if (err.response.data.detail === "CODE_ALREADY_SENT") {
            setPhoneErrorMessage(t("register.errors.codeAlreadySend"));
            setPhoneError(true);
            navigate("/dashboard/register/code")
          }
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="resend-number-container">
      <div className="content">
        <h1 className="title">{t('register.phone.verification')}</h1>
        <span className="subtitle">{t("register.phone.correctPhoneVerification")}</span>
        <InputMasked
          mask={t("register.identification.placeholder.phoneNumber")}
          label={t('register.identification.phoneNumber')}
          id="phone"
          type="phone"
          required={true}
          autoComplete="phone"
          onChange={(e) => { setPhoneNumber(e.target.value); setPhoneError(false) }}
          value={phoneNumber}
          placeholder={t("register.identification.placeholder.phoneNumber")}
          error={phoneError}
          errorMessage={phoneErrorMessage}
        />
        <div className="confirm-code">
          <ButtonPrimary onClick={resendCode} children={t("register.resendCode")} id="confirm-btn" className='signup-primary-btn' isLoading={isLoading} ></ButtonPrimary>
        </div>
      </div>
    </div>
  )
};