import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { SwiperProps } from "swiper/react";
import { useAlertContext } from "../../../../contexts/AlertContext";
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment";
import api from "../../../../utils/api";
import { BackButton } from "../../../Buttons/BackButton";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing";
import { ThemesSlider } from "../../Create/ConfirmInvestment/ThemesSlider";
import "../Validate.sass";
import { ValidateInvestmentButtons } from "../ValidateInvestmentButtons";

export const ValidateScheduleInvestment = () => {


  const sliderSettings: SwiperProps = {
    className: "theme-slider",
    slidesPerView: 2,
    spaceBetween: 10,
    scrollbar: true,
    navigation: {
      prevEl: ".prev-btn",
      nextEl: ".next-btn"
    }
  }

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { pages, progress, setProgress, investmentSchedule, isDataLoading } = useValidateInvestment()

  const { presentAlert } = useAlertContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const investmentToken = useParams<{ id: string }>().id

  const confirmSchedule = () => {

    const data: Array<ValidatePages> = pages.filter(page => page !== null && (page.page_id === progress))

    if (data.length < 2) {
      presentAlert(
        {
          title: t("investment.validate.alert.empty.title"),
          text: t("investment.validate.alert.empty.text"),
          type: "danger",
          timeout: 4000
        }
      )

      return
    }

    setIsLoading(true)

    api.put(`/api/investment/${investmentToken}/validate`, data)
      .then(() => {
        navigate(`/investment/${investmentToken}/validate/security`)
      }).catch(() => {
        presentAlert(
          {
            title: t("investment.validate.alert.error.title"),
            text: t("investment.validate.alert.error.text"),
            type: "danger",
            timeout: 3000
          }
        )
      }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setProgress(4)
  })

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.schedule.schedule")}</h1>
          <div className="form">
            {(investmentSchedule.stages != null && investmentSchedule.stages.length > 0) &&
              <div className="validate-schedule-container">
                <ValidateInvestmentButtons page_id={4} input_id={1} textareaId="validate-timeline-investment" textareaLabel={t("investment.schedule.schedule")} />
                <div className="timeline-container">
                  <div className="timeline">
                    {investmentSchedule.stages.map((stage, index) => {
                      return (
                        <div className="step-row" key={index}>
                          <div className="circle-step">
                            {index !== 0 && <span className={`bar`}></span>}
                            <span className={`step`}>
                              <span className="text">{index + 1}</span>
                            </span>
                          </div>
                          <div className={`step-description ${index !== 0 ? "with-bar" : ""}`}>
                            <span className="finish-date">{moment(stage.expected_date).format("DD/MM/YYYY")}</span>
                            <span className="title-schedule">{stage.title}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            }
            <div className="input-block">
              <h2 className="second-title">{t("investment.schedule.operationDetail")}</h2>
            </div>
          </div>

          <div className="validate-themes-container">
            <ValidateInvestmentButtons page_id={4} input_id={2} textareaId="validate-theme-investment" textareaLabel={t("investment.schedule.operationDetail")} />
            <ThemesSlider
              themes={investmentSchedule.themes}
              noThemesFound={true}
              sliderSettings={sliderSettings}
            />
          </div>
        </div>

        <div className="right-container">
          <div className="row">
            <BackButton
              to="../values/"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => confirmSchedule()} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div >
    </>
  )
}