import { useTranslation } from 'react-i18next';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import "./Footer.sass";

/**
 * This is Footer component to use in all project
 * 
 * @author Samuel Silva de Ataídes
 * @since 2022-09-15
 */
export const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-links">
                <div>
                    <ul>
                        <li >
                            <Link to="#">{t("footer.aboutUs")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.investments")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.proposeInvestment")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.login")}</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li >
                            <Link to="#">{t("footer.faq")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.feesTerms")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.contactUs")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.singleStore")}</Link>
                        </li>
                    </ul>
                </div>
                <div className='md:col-span-2'>
                    <ul>
                        <li >
                            <Link to="#">{t("footer.highlights")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.winningInvestments")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.blog")}</Link>
                        </li>
                        <li >
                            <Link to="#">{t("footer.privacyPolicy")}</Link>
                        </li>
                    </ul>
                </div>
                <div className='md:col-span-2'>
                    <ul>
                        <li>
                            <div className='footer-follow-ours-channels'>
                                <div className='mr-2'>{t("footer.followOurMedias")}</div>
                                <div className='footer-media-icons'>
                                    <Link to="#">
                                        <FaInstagram className='footer-media-icon' />
                                    </Link>
                                    <Link to="#">
                                        <FaFacebook className='footer-media-icon' />
                                    </Link>
                                    <Link to="#">
                                        <FaTwitter className='footer-media-icon' />
                                    </Link>
                                    <Link to="#">
                                        <FaYoutube className='footer-media-icon' />
                                    </Link>
                                    <Link to="#">
                                        <FaLinkedin className='footer-media-icon' />
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <li >
                            <Link to="#">
                                {t("footer.telephoneService")}
                                <br />
                                +55 99 99999-9999
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div className="footer-end">
                <img src="/single-inv-white.svg" alt="Logo Single Investimentos" />
                <span>
                    {t("footer.rightsReserved")}
                </span>
            </div>
        </footer>
    );
};