import { ServerInvestment } from "../../../contexts/InvestmentContext";
import { Description } from "./Description";
import { Links } from "./Links";
import { ProjectionTable } from "./ProjectionTable";

interface InvestmentDetailsProps {
  /**
   * Investment
   */
  investment: ServerInvestment
};

/**
 * Investment details. This field control which content will be showed in the investment portfolio 
 */
export const InvestmentDetails = ({ investment }: InvestmentDetailsProps) => {

  if (!investment.investment_values) {
    return <>No investment values</>
  }

  return (
    <div className="my-16">
      <div>
        <Description description={investment.description} />
        <Links links={investment.links} />
        <ProjectionTable
          expected={investment.investment_values.projections[0]}
          optimistic={investment.investment_values.projections[1]}
          pessimistic={investment.investment_values.projections[2]}
        />
      </div>
    </div>
  );
};