import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaSyncAlt } from "react-icons/fa"
import { useRegister } from "../../../../contexts/RegisterContext"
import { Utils } from "../../../../utils/utils"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { Input } from "../../../Input/Input"
import { InputMasked } from "../../../Input/InputMasked"
import { SelectSync } from "../../../Select/SelectSync"
import "./UserData.sass"
import { SettingsModal } from "../SettingsModal"

export const UserData = () => {

  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [phoneError, setPhoneError] = useState<boolean>(false)

  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false)

  const { setAddressInfo, identificationInfo, setIdentificationInfo, addressInfo, setBankData, bankData } = useRegister()

  const [countryError, setCountryError] = useState<boolean>(false);
  const [postalCodeError, setPostalCodeError] = useState<boolean>(false);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string>("");
  const [stateError, setStateError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [neighborhoodError, setNeighborhoodError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [complementError, setComplementError] = useState<boolean>(false);


  const [monthlyIncomeError, setMonthlyIncomeError] = useState<boolean>(false)
  const [realEstatesError, setRealEstatesError] = useState<boolean>(false)
  const [financeApplicationsError, setFinanceApplicationsError] = useState<boolean>(false)
  const [movablePropertiesError, setMovablePropertiesError] = useState<boolean>(false)


  const { t } = useTranslation()

  return (
    <div className="user-profile-container">
      <h1 className="title">{t("dashboard.settings.userData.title")}</h1>
      <div className="alter-data-container">
        <div className="alter-phone-container">
          <InputMasked
            mask={"+99 (99) 99999-9999"}
            label={"Telefone"}
            id="phone"
            type="phone"
            className="alter-phone-input"
            required={true}
            autoComplete="phone"
            onChange={(e) => { setPhoneNumber(e.target.value); setPhoneError(false) }}
            value={phoneNumber}
            placeholder={t("register.identification.placeholder.phoneNumber")}
            error={phoneError}
            errorMessage={t("register.identification.errors.phoneNumber")}
          />
          <button className="alter-button" onClick={() => setShowPhoneModal(true)}>
            <FaSyncAlt className="icon" /> <span className="button-text">Atualizar</span>
          </button>
          <SettingsModal title="Phone number verification" addressee={identificationInfo.phone_number} open={showPhoneModal} handleClose={() => { setShowPhoneModal(false) }} errorCodeMessage="" />
        </div>

        <h2 className="second-title">Endereço</h2>

        <div className="down-input-group">
          <SelectSync
            label={t('register.address.country')}
            id="country"
            type="text"
            required={true}
            options={[]}
            onChange={(value: any) => { setAddressInfo(registerInfo => { registerInfo.country = value.value }); setCountryError(false); }}
            // value={optionsCountries.find(option => option.value === addressInfo.country)}
            placeholder={t("register.address.placeholder.country")}
            error={countryError}
            errorMessage={t("register.address.errors.country")}
          />
          <InputMasked
            mask={addressInfo.country === 31 ? "99.999-999" : ""}
            label={t('register.address.postalCode')}
            id="postalCode"
            type="text"
            required={true}
            autoComplete={"new"}
            onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.postal_code = e.target.value }); }}
            value={addressInfo.postal_code}
            placeholder={t("register.address.placeholder.postalCode")}
            error={postalCodeError}
            errorMessage={postalCodeErrorMessage}
          // before={isCEPLoading ?
          //   <div className={`w-4 h-4`}>
          //     <BlackLoadingRing />
          //   </div> : undefined}
          />
        </div>
        <div className="down-input-group">
          <SelectSync
            label={t('register.address.state')}
            id="state"
            type="text"
            required={true}
            options={[]}
            onChange={(value: any) => { setAddressInfo(registerInfo => { registerInfo.state = value.value }); setStateError(false); }}
            // value={optionsStates.find(option => option.value === addressInfo.state)}
            placeholder={t("register.address.placeholder.state")}
            error={stateError}
            errorMessage={t("register.address.errors.state")}
          />
          <SelectSync
            label={t('register.address.city')}
            id="city"
            type="text"
            required={true}
            options={[]}
            onChange={(value: any) => { setAddressInfo(registerInfo => { registerInfo.city = value.value }); setCityError(false); }}
            // value={optionsCities.find(option => option.value === addressInfo.city)}
            placeholder={t("register.address.placeholder.city")}
            error={cityError}
            errorMessage={t("register.address.errors.city")}
          />
        </div>
        <div className="down-input-group">
          <Input
            label={t('register.address.district')}
            id="district"
            type="text"
            required={true}
            onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.district = e.target.value }); setNeighborhoodError(false); }}
            value={addressInfo.district}
            placeholder={t("register.address.placeholder.district")}
            error={neighborhoodError}
            errorMessage={t("register.address.errors.neighborhood")}
          />
          <Input
            label={t('register.address.number')}
            id="number"
            type="text"
            required={true}
            onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.number = e.target.value }); setNumberError(false); }}
            value={addressInfo.number}
            placeholder={t("register.address.placeholder.number")}
            error={numberError}
            errorMessage={t("register.address.errors.number")}
          />
        </div>
        <Input
          label={t('register.address.address')}
          id="address"
          type="text"
          required={true}
          autoComplete="address"
          onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.address = e.target.value }); setAddressError(false); }}
          value={addressInfo.address}
          placeholder={t("register.address.placeholder.address")}
          error={addressError}
          errorMessage={t("register.address.errors.address")}
        />
        <Input
          label={t('register.address.complement')}
          id="complement"
          type="text"
          required={true}
          onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.complement = e.target.value }); setComplementError(false); }}
          value={addressInfo.complement}
          placeholder={t("register.address.placeholder.complement")}
          error={complementError}
          errorMessage={t("register.address.errors.complement")}
        />

        <h2 className="second-title">Dados financeiros</h2>

        <div className="down-input-group">
          <Input
            label={t('register.bankData.monthlyIncome')}
            id="monthlyIncome"
            type="text"
            required={true}
            onChange={(e: any) => setBankData(bankData => { bankData.monthly_income = e.target.value; setMonthlyIncomeError(false) })}
            onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
            value={bankData.monthly_income ?? ""}
            maxLength={18}
            before="R$"
            placeholder={t("register.bankData.placeholder.money")}
            error={monthlyIncomeError}
            errorMessage={t("register.bankData.errors.monthlyIncome")}
          />
          <Input
            label={t('register.bankData.realEstates')}
            id="realEstates"
            type="text"
            required={true}
            onChange={(e: any) => setBankData(bankData => { bankData.real_estates = e.target.value; setRealEstatesError(false) })}
            onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
            value={bankData.real_estates ?? ''}
            maxLength={18}
            before="R$"
            placeholder={t("register.bankData.placeholder.money")}
            error={realEstatesError}
            errorMessage={t("register.bankData.errors.realEstates")}
          />
        </div>

        <div className="down-input-group">
          <Input
            label={t('register.bankData.financeApplications')}
            id="financeApplications"
            type="text"
            required={true}
            onChange={(e: any) => setBankData(bankData => { bankData.finance_applications = e.target.value; setFinanceApplicationsError(false) })}
            onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
            value={bankData.finance_applications ?? ''}
            maxLength={18}
            placeholder={t("register.bankData.placeholder.money")}
            before="R$"
            error={financeApplicationsError}
            errorMessage={t("register.bankData.errors.financeApplications")}
          />
          <Input
            label={t('register.bankData.movableProperties')}
            id="movablePropertys"
            type="text"
            required={true}
            onChange={(e: any) => setBankData(bankData => { bankData.movable_properties = e.target.value; setMovablePropertiesError(false) })}
            onKeyDown={(e) => Utils.formatCurrency(e, e.target, 2)}
            value={bankData.movable_properties ?? ''}
            maxLength={18}
            placeholder={t("register.bankData.placeholder.money")}
            before="R$"
            error={movablePropertiesError}
            errorMessage={t("register.bankData.errors.movableProperties")}
          />
        </div>

        <div className="update-button-container">
          <ButtonPrimary children="Atualizar" className="update-button" />
        </div>
        {/* <span className="topic">{t("dashboard.settings.userData.alterTopic")}</span>
        <ButtonPrimary children={t("dashboard.settings.userData.alterButton")} className="action-button" /> */}
      </div>

      <div className="get-data-container">
        <span className="topic">{t("dashboard.settings.userData.data")}</span>
        <span className="data-text">{t("dashboard.settings.userData.dataText")}</span>
        <div className="list">
          <li>{t("dashboard.settings.userData.dataBulletOne")}</li>
          <li>{t("dashboard.settings.userData.dataBulletTwo")}</li>
          <li>{t("dashboard.settings.userData.dataBulletThree")}</li>
          <li>{t("dashboard.settings.userData.dataBulletFour")}</li>
        </div>
        <ButtonPrimary children={t("dashboard.settings.userData.dataButton")} className="action-button" />
        <div className="note-container">
          <span className="note-text">{t("dashboard.settings.userData.note")}</span>
        </div>
      </div>
    </div>
  )
}