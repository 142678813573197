import InputMask from 'react-input-mask';
import '../Input.sass';
import { InputProps } from '../Input';

export interface InputMaskedProps extends InputProps {
  /**
   * Mask property.
   */
  mask: string | (string | RegExp)[]
};

/**
 * Input default with labels and details span bellow
 * 
 * @author Samuel S. de Ataídes
 * @since 2022-02-29
 */
export const InputMasked = ({ id, label, type, onChange, onKeyDown, onKeyUp, autoComplete, value, placeholder, className, span, required, errorMessage, error, mask, before, after, disabled }: InputMaskedProps) => {

  return (
    <div data-testid="input-test" className={`input-default ${className ? className : ''} ${error ? 'input-default-error' : ''}`}>
      {
        label !== undefined && <label htmlFor={id}>{label}{required === true ? "*" : ''}</label>
      }
      <div className='input-default-group'>
        {
          before !== undefined &&
          <div className='input-default-group-before rounded-tl-md rounded-bl-md'>
            <div>
              {before}
            </div>
          </div>
        }
        <InputMask
          mask={mask}
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          maskChar={null}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          className={`${before === undefined && after === undefined ? "rounded-md" : (before !== undefined && after !== undefined ? "" : (before !== undefined ? "rounded-tr-md rounded-br-md" : "rounded-tl-md rounded-bl-md"))}`}
          disabled={disabled}
        />
        {
          after !== undefined &&
          <div className='input-default-group-after rounded-tr-md rounded-br-md'>
            {after}
          </div>
        }
      </div>
      {
        span !== undefined && <span>{span}</span>
      }
      {
        errorMessage !== undefined && <span className="input-error-message">{errorMessage}</span>
      }
    </div>
  );
};