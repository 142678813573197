import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useQuery } from "react-query"
import { Navigate, useNavigate } from "react-router-dom"
import { useRegister } from "../../../contexts/RegisterContext"
import { useAuth } from "../../../contexts/UserContext"
import api from "../../../utils/api"
import { BackButton } from "../../Buttons/BackButton"
import { ButtonPrimary } from "../../Buttons/ButtonPrimary"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import "../Register.sass"
import { RadioCheckbox } from "./RadioCheckbox"

export const SpecialDeclarations = () => {

  const { t } = useTranslation()
  const { user, checkAuth } = useAuth()
  const navigate = useNavigate()

  const { specialDeclarations, setSpecialDeclarations, setProgress } = useRegister()

  const [declarationsError, setDeclarationsError] = useState<boolean>(false)

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  useEffect(() => {
    setProgress(6)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useQuery("special", () => {
    api.get("/api/user/register/step-data/6")
      .then((response) => {
        setSpecialDeclarations(special => {
          special.from_usa = !!response.data.from_usa;
          special.publicly_exposed = !!response.data.publicly_exposed;
          special.single_linked = !!response.data.single_linked;
          special.none_previous = !!response.data.none_previous;
        });
      }).finally(() => setIsDataLoading(false))
  })

  const confirmDeclarations = () => {

    let errorsCount: number = 0;

    if (!specialDeclarations.from_usa && !specialDeclarations.none_previous && !specialDeclarations.publicly_exposed && !specialDeclarations.single_linked) {
      setDeclarationsError(true)
      errorsCount++
    }

    if (errorsCount > 0)
      return

    api.post("/api/user/register", specialDeclarations)
      .then(() => {
        checkAuth().then(() => { navigate("/dashboard/") })
      })
      .catch((err) => {
        if (err.response.data.detail === "INVALID_STEP") {
          navigate("/dashboard/register/code")
        }

        if (err.response.data.detail === "ALREADY_REGISTERED") {
          navigate("/dashboard/")
        }
      })
  }

  if (user === null) {
    return <Navigate to="/login" />
  }

  if (user.register_step < 6) {
    return <Navigate to="/dashboard/register" />
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1 className="title">{t("register.special.title")}</h1>
          <span className="register-description">{t("register.special.subtitle")}</span>
          <div className="declaration-container">
            <RadioCheckbox
              onChange={e => {
                setSpecialDeclarations(specialDeclarations => {
                  if (specialDeclarations.from_usa === false) {
                    if (specialDeclarations.none_previous === true) {
                      specialDeclarations.none_previous = false
                    }
                    specialDeclarations.from_usa = true
                  } else {
                    specialDeclarations.from_usa = false
                  }
                });
                setDeclarationsError(false)
              }}

              id="1"
              checked={specialDeclarations.from_usa}
              label={t("register.special.checkbox.fromUSA")}
              htmlFor="1"
              name="declaration"
            />
            {/* Acho que deve ser public person */}
            <RadioCheckbox
              onChange={e => {
                setSpecialDeclarations(specialDeclarations => {
                  if (specialDeclarations.publicly_exposed === false) {
                    if (specialDeclarations.none_previous === true) {
                      specialDeclarations.none_previous = false
                    }
                    specialDeclarations.publicly_exposed = true
                  } else {
                    specialDeclarations.publicly_exposed = false
                  }
                });
                setDeclarationsError(false)
              }}
              id="2"
              checked={specialDeclarations.publicly_exposed}
              label={t("register.special.checkbox.exposedPerson")}
              htmlFor="2"
              name="declaration"
            />
            <RadioCheckbox
              onChange={e => {
                setSpecialDeclarations(specialDeclarations => {
                  if (specialDeclarations.single_linked === false) {
                    if (specialDeclarations.none_previous === true) {
                      specialDeclarations.none_previous = false
                    }
                    specialDeclarations.single_linked = true
                  } else {
                    specialDeclarations.single_linked = false
                  }
                });
                setDeclarationsError(false)
              }}
              id="3"
              checked={specialDeclarations.single_linked}
              label={t("register.special.checkbox.linkedSingle")}
              htmlFor="3"
              name="declaration"
            />
            <RadioCheckbox
              onChange={e => {
                setSpecialDeclarations(specialDeclarations => {
                  if (specialDeclarations.none_previous === false) {
                    specialDeclarations.from_usa = false
                    specialDeclarations.publicly_exposed = false
                    specialDeclarations.single_linked = false
                    specialDeclarations.none_previous = true
                  } else {
                    specialDeclarations.none_previous = false
                  }
                });
                setDeclarationsError(false)
              }}
              id="4"
              checked={specialDeclarations.none_previous}
              label={t("register.special.checkbox.none")}
              htmlFor="4"
              name="declaration"
            />

            {declarationsError &&
              <div className="error">
                <span className="input-error-message">{t("register.special.errors.declarations")}</span>
              </div>
            }

          </div>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="/dashboard/register/security"
              children={t("register.back")}
              className="back-button"
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={confirmDeclarations} children={t("register.nextButton")} />
          </div>
        </div>
      </div >
    </>
  )
}