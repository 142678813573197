import FileSaver from "file-saver";
import { useState } from "react";
import { FaCircleNotch, FaFile, FaFileAlt, FaFileArchive, FaFileAudio, FaFileCode, FaFileCsv, FaFileExcel, FaFileImage, FaFilePdf, FaFilePowerpoint, FaFileVideo, FaFileWord, FaTimes } from "react-icons/fa";
import { Updater } from "use-immer";
import { ServerFile } from "../../../../contexts/InvestmentContext";
import api from "../../../../utils/api";

export const InvestmentDocument = ({ file, index, investmentContext, setInvestmentContext, deleteFileCallback, investment_code = null }: { file: ServerFile | File, index: number, investmentContext?: any, setInvestmentContext?: Updater<any> | undefined, deleteFileCallback?: (index: number) => void, investment_code?: string | null }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const deleteFile = (index: number) => {
    if (setInvestmentContext) {
      setInvestmentContext((investmentContext: any) => { investmentContext.documents.splice(index, 1) });
    }
  }

  const deleteServerFile = (index: number, id: string) => {
    setIsLoading(true)
    if (setInvestmentContext) {
      api.delete(`/api/investment/document/${id}`).then(
        (response) => {
          setInvestmentContext((investmentContext: any) => { investmentContext.documents.splice(index, 1) });
        }
      ).catch(
        (error) => {
          setInvestmentContext((investmentContext: any) => { investmentContext.documents.splice(index, 1) });
        }
      ).finally(() => {
        setIsLoading(false)
      })
    }
  }

  function downloadImage(file: ServerFile) {
    setIsLoading(true)
    api.get(investment_code ? `/api/investment/document/public/${investment_code}/${file["id"]}` : `/api/investment/document/${file["id"]}`,
      { responseType: "blob" }
    ).then(
      (response) => {
        FileSaver.saveAs(response.data, file.name)
      }
    ).finally(() => setIsLoading(false))
  }

  if ("id" in file) {
    return (
      <a className="file-card" data-testid="document-slider-test" key={index} href={file["id"]} onClick={e => { e.preventDefault(); downloadImage(file) }}>
        <div>
          {(/^image\//ig).test(file.type) && <FaFileImage className="icon" />}
          {(/^video\//ig).test(file.type) && <FaFileVideo className="icon" />}
          {(/^audio\//ig).test(file.type) && <FaFileAudio className="icon" />}
          {(/^application\/pdf/ig).test(file.type) && <FaFilePdf className="icon" />}
          {(/^application\/csv/ig).test(file.type) && <FaFileCsv className="icon" />}
          {(/^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|^application\/vnd.ms-excel/ig).test(file.type) && <FaFileExcel className="icon" />}
          {(/^application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^application\/msword/ig).test(file.type) && <FaFileWord className="icon" />}
          {(/^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|^application\/powerpoint/ig).test(file.type) && <FaFilePowerpoint className="icon" />}
          {(/^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|^application\/powerpoint/ig).test(file.type) && <FaFilePowerpoint className="icon" />}
          {(/^application\/vnd.rar|^application\/zip/ig).test(file.type) && <FaFileArchive className="icon" />}
          {(/^text\/plain/ig).test(file.type) && <FaFileAlt className="icon" />}
          {(/^text\/html/ig).test(file.type) && <FaFileCode className="icon" />}

          {!file.type.match(/(^image\/)|(^video\/)|(^audio\/)|(^application\/pdf)|(^application\/csv)|(^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/vnd.ms-excel)|(^application\/vnd.openxmlformats-officedocument.wordprocessingml.document|application\/msword)|(^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|application\/powerpoint)|(^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|application\/powerpoint)|(^application\/vnd.rar|application\/zip)|(^text\/plain)|(^text\/html)/ig) && <FaFile className="icon" />}
        </div>
        <span>{file.name}</span>

        {(!isLoading && setInvestmentContext) && (
          <button type="button" className="close-button" onClick={e => { e.stopPropagation(); e.preventDefault(); deleteServerFile(index, file.id) }}>
            <FaTimes />
          </button>
        )}

        {(isLoading) && (
          <div className="loading">
            <FaCircleNotch />
          </div>
        )}
      </a>
    )
  }

  return (
    <div className="file-card" data-testid="document-slider-test" key={index}>
      <div>
        {(/^image\//ig).test(file.type) && <FaFileImage className="icon" />}
        {(/^video\//ig).test(file.type) && <FaFileVideo className="icon" />}
        {(/^audio\//ig).test(file.type) && <FaFileAudio className="icon" />}
        {(/^application\/pdf/ig).test(file.type) && <FaFilePdf className="icon" />}
        {(/^application\/csv/ig).test(file.type) && <FaFileCsv className="icon" />}
        {(/^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|^application\/vnd.ms-excel/ig).test(file.type) && <FaFileExcel className="icon" />}
        {(/^application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^application\/msword/ig).test(file.type) && <FaFileWord className="icon" />}
        {(/^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|^application\/powerpoint/ig).test(file.type) && <FaFilePowerpoint className="icon" />}
        {(/^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|^application\/powerpoint/ig).test(file.type) && <FaFilePowerpoint className="icon" />}
        {(/^application\/vnd.rar|^application\/zip/ig).test(file.type) && <FaFileArchive className="icon" />}
        {(/^text\/plain/ig).test(file.type) && <FaFileAlt className="icon" />}
        {(/^text\/html/ig).test(file.type) && <FaFileCode className="icon" />}

        {!file.type.match(/(^image\/)|(^video\/)|(^audio\/)|(^application\/pdf)|(^application\/csv)|(^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/vnd.ms-excel)|(^application\/vnd.openxmlformats-officedocument.wordprocessingml.document|application\/msword)|(^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|application\/powerpoint)|(^application\/vnd.ms-vnd.openxmlformats-officedocument.presentationml.presentation|application\/powerpoint)|(^application\/vnd.rar|application\/zip)|(^text\/plain)|(^text\/html)/ig) && <FaFile className="icon" />}

      </div>

      <span>{file.name}</span>

      {setInvestmentContext &&
        <button type="button" className="close-button" onClick={e => { e.stopPropagation(); deleteFileCallback ? deleteFileCallback(index) : deleteFile(index) }}>
          <FaTimes />
        </button>
      }
    </div>
  )
}