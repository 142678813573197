import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaExternalLinkAlt, FaTimes } from "react-icons/fa"
import { Link } from "react-router-dom"
import { BalanceCard } from "../../components/Dashboard/BalanceCard"
import { BarCard } from "../../components/Dashboard/Graphs/BarCard"
import { DoughnutCard } from "../../components/Dashboard/Graphs/DoughnutCard"
import { LineCard } from "../../components/Dashboard/Graphs/LineCard"
import { RankCard } from "../../components/Dashboard/RankCard"
import { TransactionsCard } from "../../components/Dashboard/TransactionsCard"
import { Footer } from "../../components/Footer"
import { DashboardHeader } from "../../components/Headers/DashboardHeader"
import { useAuth } from "../../contexts/UserContext"
import "./Dashboard.sass"

export const Dashboard = () => {

    const renderRank = [
        {
            title: "Praesent in mauris eu tortor porttitor accumsan. Mauris suscipit, ligula sit ame",
            percentage: "69%"
        },
        {
            title: "Praesent in mauris eu tortor porttitor accumsan. Mauris suscipit, ligula sit ame",
            percentage: "69%"
        },
        {
            title: "Praesent in mauris eu tortor porttitor accumsan. Mauris suscipit, ligula sit ame",
            percentage: "69%"
        },
    ]

    const { t } = useTranslation()

    const { user } = useAuth()

    const [verifyToUser, setVerifyToUser] = useState<boolean>(true)

    return (
        <div className="dashboard-content">

            {((user?.register_step && user?.register_step < 7) && verifyToUser) &&
                <div className="verify-account">
                    <span className="verify-text">
                        {t("dashboard.firstAlertText")}
                        <Link to={"./register/"}>{t("dashboard.alertClick")}</Link> <FaExternalLinkAlt className="icon" />
                        {t("dashboard.secondAlertText")}
                    </span>

                    <button className="close-verify-btn" onClick={() => { setVerifyToUser(false) }}>
                        <FaTimes />
                    </button>
                </div>
            }

            <DashboardHeader />
            <div className="information-content">
                <BalanceCard />
                <div className="dashboard-graphs">
                    <DoughnutCard />
                    <BarCard />
                    <RankCard title={t("dashboard.charts.myInvestmentsTitle")} rank={renderRank} />
                </div>
                <div className="dashboard-graphs">
                    <TransactionsCard />
                    <RankCard title={t("dashboard.charts.bestInvestmentsTitle")} rank={renderRank} />
                    <LineCard />
                </div>
            </div>
            <Footer />
        </div>
    )
}