import * as React from 'react';

import "./Icon.sass"

interface IconProps {
  /**
   * Optional aditional classes
   */
  className?: string
  /**
   * Icon element inside the circle
   */
  children: React.ReactNode
}

/**
 * This is Icon component, it have a circle around.
 * 
 * @author Samuel Silva de Ataídes
 * @since 2022-09-15
 */
export default function Icon({ className, children }: IconProps) {
  return (
    <span className={className} data-testid="icon-test">
      {children}
    </span>
  );
};