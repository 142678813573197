import { useState } from "react"
import { useTranslation } from "react-i18next"
import "./ProjectionTable.sass"
import { FaArrowAltCircleDown, FaArrowAltCircleUp, FaThumbsUp } from "react-icons/fa"

export interface ProjectionProps {
  /**
   * Projection profitability over CDI
   */
  // profitabilityCDI: number
  /**
   * Projection profitability
   */
  profitability: string
  /**
   * Projection profitability
   */
  monthly_profit: string
  /**
   * Projection term
   */
  term: number
}

interface ProjectionTableProps {
  /**
   * Projection Table expected scenarios
   */
  expected: ProjectionProps
  /**
   * Projection Table optimistic scenarios
   */
  optimistic: ProjectionProps
  /**
   * Projection Table pessimistic scenarios
   */
  pessimistic: ProjectionProps
};

/**
 * Investment Projection Table
 */
export const ProjectionTable = ({ expected, optimistic, pessimistic }: ProjectionTableProps) => {
  const { t } = useTranslation();

  const [toggleState, setToggleState] = useState<number>(1);

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  return (
    <>
      <div className="inv-details-tab">
        <h2>{t("investmentPage.details.projectionTable.title")}</h2>
        <div className="inv-bloc-tabs">
          <button
            className={toggleState === 1 ? "inv-tabs inv-active-tabs" : "inv-tabs"}
            onClick={() => toggleTab(1)}
          >
            {t("investmentPage.details.projectionTable.expected")} <FaThumbsUp className="icon blue" />
          </button>
          <button
            className={toggleState === 2 ? "inv-tabs inv-active-tabs" : "inv-tabs"}
            onClick={() => toggleTab(2)}
          >
            {t("investmentPage.details.projectionTable.overcoming")} <FaArrowAltCircleUp className="icon green" />
          </button>
          <button
            className={toggleState === 3 ? "inv-tabs inv-active-tabs" : "inv-tabs"}
            onClick={() => toggleTab(3)}
          >
            {t("investmentPage.details.projectionTable.pessimistic")}  <FaArrowAltCircleDown className="icon black" />
          </button>
        </div>
        <div className="inv-content-tabs">
          <div className={toggleState === 1 ? "inv-content  inv-active-content" : "inv-content"} >
            <span className="">{t("investmentPage.details.projectionTable.totalProfitability")}</span>
            <span className="value ">{(parseFloat(expected.monthly_profit) * expected.term).toFixed(2)}%</span>
            <span className="row-gray">{t("investmentPage.details.projectionTable.monthlyProfitability")}</span>
            <span className="value row-gray">{expected.monthly_profit}% a.m.</span>
            <span className="">{t("investmentPage.details.projectionTable.term")}</span>
            <span className="value">{expected.term} {t("investmentPage.details.projectionTable.months")}</span>
          </div>
          <div className={toggleState === 2 ? "inv-content  inv-active-content" : "inv-content"} >
            <span className="">{t("investmentPage.details.projectionTable.totalProfitability")}</span>
            <span className="value ">{(parseFloat(optimistic.monthly_profit) * optimistic.term).toFixed(2)}%</span>
            <span className="row-gray">{t("investmentPage.details.projectionTable.monthlyProfitability")}</span>
            <span className="value row-gray">{optimistic.monthly_profit}% a.m.</span>
            <span className="">{t("investmentPage.details.projectionTable.term")}</span>
            <span className="value">{optimistic.term} {t("investmentPage.details.projectionTable.months")}</span>
          </div>
          <div className={toggleState === 3 ? "inv-content  inv-active-content" : "inv-content"} >
            <span className="">{t("investmentPage.details.projectionTable.totalProfitability")}</span>
            <span className="value">{(parseFloat(pessimistic.monthly_profit) * pessimistic.term).toFixed(2)}%</span>
            <span className="row-gray">{t("investmentPage.details.projectionTable.monthlyProfitability")}</span>
            <span className="value row-gray">{pessimistic.monthly_profit}% a.m.</span>
            <span className="">{t("investmentPage.details.projectionTable.term")}</span>
            <span className="value">{pessimistic.term} {t("investmentPage.details.projectionTable.months")}</span>
          </div>
        </div>
      </div>
    </>
  );
};