import { useTranslation } from "react-i18next"
import { ServerInvestment } from "../../../contexts/InvestmentContext"
import "./InvestmentOperationDetails.sass"
import { useEffect } from "react"

interface InvestmentOperationDetailsProps {
  /**
   * Investment
   */
  investment: ServerInvestment
};

export const InvestmentOperationDetails = ({ investment }: InvestmentOperationDetailsProps) => {

  const { t } = useTranslation()

  return (
    <>
      <div className="investment-operations-details-container">
        <h1 className="title">{t("investment.schedule.operationDetail")}</h1>
        <div className="operations-details">
          {investment.investment_schedule?.operation_details.length === 0 &&
            <div className="default-theme-container">
              <span className="default-theme">{t("theme.noThemes")}</span>
            </div>
          }
          {investment.investment_schedule?.operation_details.map((element, index) => {
            return (
              <div className={`theme ${element.description.length > 400 ? "larger" : ""}`} key={index}>
                <div className="theme-title-container">
                  <h3>{element.theme}</h3>
                </div>
                <div className="description-container">
                  <h3>{element.description}</h3>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}