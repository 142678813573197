import { FaExternalLinkAlt, FaHome, FaTrophy } from "react-icons/fa"
import "./RankCard.sass"

interface RankCardProps {
  title: string
  rank: Array<{ title: string, percentage: string }> // Need to change type after API integration.
}

export const RankCard = ({ title, rank }: RankCardProps) => {

  return (
    <div className="card rank-card">
      <div className="title-container">
        <h1 className="card-title">{title}</h1>
        <button className="open-window-button"><FaExternalLinkAlt className="icon" /></button>
      </div>
      <div className="rank-container">

        {
          rank.map((element, index) => {
            if (index === 0) {
              return (
                <div className="rank-item primary-rank-item">
                  <span className="rank-number">{index + 1}</span>
                  <div className="avatar">
                    <FaHome className="icon" />
                  </div>
                  <div className="item-name-percentage-container">
                    <div className="marquee-wrapper">
                      <span className="item-name one">{element.title}</span>
                      <span className="item-name two">{element.title}</span>
                    </div>
                    <span className="item-percentage">{element.percentage}</span>
                  </div>
                  <FaTrophy className="trophy-icon" />
                  <div className="paper-div"></div>
                </div>
              )
            }

            return (
              <div className="rank-item">
                <span className="rank-number">{index + 1}</span>
                <div className="avatar">
                  <FaHome className="icon" />
                </div>
                <div className="item-name-percentage-container">
                  <div className="marquee-wrapper">
                    <span className="item-name one">{element.title}</span>
                    <span className="item-name two">{element.title}</span>
                  </div>
                  <span className="item-percentage">{element.percentage}</span>
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}