import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { useQuery } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { useRegister } from "../../../contexts/RegisterContext";
import { useAuth } from "../../../contexts/UserContext";
import api from "../../../utils/api";
import { BackButton } from "../../Buttons/BackButton";
import { ButtonPrimary } from "../../Buttons/ButtonPrimary";
import { Input } from "../../Input/Input";
import { InputMasked } from "../../Input/InputMasked";
import { BlackLoadingRing } from "../../Loading/BlackLoadingRing";
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing";
import { SelectSync } from "../../Select/SelectSync";
import "../Register.sass";

/**
 * Address screen to the register step
 */
export const Address = () => {

  const { t } = useTranslation();
  const { user, checkAuth } = useAuth()

  const [enableFields, setEnableFields] = useState<boolean>(true);
  const [enableNeighborhood, setEnableNeighborhood] = useState<boolean>(true);
  const [enableAddress, setEnableAddress] = useState<boolean>(true);

  const { addressInfo, setAddressInfo, setProgress } = useRegister()

  // Forms errors controller
  const [countryError, setCountryError] = useState<boolean>(false);
  const [postalCodeError, setPostalCodeError] = useState<boolean>(false);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string>("");
  const [stateError, setStateError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [neighborhoodError, setNeighborhoodError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [complementError, setComplementError] = useState<boolean>(false);

  const [checkingError, setCheckingError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate()

  const [optionsCountries, setOptionsCountries] = useState<Array<any>>([]);
  const [optionsStates, setOptionsStates] = useState<Array<any>>([]);
  const [optionsCities, setOptionsCities] = useState<Array<any>>([]);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  const [isCEPLoading, setIsCEPLoading] = useState<boolean>(false);

  useEffect(() => {
    setProgress(3)
  })

  const countriesAndCitiesToValueLabel = (data: any) => {
    return {
      value: data.id,
      label: data.name
    }
  };

  const statesToValueLabel = (data: any) => {
    return {
      value: data.id,
      label: data.name,
      code: data.code
    }
  };

  useEffect(() => {
    let elementSelect = document.getElementsByClassName("select-default-error")[0]
    let elementInput = document.getElementsByClassName("input-default-error")
    if ((elementInput[0] || elementSelect) && !checkingError) {
      setCheckingError(true)
      if (elementSelect) {
        elementSelect.scrollIntoView({ behavior: "smooth", block: "center" })
      } else {
        elementInput[0].scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryError, postalCodeError, postalCodeErrorMessage, stateError, cityError, neighborhoodError, numberError, addressError, complementError, countryError, postalCodeError, postalCodeErrorMessage, stateError, cityError, neighborhoodError, numberError, addressError, complementError])

  function searchCities(idCountry: number | null, state: number, city?: string | number) {
    api.get(`/api/utils/cities/${idCountry}/${state}`)
      .then((response) => {
        response.data = response.data.map((data: any) => countriesAndCitiesToValueLabel(data))
        setOptionsCities(response.data);

        if (typeof city === "string") {
          setAddressInfo(registerInfo => {
            registerInfo.city = response.data.find((option: any) => {
              return option.label === city
            })?.value
          });
        }
        else if (typeof city === "number") {
          setAddressInfo(registerInfo => {
            registerInfo.city = city
          });
        }
        setIsDataLoading(false);
        setIsCEPLoading(false);
      })
  }


  /**
   * Search for States on sync mode
   * @param idCountry number
   * @param state number | string
   */
  function searchStates(idCountry: number, state?: number | string, city?: string | number) {
    let idState: number = 0;

    api.get(`/api/utils/states/${idCountry}`)
      .then((response) => {
        response.data = response.data.map((data: any) => statesToValueLabel(data))
        setOptionsStates(response.data);

        idState = 0;

        if (typeof state === "string") {
          idState = response.data.find((option: any) => option.code === state).value;
          setAddressInfo(registerInfo => { registerInfo.state = idState });
          searchCities(idCountry, idState, city);

        } else if (typeof state === "number") {
          idState = state
          setAddressInfo(registerInfo => { registerInfo.state = state });
          searchCities(idCountry, idState, city);

        } else {
          setAddressInfo(registerInfo => { registerInfo.state = 0 });
        }
      })

  }

  const getAddress = (cep: any) => {
    let newCep = cep.replace(".", "").replace("-", "")
    if (!(cep.length === 10 && newCep.length === 8)) {
      setEnableFields(true);
      setEnableNeighborhood(true);
      setEnableAddress(true);

      setPostalCodeErrorMessage(t("register.address.errors.postalCodeWrong"));
      setPostalCodeError(false);
      return
    }

    setIsCEPLoading(true);
    api.get(`https://viacep.com.br/ws/${newCep}/json/`)
      .then(
        response => {
          if (response.data.erro) {
            setPostalCodeErrorMessage(t("register.address.errors.postalCodeWrong"));
            setPostalCodeError(true);
            setIsCEPLoading(false);
            return;
          }
          setAddressInfo(registerInfo => { registerInfo.country = 31 })

          searchStates(31, response.data.uf, response.data.localidade);

          setAddressInfo(registerInfo => { registerInfo.district = response.data.bairro })
          setAddressInfo(registerInfo => { registerInfo.address = response.data.logradouro })
          setAddressInfo(registerInfo => { registerInfo.complement = response.data.complemento })

          setCountryError(false);
          setPostalCodeError(false);
          setStateError(false);
          setCityError(false);
          setNeighborhoodError(false);
          setNumberError(false);
          setAddressError(false);
          setComplementError(false);

          setEnableFields(false);

          setEnableNeighborhood(!response.data.bairro)
          setEnableAddress(!response.data.logradouro)
        }
      )
      .catch(
        (error) => {
          return Promise.reject(error)
        }
      )
  }

  const confirmAddress = () => {

    let errorsCount: number = 0;

    if (!addressInfo.country) {
      setCountryError(true);
      errorsCount++
    }
    if (postalCodeError || (addressInfo.postal_code.length !== 10 && addressInfo.country === 31)) {
      setPostalCodeErrorMessage(t("register.address.errors.postalCodeWrong"))
      setPostalCodeError(true);
      errorsCount++
    }
    if (!addressInfo.postal_code) {
      setPostalCodeErrorMessage(t("register.address.errors.postalCodeMissing"))
      setPostalCodeError(true);
      errorsCount++
    }
    if (!addressInfo.state) {
      setStateError(true);
      errorsCount++
    }
    if (!addressInfo.city) {
      setCityError(true);
      errorsCount++
    }
    if (!addressInfo.district) {
      setNeighborhoodError(true);
      errorsCount++
    }
    if (!addressInfo.number) {
      setNumberError(true);
      errorsCount++
    }
    if (!addressInfo.address) {
      setAddressError(true);
      errorsCount++
    }
    if (!addressInfo.complement) {
      setComplementError(true);
      errorsCount++
    }

    if (errorsCount > 0) {
      setCheckingError(false);
      return
    }

    const data = {
      country: addressInfo.country,
      postal_code: addressInfo.postal_code,
      state: addressInfo.state,
      city: addressInfo.city,
      district: addressInfo.district,
      number: addressInfo.number,
      address: addressInfo.address,
      complement: addressInfo.complement
    }

    setIsLoading(true);

    api.post("/api/user/register", data)
      .then(
        (response) => {
          checkAuth().then(() => { navigate("/dashboard/register/bank-data") })
        }
      )
      .catch(
        (err) => {
          if (err.response.data.detail === "INVALID_STEP") {
            navigate("/dashboard/register/bank-data")
          }

          if (err.response.data.detail === "ALREADY_REGISTERED") {
            navigate("/dashboard/")
          }
        }
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  useQuery("register/address/countries", () => {
    api.get(`/api/utils/countries`)
      .then((response) => {
        response.data = response.data.map((data: any) => countriesAndCitiesToValueLabel(data))
        setOptionsCountries(response.data);
      })
  })

  useQuery("register/address",
    () => {
      setIsDataLoading(true);
      api.get("/api/user/register/step-data/3").then(
        (response) => {
          setAddressInfo(registerInfo => {
            registerInfo.postal_code = response.data.country ? (response.data.country === 31 ? response.data.postal_code.slice(0, 2) + '.' + response.data.postal_code.slice(2, 5) + '-' + response.data.postal_code.slice(5) : response.data.postal_code) : '';
          });

          setAddressInfo(registerInfo => { registerInfo.country = response.data.country })

          if (response.data.country && response.data.state && response.data.city) {
            searchStates(response.data.country, response.data.state, response.data.city);
          }
          else {
            setIsDataLoading(false)
          }

          let enableFields: any = null;

          setAddressInfo(registerInfo => {
            registerInfo.district = response.data.district ?? "";
            registerInfo.number = response.data.number ?? "";
            registerInfo.address = response.data.address ?? "";
            registerInfo.complement = response.data.complement ?? "";

          });

          enableFields = response.data.postal_code === null ? true : false

          setEnableFields(enableFields ?? true);
        }
      )
    }
  )

  if (user === null) {
    return <Navigate to="/login" />
  }

  if (user.register_step < 3) {
    return <Navigate to="/dashboard/register" />
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t('register.address.title')}</h1>
          <div className="form">
            <div className="input-block">
              <div className="down-input-group">
                <SelectSync
                  label={t('register.address.country')}
                  id="country"
                  type="text"
                  required={true}
                  options={optionsCountries}
                  onChange={(value: any) => { setAddressInfo(registerInfo => { registerInfo.country = value.value }); setCountryError(false); searchStates(value.value); }}
                  value={optionsCountries.find(option => option.value === addressInfo.country)}
                  placeholder={t("register.address.placeholder.country")}
                  disabled={!enableFields}
                  error={countryError}
                  errorMessage={t("register.address.errors.country")}
                />
                <InputMasked
                  mask={addressInfo.country === 31 ? "99.999-999" : ""}
                  label={t('register.address.postalCode')}
                  id="postalCode"
                  type="text"
                  required={true}
                  autoComplete={"new"}
                  onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.postal_code = e.target.value }); getAddress(e.target.value); }}
                  value={addressInfo.postal_code}
                  placeholder={t("register.address.placeholder.postalCode")}
                  error={postalCodeError}
                  errorMessage={postalCodeErrorMessage}
                  before={isCEPLoading ?
                    <div className={`w-4 h-4`}>
                      <BlackLoadingRing />
                    </div> : undefined}
                />
              </div>
              <div className="down-input-group">
                <SelectSync
                  label={t('register.address.state')}
                  id="state"
                  type="text"
                  required={true}
                  options={optionsStates}
                  onChange={(value: any) => { setAddressInfo(registerInfo => { registerInfo.state = value.value }); setStateError(false); searchCities(addressInfo.country, value.value) }}
                  value={optionsStates.find(option => option.value === addressInfo.state)}
                  placeholder={t("register.address.placeholder.state")}
                  disabled={!enableFields}
                  error={stateError}
                  errorMessage={t("register.address.errors.state")}
                />
                <SelectSync
                  label={t('register.address.city')}
                  id="city"
                  type="text"
                  required={true}
                  options={optionsCities}
                  onChange={(value: any) => { setAddressInfo(registerInfo => { registerInfo.city = value.value }); setCityError(false); }}
                  value={optionsCities.find(option => option.value === addressInfo.city)}
                  placeholder={t("register.address.placeholder.city")}
                  disabled={!enableFields}
                  error={cityError}
                  errorMessage={t("register.address.errors.city")}
                />
              </div>
              <div className="down-input-group">
                <Input
                  label={t('register.address.district')}
                  id="district"
                  type="text"
                  required={true}
                  onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.district = e.target.value }); setNeighborhoodError(false); }}
                  value={addressInfo.district}
                  placeholder={t("register.address.placeholder.district")}
                  disabled={!enableNeighborhood}
                  error={neighborhoodError}
                  errorMessage={t("register.address.errors.neighborhood")}
                />
                <InputMasked
                  mask="999999"
                  label={t('register.address.number')}
                  id="number"
                  type="text"
                  required={true}
                  onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.number = e.target.value }); setNumberError(false); }}
                  value={addressInfo.number}
                  placeholder={t("register.address.placeholder.number")}
                  error={numberError}
                  errorMessage={t("register.address.errors.number")}
                />
              </div>
              <Input
                label={t('register.address.address')}
                id="address"
                type="text"
                required={true}
                autoComplete="address"
                onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.address = e.target.value }); setAddressError(false); }}
                value={addressInfo.address}
                disabled={!enableAddress}
                placeholder={t("register.address.placeholder.address")}
                error={addressError}
                errorMessage={t("register.address.errors.address")}
              />
              <Input
                label={t('register.address.complement')}
                id="complement"
                type="text"
                required={true}
                onChange={(e: any) => { setAddressInfo(registerInfo => { registerInfo.complement = e.target.value }); setComplementError(false); }}
                value={addressInfo.complement}
                placeholder={t("register.address.placeholder.complement")}
                error={complementError}
                errorMessage={t("register.address.errors.complement")}
                maxLength={50}
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="/dashboard/register/code"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={confirmAddress} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};