import { Dispatch, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleLeft, FaAngleRight, FaCamera } from "react-icons/fa";
import { SwiperProps, SwiperSlide } from "swiper/react";
import Slider from "..";
import { ServerFile } from "../../../contexts/InvestmentContext";
import Icon from "../../Icon";
import "./AddImageSlider.sass";
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing";

interface AddImagesSliderProps {
  title?: string
  subtitle?: string
  label?: string
  sliderSettings?: SwiperProps
  images: Array<ServerFile | File> | null
  showProgressBar?: boolean
  uploadFileProgress?: number
  addFileCallback?: (file: HTMLInputElement) => void
  documentSliderComponent: ReactNode
  isLoading?: boolean
  errorState?: boolean
  errorSetState?: Dispatch<boolean>
}

export const AddImageSlider = ({ title, subtitle, label, sliderSettings, images, showProgressBar, uploadFileProgress, addFileCallback, documentSliderComponent, errorState, errorSetState, isLoading }: AddImagesSliderProps) => {

  const { t } = useTranslation()

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const imagesElRef = useRef<HTMLInputElement>(null);

  let imagesSettings: SwiperProps = {
    freeMode: true,
    className: "swiper-container-horizontal-reg-inv",
    slidesPerView: 3,
    spaceBetween: 10,
    scrollbar: true,
    breakpoints: {
      512: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 4,
      },
    },
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current
    }
  }

  if (sliderSettings !== undefined) {
    imagesSettings = {
      ...sliderSettings,
      navigation: {
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current
      }
    }
  }

  return (
    <div className="images-slider-container">
      <h1 className="title">{title}</h1>
      <span className="subtitle">{subtitle}</span>
      <label className="slider-label">{label}</label>

      <div className="slider-reg-inv-images">
        <Slider
          settings={imagesSettings}
        >
          {addFileCallback !== undefined &&
            <SwiperSlide>
              <div className="reg-inv-images-slide-viewer cursor-pointer" onClick={() => imagesElRef.current !== null ? imagesElRef.current.click() : false}>
                <div className={`image-upload ${errorState ? "error" : ""}`}>
                  <FaCamera className="reg-inv-images-icon" />
                  <span className="reg-inv-images-title">{t("investment.images.button.add")}</span>
                  <span className="reg-inv-images-subtitle">{t("investment.images.button.types")}</span>
                </div>
              </div>
              <input type="file" accept="image/gif, image/jpeg, image/png" data-testid="add-image-slider-test" ref={imagesElRef} multiple onChange={(e: React.ChangeEvent<HTMLInputElement>) => { addFileCallback(e.target) }} className="hidden" />
            </SwiperSlide>
          }

          {isLoading &&
            <SwiperSlide>
              <div className="reg-inv-images-slide-viewer cursor-wait">
                <div className="w-10 h-10">
                  <GreenLoadingRing />
                </div>
              </div>
            </SwiperSlide>
          }

          <>
            {documentSliderComponent}
          </>


          <button ref={navigationPrevRef} className="prev-btn" onClick={() => { }}>
            <Icon className='bg-gray-white-rounded w-6 h-6 text-xs'>
              <FaAngleLeft className='icon' />
            </Icon>
          </button>
          <button ref={navigationNextRef} className="next-btn" onClick={() => { }}>
            <Icon className='bg-gray-white-rounded w-6 h-6 text-xs'>
              <FaAngleRight className='icon' />
            </Icon>
          </button>

        </Slider>

        {errorState && <span className="error-span">{t("investment.images.errors.span")}</span>}
      </div>
    </div>
  )
}