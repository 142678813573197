import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { Alert } from "../components/Alert";

export interface AlertProps {
  /**
   * Type of alert.
   */
  type: "success" | "warning" | "danger" | "common"
  /**
   * Title of alert, like "Success", "Something Wrong".
   */
  title: String
  /**
   * Text to complement the alert.
   */
  text: String
  /**
   * Time in milliseconds to alert disappear.
   */
  timeout: number
}

interface AlertContextProps {
  presentAlert: (alert: AlertProps) => boolean
  showAlert: boolean
  setShowAlert: Dispatch<SetStateAction<boolean>>
}


export const AlertContext = createContext({} as AlertContextProps)

export const AlertContextProvider = ({ children }: { children: ReactNode }) => {

  const [alert, setAlert] = useState<AlertProps>(
    {
      type: "common",
      title: "Common",
      text: "Common alert",
      timeout: 5000
    }
  )

  const [showAlert, setShowAlert] = useState(false)

  function presentAlert(alert: AlertProps) {
    setAlert(alert)
    setShowAlert(true)
    return showAlert
  }

  return (
    <AlertContext.Provider
      value={{ showAlert, setShowAlert, presentAlert }}
    >
      {children}
      {showAlert === true &&
        <Alert type={alert.type} text={alert.text} title={alert.title} timeout={alert.timeout} />
      }
    </AlertContext.Provider>
  )
}

export const useAlertContext = () => {
  return useContext(AlertContext)
}