import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useCreateInvestment } from "../../../../contexts/CreateInvestment";
import { ServerFile } from "../../../../contexts/InvestmentContext";
import api from "../../../../utils/api";
import { FullWhiteLoadingRings } from "../../../Loading/FullWhiteLoadingRings";

export const SwiperImage = ({ data, index, canDelete = true }: { data: File | ServerFile, index: number, canDelete?: boolean }) => {

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { investmentImages, setInvestmentImages } = useCreateInvestment()

  const deleteImage = (index: number) => {
    let arrayCopy = structuredClone(investmentImages.images);
    arrayCopy.splice(index, 1);
    setInvestmentImages(investmentImages => { investmentImages.images = arrayCopy });
  }

  const deleteServerImage = (index: number, id: string) => {
    setIsLoading(true)
    api.delete(`/api/investment/document/${id}`).then(
      response => {
        deleteImage(index)
      }
    ).finally(
      () => {
        setIsLoading(false)
      }
    )
  }

  return (
    <div className='reg-inv-images-slide-viewer relative'>
      <img className='img-blur' src={"id" in data ? `/api/investment/document/${data.id}` : (URL.createObjectURL(data))} alt="" />
      <img src={"id" in data ? `/api/investment/document/${data.id}` : (URL.createObjectURL(data))} alt="investment" className='img-view' />
      {index === 0 && <span className="principal">PRINCIPAL</span>}
      {canDelete &&
        <button className="delete-reg-inv-image" onClick={() => { "id" in data ? deleteServerImage(index, data.id) : deleteImage(index) }}>
          <FaTimes />
        </button>
      }
      {isLoading && (
        <FullWhiteLoadingRings />
      )}
    </div>
  )
}