import { Footer } from "../../components/Footer";
import { SignUpCard } from "../../components/SignupCard";

export const SignUp = () => {
  return (
    <>
      <SignUpCard></SignUpCard>
      <Footer></Footer>
    </>
  );
};