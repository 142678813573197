import { useEffect } from "react"
import { IoClose } from "react-icons/io5"

import "./SmartLink.sass";

export const SmartLink = ({ link }: { link: string }) => {
  const id = [...Array(30)].map(() => Math.random().toString(36)[2]).join('')
  const domain = link.match(/(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm)

  useEffect(() => {
    const element: HTMLImageElement | null = document.querySelector(`#--${id}`)
    if (element !== null) {
      element.addEventListener('error', () => {
        element.src = "/resources/link.svg"
      })
    }
  })

  if (domain === null) throw new Error("link with no domain is not acceptable")

  return (
    <a href={link} target="blank" title={link} className="investment-page-smart-link">
      <div className="link-img">
        <img id={"--" + id} src={`https://www.google.com/s2/favicons?domain=${domain}&sz=32`} alt="" />
      </div>
      <div className="link-name">
        {link}
      </div>
    </a>
  )
}


export const SmartLinkSmall = ({ link, index, close }: { link: string, close?: (index: number) => void, index?: number }) => {
  const id = [...Array(30)].map(() => Math.random().toString(36)[2]).join('')
  const domain = link.match(/(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm)

  useEffect(() => {
    const element: HTMLImageElement | null = document.querySelector(`#--${id}`)
    if (element !== null) {
      element.addEventListener('error', () => {
        element.src = "/resources/link.svg"
      })
    }
  })

  if (domain === null) throw new Error("link with no domain is not acceptable")

  return (
    <a href={link} target="blank" className="flex items-center h-8 w-full max-w-[265px] p-[5px] bg-[#EAEAEA] rounded-[15px] gap-2 transition-all">
      <img id={"--" + id} className="w-6 h-6 rounded-full" src={`https://www.google.com/s2/favicons?domain=${link}&sz=32`} alt="" />
      <p className="text-gray-500 w-full overflow-hidden whitespace-nowrap text-xs font-medium text-ellipsis">
        {link}
      </p>
      <button type="button" className="w-6 h-6 justify-self-end" onClick={(e) => { e.stopPropagation(); e.preventDefault(); (close !== undefined && index !== undefined) && close(index) }}>
        <IoClose />
      </button>
    </a>
  )
}