import { AxiosResponse } from "axios";
import { createContext, ReactNode, useContext, useState } from "react";
import api from "../utils/api";

export interface UserProps {
  is_email_verified: boolean
  is_number_verified: boolean
  register_step: number
  name: string
  email: string
  type: "investor" | "consultant"
}

interface UserContextProps {
  checkAuth: () => Promise<AxiosResponse<UserProps, any>>
  user: UserProps | null,
  logOut: () => Promise<AxiosResponse<UserProps, any>>
}


export const UserContext = createContext({} as UserContextProps)

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserProps | null>(null)

  function checkAuth() {
    return api.get<UserProps>("/api/user").then(
      response => {
        setUser({ ...response.data })
        return response
      },
      error => {
        setUser(null)
        return error
      }
    )
  }

  function logOut() {
    return api.post("/api/user/logout").then(
      response => {
        setUser(null)
        return response
      },
      error => {
        console.error(error)
        return error
      }
    )
  }

  return (
    <UserContext.Provider
      value={{ checkAuth, user, logOut }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(UserContext)
}
