import { FaTimes } from "react-icons/fa";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TextArea } from "../../../Input/TextArea";
import { useState } from "react";
import api from "../../../../utils/api";
import { useAlertContext } from "../../../../contexts/AlertContext";
import { useParams } from "react-router-dom";

export const RejectInvestmentModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

  const { t } = useTranslation()

  const [rejectDescription, setRejectDescription] = useState<string>("")

  const [rejectDescriptionError, setRejectDescriptionError] = useState<boolean>(false)

  const { presentAlert } = useAlertContext()

  const { id } = useParams<{ id: string }>()

  function handleReject() {
    if (rejectDescription === "") {
      setRejectDescriptionError(true)
      return
    }

    api.put(`/api/investment/${id}/status`, { status: 3, reason: rejectDescription })
      .then((res) => {
        presentAlert({
          title: t("investment.validate.room.rejectModal.alert.success.title"),
          text: t("investment.validate.room.rejectModal.alert.success.text"),
          type: "success",
          timeout: 3000
        })
        handleClose()
      })
      .catch((err) => {
        presentAlert({
          title: t("investment.validate.room.rejectModal.alert.error.title"),
          text: t("investment.validate.room.rejectModal.alert.error.text"),
          type: "danger",
          timeout: 3000
        })
      })
  }

  return (
    <Dialog open={open}
      onClose={handleClose}
      PaperProps={{
        style: { "borderRadius": "12px" }
      }}
      maxWidth={"md"}
      scroll="body"
      fullWidth
    >
      <div className="investment-modal-container">
        <button className="close-button" onClick={() => handleClose()}>
          <FaTimes className="icon" />
        </button>

        <div className="left-container">
          <div className="message-container">
            <h1 className="title">{t("investment.validate.room.rejectModal.title")}</h1>
            <TextArea
              label={t("investment.validate.room.rejectModal.label")}
              value={rejectDescription}
              onChange={e => { setRejectDescription(e.target.value); setRejectDescriptionError(false) }}
              placeholder={t("investment.validate.room.rejectModal.placeholder")}
              error={rejectDescriptionError}
              errorMessage={t("investment.validate.room.rejectModal.error")}
              maxLength={300}
              required
            />
          </div>
        </div>
        <div className="right-container">
          <ButtonPrimary
            children={t("investment.validate.room.launchModal.save")}
            onClick={handleReject}
          />
        </div>
      </div>
    </Dialog>
  )
}