import { useEffect, useState } from "react"
import { IoChevronBack, IoChevronForward } from "react-icons/io5"
import { SwiperProps, SwiperSlide } from "swiper/react"
import SwiperClass from "swiper/types/swiper-class"
import Slider, { SwiperSettings } from ".."

/**
 * ImageSlider component
 * @param images Arrays of images
 */
export const ImageSlider = ({ images }: { images: string[] }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const [swiperSettings, setSwiperSettings] = useState<SwiperSettings>({ isStart: true, isEnd: true })
  /**
   * SLider setting constant
   */
  const sliderSettings: SwiperProps = {
    onSwiper: setSwiperRef,
    className: "image-slider",
    onActiveIndexChange: swiperCore => {
      if (swiperCore !== undefined) {
        setSwiperSettings({
          isStart: swiperCore.activeIndex === 0,
          isEnd: swiperCore.isEnd
        })
      }
    }
  }
  /**
   * Method to handle the prev button 
   * @param ref 
   */
  const handlePrevClick = (ref: SwiperClass | undefined) => {
    if (!ref) return;
    ref.slidePrev()
  }
  /**
   * Method to handle the next button 
   * @param ref 
   */
  const handleNextClick = (ref: SwiperClass | undefined) => {
    if (!ref) return;
    ref.slideNext()
  }

  useEffect(() => {
    if (swiperRef !== undefined) {
      setSwiperSettings({ isStart: swiperRef.activeIndex === 0, isEnd: swiperRef.isEnd })
    }
  }, [swiperRef])

  return (
    <div className="flex h-full w-full items-center relative">
      <button type="button" className={`absolute top-auto left-2 z-10 rounded-full bg-white bg-opacity-80 w-8 h-8 flex items-center justify-center ${swiperSettings.isStart ? "opacity-40 cursor-not-allowed" : "opacity-100"}`} onClick={e => { e.stopPropagation(); handlePrevClick(swiperRef) }}><IoChevronBack className="w-4/5 h-4/5" /></button>
      <Slider settings={sliderSettings}>
        {
          images.map((images, index) => (
            <SwiperSlide key={images + index} data-testid="swiper-slide">
              <div className="flex items-center justify-center w-full h-full relative overflow-hidden">
                <img className="background-image" src={images} alt="" />
                <img className="z-10" src={images} alt="" />

              </div>
            </SwiperSlide>
          ))
        }
      </Slider>
      <button type="button" className={`absolute top-auto right-2 z-10 rounded-full bg-white bg-opacity-80 w-8 h-8 flex items-center justify-center ${swiperSettings.isEnd ? "opacity-40 cursor-not-allowed" : "opacity-100"}`} onClick={e => { e.stopPropagation(); handleNextClick(swiperRef) }}><IoChevronForward className="w-4/5 h-4/5" /></button>
    </div>
  )
}