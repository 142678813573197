import { Dialog } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaTimes } from "react-icons/fa"
import { useParams } from "react-router-dom"
import { useAlertContext } from "../../../../contexts/AlertContext"
import api from "../../../../utils/api"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { InputDatePicker } from "../../../Input/InputDatePicker"
import "./LaunchInvestmentModal.sass"

export const LaunchInvestmentModal = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

  const { t } = useTranslation()

  const { presentAlert } = useAlertContext()

  const [makeDate, setMakeDate] = useState<Date | null>()
  const [finalCapitationDate, setFinalCapitationDate] = useState<Date | null>()

  const [makeDateNow, setMakeDateNow] = useState<boolean>(false)

  const [makeDateError, setMakeDateError] = useState<boolean>(false)
  const [finalCapitationDateError, setFinalCapitationDateError] = useState<boolean>(false)

  const { id } = useParams<{ id: string }>()

  function handleMakeInvestment() {

    let errorsCount: number = 0

    if (makeDateNow === false) {
      if (!makeDate) {
        setMakeDateError(true)
        errorsCount++
      }
    }

    if (!finalCapitationDate) {
      setFinalCapitationDateError(true)
      errorsCount++
    }

    if (errorsCount > 0) {
      return
    }

    api.put(`/api/investment/${id}/launch`, {
      start_date: makeDate || new Date(),
      end_date: finalCapitationDate
    })
      .then((response) => {
        presentAlert({
          title: t("investment.validate.room.launchModal.alert.success.title"),
          text: t("investment.validate.room.launchModal.alert.success.text"),
          type: "success",
          timeout: 3000
        })
        handleClose()
      })
      .catch((err) => {
        presentAlert({
          title: t("investment.validate.room.launchModal.alert.error.title"),
          text: t("investment.validate.room.launchModal.alert.error.text"),
          type: "danger",
          timeout: 5000
        })
      })
  }


  return (
    <Dialog open={open}
      onClose={handleClose}
      PaperProps={{
        style: { "borderRadius": "12px" }
      }}
      maxWidth={"md"}
      scroll="body"
      fullWidth
    >
      <div className="investment-modal-container launch-modal-container">
        <button className="close-button" onClick={() => handleClose()}>
          <FaTimes className="icon" />
        </button>

        <div className="left-container">

          <div className="message-container">
            <h1 className="title">{t("investment.validate.room.launchModal.title")}</h1>
            <div className="make-date-container">
              <InputDatePicker
                dateFormat={"dd/MM/yyyy"}
                momentDateFormat="DD/MM/YYYY"
                selected={makeDate}
                className="make-date-input"
                onChange={(date: Date | null) => { setMakeDate(date); if (date !== null) { setMakeDateError(false) }; }}
                id="make-date"
                label={t("investment.validate.room.launchModal.startDateLabel")}
                type={"text"}
                placeholder={"01/05/2023 00:00:00"}
                error={makeDateError}
                errorMessage={t("investment.validate.room.launchModal.startDateError")}
                disabled={makeDateNow}
              />

              <input id="now-check" type="checkbox" onClick={(e) => { setMakeDateNow(e.isTrusted); setMakeDateError(false) }} />
              <label className="checkbox-label" htmlFor="now-check">{t("investment.validate.room.launchModal.startDateCheckbox")}</label>
            </div>

            <div className="make-date-container">
              <InputDatePicker
                dateFormat={"dd/MM/yyyy"}
                momentDateFormat="DD/MM/YYYY"
                selected={finalCapitationDate}
                className="make-date-input"
                onChange={(date: Date | null) => { setFinalCapitationDate(date); if (date !== null) { setFinalCapitationDateError(false) }; }}
                id="make-date"
                label={t("investment.validate.room.launchModal.endDateLabel")}
                type={"text"}
                placeholder={"01/05/2023 00:00:00"}
                error={finalCapitationDateError}
                errorMessage={t("investment.validate.room.launchModal.endDateError")}
              />

              <label className="checkbox-label" htmlFor="now-check">{t("investment.validate.room.launchModal.endDateCheckbox")}</label>
            </div>
          </div>
        </div>
        <div className="right-container">
          <ButtonPrimary
            children={t("investment.validate.room.launchModal.save")}
            onClick={handleMakeInvestment}
          />
        </div>
      </div>
    </Dialog>
  )
}