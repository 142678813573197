import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaBars, FaChartLine, FaCheck, FaCog, FaMagic, FaSearch, FaSignOutAlt, FaTachometerAlt, FaTimes, FaUser, FaWallet } from "react-icons/fa"
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../contexts/UserContext"
import Icon from "../Icon"
import "./Menu.sass"

/**
 * Dashboard Side Menu
 */
export const Menu = () => {

  const { user, logOut } = useAuth()

  const { t } = useTranslation()

  const [menu, setMenu] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  let menuData = [
    {
      title: t("menu.dashboard"),
      path: "/dashboard/",
      icon: <FaTachometerAlt />,
    },
    {
      title: t("menu.wallet"),
      path: "/dashboard/my-wallet/",
      icon: <FaWallet />,
    },
    {
      title: t("menu.investments"),
      path: "/dashboard/my-investments/",
      icon: <FaChartLine />,
    },
    {
      title: t("menu.proposeInvestment"),
      path: "/dashboard/my-proposes/",
      icon: <FaMagic />,
    },
    {
      title: t("menu.searchInvestments"),
      path: "/investments",
      icon: <FaSearch />,
    },
    {
      title: t("menu.settings"),
      path: "/dashboard/settings/",
      icon: <FaCog />,
    },
  ]

  if (user?.type === "consultant") {
    menuData = [
      {
        title: t("menu.dashboard"),
        path: "/dashboard/",
        icon: <FaTachometerAlt />,
      },
      {
        title: t("menu.investments"),
        path: "/dashboard/my-investments/",
        icon: <FaChartLine />,
      },
      {
        title: t("menu.validateInvestment"),
        path: "/dashboard/validate-investments/",
        icon: <FaCheck />,
      },
      {
        title: t("menu.searchInvestments"),
        path: "/investments",
        icon: <FaSearch />,
      },
      {
        title: t("menu.settings"),
        path: "/dashboard/settings/",
        icon: <FaCog />,
      },
    ]
  }

  const showSideBar = () => {
    setMenu(!menu);
    if (!menu) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "scroll"
    }
  }

  if (user === null) {
    return (
      <Navigate to="/login" />
    )
  }

  /**
   * This is a event listener to close the menu when the user clicks the escape key.
   */
  document.addEventListener("keyup", (e) => { e.stopPropagation(); if (e.key === "Escape" && menu) showSideBar() })

  /**
   * This method handles the active class of the navigation links, which is activated after the end of the path.
   * @param navLinkPath Redirect link listed in menuData
   * @returns boolean
   */
  const selectActiveByLocation = (navLinkPath: string) => {
    if (navLinkPath === "/dashboard/") return false;

    return pathname.includes(navLinkPath.replace("/dashboard", ""))
  }

  return (
    <div>
      <div>
        <button className="menu-icon-container" onClick={showSideBar}>
          <FaBars className="icon" />
        </button>
      </div>

      <div className={menu ? "backdrop active" : "backdrop"} onClick={showSideBar} />

      <div className={menu ? "menu-container active" : "menu-container"}>
        <div className={menu ? "close-button-container" : "close-button-container active"}>
          <button className="close-button" onClick={showSideBar}>
            <FaTimes className="icon" />
          </button>
        </div>

        <div className="menu-side">
          <nav>
            <div className={"menu-profile-container"} onClick={e => { showSideBar(); navigate("/dashboard/settings/") }}>
              <Icon className="icon-container bg-gray-white-rounded" children={<FaUser className="icon" />}></Icon>
              <div className="profile-text">
                <h1>{user.name}</h1>
                <span>{user.email}</span>
              </div>
            </div>
            <ul className="items-list">
              {menuData.map((element, index) => {
                return (
                  <li key={index}>
                    <NavLink to={element.path} onClick={showSideBar} className={(navData) => navData.isActive || selectActiveByLocation(element.path) ? "item selected" : "item"} end >
                      <Icon className="item-icon" children={element.icon} />
                      <span className="item-text">{element.title}</span>
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </nav>
          <div className="logout-button-container">
            <button className="logout-button" onClick={() => { showSideBar(); logOut() }}>
              <FaSignOutAlt className="logout-icon" />
              <span>{t("menu.logout")}</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}
