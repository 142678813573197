import { Link } from "react-router-dom"
import { Menu } from "../../Menu"

import "./DashboardHeader.sass"

export const DashboardHeader = () => {
  return (
    <header className="simple-header-container">
      <div className="nav">
        <div className="menu">
          <Menu />
        </div>
        <div>
          <Link data-testid="navigateimg" to="/dashboard/" className="nav-div-grp-img">
            <img src="/single-inv-black.svg" className="img img-long" alt="Single Investimentos Logo" />
            <img src="/single-black.svg" className="img img-short" alt="Single Investimentos Logo" />
          </Link>
        </div>
      </div>
    </header>
  )
}