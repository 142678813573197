import { useEffect, useState } from "react"
import { IoChevronBack, IoChevronForward } from "react-icons/io5"
import { SwiperProps, SwiperSlide } from "swiper/react"
import SwiperClass from "swiper/types/swiper-class"
import Slider, { SwiperSettings } from ".."
import { ServerInvestment } from "../../../contexts/InvestmentContext"
import { InvestmentCard } from "../../Investment/InvestmentCard"
import "./InvestmentSlider.sass"

/**
 * InvestmentSlider props
 */
interface InvestmentSliderProps {
  /**
   * Arrays of investments
   */
  investments: ServerInvestment[]
  /**
   * Title of the slider
   */
  label: string
}
/**
 * InvestmentSlider component
 * 
 * @param investments Array of investments 
 * @param label Title of slider  
 */
export const InvestmentSlider = ({ investments, label }: InvestmentSliderProps) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const [swiperSettings, setSwiperSettings] = useState<SwiperSettings>({ isStart: true, isEnd: true })

  const sliderSettings: SwiperProps = {
    freeMode: false,
    className: "swiper-container-horizontal",
    breakpoints: {
      640: {
        slidesPerView: 1,
        freeMode: true
      },
      880: {
        slidesPerView: 3,
        freeMode: false
      },
      1160: {
        slidesPerView: 4,
        freeMode: false
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 50,
        freeMode: false
      }
    },
    onSwiper: setSwiperRef,
    onActiveIndexChange: swiperCore => {
      if (swiperCore !== undefined) {
        setSwiperSettings({
          isStart: swiperCore.activeIndex === 0,
          isEnd: swiperCore.isEnd
        })
      }
    }
  }
  /**
   * Method to handle prev button
   */
  const handlePrevClick = (ref: SwiperClass | undefined) => {
    if (!ref) return;
    ref.slidePrev()
  }
  /**
   * Method to handle next button
   */
  const handleNextClick = (ref: SwiperClass | undefined) => {
    if (!ref) return;
    ref.slideNext()
  }

  useEffect(() => {
    if (swiperRef !== undefined) {
      setSwiperSettings({ isStart: swiperRef.activeIndex === 0, isEnd: swiperRef.isEnd })
    }
  }, [swiperRef])

  return (
    <div className="investment-section">
      <h2>{label}</h2>
      <div className="flex items-center justify-between w-full h-full relative hover:z-20 my-2">
        <button type="button" data-testid="investment-button-slide" className={`hidden sm:block ${swiperSettings.isStart ? "opacity-0 cursor-default" : "opacity-100"}`} onClick={e => { e.stopPropagation(); handlePrevClick(swiperRef) }}><IoChevronBack /></button>
        <Slider settings={sliderSettings}>
          {
            investments.map((investment, index) => (
              <SwiperSlide key={investment.title + index} data-testid="swiper-investment-slide">
                <InvestmentCard investment={investment} />
              </SwiperSlide>
            ))
          }
        </Slider>
        <button type="button" data-testid="investment-button-slide" className={`hidden sm:block ${swiperSettings.isEnd ? "opacity-0 cursor-default" : "opacity-100"}`} onClick={e => { e.stopPropagation(); handleNextClick(swiperRef) }}><IoChevronForward /></button>
      </div>
    </div>
  )
}