import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { useAlertContext } from "../../../../contexts/AlertContext"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { TextArea } from "../../../Input/TextArea"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import { SelectSync } from "../../../Select/SelectSync"
import { ValidateInvestmentButtons } from "../ValidateInvestmentButtons"

const securityTypes: { value: number | null, label: string }[] = [
  {
    value: 0,
    label: "Seguro"
  },
  {
    value: 1,
    label: "Imóvel em garantia"
  },
  {
    value: 2,
    label: "Avalista"
  },
  {
    value: null,
    label: "Outro"
  },
]

export const ValidateSecurityInvestment = () => {

  const navigate = useNavigate()

  const { pages, setProgress, investmentSecurity, isDataLoading } = useValidateInvestment()

  const { presentAlert } = useAlertContext()

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const investmentToken = useParams<{ id: string }>().id

  const confirmSecurity = () => {

    const data: Array<ValidatePages> = pages.filter(page => page !== null && (page.page_id === 5))

    if (data.length < 3) {
      presentAlert(
        {
          title: t("investment.validate.alert.empty.title"),
          text: t("investment.validate.alert.empty.text"),
          type: "danger",
          timeout: 4000
        }
      )

      return
    }

    setIsLoading(true)

    api.put(`/api/investment/${investmentToken}/validate`, data)
      .then(() => {
        navigate(`/investment/${investmentToken}/validate/public-documents`)
      }).catch(() => {
        presentAlert(
          {
            title: t("investment.validate.alert.error.title"),
            text: t("investment.validate.alert.error.text"),
            type: "danger",
            timeout: 3000
          }
        )
      }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setProgress(5)
  })

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1>{t("investment.create.investmentSecurity.title")}</h1>
          <p>{t("investment.create.investmentSecurity.subtitle")}</p>
          <form className="form">
            <div className="input-block">
              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={5} input_id={1} textareaId="validate-security-investment" textareaLabel={t("investment.create.investmentSecurity.securityType.label")} />
                <SelectSync
                  label={t("investment.create.investmentSecurity.securityType.label")}
                  value={securityTypes.find(category => category.value === investmentSecurity.securityType)}
                  options={securityTypes}
                  onChange={() => { }}
                  disabled
                />
              </div>
              <div className="validate-input-container">
                <ValidateInvestmentButtons page_id={5} input_id={2} textareaId="validate-description-investment" textareaLabel={t("investment.create.investmentSecurity.description.label")} />
                <TextArea
                  label={t("investment.create.investmentSecurity.description.label")}
                  value={investmentSecurity.description}
                  placeholder={t("investment.create.investmentSecurity.description.placeholder")}
                  disabled
                />
              </div>

              <DocumentsInput
                title={t("investment.create.investmentSecurity.documents.label")}
                documents={investmentSecurity.documents}
                validate_page_id={5}
                validate_input_id={3}
                textareaId="validate-security-documents-investment"
                textareaLabel={t("investment.create.investmentSecurity.documents.label")}
                documentsComponent={
                  investmentSecurity.documents !== null &&
                  investmentSecurity.documents.map((file: File | ServerFile, index: number) => (
                    <InvestmentDocument file={file} index={index} key={index} investmentContext={investmentSecurity} />
                  ))
                }
              />
            </div>
          </form>
        </div>
        <div className="right-container">
          <div className="row">
            <BackButton
              to="../schedule/"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={() => confirmSecurity()} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}