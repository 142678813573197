import moment from "moment"
import { Stage } from "../../../../../contexts/CreateInvestment"
import "./Timeline.sass"

interface TimelineProps {
  /**
    Steps array
   */
  stages: Stage[] | undefined
  title?: string
}

export const Timeline = ({ stages, title }: TimelineProps) => {

  return (
    <div className="timeline-container">
      <h1 className="title">{title}</h1>
      <div className="timeline">

        {stages && stages.map((stage, index) => {
          return (
            <div className="step-row" key={index}>
              <div className="circle-step">
                {index !== 0 && <span className={`bar`}></span>}
                <span className={`step`}>
                  <span className="text">{index + 1}</span>
                </span>
                {/* <button className={`close-button ${index !== 0 ? "with-bar" : ""}`}>
                  <FaTimes />
                </button> */}
              </div>

              <div className={`step-description ${index !== 0 ? "with-bar" : ""}`}>
                <span className="finish-date">{moment(stage.expected_date).format("DD/MM/YYYY")}</span>
                <span className="title-schedule">{stage.title}</span>
              </div>
            </div>
          )
        })}

      </div>
    </div>
  )
}