import "../Input.sass";
import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /**
   * Html atributte id to input.
   */
  id?: string
  /**
   * Iput label, helps on accessibility of the project as well.
   */
  label?: string
  /**
   * Html atributte type ot input, define the type of the input.
   */
  type?: string
  /**
   * onChange event.
   */
  onChange?: (e: any) => void | any
  /**
   * Html atributte autoComplete to input, define how browser should auto complete the field.
   */
  autoComplete?: string | undefined
  /**
   * Define the value of input.
   */
  value?: any
  /**
   * Define the value to show in input before enter any value.
   */
  placeholder?: any
  /**
   * Html atribbute className, define the class name of the input.
   */
  className?: string
  /**
   * Is a text that is under the input element, can be used for describe the field, drescribe constraints, etc. 
   */
  span?: string | ReactNode
  /**
   * Input error mode.
   */
  error?: boolean
  /**
   * Is a red error message that is under the input element, can be used for alert the user about something wrong.
   */
  errorMessage?: string
  /**
   * Is a label or component placed before the input element that is used for some information, can be used for money nottation. 
   */
  before?: string | ReactNode
  /**
   * Is a label or component placed after the input element that is used for some action, can be used for toggle view password. 
   */
  after?: string | ReactNode
};


/**
 * Input default with labels and details span bellow
 * 
 * @author Samuel S. de Ataídes
 * @since 2022-02-29
 */
export const Input = ({ id, label, type, onChange, autoComplete, value, placeholder, className, span, required, errorMessage, error, before, after, ...attr }: InputProps) => {

  return (
    <div data-testid="input-test" className={`input-default ${className ? className : ''} ${error ? 'input-default-error' : ''}`}>
      {
        label !== undefined && <label htmlFor={id}>{label}{required === true ? "*" : ''}</label>
      }
      <div className='input-default-group'>
        {
          before !== undefined &&
          <div className='input-default-group-before rounded-tl-md rounded-bl-md'>
            <div>
              {before}
            </div>
          </div>
        }
        <input
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          {...attr}

          className={`${before === undefined && after === undefined ? "rounded-md" : (before !== undefined && after !== undefined ? "" : (before !== undefined ? "rounded-tr-md rounded-br-md" : "rounded-tl-md rounded-bl-md"))}`}
        />
        {
          after !== undefined &&
          <div className='input-default-group-after rounded-tr-md rounded-br-md'>
            {after}
          </div>
        }
      </div>
      {
        span !== undefined && <span>{span}</span>
      }
      {
        errorMessage !== undefined && <span className="input-error-message">{errorMessage}</span>
      }
    </div>
  );
};