import { useTranslation } from "react-i18next";
import { ButtonSecondary } from "../../Buttons/ButtonSecondary";
import "./ProposeInvestment.sass"

export const ProposeInvestment = () => {
  const { t } = useTranslation();

  return (
    <div className="propose-investment-card">
      <div className="prop-content">
        <h1 className="title">{t("landingProposeInvestment.title")}</h1>
        <div className="description">
          <span>{t("landingProposeInvestment.description")}</span>
        </div>
        <ButtonSecondary children={t("landingProposeInvestment.iHaveAProject")}></ButtonSecondary>
      </div>
      <div>
        <img src="resources/haveAProject_Ellipse.png" alt="" />
      </div>
    </div>
  );
};