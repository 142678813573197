import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaFilter, FaSearch, FaTimes } from "react-icons/fa";
import { useImmer } from "use-immer";
import { ButtonSecondary } from "../../components/Buttons/ButtonSecondary";
import { Input } from "../../components/Input/Input";
import { RangeSlider } from "../../components/Input/RangeSlider";
import { InvestmentCard } from "../../components/Investment/InvestmentCard";
import { BlackLoadingRing } from "../../components/Loading/BlackLoadingRing";
import { SelectSync } from "../../components/Select/SelectSync";
import { useAlertContext } from "../../contexts/AlertContext";
import { ServerInvestment } from "../../contexts/InvestmentContext";
import api from "../../utils/api";
import "./InvestmentsSearch.sass";
import _ from "lodash"

interface InvestmentQueryInfo {
  min_value: number | undefined
  max_value: number | undefined
  min_aport: number | undefined
  max_aport: number | undefined
  min_raising: number | undefined
  max_raising: number | undefined
  min_raised: number | undefined
  max_raised: number | undefined
  min_rentability: number | undefined
  max_rentability: number | undefined
  min_term: number | undefined
  max_term: number | undefined
  categories: {
    index: number
    name: string
  }[]
}

interface PageInfo {
  available_investments: number | undefined
  pages: number | undefined
}

export interface InvestmentSearchResponse {
  investments: ServerInvestment[]
  query_info: PageInfo
}

interface Sort {
  sortBy: "value" | "aport" | "rentability"
  order: "asc" | "desc"
}


export const InvestmentsSearch = () => {
  const { t } = useTranslation()
  const { presentAlert } = useAlertContext()

  const [investments, setInvestments] = useState<ServerInvestment[]>([])

  const [pageInfo, setPageInfo] = useImmer<PageInfo | null>(null)
  const [serverParameters, setServerParameters] = useImmer<InvestmentQueryInfo | null>(null)
  const [userParameters, setUserParameters] = useImmer<InvestmentQueryInfo>({} as InvestmentQueryInfo)
  const [category, setCategory] = useState<string[]>([]);
  const [situation, setSituation] = useState<number | null>(5);
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string | undefined>();

  const [availableSorting,] = useState([
    {
      value: {
        sortBy: "value",
        order: "asc"
      },
      label: t("search.order.asc_value")
    },
    {
      value: {
        sortBy: "value",
        order: "desc"
      },
      label: t("search.order.desc_value")
    },
    {
      value: {
        sortBy: "aport",
        order: "asc"
      },
      label: t("search.order.asc_aport")
    },
    {
      value: {
        sortBy: "aport",
        order: "desc"
      },
      label: t("search.order.desc_aport")
    },
    {
      value: {
        sortBy: "rentability",
        order: "asc"
      },
      label: t("search.order.asc_rentability")
    },
    {
      value: {
        sortBy: "rentability",
        order: "desc"
      },
      label: t("search.order.desc_rentability")
    },
  ])

  const [items, setItems] = useState<number>(12)
  const [orderBy, setOrderBy] = useState<Sort>({
    sortBy: "value",
    order: "asc"
  })

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [, updateState] = useState<any>();
  const forceReRender = useCallback(() => updateState({}), []);


  const search = useCallback(async (data: InvestmentQueryInfo | null, itemsPerPage: number, orderBy: Sort, page: number = 1, categories: string[] = [], situation: number | null = 5, name: string | undefined = undefined): Promise<InvestmentSearchResponse> => {
    const category: string = categories.join(";")
    if (data === null) {
      const response = await api.get<InvestmentSearchResponse>(`/api/investment/search?page=${page}${category.length > 0 ? "&category=" + category : ""}}&situation=${situation}&sort_by=${orderBy["sortBy"]}&order=${orderBy["order"]}&limit=${itemsPerPage}`)
      return response.data
    } else {
      const parameters: string[] = []

      Object.entries(data).forEach((entry) => {
        const [key, value] = entry
        parameters.push(`${key}=${value}`)
      })

      parameters.push(`page=${page}`)
      parameters.push(`situation=${situation}`)
      parameters.push(`sort_by=${orderBy["sortBy"]}`)
      parameters.push(`order=${orderBy["order"]}`)
      parameters.push(`limit=${itemsPerPage}`)
      if (category.length > 0) parameters.push(`category=${category}`)
      if (name && name.length > 0) parameters.push(`name=${name}`)


      const response = await api.get<InvestmentSearchResponse>(`/api/investment/search?${parameters.join("&")}`)
      return response.data
    }
  }, [])

  useEffect(() => {
    function handleResize() {
      setIsMenuOpen(false);
    }

    window.addEventListener('resize', handleResize);
    document.getElementById("investments-search-left-side-menu")?.addEventListener('scroll', forceReRender);
  })

  useEffect(() => {
    if (isMenuOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    }
    else
      document.body.style.overflow = 'unset';

  }, [isMenuOpen]);

  useEffect(() => {
    let _search = () => {
      setIsLoading(true)
      search(userParameters, items, orderBy, page, category, situation, title).then(data => {
        setInvestments(data.investments)
        setPageInfo({ ...data.query_info })
      }).catch(() => {
        presentAlert({
          title: t("search.error.title"),
          text: t("search.error.text"),
          type: "danger",
          timeout: 5000
        })
      }).finally(() => {
        setIsLoading(false)
      })
    }
    const timeout = setTimeout(() => {
      _search()
    }, 500)

    return () => {
      clearTimeout(timeout)
      _search = () => { }
    }
  }, [presentAlert, search, t, setPageInfo, userParameters, page, category, situation, items, orderBy, title])

  useEffect(() => {
    if (serverParameters === null) {
      api.get<InvestmentQueryInfo>("/api/investment/search/info").then(response => {
        setServerParameters(response.data)
      }).catch(error => {
        presentAlert({
          title: t("search.query.error.title"),
          text: t("search.query.error.text"),
          type: "danger",
          timeout: 5000
        })
      })
    }
  })


  return (
    <div className="investments-search-main">
      <div id="investments-search-left-side-menu" className={`investments-search-left-side-menu ${isMenuOpen ? "active overflow-y-scroll" : ""}`}>
        <div id="investments-search-left-side-menu-content" className="investments-search-left-side-menu-content ">
          <span className="flex text-2xl font-bold justify-between">
            &nbsp;{t("search.filters")}
            <button className="close-button block 1366px:hidden" onClick={() => setIsMenuOpen(false)}>
              <FaTimes className="icon" />
            </button>
          </span>
          <SelectSync
            label={t("search.category")}
            id="investments-search-left-side-menu-content-category"
            options={serverParameters ? serverParameters.categories.map(category => ({ value: category.index, label: category.name })) : []}
            onChange={(val: any) => { setCategory(val.map((value: { value: number, label: string }) => value["label"])) }}
            isMulti={true}
          // value={optionsCountries.find(option => option.value === addressInfo.country)}
          />
          <RangeSlider type="currency" label={t("search.investmentValue")} value={[userParameters?.min_value ?? serverParameters?.min_value ?? 0, userParameters?.max_value ?? serverParameters?.max_value ?? 0]} onChange={(e: Event, newValue: number | number[], a: number) => { setUserParameters(userParameters => { (userParameters && newValue instanceof Array) && ([userParameters.min_value, userParameters.max_value] = newValue) }) }} min={serverParameters?.min_value ?? 0} max={serverParameters?.max_value ?? 0} />
          <RangeSlider type="currency" label={t("search.minimumAport")} value={[userParameters?.min_aport ?? serverParameters?.min_aport ?? 0, userParameters?.max_aport ?? serverParameters?.max_aport ?? 0]} onChange={(e: Event, newValue: number | number[], a: number) => { setUserParameters(userParameters => { (userParameters && newValue instanceof Array) && ([userParameters.min_aport, userParameters.max_aport] = newValue) }) }} min={serverParameters?.min_aport ?? 0} max={serverParameters?.max_aport ?? 0} />
          <RangeSlider type="percentage" label={t("search.minimumUptake")} value={[userParameters?.min_raising ?? serverParameters?.min_raising ?? 0, userParameters?.max_raising ?? serverParameters?.max_raising ?? 0]} onChange={(e: Event, newValue: number | number[], a: number) => { setUserParameters(userParameters => { (userParameters && newValue instanceof Array) && ([userParameters.min_raising, userParameters.max_raising] = newValue) }) }} min={serverParameters?.min_raising ?? 0} max={serverParameters?.max_raising ?? 0} />
          <RangeSlider type="percentage" label={t("search.rentability")} value={[userParameters?.min_rentability ?? serverParameters?.min_rentability ?? 0, userParameters?.max_rentability ?? serverParameters?.max_rentability ?? 0]} onChange={(e: Event, newValue: number | number[], a: number) => { setUserParameters(userParameters => { (userParameters && newValue instanceof Array) && ([userParameters.min_rentability, userParameters.max_rentability] = newValue) }) }} min={serverParameters?.min_rentability ?? 0} max={serverParameters?.max_rentability ?? 0} />
          <RangeSlider type="months" label={t("search.term")} value={[userParameters?.min_term ?? serverParameters?.min_term ?? 0, userParameters?.max_term ?? serverParameters?.max_term ?? 0]} onChange={(e: Event, newValue: number | number[], a: number) => { setUserParameters(userParameters => { (userParameters && newValue instanceof Array) && ([userParameters.min_term, userParameters.max_term] = newValue) }); }} min={serverParameters?.min_term ?? 0} max={serverParameters?.max_term ?? 0} />
          <SelectSync
            id="investments-search-left-side-menu-content-status"
            label={t("search.situation")}
            placeholder="-"
            value={[{ value: 5, label: t("search.available") }, { value: 6, label: t("search.closed") }].find(options => options.value === situation)}
            options={[{ value: 5, label: t("search.available") }, { value: 6, label: t("search.closed") }]}
            onChange={(val: { value: number | null, label: string }) => { setSituation(val["value"]) }}
          // value={optionsCountries.find(option => option.value === addressInfo.country)}
          />
        </div>
      </div>
      <div className="investments-search-right-side" onClick={() => setIsMenuOpen(false)}>

        <div className="investments-search-right-side-inputs"> {/* search bar*/}
          <Input
            label={t("search.search")}
            type="text"
            placeholder={t("search.searchPlaceholder")}
            autoComplete="off"
            className="max-w-[528px]"
            after={
              <button type="button">
                <FaSearch />
              </button>
            }
            value={title}
            onChange={e => { setTitle(e.target.value) }}
          />
          <div className="flex w-full justify-start lg:justify-end gap-5">
            <SelectSync
              label={t("search.items")}
              placeholder="-"
              className="w-40"
              value={[{ value: 12, label: `12 ${t("search.items")}` }, { value: 24, label: `24 ${t("search.items")}` }, { value: 36, label: `36 ${t("search.items")}` }].find(option => option.value === items)}
              options={[{ value: 12, label: `12 ${t("search.items")}` }, { value: 24, label: `24 ${t("search.items")}` }, { value: 36, label: `36 ${t("search.items")}` }]}
              onChange={(val: { value: number, label: string }) => { setItems(val.value) }}
            />
            <SelectSync
              label={t("search.orderBy")}
              placeholder="-"
              className="w-40"
              value={availableSorting.find(order => _.isEqual(order.value, orderBy))}
              options={availableSorting}
              onChange={(val: { value: Sort, label: string }) => { setOrderBy(val.value) }}
            />
          </div>
        </div>
        <div className="investments-search-right-side-filter-button">
          <div className="w-1/2">
            <ButtonSecondary onClick={(e) => { setIsMenuOpen(true); e.stopPropagation(); }}>
              <FaFilter />&nbsp;{t("search.filters")}
            </ButtonSecondary>
          </div>
        </div>
        <div className={`w-20 h-20 mx-auto my-20 ${!isLoading ? 'hidden' : ''}`}>
          <BlackLoadingRing />
        </div>
        {
          (investments.length === 0 && !isLoading) ? (
            <div className="w-full h-full flex items-center justify-center text-center text-gray-600">
              <p>{t("search.noInvestments")}</p>
            </div>
          ) : (
            <div className={`${isLoading ? "hidden" : "grid"} grid-cols-1 sm:grid-cols-2 900px:grid-cols-3 1180px:grid-cols-4 2xl:grid-cols-5 grid-flow-row gap-10 mt-8`}>{/*cards*/}
              {
                investments.map((investment, index) => (
                  <InvestmentCard
                    investment={investment}
                  />
                ))
              }
            </div>
          )
        }

        <div className="relative w-full my-16">{/* pagination */}
          <div className="absolute w-full text-right mt-10 md:mt-0 md:absolute md:right-0">
            <span className="text-xs">
              {pageInfo?.pages ?? 0} {t("search.pages")} ({pageInfo?.available_investments ?? 0} {t("search.items")})
            </span>
          </div>
          <div className="flex absolute left-[50%] right-[50%] md:w-64 w-52 justify-between -translate-x-2/4">
            <button disabled={page === 1} className={`investment-search-pagination-icons ${page === 1 ? "text-gray-300" : ""}`}>
              <FaAngleDoubleLeft />
            </button>
            <button disabled={page === 1} className={`investment-search-pagination-icons ${page === 1 ? "text-gray-300" : ""}`} onClick={() => setPage(page - 1)}>
              <FaAngleLeft />
            </button>
            <span className="text-sm">{page}</span>

            <button disabled={!(page < (pageInfo?.pages || 1))} className={`investment-search-pagination-icons`} type="button" onClick={() => setPage(page + 1)}>
              <FaAngleRight />
            </button>
            <button disabled={!(page < (pageInfo?.pages || 1))} className={`investment-search-pagination-icons`} type="button" >
              <FaAngleDoubleRight />
            </button>
          </div>
        </div>
      </div>
    </div >
  )
};