import { ECharts, EChartsOption, getInstanceByDom, init } from "echarts"
import { useEffect, useRef } from "react";
import { FaExternalLinkAlt } from "react-icons/fa"
import "../GraphCard.sass";
import { useTranslation } from "react-i18next";

export const LineCard = () => {

  const { t } = useTranslation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const option: EChartsOption = {
    legend: {
      orient: 'horizontal',
      bottom: "0",
      itemGap: 16,
      selectorPosition: "start",
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      data: [0, 1, 2, 3, 4, 5, 6]
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: t("dashboard.charts.4"),
        stack: "Total",
        data: [200, 300, 420, 680, 790, 800, 900],
        type: 'line'
      },
      {
        name: t("dashboard.charts.5"),
        stack: "Total",
        data: [700, 400, 200, 500, 500, 490, 490],
        type: 'line'
      }
    ]
  }

  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [])

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart !== undefined) {
        chart.setOption(option);
      }
    }
  }, [option]);

  return (
    <div className="card line-card">
      <div className="line-container">
        <h1 className="card-title">{t("dashboard.charts.lineTitle")}</h1>
        <button className="open-window-button"><FaExternalLinkAlt className="icon" /></button>
        <div ref={chartRef} className="line-chart">
        </div>
      </div>
    </div>
  )
}