import { MenuItem } from "@mui/material"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaCogs, FaDollarSign, FaHeadset, FaInfoCircle, FaLongArrowAltRight } from "react-icons/fa"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import api from "../../../utils/api"
import { InvestmentDetailsList } from "../../Investment/Create/ConfirmInvestment/InvestmentDetailsList"
import { Timeline } from "../../Investment/Create/ConfirmInvestment/Timeline"
import { optionsInvestmentCategory } from "../../Investment/Create/NewInvestment"
import { GreenLoadingRing } from "../../Loading/GreenLoadingRing"
import MenuStrip from "../../MenuStrip"
import { ActivityHistory } from "./ActivityHistory"
import "./InvestmentDashboard.sass"
import { RequestResourceModal } from "./RequestResourceModal"
import { TransactionProps, TransactionsTable } from "./TransactionsTable"
import { Column } from "react-table"

const mockTransactions: TransactionProps[] = [
  {
    title: "Distribuição de lucros",
    type: 1,
    date: "01/01/2002",
    step: {
      title: "Encerramento de operação",
      expected_date: "01/01/2022"
    },
    value: 340000000
  },
  {
    title: "Distribuição de lucros",
    type: 2,
    date: "01/01/2002",
    step: {
      title: "Encerramento de operação",
      expected_date: "01/01/2022"
    },
    value: 340000000
  },
  {
    title: "Distribuição de lucros",
    type: 2,
    date: "01/01/2002",
    step: {
      title: "Encerramento de operação",
      expected_date: "01/01/2022"
    },
    value: 340000000
  },
  {
    title: "Distribuição de lucros",
    type: 0,
    date: "01/01/2002",
    step: {
      title: "Encerramento de operação",
      expected_date: "01/01/2022"
    },
    value: 340000000
  },
]

export const InvestmentDashboard = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const initialState = {
    title: "",
    description: "",
    category: optionsInvestmentCategory[0].value,
    investment_type: 0,
    investment_values: {
      participation: 0,
      total_value: 0,
      minimum_foundation: 0,
      projections: [
        {
          projection_type: 0,
          profitability: "0",
          term: 0
        }
      ]
    },
    investment_schedule: {
      stages: []
    }
  }

  const columns = useMemo<Column[]>(() => [
    {
      Header: t("dashboard.investments.table.title"),
      accessor: "title"
    },
    {
      Header: t("dashboard.investments.table.progress"),
      accessor: "type"
    },
    {
      Header: "Data",
      accessor: "date"
    },
    {
      Header: t("dashboard.investments.table.valueInvested"),
      accessor: "stage"
    },
    {
      Header: t("dashboard.investments.table.rentability"),
      accessor: "value"
    },
  ], [t])

  const [investment, setInvestment] = useState(initialState)

  const [requestResourceModal, setRequestResourceModal] = useState<boolean>(false)

  const investmentToken = useParams<{ id: string }>().id

  const { isLoading } = useQuery(
    "investment", () => {
      api.get(`/api/investment/propose/${investmentToken}`).then(
        (res) => {
          setInvestment(res.data)
          if (res.data === null) {
            navigate("dashboard/my-investments/")
          }
        }
      )
    }
  )

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`investment-dashboard ${isLoading ? 'hidden' : ''}`}>
        {requestResourceModal &&
          <RequestResourceModal open={requestResourceModal} handleClose={() => setRequestResourceModal(false)} />
        }
        <div className="investment-revision-container">
          <div className="title-container">
            <div>
              <h1 className="primary-title">{investment.title}</h1>
              <span className="subtitle">Cod.</span>
            </div>
            <div>
              <MenuStrip className="options-button" title={<><FaCogs className="w-5 h-5" />{t("dashboard.investments.optionButton.options")}</>}>
                <MenuItem data-testid="option-menu-item-test" onClick={() => { }} disableRipple>
                  <div className="options-item">
                    <FaLongArrowAltRight className="w-5 h-5 text-blue-600" /> {t("dashboard.investments.optionButton.nextStep")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => { setRequestResourceModal(true) }} disableRipple>
                  <div className="options-item" >
                    <FaDollarSign className="w-5 h-5 text-green-default" /> {t("dashboard.investments.optionButton.getResource")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => { }} disableRipple>
                  <div className="options-item">
                    <FaHeadset className="w-5 h-5" /> {t("dashboard.investments.optionButton.support")}
                  </div>
                </MenuItem>
                <hr />
                <MenuItem data-testid="option-menu-item-test" onClick={() => { }} disableRipple>
                  <div className="options-item">
                    <FaInfoCircle className="w-5 h-5" /> {t("dashboard.investments.optionButton.logs")}
                  </div>
                </MenuItem>
              </MenuStrip>
            </div>
          </div>
          <div className="top-container">
            <div className="left-container">
              <InvestmentDetailsList
                title={investment.title}
                description={investment.description}
                investment_type={investment.investment_type}
                term={investment.investment_values.projections.find((projection: { projection_type: number }) => projection.projection_type === 0)?.term.toString()}
                category={optionsInvestmentCategory.find(category => category.value === investment.category)?.label}
                desired_capture={investment.investment_values.total_value}
                invested_value={investment.investment_values.participation}
                min_capture={investment.investment_values.minimum_foundation}
                projected_profitability={investment.investment_values.projections.find((projection: { projection_type: number }) => projection.projection_type === 0)?.profitability}
              />
            </div>
            <div className="right-container">
              <Timeline
                stages={investment.investment_schedule.stages}
                title={t("investment.confirm.schedule")} />
            </div>
          </div>
          <div className="bottom-container">
            <div className="left-container">
              <ActivityHistory />
            </div>
            <div className="right-container"></div>
            <div>
              <TransactionsTable transactions={mockTransactions} columns={columns} title="Movimentações" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}