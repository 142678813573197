import { useTranslation } from "react-i18next";

import "./BalanceCard.sass";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState } from "react";

export const BalanceCard = () => {

  const { t } = useTranslation()

  const [showBalance, setShowBalance] = useState<boolean>(true)

  return (
    <div className="balance-content">
      <div className="balance-card">

        <div className="balance-card-title">
          <h1 className="title">{t("dashboard.title")}</h1>
          <button onClick={() => { setShowBalance(!showBalance) }}>
            {showBalance ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>

        <div className="balance-card-content">
          <div className="balance">
            <span className="label">{t("dashboard.balance")}</span>
            <div className={`${!showBalance ? "skeleton-title" : ""}`}>
              <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>R$ 2.387.421,00</h1>
            </div>
          </div>
          <div className="line"></div>
          <div className="balance">
            <span className="label">{t("dashboard.value")}</span>
            <div className={`${!showBalance ? "skeleton-title" : ""}`}>
              <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>R$ 4.830.000,00</h1>
            </div>
          </div>
          <div className="line"></div>
          <div className="balance">
            <span className="label">{t("dashboard.profitability")}</span>
            <div className={`${!showBalance ? "skeleton-title" : ""}`}>
              <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>67% A.A.</h1>
            </div>
          </div>
          <div className="line"></div>
          <div className="balance">
            <span className="label">{t("dashboard.retPrev")}</span>
            <div className={`${!showBalance ? "skeleton-title" : ""}`}>
              <h1 className={`title ${!showBalance ? "skeleton-title" : ""}`}>R$ 3.236.100,00</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}