import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useCreateInvestment } from "../../../../contexts/CreateInvestment"
import { ServerFile } from "../../../../contexts/InvestmentContext"
import api from "../../../../utils/api"
import { BackButton } from "../../../Buttons/BackButton"
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary"
import { DocumentsInput } from "../../../Input/DocumentsInput"
import { InvestmentDocument } from "../../../Input/DocumentsInput/InvestmentDocument"
import { GreenLoadingRing } from "../../../Loading/GreenLoadingRing"
import "../../../Register/Register.sass"
import "./InvestmentPublicDocuments.sass"

export const InvestmentPublicDocuments = () => {

  const { publicDocuments, setPublicDocuments, setProgress, investmentId, isDataLoading, getAndSetInvestment } = useCreateInvestment()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(1)
  const [showProgress, setShowProgress] = useState<boolean>(false)

  const [filesError, setFilesError] = useState<boolean>(false)

  const [checkingError, setCheckingError] = useState<boolean>(false);

  const verifyFiles = (e: HTMLInputElement) => {

    setPublicDocuments(publicDocument => {
      let oldArray = publicDocument.documents || [];

      if (e.files && e.files.length > 0) {

        let array = [];

        for (let index = 0; index < e.files.length; index++) {
          array.push(e.files[index])
        }

        publicDocument.documents = oldArray.concat(array);
      }
    });
  }

  useEffect(() => {
    setProgress(6)
  })

  useEffect(() => {
    let element = document.getElementsByClassName("documents-span-error")
    if (element[0] && !checkingError) {
      setCheckingError(true)
      element[0].scrollIntoView({ behavior: "smooth", block: "center" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesError])

  const submitFiles = () => {

    if (publicDocuments.documents.length <= 0) {
      setCheckingError(false)
      setFilesError(true)
      return
    };

    setIsLoading(true)
    setShowProgress(true)
    setUploadProgress(0)

    const documents = new FormData()

    publicDocuments.documents.forEach(document => {
      if (!("id" in document)) {
        documents.append("documents", document)
      }
    })

    /**
     * This is to prevent broken request with empty FormData.
     */
    if (documents.get("documents")?.valueOf()) {
      api.post(`/api/investment/${investmentId}/documents?document_category=PUBLIC`, documents, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round(progressEvent.loaded * 100) / progressEvent.total)
        }
      })
        .then(
          (response) => {
            navigate(`/investment/${investmentId}/create/confirm-investment/`)
            getAndSetInvestment()
          }
        ).finally(
          () => setIsLoading(false)
        )
    } else {
      navigate(`/investment/${investmentId}/create/confirm-investment/`)
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className={`w-20 h-20 mx-auto my-20 ${!isDataLoading ? 'hidden' : ''}`}>
        <GreenLoadingRing />
      </div>
      <div className={`register-content ${isDataLoading ? 'hidden' : ''}`}>
        <div className="left-container">
          <h1 className="title">{t("investment.publicDocuments.title")}</h1>
          <span className="subtitle">{t("investment.publicDocuments.description")}</span>

          <DocumentsInput
            addFileCallback={verifyFiles}
            documents={publicDocuments.documents}
            showProgressBar={showProgress}
            uploadFileProgress={uploadProgress}
            errorState={filesError}
            errorSetState={setFilesError}
            documentsComponent={
              publicDocuments.documents !== null &&
              publicDocuments.documents.map((file: File | ServerFile, index: number) => (
                <InvestmentDocument file={file} index={index} key={index} investmentContext={publicDocuments} setInvestmentContext={setPublicDocuments} />
              ))
            }
          />
        </div>

        <div className="right-container">
          <div className="row">
            <BackButton
              to="../security/"
              className="back-button"
              children={t("register.back")}
              icon={<FaArrowLeft className="icon" />}
            />
            <ButtonPrimary onClick={submitFiles} children={t("investment.publicDocuments.reviewButton")} isLoading={isLoading} />
          </div>
        </div>
      </div >
    </>
  )
}