import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { useImmer } from "use-immer"
import { StepProps } from "../components/ProgressBar"
import api from "../utils/api"
import { Utils } from "../utils/utils"
import { InvestmentImagesProps, InvestmentSecurityProps, NewInvestmentProps, PublicDocumentsProps, RentabilityProps, ScheduleProps } from "./CreateInvestment"
import { ServerFile, ServerPropose } from "./InvestmentContext"

export interface ValidatePages {
  page_id: number
  input_id: number
  accepted: boolean
  description: string | null
}

interface ValidateInvestmentContextProps {
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  stepsProgress: Array<StepProps>
  setStepsProgress: Dispatch<SetStateAction<Array<StepProps>>>
  pages: Array<ValidatePages>
  setPages: Dispatch<SetStateAction<Array<ValidatePages>>>
  newInvestment: NewInvestmentProps
  investmentImages: InvestmentImagesProps
  investmentValues: RentabilityProps
  investmentSchedule: ScheduleProps
  investmentSecurity: InvestmentSecurityProps
  investmentPublicDocument: PublicDocumentsProps
  isDataLoading: boolean
  haveInputError: () => boolean
}

export const ValidateInvestmentContext = createContext({} as ValidateInvestmentContextProps)

export const ValidateInvestmentProvider = ({ children }: { children: ReactNode }) => {

  const [progress, setProgress] = useState(1)

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true)

  const [validateInvestmentStep, setValidateInvestmentStep] = useState<number>(0)

  const [stepsProgress, setStepsProgress] = useState<Array<StepProps>>([]);

  const [pages, setPages] = useImmer<Array<ValidatePages>>(JSON.parse(localStorage.getItem("single.validatePages") ?? "null") || [])

  const [newInvestment, setNewInvestment] = useImmer<NewInvestmentProps>({ links: [] as string[] } as NewInvestmentProps)

  const [investmentImages, setInvestmentImages] = useImmer<InvestmentImagesProps>({
    images: [] as File[]
  })

  const [investmentValues, setInvestmentValues] = useImmer<RentabilityProps>({
    total: "",
    minCapture: "",
    minimumAport: "",
    userParticipation: "",
    valueJustification: "",
    expectedValue: "",
    overcomeValue: "",
    pessimistValue: "",
    expectedDate: "",
    overcomeDate: "",
    pessimistDate: "",
    rentabilityJustification: "",
    documents: []
  } as RentabilityProps)

  const [investmentSecurity, setInvestmentSecurity] = useImmer<InvestmentSecurityProps>({ documents: [] as File[] } as InvestmentSecurityProps)

  const [investmentSchedule, setInvestmentSchedule] = useImmer<ScheduleProps>({
    stages: [] as Object[] | null,
    themes: [] as Object[]
  } as ScheduleProps)

  const [publicDocuments, setPublicDocuments] = useImmer<PublicDocumentsProps>({ documents: [] as File[] } as PublicDocumentsProps)

  useEffect(() => {
    /**
     * This condition is to update the progress bar when the user validate a investment step.
     * When the user validate a investment step, the progress bar will be updated to the next step.
     */
    if (progress > validateInvestmentStep + 1)
      setValidateInvestmentStep(progress - 1)

    let stepsHrefs: Array<string> = ['../validate/', 'images/', 'values/', 'schedule/', 'security/', 'public-documents/'];
    setStepsProgress(Array.from({ length: 6 - 1 + 1 }, (e, i) => i + 6).map((_, index) => {
      let stepProp: StepProps = {
        step: index + 1,
        completed: validateInvestmentStep > index,
        error: false,
        href: stepsHrefs[index]
      }
      return stepProp
    }))
  }, [progress, validateInvestmentStep]);

  /**
   * This function is to set the retrieved data from the server to the context.
   * @param investment ServerPropose
   */
  const setServerInvestment = (investment: ServerPropose) => {

    if (investment["reviews"] && investment["reviews"].length > 0) {
      setPages(investment["reviews"])
    }

    setNewInvestment({
      title: investment["title"],
      description: investment["description"],
      investment_type: investment["investment_type"],
      category: investment["category"],
      links: investment["links"]
    })

    setValidateInvestmentStep(1)

    if (investment["investment_documents"] && investment["investment_documents"].length > 0 && investment["investment_documents"].filter((file: ServerFile) => file.category === "IMAGES").length > 0) {
      setInvestmentImages({ images: [...investment["investment_documents"].filter((file: ServerFile) => file.category === "IMAGES")] })
      setValidateInvestmentStep(2)
    }


    if (investment["investment_values"]) {
      setInvestmentValues({
        total: Utils.realFormat(investment["investment_values"]["total_value"], 2),
        minCapture: Utils.realFormat(investment["investment_values"]["minimum_foundation"], 2),
        minimumAport: Utils.realFormat(investment["investment_values"]["minimum_aport"], 2),
        userParticipation: Utils.realFormat(investment["investment_values"]["participation"], 2),
        valueJustification: investment["investment_values"]["values_justification"],
        expectedValue: Utils.realFormat(investment["investment_values"]["projections"][0]["profitability"], 2), // TODO: Após arrumar a forma como a API está retornando esses dados, lembrar de formatar ela aqui. 
        overcomeValue: Utils.realFormat(investment["investment_values"]["projections"][1]["profitability"], 2),
        pessimistValue: Utils.realFormat(investment["investment_values"]["projections"][2]["profitability"], 2),
        expectedDate: investment["investment_type"] !== 1 ? investment["investment_values"]["projections"][0].term.toString() : null,
        overcomeDate: investment["investment_type"] !== 1 ? investment["investment_values"]["projections"][1].term.toString() : null,
        pessimistDate: investment["investment_type"] !== 1 ? investment["investment_values"]["projections"][2].term.toString() : null,
        rentabilityJustification: investment["investment_values"]["projections_justifications"],
        documents: investment["investment_documents"] ? investment["investment_documents"].filter((file: ServerFile) => file.category === "VALUES") : []
      })
      setValidateInvestmentStep(3)
    }

    if (investment["investment_schedule"] && investment["investment_schedule"]["stages"].length > 0) {
      setInvestmentSchedule({
        stages: [...investment["investment_schedule"]["stages"]],
        themes: [...investment["investment_schedule"]["operation_details"]]
      })
      setValidateInvestmentStep(4)
    }

    if (investment["investment_security"]) {
      setInvestmentSecurity({
        securityType: investment["investment_security"]["security_type"],
        description: investment["investment_security"]["description"],
        documents: investment["investment_documents"] ? investment["investment_documents"].filter((file: ServerFile) => file.category === "SECURITY") : []
      })
      setValidateInvestmentStep(5)
    }

    if (investment["investment_documents"] && investment["investment_documents"].length > 0 && investment["investment_documents"].filter((file: ServerFile) => file.category === "PUBLIC").length > 0) {
      setPublicDocuments({ documents: investment["investment_documents"].filter((file: ServerFile) => file.category === "PUBLIC") })
      setValidateInvestmentStep(6)
    }

  }

  /**
   * This function is to get the completed steps of the investment.
   * @param reviews Reviews of the investment
   */
  const getCompletedSteps = (reviews: ValidatePages[]) => {
    let currentStep = 0;
    for (let index = 0; index < reviews.length; index++) {
      if (reviews[index].page_id === 6) {
        setValidateInvestmentStep(6)
        return
      }

      if (reviews[index].page_id > currentStep)
        currentStep = reviews[index].page_id
    }

    setValidateInvestmentStep(currentStep)
  }

  const investmentToken = useParams<{ id: string }>().id

  useQuery("investment",
    () => {
      if (investmentToken !== "null") {
        setIsDataLoading(true)
        api.get<ServerPropose>(`/api/investment/propose/${investmentToken}`).then(
          (response) => { setServerInvestment(response.data); getCompletedSteps(response.data["reviews"] ?? []) }
        ).finally(() => setIsDataLoading(false))
      }
    }
  )

  /**
   * Returns a boolean with we have any error in the pages.
   * This is used in the last form of validate investment.
   * @returns boolean
   */
  const haveInputError = (): boolean => {

    // eslint-disable-next-line array-callback-return
    return pages?.find((page: ValidatePages) => {
      if (page.accepted === false) {
        return true
      }
    }) ? true : false
  }

  return (
    <ValidateInvestmentContext.Provider
      value={{
        progress: progress,
        setProgress: setProgress,
        pages: pages,
        setPages: setPages,
        stepsProgress: stepsProgress,
        setStepsProgress: setStepsProgress,
        newInvestment: newInvestment,
        investmentImages: investmentImages,
        investmentValues: investmentValues,
        investmentSchedule: investmentSchedule,
        investmentSecurity: investmentSecurity,
        investmentPublicDocument: publicDocuments,
        haveInputError: haveInputError,
        isDataLoading: isDataLoading,
      }}
    >
      {children}
    </ValidateInvestmentContext.Provider>
  )
}

export const useValidateInvestment = () => {
  return useContext(ValidateInvestmentContext)
}