import { Link, Outlet } from "react-router-dom"
import { Footer } from "../../components/Footer"
import { ProgressBar, StepProps } from "../../components/ProgressBar"
import "./Register.sass"

interface RegisterProps {
  useRegister: () => { progress: number, stepsProgress: Array<StepProps> }
  stepAmount: number
}


export const Register = ({ useRegister, stepAmount }: RegisterProps) => {

  const { progress, stepsProgress } = useRegister()

  return (
    <div className="register">
      <div className="content">
        <div className="logo">
          <Link to="/dashboard/">
            <img src="/single-inv-black.svg" alt="Logo Single Investimentos" />
          </Link>
        </div>
        <div className="register-card">
          <div className="register-progress">
            <ProgressBar steps={stepsProgress} progress={progress} />
          </div>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}