import { Dispatch, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import { ServerFile } from "../../../contexts/InvestmentContext";
import { ProgressLine } from "../../ProgressLine";
import "./DocumentsInput.sass";
import { ValidateInvestmentButtons } from "../../Investment/Validate/ValidateInvestmentButtons";

interface DocumentsInputProps {
  title?: string
  subtitle?: string
  label?: string
  documents: Array<ServerFile | File> | null
  showProgressBar?: boolean
  uploadFileProgress?: number
  addFileCallback?: (file: HTMLInputElement) => void
  documentsComponent: ReactNode
  errorState?: boolean
  errorSetState?: Dispatch<boolean>
  validate_page_id?: number
  validate_input_id?: number
  textareaId?: string
  textareaLabel?: string
  className?: string
}

export const DocumentsInput = ({ title, subtitle, className, label, documents, showProgressBar, uploadFileProgress, addFileCallback, documentsComponent, errorState, errorSetState, validate_input_id, validate_page_id, textareaId, textareaLabel }: DocumentsInputProps) => {

  const { t } = useTranslation()

  const filesRef = useRef<HTMLInputElement>(null)

  return (
    <div className={`document-slider ${className}`}>
      <div className="documents">

        <h1 className="title">{title}</h1>
        <span className="documents-text">{subtitle}</span>
        <label className="slider-label">{label}</label>

        {addFileCallback !== undefined &&
          <>
            <div className={`document-input-container ${errorState ? "error" : ""}`} onClick={() => filesRef.current !== null ? filesRef.current.click() : false}>
              <FaFileAlt className="icon" />
              <span>{t("investment.investmentValues.documents.placeholder")}</span>
            </div>

            {errorState &&
              <span className="documents-span-error">{t("investment.investmentValues.errors.documents")}</span>
            }

            <input data-testid="file-input-test" type="file" accept="*" ref={filesRef} className="hidden" multiple
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (errorSetState) errorSetState(false);
                addFileCallback(e.target)
              }}
            />
          </>
        }

        {(documents !== null && documents.length > 0) &&
          <>
            {/* Validate buttons to check documents containers */}
            {(validate_input_id !== undefined && validate_page_id !== undefined) &&
              <div className="validate-documents-container">
                <ValidateInvestmentButtons page_id={validate_page_id} input_id={validate_input_id} textareaId={textareaId ?? ""} textareaLabel={textareaLabel ?? ""} />
                <div className="uploaded-files">
                  {documentsComponent}
                </div>
              </div>
            }

            {/* If there is no validate buttons, show documents containers */}
            {(validate_input_id === undefined || validate_page_id === undefined) &&
              <div className="uploaded-files">
                {documentsComponent}
              </div>
            }
          </>
        }

        {showProgressBar && <div className="progress">
          <ProgressLine color="green" value={uploadFileProgress === undefined ? 0 : uploadFileProgress} />
        </div>}
      </div>
    </div>
  )
}