import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { SwiperProps, SwiperSlide } from "swiper/react"
import { Theme } from "../../../../../contexts/CreateInvestment"
import Slider from "../../../../Slider"
import "./ThemesSlider.sass"
import Icon from "../../../../Icon"
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa"

interface ThemesSliderProps {
  title?: string
  themes: Theme[]
  sliderSettings?: SwiperProps
  addThemeCallback?: (theme: Theme) => void
  deleteThemeCallback?: (index: number) => void
  noThemesFound?: boolean
}

export const ThemesSlider = ({ themes, addThemeCallback, deleteThemeCallback, noThemesFound, sliderSettings, title }: ThemesSliderProps) => {

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const themeSettings: SwiperProps = {
    freeMode: true,
    className: "theme-slider",
    slidesPerView: 2,
    spaceBetween: 10,
    scrollbar: true,
    breakpoints: {
      768: {
        slidesPerView: 2
      },
    },
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current
    }
  }

  const { t } = useTranslation()

  return (
    <div className="operation-details">
      <h1 className="title">{title}</h1>
      <div className="themes-container">
        {(themes.length === 0 && noThemesFound) &&
          <div className="default-theme-container">
            <span className="default-theme">{t("theme.noThemes")}</span>
          </div>
        }
        {themes.length > 0 &&
          <Slider settings={sliderSettings ? sliderSettings : themeSettings}>
            {themes.map((element, index) => {
              return (
                <SwiperSlide className="theme" key={index}>
                  <div className="theme-title-container">
                    <h3>{element.theme}</h3>
                  </div>
                  <div className="description-container">
                    <h3>{element.description}</h3>
                  </div>
                  {addThemeCallback && deleteThemeCallback &&
                    <button className="close-button" onClick={() => deleteThemeCallback(index)}>
                      <FaTimes />
                    </button>
                  }
                </SwiperSlide>
              )
            })}
            {addThemeCallback &&
              <>
                <button ref={navigationPrevRef} className="prev-btn" onClick={() => { }}>
                  <Icon className='bg-gray-white-rounded w-6 h-6 text-xs'>
                    <FaAngleLeft className='icon' />
                  </Icon>
                </button>
                <button ref={navigationNextRef} className="next-btn" onClick={() => { }}>
                  <Icon className='bg-gray-white-rounded w-6 h-6 text-xs'>
                    <FaAngleRight className='icon' />
                  </Icon>
                </button>
              </>
            }
          </Slider>
        }
      </div>
    </div>
  )
}