import { useTranslation } from "react-i18next";
import Icon from "../../../Icon";
import { FaPen } from "react-icons/fa";
import { BankAccountProps } from "..";

interface BankCardProps {
  /**
   * Card datas
   */
  card: BankAccountProps
  /**
   * Function to edit the account card data
   */
  editAccount: (card: BankAccountProps) => void
}

/**
 * Account Card
 */
export const BankCard = ({ card, editAccount }: BankCardProps) => {
  const { t } = useTranslation()

  const optionsAccountTypes: any = [
    {
      value: 0,
      label: t("register.bankData.accountTypes.checking.individual")
    },
    {
      value: 1,
      label: t("register.bankData.accountTypes.checking.joint")
    },
    {
      value: 2,
      label: t("register.bankData.accountTypes.savings.individual")
    },
    {
      value: 3,
      label: t("register.bankData.accountTypes.savings.joint")
    }
  ]

  return (
    <div data-testid="bank-card" className='register-bank-card'>
      <div className='register-bank-card-header'>
        <h2>{card.bank}</h2>
        <button data-testid="edit-account" onClick={() => editAccount(card)}>
          <Icon className='bg-gray-white-rounded w-6 h-6 m-0 transition-colors hover:bg-gray-500'>
            <FaPen className='icon w-3 h-3' />
          </Icon>
        </button>
      </div>
      <div className='register-bank-card-account-type'>
        {optionsAccountTypes[card.accountType]?.label}
      </div>
      <div className='register-bank-card-footer'>
        <div className='register-bank-card-branch'>
          <span>{t("register.bankData.branch")}:</span>
          <span>{card.branch}</span>
        </div>
        <div className='register-bank-card-account'>
          <span>{t("register.bankData.account")}:</span>
          <span>{card.account}-{card.digit}</span>
        </div>
      </div>
    </div>
  );
}