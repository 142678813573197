import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useInvest } from "../../../../contexts/InvestContext";
import { Utils } from "../../../../utils/utils";
import { ButtonPrimary } from "../../../Buttons/ButtonPrimary";
import { Input } from "../../../Input/Input";
import { InformationCard } from "../../InformationCard";
import "../InvestComponent.sass";

/**
 * This component is a initial Investment page, where the investor choice the value to invest. 
 */
export const Balance = () => {
  const { t } = useTranslation()
  const id = useParams().id
  const navigate = useNavigate()

  const { investInfo, setInvestInfo, investment, setProgress } = useInvest()

  const [hasError, setHasError] = useState<boolean>(false)

  // NEED TO CHANGE THIS TO BE DYNAMIC WITH USER MONEY FORMAT
  let minimumAport: string | undefined = investment.investment_values?.minimum_aport.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

  useEffect(() => {
    setProgress(1)
  })

  if (id === undefined) {
    return <Navigate to="/" />
  }

  const handleSubmit = () => {
    if (investInfo.amountInvested === undefined || investInfo.amountInvested === "") {
      setHasError(true)
      return
    }

    if (minimumAport && parseFloat(investInfo.amountInvested.replace(".", "").replace(",", ".")) < parseFloat(minimumAport.toString().replace(".", "").replace(",", "."))) {
      setHasError(true)
      return
    }

    navigate("../contracts")

  }

  return (
    <div className="invest-content">
      <div className="left-container">
        <h1 className="title">{t("investmentPage.investForm.balance.title")}</h1>
        <span className="subtitle">{t("investmentPage.investForm.balance.subtitle")}</span>
        <div className="data-container">
          <h2 className="topic-title">{t("investmentPage.investForm.balance.currentBalance")}</h2>
          <h1 className="value">R$ 531.300,00</h1>
        </div>
        <div className="data-container">
          <Input
            label={t("investmentPage.investForm.balance.invest")}
            id="amount"
            type="text"
            required={true}
            className="text-large"
            onChange={(e) => { setInvestInfo((investInfo) => { investInfo.amountInvested = e.target.value }); setHasError(false) }}
            onKeyDown={e => Utils.formatCurrency(e, e.target, 2)}
            value={investInfo.amountInvested}
            error={hasError}
            errorMessage={t("investmentPage.investForm.balance.minValue", { minAport: minimumAport })}
            placeholder={minimumAport}
            before="R$"
          />
          <span className="information">{t("investmentPage.investForm.balance.minValue", { minAport: minimumAport })}</span>
        </div>
        <div className="data-container">
          <h2 className="topic-title">{t("investmentPage.investForm.balance.return")}</h2>
          <h1 className="value">R$ 3.000,00</h1>
        </div>
        <div className="data-container">
          <h2 className="topic-title">{t("investmentPage.investForm.balance.total")}</h2>
          <h1 className="value">R$ 8.000,00</h1>
        </div>
        <div className="data-container">
          <h2 className="topic-title">{t("investmentPage.investForm.balance.percentage")}</h2>
          <h1 className="percentual"> ~ 0,2%</h1>
        </div>
      </div>

      <div className="right-container">
        <div className="information-card">
          <InformationCard toInvestForm={true} investment={investment} />
        </div>

        <div className="row button-container">
          <Link className="back-button" to="../../" ><FaArrowLeft className="icon" /> {t("register.back")} </Link>
          <ButtonPrimary onClick={handleSubmit} children={t("register.nextButton")} />
        </div>
      </div>
    </div>
  )
}