import { Dispatch, useEffect, useState } from "react"
import { FaCheck, FaTimes } from "react-icons/fa"
import { TextArea } from "../../../Input/TextArea"
import { ValidatePages, useValidateInvestment } from "../../../../contexts/ValidateInvestment"

interface ValidateButtonsProps {
  page_id: number
  input_id: number
  handleDisable?: Dispatch<boolean>
  textareaId: string
  textareaLabel: string
}

export const ValidateInvestmentButtons = ({ page_id, input_id, handleDisable, textareaId, textareaLabel }: ValidateButtonsProps) => {

  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>()

  const { pages, setPages, isDataLoading } = useValidateInvestment()

  const [inputIndex, setInputIndex] = useState<number>()

  useEffect(() => {
    let index = pages.findIndex((page: ValidatePages) => { return page !== null && (page.page_id === page_id) && (page.input_id === input_id) })

    setInputIndex(index !== -1 ? index : undefined)

    if (index !== undefined && pages[index] !== undefined && pages[index].page_id !== 0) {
      handleDisable && handleDisable(pages[index].accepted)
      setShowDescriptionModal(!pages[index].accepted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoading])

  return (
    <div className="buttons-container">
      <button
        type="button"
        className={`confirm-validate-btn ${showDescriptionModal === false ? "active" : ""}`}
        onClick={() => {
          setShowDescriptionModal(false);
          setPages((pages) => {

            setInputIndex(inputIndex !== undefined ? inputIndex : pages.length)

            if (handleDisable)
              handleDisable(true)

            pages[inputIndex !== undefined ? inputIndex : pages.length] = {
              page_id: page_id,
              input_id: input_id,
              accepted: true,
              description: null
            }

            return pages;
          });
        }}>
        <FaCheck className="icon" />
      </button>
      <button
        className={`denied-validate-btn ${showDescriptionModal === true ? "active" : ""}`}
        onClick={() => {
          setShowDescriptionModal(true);
          setPages((page) => {

            setInputIndex(inputIndex !== undefined ? inputIndex : pages.length)

            if (handleDisable)
              handleDisable(false)

            page[inputIndex !== undefined ? inputIndex : page.length] = {
              page_id: page_id,
              input_id: input_id,
              accepted: false,
              description: ""
            }

            return page;
          });
        }}>
        <FaTimes className="icon" />
      </button>

      {showDescriptionModal &&
        <TextArea
          label={textareaLabel}
          id={textareaId}
          placeholder={"Type the reason for no approve it."}
          className="reason-modal-textarea"
          onChange={
            (e) => {
              setPages((pages) => {

                setInputIndex(inputIndex !== undefined ? inputIndex : pages.length)
                pages[inputIndex !== undefined ? inputIndex : pages.length] = {
                  page_id: page_id,
                  input_id: input_id,
                  accepted: false,
                  description: e.target.value
                };

                return pages;
              })
            }
          }
          value={pages[inputIndex !== undefined ? inputIndex : pages.length]?.description === undefined ? "" : pages[inputIndex !== undefined ? inputIndex : pages.length]?.description}
          required
        />
      }
    </div>
  )
}