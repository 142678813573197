import { useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { FaCalendarAlt, FaCalendarWeek, FaPercentage } from "react-icons/fa";
import { ServerInvestment, ServerPropose } from '../../../contexts/InvestmentContext';
import { ButtonPrimary } from "../../Buttons/ButtonPrimary";
import Icon from "../../Icon";
import "./InformationCard.sass";

interface InvestmentCardProps {
  investment: ServerInvestment | ServerPropose
  toInvestForm?: boolean
}


export const InformationCard = ({ investment, toInvestForm }: InvestmentCardProps) => {
  const { t } = useTranslation();

  const reserved = useMemo(() => parseFloat((investment.investment_values ? (investment.investment_values.participation / investment.investment_values.total_value) * 100 : 0).toFixed(2)), [investment])
  const progress = useMemo(() => parseFloat(((investment.investment_values ? (investment.investment_values.raised_value / investment.investment_values.total_value) * 100 : 0) + reserved).toFixed(2)), [investment, reserved])

  useEffect(() => {
    console.info(investment.investment_values?.raised_value)
  }, [investment])

  return (
    <div className={`information-card-container ${toInvestForm ? "invest-card-container" : ""}`}>
      <div className='inv-card' >
        <div className="investment-info">
          <div>
            {
              toInvestForm &&
              <div className="head-title">
                <h1>{investment.title}</h1>
                <div className="head-subtitle">
                  <span>{`${t("investmentPage.code")}: ${investment.code}`}</span>
                  <span>{investment.category}</span>
                </div>
              </div>
            }
          </div>
          <div className="progress">
            <p className="price">
              {investment.investment_values?.total_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </p>
            <div className="progress-bar">
              <span style={{ width: `${progress}%` }} className="green-bar" />
              <span style={{ width: `${reserved}%` }} className="blue-bar" />
            </div>
            <p className="percentage">
              {progress}% {t("investmentPage.raised")}
            </p>
            <div className='legend-vertical'>
              <p>
                <span className='circle blue-bar'></span>
                {t("investmentPage.reserved")} <span className="legend-value">{investment.investment_values?.participation.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
              </p>
              <p>
                <span className='circle green-bar'></span>
                {t("investmentPage.raised")} <span className="legend-value">{investment.investment_values?.raised_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
              </p>
              <p>
                <span className='circle gray-bar'></span>
                {t("investmentPage.available")} <span className="legend-value">{(investment.investment_values ? investment.investment_values.total_value - investment.investment_values.participation - investment.investment_values.raised_value : 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
              </p>
            </div>
          </div>
          {
            (!toInvestForm && !("reviews" in investment)) &&
            <div className='invest-div'>
              <ButtonPrimary to={"./invest/balance"} children={t("investmentPage.invest")}></ButtonPrimary>
            </div>
          }
          <div className="card-info">
            <div className='icon-details'>
              <Icon className='bg-gray-white-rounded'>
                <FaCalendarWeek className='icon' />
              </Icon>
              <div className="detail">
                <p>{t("investmentPage.term")}</p>
                <p><b>{investment.investment_values?.projections[0].term} {t("investmentPage.months")}</b></p>
              </div>
            </div>
            <div className='icon-details'>
              <Icon className='bg-gray-white-rounded'>
                <FaPercentage className='icon' />
              </Icon>
              <div className="detail">
                <p>{t("investmentPage.profitability")}</p>
                <p><b>{investment.investment_values?.projections[0].monthly_profit}% {t("investmentPage.perMonth")}</b></p>
              </div>
            </div>
            <div className='icon-details'>
              <Icon className='bg-gray-white-rounded'>
                <FaPercentage className='icon' />
              </Icon>
              <div className="detail">
                <p>{t("investmentPage.minimumRaising")}</p>
                <p><b>{investment.investment_values?.minimum_foundation}%</b></p>
              </div>
            </div>
            <div className='icon-details'>
              <Icon className='bg-gray-white-rounded'>
                <FaCalendarAlt className='icon' />
              </Icon>
              <div className="detail">
                <p>{t("investmentPage.endRaising")}</p>
                <p><b>{investment.date_end_raising ? (new Date(investment.date_end_raising).toLocaleDateString()) : (t("investmentPage.toBeDecided"))}</b></p>
                {/* TODO: FIX DATE */}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
}