import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft, FaCheck } from "react-icons/fa"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useRegister } from "../../../contexts/RegisterContext"
import { useAuth } from "../../../contexts/UserContext"
import api from "../../../utils/api"
import { BackButton } from "../../Buttons/BackButton"
import { ButtonPrimary } from "../../Buttons/ButtonPrimary"

import "./PhoneVerification.sass"
import { Utils } from "../../../utils/utils"

export const PhoneVerification = () => {

  const { t } = useTranslation()
  const { user, checkAuth } = useAuth()
  const navigate = useNavigate()
  const { identificationInfo, setProgress, } = useRegister()

  const [codeErrorMessage, setCodeErrorMessage] = useState<string>("");
  const [, setCodeError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const confirmCode = () => {

    if (user?.is_number_verified) return navigate("/dashboard/register/address");

    const inputs = Array.from(document.querySelectorAll("input.input-code-grid"));
    const code = inputs.map((inp: any) => { return inp.value }).join("");

    if (code.length === 4) {
      setIsLoading(true)
      api.post("/api/user/register", { "code": code })
        .then(
          (res) => {
            if (res.data === null && res.status === 200) {
              setProgress(3)
              checkAuth().then(() => { navigate("/dashboard/register/address"); })

            }
          }
        )
        .catch(
          (err) => {
            if (err.response.data.detail === "EXPIRED_CODE") {
              setCodeErrorMessage(t("register.errors.expiredCode"));
              setCodeError(true);
            }

            if (err.response.data.detail === "INVALID_CODE") {
              setCodeErrorMessage(t("register.errors.invalidCode"));
              setCodeError(true);
            }
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
    else {
      setCodeErrorMessage(t("register.errors.invalidCode"));
      setCodeError(true);
    }
  }

  useEffect(() => {
    setProgress(2)
  })

  const verifKeyDown = (e: any) => {
    Utils.verifKeyDown(e)
  };

  const verifKeyUp = (e: any) => {
    Utils.verifKeyUp(e, confirmCode)
  }

  if (user === null) {
    return <Navigate to="/login" />
  }

  if (user.register_step < 2) {
    return <Navigate to="/dashboard/register" />
  }

  return (
    <div className="register-content">
      <div className="phone-left-container">
        <h1 className="title">{t('register.phone.verification')}</h1>
        {user.is_number_verified
          ? <>
            <span className="subtitle">{t("register.phone.phoneAlreadyVerified", { phoneNumber: identificationInfo.phone_number })}</span>
            <div className="verified-container">
              <div className="verified-icon">
                <FaCheck className="icon" />
              </div>
            </div>
          </>
          : <>
            <span className="subtitle">{t("register.phone.phoneVerification", { phoneNumber: identificationInfo.phone_number })}</span>
            <div className="code-input">
              <label htmlFor="0">{t("register.code")}*</label>
              <div className="code-grid">
                <input type="number" className="input-code-grid" id="0" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoFocus autoComplete="off" maxLength={1} />
                <input type="number" className="input-code-grid" id="1" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
                <input type="number" className="input-code-grid" id="2" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
                <input type="number" className="input-code-grid" id="3" onKeyUp={(e) => verifKeyUp(e)} onKeyDown={(e) => verifKeyDown(e)} onChange={(e) => setCodeError(false)} autoComplete="off" maxLength={1} />
              </div>
              <div className="code-input-error-message">
                {
                  codeErrorMessage !== undefined && <span>{codeErrorMessage}</span>
                }
              </div>
            </div>
            <div className="confirm-code">
              <div className="resend-code"><Link to="/dashboard/register/code/resend">{t("register.resendCode")}</Link></div>
              <ButtonPrimary onClick={confirmCode} children={t("register.confirm")} id="confirm-btn" className='signup-primary-btn' isLoading={isLoading}></ButtonPrimary>
            </div>
          </>
        }
      </div>
      <div className="phone-right-container">
        {user.is_number_verified &&
          <div className="row">
            <BackButton
              to="/dashboard/register/identification"
              children={t("register.back")}
              className="back-button"
              icon={<FaArrowLeft className="icon" />}
            />

            <ButtonPrimary onClick={confirmCode} children={t("register.nextButton")} isLoading={isLoading} />
          </div>
        }
      </div>
    </div>
  )
}