import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../Input/Input';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { BackButton } from '../../Buttons/BackButton';
import { FaArrowLeft } from 'react-icons/fa';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';

export const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const navigate = useNavigate()

  const resetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    api.post(`/api/user/resend/recover`, {
      "email": email
    }).then(
      (res) => {
        navigate("/login/")
      }
    ).catch(
      (err) => {
        setEmailError(true)
      }
    )

    // TODO: show success warning and redirect to success page/login page
  }

  return (
    <>
      <h1 className="title">{t('logIn.resetPassword.title')}</h1>

      <span className='subtitle'>{t('logIn.resetPassword.description')}</span>

      <form onSubmit={resetPassword}>

        <Input
          label={t("logIn.email")}
          id="email"
          type="email"
          value={email}
          required={true}
          onChange={(e) => { setEmail(e.target.value); setEmailError(false); }}
          onInput={(e: any) => { setEmail(e.target.value); }}
          placeholder={t('logIn.resetPassword.emailPlaceholder')}
          error={emailError}
          errorMessage={t('logIn.resetPassword.emailMessageError')}
        />

        <div className="create-account reset-password-submit-container">
          <ButtonPrimary type="submit" children={t('logIn.resetPassword.resetButton')} className='signup-primary-btn' isLoading={isLoading} />
          <BackButton
            to=".."
            children={t("register.back")}
            className="back-button"
            icon={<FaArrowLeft className="icon" />}
          />
        </div>
      </form>
    </>
  );
};  