import { useTranslation } from "react-i18next"
import "./InvestmentDetailsList.sass"

interface DetailsProps {
  title: string,
  description: string,
  investment_type: number | null,
  term: string | undefined, // Changed to amount of months instead of a date
  category: string | undefined,
  desired_capture: string | number,
  invested_value: string | number,
  min_capture: string | number
  projected_profitability: string | undefined,
}

export const InvestmentDetailsList = ({ title, description, investment_type, term, category, desired_capture, invested_value, min_capture, projected_profitability }: DetailsProps) => {

  const { t } = useTranslation()

  return (
    <div className="details-content">
      <div className="information-container">
        <div className="title-container">
          <h2 className="title">{t("dashboard.investments.details.details")}</h2>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.title")}:</div>
          <div className="value-col">{title}</div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.description")}:</div>
          <div className="value-col">{description}</div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.investment_type")}:</div>
          <div className="value-col">{investment_type}</div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.term")}:</div>
          <div className="value-col">
            {term === undefined ? "" : term}
            {/* <span>04/2023</span> */}
          </div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.category")}:</div>
          <div className="value-col">{category === undefined ? "" : category}</div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.desired_capture")}:</div>
          <div className="value-col">{desired_capture}</div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.invested")}:</div>
          <div className="value-col">
            {invested_value}
            <span>Você possui {(parseInt(invested_value.toString()) * 100 / parseInt(desired_capture.toString())).toFixed(2)}% do investimento</span>
          </div>
        </div>
        <div className="row-bloc">
          <div className="key-col">{t("dashboard.investments.details.rentability")}:</div>
          <div className="value-col">{projected_profitability === undefined ? "" : projected_profitability}</div>
        </div>
      </div>
    </div>
  )
}