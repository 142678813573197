import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import { KeyboardEvent } from "react";

interface SingleProps {
  inpsFormatCurrency: Array<HTMLInputElement>
}

export abstract class Utils {

  static single: SingleProps = {
    inpsFormatCurrency: []
  };

  /**
   * Method to verify keyDown input event
   * @param e Input event
   * @returns void
   */
  static verifKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if ((e.ctrlKey && e.key === "v")) {
      return
    }

    const input = e.target as HTMLInputElement;
    const nextInput = document.getElementById(`${parseInt(input.id) + 1}`);

    if (e.keyCode === 9) {
      nextInput?.focus();
    }

    // If the characters isn't a number, backspace or enter, or the field is already filled, the event is canceled.  
    if (!((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8) || (input.value !== "" && e.keyCode !== 8)) {
      e.preventDefault();
      return;
    }

    if (e.key !== "" && e.keyCode !== 8) {
      nextInput?.focus();
      e.preventDefault();
      input.value = e.key;
    }

    if (e.keyCode === 8) {
      input.value = ''
      if (parseInt(input.id) > 0) {
        const prevInput = (document.getElementById(`${parseInt(input.id) - 1}`) as HTMLInputElement);
        prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
        prevInput?.focus();
      }
    }
  };
  /**
   * Method to verify keyUp input event and focus in the confirm-btn
   * @param e Input event
   * @param callback Callback function to invoke
   */
  static verifKeyUp(e: KeyboardEvent<HTMLInputElement>, callback: () => void) {
    const input = e.target as HTMLInputElement;

    if (input.id === "3" && e.keyCode !== 8 && ((e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || (e.keyCode >= 96 && e.keyCode <= 105)) && input.value !== "") {
      document.getElementById('confirm-btn')?.focus();
      callback()
    }
  }

  /**
   * Método para retornar o valor do mês formatado
   * @param {string} date Data que vai ser formatada. Aceita apenas formato internacional "YYYY, MM, DD".
   * @return {string} Retorna apenas o mês da data fornecida.
   */
  static formatDate(date: string): string {
    return format(parseISO(date), "MMMM", { locale: pt });
  }

  /**
  * Formata moeda em um input no evento keydown
  *
  * @author Samuel Silva de Ataídes
  * @since 2022-09-28
  *
  * @param {KeyboardEvent<HTMLInputElement>} e
  * @param {HTMLInputElement} inp
  * @param {number} casas
  *
  * @return void
  */
  static formatCurrency(e: KeyboardEvent<HTMLInputElement>, inp: any, casas: number) {
    if (this.isNumeric(e)) {
      if (casas === 0) {
        this.single.inpsFormatCurrency = this.single.inpsFormatCurrency || [];

        /*Para não setar o evento duas vezes*/
        for (var i = 0; i < this.single.inpsFormatCurrency.length; i++) {
          if (this.single.inpsFormatCurrency[i] === inp) {
            return;
          }
        }

        this.single.inpsFormatCurrency.push(inp);
      } else {
        if (inp.maxLength === inp.value.length) {
          this.cancelEvent(e);
          return;
        }

        var temp, aux;
        var textSelect = Utils.getSelection(inp);

        if (
          inp.value === "" ||
          (textSelect.start === 0 &&
            textSelect.end === inp.value.length &&
            inp.value !== "")
        ) {
          inp.value = "0,";
        }

        if (
          inp.value.length !== 0 &&
          inp.value.substr(inp.value.indexOf(",") + 1, casas).length === casas
        ) {
          temp = inp.value.replace(/\./gi, "").split(",");
          if (casas === 1) {
            inp.value =
              Utils.realFormat(parseInt(temp[0] + temp[1]).toString()) + ",";
          } else if (inp.value.length === casas) {
            inp.value = inp.value.substr(0, 1) + "," + inp.value.substr(1);
          } else {
            aux =
              temp[0] + parseInt(temp[1].toString().substr(0, 1)).toString();
            aux = parseFloat(aux);
            inp.value =
              Utils.realFormat(aux.toString()) +
              "," +
              temp[1].toString().substr(1);
          }
        }
      }
    } else if (e.keyCode === 8 && inp.value.length > casas && casas) {
      var sum,
        num = inp.value.replace(/\./gi, "").replace(",", "");
      sum = casas + 1;
      if (num.length <= sum) {
        inp.value = "0," + num;
      } else {
        inp.value = Utils.realFormat(
          num.substr(0, num.length - sum) + "." + num.substr(num.length - sum)
        );
      }
    } else if (!this.teclasEsp(e)) {
      this.cancelEvent(e);
    }
  }

  /**
   * Transforma um numero double para real
   *
   * value = numero float a ser transformado
   * casas = numero de casas a ser considerado
   * roundNumber = se o numero deve ser arredondado
   *
   * @author Samuel Silva de Ataídes
   * @author Airton Martins Ferreira
   * @since 2022-09-28
   *
   * @param {Number} value
   * @param {Number} casas
   * @param {Boolean} roundNumber
   * @param {String} decimalDot
   * @param {String} thousandDot
   *
   * @return string
   */
  static realFormat(
    value: number | any,
    casas?: number,
    roundNumber: boolean = false,
    decimalDot: string = ",",
    thousandDot: string = "."
  ) {
    if (roundNumber && casas !== undefined) {
      value = Utils.round(value, casas);
    }

    var str = value.toString();
    var arr = str.split(".");
    var saida = "";
    var sinal = "";

    if (/-/.test(arr[0])) {
      /*Quando o numero é negativo*/
      sinal = arr[0].substr(0, 1);
      arr[0] = arr[0].substr(1);
    }

    if (arr[0].length / 3 > 0) {
      str = arr[0];
      while (str.length > 3) {
        saida = thousandDot + str.substr(str.length - 3, 3) + saida;
        str = str.substr(0, str.length - 3);
      }

      saida = sinal + str + saida;
    } else {
      saida = sinal + arr[0];
    }

    saida = casas && casas > 0 && (saida === "" || saida === "-") ? saida + 0 : saida;

    if (casas !== undefined) {
      /*Quando passa o parametro de casas*/
      arr[1] = arr[1] || "";

      if (arr[1].length > casas) {
        arr[1] = arr[1].substr(0, casas);
      }

      while (arr[1].length < casas) {
        arr[1] += "0";
      }

      saida += casas > 0 ? decimalDot + arr[1] : "";
    } else {
      saida += typeof arr[1] === "string" ? decimalDot + arr[1] : "";
    }
    return saida;
  }
  /**
   * Cancela Eventos keyDown
   *
   * @author Samuel Silva de Ataídes
   * @author Airton Martins Ferreira
   * @since 2022-09-28
   *
   * @param {Event} e
   *
   * @return void
   */
  static cancelEvent(e: Event | any) {
    if (e.stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      e.keyCode = 8;
      e.cancelBubble = true;
      e.returnValue = false;
    }
  }
  /**
   * Verifica se a tecla apertada foi numero ou não
   *
   * @author Samuel Silva de Ataídes
   * @author Airton Martins Ferreira
   * @since 2022-09-28
   * @param {KeyboardEvent} e
   * @return {Boolean}
   */
  static isNumeric(e: KeyboardEvent): boolean {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Verifica se as teclas digitadas são especiais Ex: Del, Backspace
   *
   * @author Samuel Silva de Ataídes
   * @author Airton Martins Ferreira
   * @since 2022-09-28
   * @param {Event} e
   * @return {Boolean}
   */
  static teclasEsp(e: KeyboardEvent): boolean {
    if (
      e.keyCode === 145 /*Scroll Lock*/ ||
      e.keyCode === 144 /*Num Lock*/ ||
      (e.keyCode > 111 && e.keyCode < 124) /*F's*/ ||
      e.keyCode === 93 /*Menu */ ||
      e.keyCode === 91 /*Win Key*/ ||
      e.keyCode === 46 /*Delete */ ||
      e.keyCode === 45 /*Insert */ ||
      e.keyCode === 44 /*Print Screen*/ ||
      e.keyCode === 40 /*Down*/ ||
      e.keyCode === 39 /*Right*/ ||
      e.keyCode === 38 /*Up*/ ||
      e.keyCode === 37 /*Left*/ ||
      e.keyCode === 36 /*Home */ ||
      e.keyCode === 35 /*End*/ ||
      e.keyCode === 34 /*Page Down*/ ||
      e.keyCode === 33 /*Page Up*/ ||
      e.keyCode === 27 /*Esc*/ ||
      e.keyCode === 20 /*Caps Lock*/ ||
      e.keyCode === 19 /*Pause Break*/ ||
      e.keyCode === 18 /*Alt*/ ||
      e.keyCode === 17 /*Ctrl*/ ||
      e.keyCode === 16 /*Shift*/ ||
      e.keyCode === 13 /*Enter*/ ||
      e.keyCode === 9 /*Tab*/ ||
      e.keyCode === 8 /*Backspace*/
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Retorna aonde está selecionado em um input
   *
   * @param {DomInputElement} inputBox
   * @since 2022-09-28
   * @author Samuel Silva de Ataídes
   * @author Airton Martins Ferreira
   * @return {Object}
   */
  static getSelection(inputBox: any): { start: number, end: number } {
    if ("selectionStart" in inputBox) {
      return { start: inputBox.selectionStart, end: inputBox.selectionEnd };
    }

    /*IE*/
    var selection = inputBox.createTextRange();

    var before = inputBox.createTextRange();
    before.collapse(true);
    before.setEndPoint("EndToStart", selection);

    var beforeLength = before.text.length;
    var selLength = selection.text.length;

    return { start: beforeLength, end: beforeLength + selLength };
  }

  /**
   * Round a number
   *
   * @param {Number} number
   * @param {Number} decimals
   * @returns {Number}
   */
  static round(number: number, decimals: number): number {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }

  /**
   * Verify if the string is a valid CPF
   *
   * @param String cpf
   * @returns {Boolean}
   */
  static isCpf(cpf: string, invalidos: boolean = true): boolean {

    var strCPF = this.onlyNumbers(cpf);

    var Soma = 0;
    var Resto;

    /*CPF invalidos*/
    if (invalidos && /(^0{11}$)|(^1{11}$)|(^2{11}$)|(^3{11}$)|(^4{11}$)|(^5{11}$)|(^6{11}$)|(^7{11}$)|(^8{11}$)|(^9{11}$)/.test(strCPF)) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }

    Resto = Soma % 11;
    Resto = (Resto < 2) ? 0 : 11 - Resto;

    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      return false;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }

    Resto = Soma % 11;
    Resto = (Resto < 2) ? 0 : 11 - Resto;

    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      return false;
    }

    return true;
  }

  /**
   * Verify if the string is a valid CNPJ
   *
   * @param {String} cnpj
   * @returns {Boolean}
   */
  static isCnpj(cnpj: string): boolean {

    cnpj = this.onlyNumbers(cnpj);

    if (cnpj.length !== 14) {
      return false;
    }

    /*Elimina CNPJs invalidos conhecidos*/
    /*
    if (invalidos && /(^0{14}$)|(^1{14}$)|(^2{14}$)|(^3{14}$)|(^4{14}$)|(^5{14}$)|(^6{14}$)|(^7{14}$)|(^8{14}$)|(^9{14}$)/.test(cnpj)){
      return false;
    }
    */

    /*Valida DVs*/
    let tamanho = 12;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);

    let soma = 0;
    let pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      pos = (pos < 2) ? 9 : pos;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);

    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      pos = (pos < 2) ? 9 : pos;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false;
    }

    return true;
  }

  /**
   * Format the value to CPF format
   *
   * @param {String} cpf
   * @returns {String}
   */
  static formatCpf(cpf: string): string {
    if (cpf.length === 11) {
      return cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9, 2);
    }

    return cpf
  }

  /**
   * Format the value to CNPJ format
   *
   * @param {String} cnpj
   * @returns {String}
   */
  static formatCnpj(cnpj: string): string {
    if (cnpj.length === 14) {
      return cnpj.substr(0, 2) + "." + cnpj.substr(2, 3) + "." + cnpj.substr(5, 3) + "/" + cnpj.substr(8, 4) + "-" + cnpj.substr(12);
    }

    return cnpj
  }

  /**
   * Return only number of a string given 
   *
   * @param {String} str
   * @returns {String}
   */
  static onlyNumbers(str: string): string {
    if (str !== null) {
      return str.replace(/[^0-9]/g, '');
    }

    return str;
  }

  /**
   * Remove white spaces
   *
   * @param String str
   * @returns String
   */
  static trim(str: string) {
    if (typeof str === "string") {
      return str.replace(/^\s+|\s+$/g, "");
    }

    return str;
  }

  /**
   * Select the text of a input element
   *
   * @param {DomInputElement} inp
   * @param {Number} start
   * @param {Number} end
   */
  selectText(inp: HTMLInputElement, start: number, end: number) {
    var v = inp.value;
    var doFocus = false;
    if (v.length > 0) {
      start = start === undefined ? 0 : start;
      end = end === undefined ? v.length : end;
      var d: any = inp;
      if (d.setSelectionRange) {
        d.setSelectionRange(start, end);
      }
      else if (d.createTextRange) {
        var range = d.createTextRange();
        range.moveStart('character', start);
        range.moveEnd('character', end - v.length);
        range.select();
      }
      doFocus = true;
    }
    else {
      doFocus = true;
    }

    if (doFocus) {
      inp.focus();
    }
  }

  /**
   * Parse a formated (or not) number string and return a float 
   *
   * @param {String} val
   * @returns {Number}
   */
  static str2float(val: string | null, type: "br" | null): number {
    if (val === null || this.trim(val) === "") {
      return 0.0;
    }
    else {
      val = String(val);
      if (/,/.test(val) || type === 'br') {/*Esta no formato brasileiro*/
        return parseFloat(val.replace(/[.]/g, '').replace(/[,]/g, '.'))
      }
      else {/*Esta no formato americano*/
        return parseFloat(val);
      }
    }
  }

  /**
   * Get a formatted (or not) number string and return a integer 
   *
   * @param {String} val
   * @returns {Number}
   */
  static str2int(val: string, type: "br" | null): number {
    if (val === null || this.trim(val) === "") {
      return 0;
    }
    else {
      val = String(val);
      if (/,/.test(val) || type === 'br') {/*Esta no formato brasileiro*/
        return parseInt(val.replace(/[.]/g, '').replace(/[,]/g, '.'))
      }
      else {/*Esta no formato americano*/
        return parseInt(val);
      }
    }
  }

  /**
   * Get a formatted date string and return a JS Date
   *
   * @param {String} val "DD/MM/YYYY h:m:s"
   * @returns {Date}
   */
  static getDate(val: string): Date | string {
    if (val === null || val === undefined || this.trim(val) === "") {
      return val;
    }
    else {

      var temp: Array<string> | string = val.split(/[ ]|T/), horas = ['0', '0', '0'];
      if (temp.length > 1) {
        if (temp[1].length > 8) {
          temp[1] = temp[1].substr(0, 8);
        }
        horas = temp[1].split(':');
      }
      temp = temp[0];
      var data = temp.split('-');

      if (data.length === 1) {
        temp = data[0].split('/');

        if (temp.length === 1 && temp[0].length === 8) {/*PARA PEGAR AS DATAS Ymd*/
          data[0] = temp[0].substr(0, 4);
          data[1] = temp[0].substr(4, 2);
          data[2] = temp[0].substr(6, 2);
        }
        else {
          data[0] = temp[2];
          data[1] = temp[1];
          data[2] = temp[0];
        }
      }

      return new Date(parseInt(data[0]), parseInt(data[1]) - 1, parseInt(data[2]), parseInt(horas[0]), parseInt(horas[1]), parseInt(horas[2]));
    }
  }

  /**
   * Return a difference between two dates in months. Use valid date or your browser will crash.
   *
   * @param {Date} d1
   * @param {Date} d2
   * @returns Number
   */
  static diffInMonths(d1: Date, d2: Date) {

    let date1 = parseInt(d1.getFullYear() + ("00" + (d1.getMonth() + 1)).slice(-2));
    let date2 = parseInt(d2.getFullYear() + ("00" + (d2.getMonth() + 1)).slice(-2));

    let diff = 0, sum = date1 > date2 ? -1 : 1;

    while (date1 !== date2) {
      diff += sum;
      date1 += sum;
      if (date1.toString().toString().substr(-2) === '13') {
        date1 = parseInt((parseInt(date1.toString().substr(0, 4)) + sum).toString() + '01');
      }
      else if (date1.toString().toString().substr(-2) === '00') {
        date1 = parseInt((parseInt(date1.toString().substr(0, 4)) + sum).toString() + '12');
      }
    }

    return diff;
  }

  /**
   * Return the text without accents
   * 
   * @param {String} str 
   * @returns {String}
   */
  static retiraAcentos(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }


  /**
   * Return a difference between two dates in months. Use valid date or your browser will crash.
   *
   * @param {Date} d1
   * @param {Date} d2
   * @returns Number
   */
  static diffInDays(d1: Date, d2: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const date1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())
    const date2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate())

    if (date1 <= date2) {
      return Math.floor((date1 - date2) / _MS_PER_DAY)
    }

    return Math.floor((date2 - date1) / _MS_PER_DAY)
  }
}