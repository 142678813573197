import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Description.sass";

interface DescriptionProps {
  /**
   * Investment description
   */
  description: string
};

/**
 * Investment description
 * 
 * @param description string 
 * @author Samuel Silva de Ataídes
 * @since 2022-09-19
 */
export const Description = ({ description }: DescriptionProps) => {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const text = description;

  return (
    <div className="inv_description">
      <h2>{t("investmentPage.details.description.title")}</h2>
      <span>{showMore ? `${text} ` : `${text.substring(0, 250)}`}
        <button className={`show-more ${text.length < 250 ? "hidden" : ""}`} onClick={() => setShowMore(!showMore)}>
          {!showMore ? `... ${t("investmentPage.details.description.showMore")}` : t("investmentPage.details.description.showLess")}
        </button>
      </span>
    </div>
  );
};